.yours-ain-group .slider-page img {
    width: 100%;
    height: 400px;
    margin-top: 40px;
}

.your-main-group .the-secret {
    display: inline-block;
    margin-top: 27px;
    width: 100%;
}

.your-main-group .the-secret h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2D3134;
    padding-right: 10px;
}

.your-main-group .the-secret p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #82A3A1;
    margin: 5px 0;
    margin-left: 0px;
}

/* .your-main-group .chat-group {
    justify-content: flex-end;
    gap: 5px;
    display: flex;
} */
/* .your-main-group .chat-group button {
 
    height: 40px;
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 100px;
    font-family: 'Inter';
    padding: 10px 8px;
    font-weight: 600;
    font-size:13px;
    line-height: 100%;
    color: #FFFFFF;
    position: relative;
    top: 0px;
} */

.your-main-group .chat-box {
    margin-top: 16px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #BFBCB8;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 6px 6px 0px 0px;
}

.your-main-group .chate-inner-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.your-main-group .chat-ima-div img {
    width: 44px;
    height: 44px;
}

.your-main-group .chat-ima-div .image-diiv {
    width: 13px;
    height: 13px;
    background: green;
    border-radius: 50px;
    position: relative;
    bottom: 13px;
    left: 31px;
}

.your-main-group .chate-inner-box .thre-point h6 {
    width: 17px;
    height: 16px;
    background: #F19357;
    border-radius: 50px;
    color: white;
    position: relative;
    top: 18px;
    right: 37px;
    padding-right: 1px;
}

.your-main-group .chate-inner-box .thre-point span i {
    font-size: 20px;
}

.your-main-group .online-div {
    display: flex;
    padding: 0px 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 96%;
    height: 46px;
    background: #F3F3F0;
    border-radius: 6px;
    margin-left: 12px;
}

.your-main-group .online-div span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin-right: 14px;
    color: #2d3134;
    display: flex;
    align-items: center;
    justify-content: center;
}

.your-main-group .online-div ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.your-main-group .online-div ul li {
    margin-right: 10px;
    width: auto;
    float: left;
    margin-top: 10px;

}

.your-main-group .online-div ul li a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
    background: #f19357;
    border-radius: 100px;
    padding: 3px 33px 5px 10px;
    text-decoration: none;
    display: block;
    width: 126px;
    height: 26px;
}

.your-main-group .online-div ul li a i {
    margin-left: 8px;
    font-weight: 400;
}

.chating-all ul li {
    display: inline-block;
    clear: both;
    margin: 15px 15px 15px 15px;
    width: calc(100% - 25px);
    font-size: 0.9em;
    width: 100%;
}

.chating-all ul li.sent img {
    margin: 35px 8px 0 0;
    width: 32px;
    /* border-radius: 50%; */
    float: left;
}


.chating-all ul li.sent p .message-time {
    justify-content: end;
}

.chating-all ul li.replies .delete-massage {
    position: absolute;
}

.chating-all ul li.replies {
    display: inline-block;
    clear: both;
    margin: 15px 25px 15px 15px;
    width: calc(100% - 13px) !important;
    font-size: 0.9em;
    position: relative;
}

.chating-all ul li.replies img {
    margin: 8px 8px 0px 0px;
    width: 32px;
    /* border-radius: 50%; */
    float: right;
}

.chating-all ul li.sent p {
    max-width: 73%;
    padding: 5px 13px;
    margin-bottom: 0px;
    font-family: "Inter";
    text-align: left;
    background: #dce8ff;
    border-radius: 10px 10px 10px 0px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    /* line-height: 130%; */
    color: #000000;
    margin-right: 10px;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.chating-all ul li.replies p {
    float: right;
    max-width: 73%;
    text-align: left;
    margin-bottom: 0px;
    padding: 5px 13px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    display: flex;
    color: #ffffff;
    /* line-height: 150%; */
    margin-right: 10px;
    background: #265472;
    border-radius: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.chating-all ul li.sent img {
    margin: 6px 8px 0 0;
    width: 32px;
    /* border-radius: 50%; */
    float: left;
}

.chating-all ul li img.chatimagesfull {
    width: 140px !important;
    height: 120px;
    border-radius: 0 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0px;
    object-fit: contain;
}

.chating-all ul li.sentt img {
    margin: 35px 8px 0 0;
    width: 32px;
    border-radius: 50%;
    float: left;
}

.chating-all ul li.sentt p {
    max-width: 45%;
    margin-bottom: 0px;
    font-family: "Inter";
    text-align: left;
    background: #dce8ff;
    border-radius: 10px 10px 10px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    margin-right: 10px;
    float: left;
}

.chating-all ul li.sentt p span {
    bottom: 7px;
    position: relative;
    left: 10px;
}

.chating-all .borderbottom {
    border: 1px solid #D9D9D9;
}

.chating-all h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #D9D9D9;
    text-align: center;
    width: 100%;
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px !important;
    padding-bottom: 0;
}

.chat-message-input {
    width: 100%;
    height: 68px;
    background-color: #E8F0F4;
    padding: 7px;
}

.chat-message-input .chat-message-input-pr {
    width: 85%;
    position: relative;
    float: left;
}

.chat-message-input .chat-message-input-pr input.form-control {
    border: none;
    background: #ffffff;
    border-radius: 30px;
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    padding: 5px 110px 0 10px;
    align-items: center;
    color: #949698;
}

.chat-message-input .chat-message-input-pr .chat-button-area {
    position: absolute;
    padding: 2px 12px 10px 16px;
    border-left: 1px solid #BFBCB8;
    top: 0px;
    right: 0px;
    display: flex;
    gap: 10px;
}

.chat-message-input .chat-message-input-pr .attech,
.chat-message-input .chat-message-input-pr .fa.fa-smile-o {
    padding-top: 13px;
}

.chat-message-input .chat-message-input-pr .chat-button-area i {
    margin-right: 10px;
    font-size: 22px;
}

.chat-message-input .chat-messages-input-pr {
    width: auto;
    float: right;
    margin-top: 8px
}

.chat-message-input .chat-messages-input-pr button {
    background-color: transparent;
    border: none;
}

.chat-message-input .chat-messages-input-pr i {
    background: #f19357;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 21px;
    transform: rotate(45deg);
    text-align: center;
    line-height: 40px;
}


.group-member-div .rating-div h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #5B5F62;
    text-align: left;
}

.group-member-div .review-div {
    margin-top: 5px;
    float: left;
    margin-bottom: 15px;
}

.group-member-div .review-button button {
    width: 100%;
    border: none;
    background: #F19357;
    border-radius: 8px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    height: 56px;
    padding: 10px auto;
    margin-top: 10px;
}

.group-member-div .group-mem-div .group-mem-inner-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 26px;
}

.group-member-div .group-mem-div .member-request .group-mem-inner-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 26px;
}

.group-member-div .group-mem-div h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #5B5F62;
}

.group-member-div .group-mem-div p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #5B5F62;
}

/* 
.group-member-div .group-mem-div input {
    width: 100%;
    height: 29px;
    padding: 10px;
    font-family: 'Inter';
    margin-top: 11px;
} */

.group-mem-div ul {
    margin-top: 15px;
}

.group-mem-div ul li {
    margin-top: 20px;
}

.group-mem-div .search-bar-inner .search-image-div img {
    width: 35px;
    height: 35px;
}

.group-mem-div .search-bar-inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.group-mem-div .view-chat {
    background-color: #fff;
    width: 100%;
    max-width: 168px;
    position: absolute;
    right: 53px;
    border-radius: 8px;

}

.group-mem-div .view-chat h1 {
    border-bottom: 1px solid grey;
    height: 34px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2D3134;
    padding: 10px;
    text-align: left;
    cursor: pointer;
}

.group-mem-div .view-chat h1:hover {
    /*   color: white;
    background-color: #265472;
    border-radius: 8px; */
}

.group-mem-div .search-bar-inner .search-image-div {
    display: flex;
}

.group-mem-div .search-bar-inner .search-image-div .heading-div {
    text-align: left;
    margin-left: 10px;
}

.group-mem-div .search-bar-inner .search-image-div .heading-div h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #5B5F62;
}

.group-mem-div .search-bar-inner .search-image-div .heading-div p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #949698;
}

.group-mem-div .search-bar-inner i {
    margin-left: 150px;
    cursor: pointer;
}

.group-mem-div button {
    width: 100%;
    height: 47px;
    border: none;
    margin-top: 15px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #2D3134;

}

.member-request .member-request-inner .member-request-flex {
    display: flex;
}

.member-request .member-request-inner .member-request-flex img {
    width: 40px;
    height: 40px;
    float: left;
}

.member-request .member-request-inner .member-request-flex h4 {
    position: relative;
    bottom: 10px;
    left: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 60px;
    color: #000000;
}

.member-request .member-request-inner {
    display: flex;
    justify-content: space-between;
    flex: auto;
    flex-wrap: wrap;
}

.member-request .member-request-inner .member-request-icon {
    display: flex;
    margin-top: 12px;
}

.member-request .member-request-inner .member-request-icon span {
    background: #BFBCB8;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 4px;
    padding: 0;
    line-height: 20px;
    text-align: center;
}

.member-request .member-request-inner .member-request-icon span i {
    align-items: center;
    color: #fff;
    margin-right: 1px;
}

.member-request .member-request-inner .member-request-icon .cross-icons {
    background: #969696;
}

.member-request .member-request-inner .member-request-icon .check-icons {
    background: #265472;

}

.main-chat {
    margin-top: 36px;
}

.your-main-group .thre-point {
    position: relative;
}

.your-main-group .thre-point .dotspop {
    display: none;
}

.your-main-group .thre-point .three-ellipis:hover .dotspop {
    display: block;
}

.your-main-group .thre-point .dotspop a {
    display: block;
    text-align: left;
    text-decoration: none;
}




@media screen and (max-width: 480px) {
    .your-main-group .the-secret h1 {
        font-size: 18px;

    }

    .your-main-group .the-secret p {
        margin-top: 8px;
        margin-left: 6px;
        font-size: 10px;
    }

    /* .your-main-group .chat-group button {
        width: 122px;
        height: 36px;
        font-size: 15px;
    } */

    .your-main-group h2 {
        font-size: 16px;
    }

    .your-main-group .online-div ul li a {
        font-size: 11px;
        padding: 3px 28px 5px 10px;
        width: 103px;

    }
}