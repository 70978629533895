.fulldecartion-div {
    width: 100%;
    float: left;
    margin-top: 50px;
}

.pop-up-container .input-popup2 input[type="radio"] {
    appearance: auto;
    position: relative;
    top: 5px;
    left: 0px;

}
.buttonprevDeletnew .preview{
    background-color: #f19357;
}
.buttonprevDeletnew .download{
    background-color: #265472;
}
.buttonprevDeletnew .download,
.buttonprevDeletnew .preview{
    margin-left: 10px;
    color: white;
    border: none;
    padding: 5px 12px;
    border-radius: 8px;
    font-family:'Inter';
}

.fulldescription .pop-up-container {
    display: table;
}

.fulldescription .pop-up-container .bookmoniterdivbox {
    max-height: 500px;
    overflow-y: auto;
}

.img_preview {
    margin-left: 100px;
}

.image_pre {
    width: 30px;
    height: 30px;
    margin-left: 4px;
}

.fulldecartion-div .fulldecartion-div-lt {
    width: 65%;
    float: left;
    text-align: left;
}

.fulldecartion-div .fulldecartion-div-rt {
    width: 30%;
    float: right;
}

.fulldecartion-div .fulldecartion-div-lt h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #2D3134;
	word-wrap: break-word;
}

.fulldecartionrightImgBox {
    width: 315px;
    height: 315px;
    /*  border-radius: 50%; */
    overflow: hidden;
    text-align: center;
}

.fulldecartionrightImgBox img {
    border-radius: 0;
}

.fulldecartion-div .fulldecartion-div-rt img {
    height: 100%;
    /*  object-fit: cover;
    aspect-ratio: 1/1; */
}

.fulldecartion-div .fulldecartion-div-lt h1 span {
    display: block;
}

.fulldecartion-div .fulldecartion-div-lt p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #5B5F62;
    margin-top: 10px;
    margin-bottom: 12px;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop {
    display: inline-block;
    margin-bottom: 25px;
    width: 100%;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop ul li {
    margin-right: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #265472;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop ul li span {
    margin-right: 8px;
    line-height: 36px;
    display: inline-block;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop ul li span:nth-of-type(2) {
    padding-top: 5px;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop ul li:nth-child(2) {
    color: #F19357;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop ul li:nth-child(3) {
    color: #AAC0CE;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop ul li:nth-child(4) {
    color: #F0B285;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop ul li span img {
    width: 24px;
    border-radius: 0;
    margin-top: 4px;
}

.fulldecartion-div .fulldecartion-div-lt .hearicontop ul li:nth-child(2) span img {
    width: 24px;
    border-radius: 0;
}

.fulldecartion-div .fulldecartion-div-lt .ratesiconding p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #5B5F62;
}

.fulldecartion-div .fulldecartion-div-lt .ratesiconding p i {
    color: #F19357;
}

.fulldecartion-div .fulldecartion-div-lt .ratesiconding p span {
    margin-right: 8px;
}

.fulldecartion-div .fulldecartion-div-lt .ratesiconding p spam {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    text-decoration-line: underline;
    color: #2D3134;
    cursor: pointer;
}

.fulldecartion-div .fulldecartion-div-lt p span.frients {
    font-weight: 700;
}

.fundingcsspublic {
    margin-top: 20px;
    display: inline-block;
    width: 100%;
}

.publicfunding {
    background: #82A3A1;
    border-radius: 100px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    border-color: #82A3A1;
    padding: 8px 15px;
    border: none;
    margin-right: 15px;
}

.publicfunding span {
    background: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 2px;
    padding: 0;
    line-height: 20px;
}

.publicfunding span i {
    color: #82a3a1;
    font-size: 13px;
    padding: 0;
    margin: 0;
}

.fundingcsspublic .approvedcss {
    display: inline-block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-decoration-line: underline;
    color: #2D3134;
}

.fundingcsspublic .approvedcss span {
    background: #BFBCB8;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 4px;
    padding: 0;
    line-height: 20px;
    text-align: center;
}

.fundingcsspublic .approvedcss span i {
    text-align: center;
    color: #fff;
}

.exampledivlink {
    float: left;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    overflow: hidden;
}

.exampledivlink ul {
    margin: 0;
    padding: 0;
}

.exampledivlink ul li {
    float: left;
    width: 100%;
    margin-right: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
}

.exampledivlink ul li a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    white-space: normal;
    word-wrap: break-word;
}

.aboutresourcesdiv {
    max-width: 100%;
    display: inline-block;
    text-align: left;
    width: 100%;
    margin-top: 32px;
    border-top: 1.5px solid #2D3134;
    padding-top: 20px;
}

.aboutresourcesdiv .aboutresourcesdiv-lt {
    max-width: 100%;
    display: inline-block;
    width: 100%;
}
.full-desupperset h2,
.aboutresourcesdiv .aboutresourcesdiv-lt h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #2D3134;
    margin-bottom: 14px;
}

.gooogle-book h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px !important;
    line-height: 30px;
    color: #2D3134;
    margin-bottom: 14px;
}

.aboutresourcesdiv .aboutresourcesdiv-lt p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #5B5F62;
}

.resourceabouttextdatainfo,
.aboutresourcesdiv .aboutresourcesdiv-lt .resourceabouttextdatainfo {
    max-height: 300px;
    overflow-y: auto;
}

.aboutresourcesdiv-rt {
    max-width: 100%;
    float: left;
    margin-top: 14px;
    margin-bottom: 42px;
}

.aboutresourcesdiv-rt .vieworder-button {
    cursor: pointer;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 212px;
    float: left;
    text-decoration: none;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #2D3134;
    margin-bottom: 10px;
}

.report-button {
    cursor: pointer;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 212px;
    float: left;
    text-decoration: none;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #2D3134;
    margin-bottom: 10px;
}

.accordinstart {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
}

.accordinstart .accordion-item {
    border: none;
}

.accordinstart .accordion-item h2.accordion-header button.accordion-button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    padding-left: 0;
    color: #2D3134;
}

.accordinstart .accordion-item .accordion-body {
    padding: 20px 0px;
}

.accordinstart .accordion-item .accordion-body label.form-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
}

.accordinstart .accordion-item .accordion-body input.form-control {
    border-radius: 14px;
    border: none;
    height: 42px;
    padding: 0px 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #2D3134;
    color: #2D3134;
}

.accordinstart .accordion-item .accordion-body input.form-control:focus {
    outline: none;
    box-shadow: none;
}

.accordinstart .accordion-item .accordion-body input.form-control::-webkit-input-placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #2D3134;
}

.accordinstart .accordion-item .accordion-body input.form-control:-ms-input-placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #2D3134;
}

.accordinstart .accordion-item .accordion-body input.form-control::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #2D3134;
}

.accordinstart .accordion-item h2.accordion-header button.accordion-button:focus {
    outline: none;
    box-shadow: none;
}

.accordinstart .accordion-item h2.accordion-header button.accordion-button:focus::after {
    content: " ";
    background: url(../../assets/Images/downpng.png) center center no-repeat !important;
}

.accordion-button:not(.collapsed)::after {
    background: url(../../assets/Images/downpng.png) center center no-repeat !important;
}

.fulldescription .aboutresourcesdiv .aboutresourcesdiv-lt .accordinstart .accordion-item .accordion-button::after {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAACJSURBVHja7JVBEgARDARN/v/nLE7URoxYly2q3KSbhICqppND0uFxBZwAgJb5FbTlIc8OnG8VduFuinZOYsWKteOIxIopbBmlZUUygncpiko8+KsGq5IZ3CwyK2Hgw4c2k7Dwutbrpmyhvbcj0UB2jewAmA1INAVsS6G7aQtc6Ve4X+b/BY8AAwCOlWAf+7ADMQAAAABJRU5ErkJggg==) center center no-repeat !important;
}


.examplecontent {
    margin-top: 0px;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 0 10px 10px;
    background: #F5F5F5;
    /* color: #AAC0CE; */
}

.examplecontent .examplecontentinner h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
}

.examplecontent .examplecontentinner button.downloadbtn {
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 8px;
    /* font-family: 'effra_trialregular'; */
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    padding: 8px;
    color: #fff;
    text-transform: capitalize;
}

.leavfeedbackreviews .form-group {
    display: block;
    margin-bottom: 15px;
    width: 33%;
    float: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
}

.leavfeedbackreviews .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.leavfeedbackreviews .form-group label {
    position: relative;
    cursor: pointer;
}

.leavfeedbackreviews .form-group label:before {
    content: '';
    appearance: none;
    border-radius: 4px;
    background-color: #F19357;
    border: 2px solid #F19357;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}

.leavfeedbackreviews .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 7px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.leavfeedbackreviews {
    float: left;
    margin-top: 20px;
    width: 100%;
}

.leavfeedbackreviews h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-bottom: 6px;
}

.leavfeedbackreviews .form-group label span {
    margin-left: 4px;
}

.leavfeedbackreviews .form-group label span i {
    color: #f19357;
    font-size: 14px;
}

.leavfeedbackreviews textarea.form-control {
    height: 144px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    padding-top: 14px;
    color: #2D3134;
    border-radius: 14px;
}

.leavfeedbackreviews textarea.form-control:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.reviewssection {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 16px;
    padding: 15px;
}

.reviewssection .reviewssectionlt {
    float: left;
    width: 50%;
}

.reviewssection .reviewssectionlt h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
}

.reviewssection .reviewssectionlt h2 span {
    font-weight: 400;
    font-size: 16px;
    margin-left: 10px;
}

.reviewssection .reviewssectionlt h2 span i {
    color: #f19357;
    font-size: 14px;
}

.reviewssection .reviewssectionrtcv {
    float: left;
    width: 100%;
    margin-top: 20px;
    border-top: 1px solid #BFBCB8;
    padding-top: 15px;
}

.reviewssection .reviewssectionrtcv .reviewssectionrtimg {
    width: 8%;
    float: left;
}

.reviewssection .reviewssectionrtcv .reviewssectionrtcontet {
    float: left;
    width: 90%;
    margin-left: 5px;
}

.reviewssection .reviewssectionrtcv .reviewssectionrtcontet h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 8px;
}

.reviewssection .reviewssectionrtcv .reviewssectionrtcontet .reviewlistcnt {
    width: 100%;
    float: left;
}

.reviewssection .reviewssectionrtcv .reviewssectionrtcontet .reviewlistcnt ul {
    /* display: grid;
	 grid-template-columns: auto auto auto auto ; */
    /* font-family: 'effra_trialregular'; */
    font-family: 'Rubik', sans-serif;
    display: inline-block;
}

.reviewssection .reviewssectionrtcv .reviewssectionrtcontet .reviewlistcnt ul li {
    float: left;
    margin-right: 10px;
    list-style: none;
}

.reviewssection .reviewssectionrtcv .reviewssectionrtcontet .reviewlistcnt ul li span {
    margin-right: 4px;
}

.reviewssection .reviewssectionrtcv .reviewssectionrtcontet p {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #65676B;
    margin-top: 4px;
    display: inline-block;
}

.reviewssection .reviewssectionrtcv .reviewssectionrtcontet .reviewlistcnt ul li spam i {
    color: #f19357;
    font-size: 13px;
    margin-right: 2px;
}

.reviewssection .shwmorebttun {
    float: left;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #2D3134;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 10px;
    cursor: pointer;
}

.reviewssection .wrapper {
    display: inline-block;
    width: 100%;
}

.reviewssection label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #2D3134;
}

.reviewssection select {
    padding: 10px;
    width: 50%;
    border-radius: 0.2em;
    border: none;
    font-family: 'Inter';
    color: #181820;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    background: url(https://cdn1.iconfinder.com/data/icons/arrows-vol-1-4/24/dropdown_arrow-512.png);
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: right;
    background-origin: content-box;
}

.reviewssection .reviewsdroptt {
    width: 30%;
    float: right;
}

.reviewssection select:focus-visible {
    border: none;
    outline: none;
}

/** full paid page start**/
.aboutresourcesdiv-rt .Purchase {
    background: #265472;
    border: 0.5px solid #265472;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 5%);
    border-radius: 6px;
    color: #fff;
}

.paiddecstiondss {
    margin-bottom: 0;
}

.showmorebutton {
    margin-top: 20px;
    margin-bottom: 50px;
}

.showmorebutton a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #F19357;
    text-decoration: none;
}

.aboutresourcesdiv .aboutresourcesdiv-lt h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #2D3134;
    margin-bottom: 0;
}

.showmorebutton2 {
    margin-bottom: 0;
}

.accordinstart .accordion-item .accordion-body h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding-left: 0;
    color: #2D3134;
    margin-bottom: 30px;
}

.accordinstart .accordion-item .accordion-body table.table {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
}

.accordinstart .accordion-item .accordion-body table.table thead.thead-light {
    background: #F5F5F5;
    border-radius: 8px;
}

.accordinstart .accordion-item .accordion-body table.table thead.thead-light th {
    font-weight: 700;
    padding: 20px 16px;
}

.accordinstart .accordion-item .accordion-body table.table tbody {
    background: #fff;
}

.accordinstart .accordion-item .accordion-body table.table tbody tr td {
    padding: 20px 16px;
    /* font-family: 'effra_trialregular'; */
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
}

.accordinstart .accordion-item .accordion-body table.table tbody tr td span {
    background: #F19357;
    border-radius: 6px;
    /* font-family: 'effra_trialregular'; */
    font-family: 'Rubik', sans-serif;
    color: #fff;
    padding: 2px 6px;
    margin-right: 13px;
}

.accordinstart .accordion-item .accordion-body table.table tbody tr:hover {
    background: #f5f5f5;
    transition: .5s;
}

.checkccc .styled-checkbox {
    position: absolute;
    opacity: 0;
}

.checkccc .styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
}

.checkccc .styled-checkbox+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #BFBCB8;
    border-radius: 4px;
}

.checkccc .styled-checkbox:hover+label:before {
    background: #fff;
}

.checkccc .styled-checkbox:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.checkccc .styled-checkbox:checked+label:before {
    background: #F19357;
}

.checkccc .styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.checkccc .styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.checkccc .styled-checkbox:checked+label:after {
    content: "";
    position: absolute;
    left: 9px;
    top: 14px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

/* ====== Checkbox styling Here==== */
.checkccc .styled-checkboxd {
    position: absolute;
    opacity: 0;
    z-index: 1;
    top: 10px;
    left: 4px;
}

.checkccc .styled-checkboxd+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
}

.checkccc .styled-checkboxd+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #BFBCB8;
    border-radius: 4px;
}

.checkccc .styled-checkboxd:hover+label:before {
    background: #fff;
}

.checkccc .styled-checkboxd:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.checkccc .styled-checkboxd:checked+label:before {
    background: #F19357;
}

.checkccc .styled-checkboxd:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.checkccc .styled-checkboxd:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.checkccc .styled-checkboxd:checked+label:after {
    content: "";
    position: absolute;
    left: 9px;
    top: 14px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.checkccc .unstyled {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.checkccc li {
    margin: 10px 30px 0px 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
}

.checkccc .centered {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.checkccc {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.checkccc .title {
    text-align: center;
    color: rgb(69, 113, 236);
}

.aboutresourcesdiv .col-lg-3 {
    padding-right: 0;
}

.instructor-boxdiv .instructor-boxdirights p {
    margin-bottom: 12px;
}

/*============= Change Field Styling Here =============*/
.resourceTypeFeild {
    border: none;
    line-height: 40px;
    height: auto;
    border-radius: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    padding: 0 15px;
}

#collapseOne .resourceTypeFeild {
    overflow-y: auto;
}

.checkccc li {
    margin-right: 10px;
}

.fa-star {
    font-size: 11px;
    margin: 0 1px;
}

/* .fulldescription .sharepopupOuter .pop-up-container { margin: 96px auto;}
.fulldescription .pop-up-container {  max-width: 650px;} */

.fulldescription .sharepopupOuter {
    z-index: 1000;
}

/* .fulldescription.removescroll {
    overflow: hidden;
} */

.type-res {
    background-color: #F5F5F5;
}

/*
.fulldescription .pop-up-container {
    max-width: 650px;
}*/
/* viewmore button rating */
.viewmorerating {
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
}

.viewmorerating p {
    margin-top: 0 !important;
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
}

/* end */
.text_capital::first-letter {
    text-transform: capitalize;
}

.rattingReviewdatainfo {
    overflow-y: auto;
    max-height: 200px;
    display: inline-block;
    width: 100%;
}

.fulldescription .accordion-item:last-of-type .accordion-collapse,
.fulldescription .accordinstart .accordion-item h2.accordion-header button.accordion-button,
.fulldescription .accordion-item,
.fulldescription .accordion-header {
    background: transparent !important;
}

.fulldescription  .aboutresourcesdiv-lt .leavfeedbackreviews .checkccc ul li{
    display: flex;
    width: 40%;
}
.fulldescription  .aboutresourcesdiv-lt .leavfeedbackreviews .checkccc{
   max-height: 180px;
   overflow-y: auto;
}
.purchaseviewOrderinfo {
    position: relative;
}

.purchaseviewOrderinfo .facustomeI {
    position: absolute;
    left: auto;
    right: -25px;
    top: 10px;
    line-height: 15px;
}

.purchaseviewOrderinfo .facustomeI .tootlippillerinfo {
    position: relative;
    left: auto;
    top: -20px;
    right: 387px;
    width: 386px;
}

.purchaseviewOrderinfo .facustomeI .tootlippillerinfo p {
    font-weight: normal;
    text-align: justify;
    line-height: 21px;
}

.purchaseviewOrderinfo .facustomeI .tootlippillerinfo:before {
    display: none;
}

.purchaseviewOrderinfo .facustomeI .tootlippillerinfo strong {
    display: block;
}

.purchaseviewOrderinfo .facustomeI .tootlippillerinfo p a:hover {
    text-decoration: underline;
}

.purchaseviewOrderinfo .facustomeI .tootlippillerinfo p a {
    color: #337ab7;
    font-weight: 600;
}

.reportsuggestChanges.purchaseviewOrderinfo .facustomeI .tootlippillerinfo p {
    text-align: left;
}

.fulldescription .aboutresourcesdiv-lt .form-select {
    width: 118px !important;
    background-image: none;
    padding-right: 0;
    height: auto;
    border: 1px solid #2D3134;
    margin-top: -7px;

}

.fulldescription .multiSelectContainer .search-wrapper span {
    position: absolute;
    top: 4px;
}

.reviewssection .reviewsdroptt {
    display: flex;
}
.fulldescription .sharepopupOuter .input-popup2 .img_preview{
    margin-left: 0;
}
.fulldescription .sharepopupOuter .input-popup2 audio{
    width: 100%;
}
.full-desupperset > h2 {
	margin-top:15px;
}
.full-desupperset .examplecontent h2 { 
margin-bottom:0;
}
.fulldescription .sharepopupOuter .pop-up-container .input-popup2 .img_preview{
    width: 100%;
}
.linkIconOuter {
	display: inline-block;
    width: 100%;
    max-height: 330px;
	overflow:hidden;
    overflow-y: auto;
}
.fulldescription .linkIconOuter .examplecontentinner .contentbtn{
    padding-left: 15%;
}
.buttonprevDeletnew {
	position: absolute;
    right: -85px; 
    top: -3px;
    z-index: 1;
}
.buttonprevDeletnew button {
	font-size:15px;
}
.fulldescription .fulldecartion-div .fulldecartion-div-lt .text_capital{
    word-break: break-word;
}

.fulldescription .fulldecartion-div .examplecontent .examplecontentinner .buttonprevdown{
    position: absolute;
    right: 23px;
    z-index: 1;
}

.fulldescription .full-desupperset .examplecontent .examplecontentinner .deletespanvid video{
    position: relative;
    top: 7px;
}
@media screen and (max-width: 1200px) {
    .fulldescription .input-popup2 video{
        width: 100%;
      }
    .buttonprevDeletnew {
        position: relative;
        right: auto;
        top: 12px;
        z-index: 1;
    }
    .examplecontent {
        padding: 6px 0 23px 10px;
}
.fulldescription .full-desupperset .examplecontent .examplecontentinner .deletespanvid video{
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
    .fulldescription .input-popup2 video{
        width: 100%;
      }
    .examplecontent .examplemoblits {
        width: 100%;
        float: left;
    }

    .img_preview {
        margin-left: 0px;
    }
    .buttonprevDeletnew {
        position: relative;
        right: auto;
        top: 12px;
        z-index: 1;
    }
    .fulldescription .full-desupperset .examplecontent .examplecontentinner .deletespanvid video{
        width: 100%;
      }
}

@media screen and (max-width: 767px) {
    .fulldescription .input-popup2 video{
        width: 100%;
      }
    .fulldescription .css-iqva2o {
        width: auto;
    }

    .fulldescription .css-iqva2o h2 {
        font-size: 18px !important;
        line-height: 25px !important;
        font-weight: normal;
    }

    .marginboottom {
        margin-bottom: 0;
    }

    .aboutresourcesdiv .col-lg-3 {
        padding-right: 15px;
    }

    .fulldecartion-div {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 30px;
        align-items: center;
        justify-content: center;
    }

    .fulldecartion-div .fulldecartion-div-lt h1 {
        font-size: 16px;
        line-height: 22px;
    }

    .fulldecartion-div .fulldecartion-div-lt h1 span {
        display: inline-block;
    }

    .fulldecartion-div .fulldecartion-div-lt p {
        font-size: 14px;
        line-height: 22px;
    }

    .fulldecartion-div .fulldecartion-div-lt {
        width: 100%;
        float: left;
    }

    .fulldecartion-div .fulldecartion-div-rt {
        width: 40%;
        float: right;
        margin-bottom: 24px;
    }

    .fulldecartion-div .fulldecartion-div-lt .hearicontop ul {
        justify-content: space-between;
    }

    .fulldecartion-div .fulldecartion-div-lt .hearicontop ul li {
        font-size: 16px;
    }

    .fulldecartion-div .fulldecartion-div-lt .hearicontop ul li span img {
        width: 15px;
        margin-top: 8px;
    }

    .fundingcsspublic {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .publicfunding {
        font-size: 16px;
        margin-bottom: 12px;
        margin-right: 0;
    }

    .exampledivlink ul li {
        width: auto;
        margin-right: 14px;
        font-size: 14px;
    }

    .exampledivlink ul li a {
        font-size: 14px;
    }

    .exampledivlink {
        margin-top: 20px;
    }

    .aboutresourcesdiv .aboutresourcesdiv-lt h1 {
        font-size: 18px;
        margin-bottom: 6px;
    }

    .aboutresourcesdiv .aboutresourcesdiv-lt p {
        font-size: 14px;
    }

    .accordinstart {
        margin-top: 20px;
    }

    .accordinstart .accordion-item h2.accordion-header button.accordion-button {
        font-size: 16px;
    }

    .examplecontent .examplemoblits {
        width: 100%;
    }

    .examplecontent .examplecontentinner h2 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .leavfeedbackreviews .form-group {
        font-size: 15px;
        width: 100%;
    }

    .leavfeedbackreviews textarea.form-control {
        margin-top: 0;
    }

    .reviewssection .reviewssectionlt {
        float: left;
        width: 100%;
    }

    .reviewssection .reviewssectionlt h2 {
        font-size: 18px;
    }

    .reviewssection .reviewsdroptt {
        width: 100%;
        float: left;
        margin-top: 10px;
    }

    .reviewssection select {
        padding: 10px;
        width: 30%;
    }

    .reviewssection .reviewssectionrtcv .reviewssectionrtcontet {
        float: left;
        width: 87%;
    }

    .aboutresourcesdiv-rt {
        max-width: 100%;
        float: left;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .aboutresourcesdiv-rt .vieworder-button {
        width: 100%;
    }

    .report-button {
        display: inline-block;
        width: 100%;
    }

    .aboutresourcesdiv {
        margin-bottom: 30px;
    }

    .search-div-2 {
        max-width: 100%;
        width: 100%;
    }

    .search-head-div {
        width: 100%;
    }

    .profile-div-user {
        Width: 100%;
    }

    .content-div1 {
        width: 100%;
    }
    .buttonprevDeletnew {
        position: relative;
        right: auto;
        top: 12px;
        z-index: 1;
    }

    .reviewssection .reviewssectionrtcv .reviewssectionrtcontet .reviewlistcnt ul {
        display: inline-block;
    }

    .purchaseviewOrderinfo div.facustomeI {
        right: 10px;
    }
    .fulldescription .full-desupperset .examplecontent .examplecontentinner .deletespanvid video{
        width: 100%;
      }
}

@media screen and (max-width:480px) {
    .fulldescription .pop-up-container {
        max-height: 400px;
        overflow-y: auto;
    }

    .fulldecartion-div .fulldecartion-div-rt {
        width: 100%;
    }

    .fulldecartionrightImgBox {
        margin: 0 auto;
    }

    .purchaseviewOrderinfo .facustomeI .tootlippillerinfo {
        right: 290px;
        width: 284px;
        top: -140px;
    }
    .fulldescription .input-popup2 video{
        width: 100%;
      }
	 .pop-up-container .input-popup2 .img_preview {
     width: 100%;
    }
    .buttonprevDeletnew {
        position: relative;
        right: auto;
        top: 12px;
        z-index: 1;
    }
    .fulldescription .full-desupperset .examplecontent .examplecontentinner .deletespanvid video{
        width: 100%;
      }
      .fulldescription .aboutresourcesdiv-lt .leavfeedbackreviews .checkccc ul li {
        width: 100%;
    }
}

