  @media only screen and (max-width:1280px) {
	  .Itwork .borderinline ul li {
		 font-size: 14px;
         line-height: 16px; 
	  }
	  div.accordion-body.content-div1 ul li a {
        margin-left: 0px;
    }
  }
 @media only screen and (max-width:992px) {
	 .hidden-sm {
		 display:none!important;
	 }
	div.Itwork h2,h2,h2.ourwhy-text,h2.we-Believe,div.storyInnerBox h4,h2.jackey-heading {
		  font-size:30px;
	 } 
	 h3.explore-resources{
		  font-size:28px;
		  margin-bottom:10px;
	 } 
	
    .menuoverlay {
         position:relative;
    }
     
	h2.jackey-heading {
      line-height: 40px;
 }
     .menuoverlay:before {
         background-color: rgba(0,0,0,0.8);
         position: absolute;
         z-index: 1;
         content: '';
         width: 100%;
         left: 0;
         top: 0;
         height: 100%;
    }
     .header ul li {
         float: none;
         width: 100%;
         border-bottom: 1px solid #265472;
    }
	.header #myLinks ul li a {
       font-weight: 600;
	}
	.header #myLinks ul li a:hover {
		background: #265472;
		color: #fff;
	}
     #myLinks {
         display:none;
    }
     #myLinks.mystyle {
         display: block;
         position: absolute;
         width: 100%;
         background: #fff;
         top: 60px;
         z-index: 2;
         border-radius: 4px;
         height: 60vh;
    }
     .header {
         position: relative;
         z-index: 2;
    }
     div.topnav .icon {
         left: 0;
         width: 55px;
         display: block;
         background: #f19357;
         padding: 10px 15px;
         font-size: 25px;
         color: #fff;
         z-index: 5;
    }
     .Imglogo {
         display: inline-block;
         text-align: center;
         width: 200px;
         margin: 0 auto;
    }
    
     .search-div-2 {
         width: 100%;
    }
     a.Imglogo {
         text-align:center;
         margin-bottom: 15px;
    }
	div.inputOuterBox select,
     div.profile-div-user, .search-head-div {
         width: 100%;
    }
	div.inputOuterBox select {
	  border-radius: 50px;
	}
	div.banner h1 {
         font-size: 31px;
 	}
	.inputOuterBox button {
	  position:absolute;
	  right: 10px;
	}
	div.decisionMaking-innerBox {
        width: 100%;
        padding: 30px 20px;
	}
	div.storyInnerBox {
       padding: 20px 10px 0 10px;
     }
	 img.mom,img.girl,img.dad {
	   position:static;
	   width: 150px;
		height: 150px;
		margin: 20px 5px;
	 }
	 div.Itwork {
        padding: 40px 10px 40px 10px;
   
    }
	a.Imglogo img {
    width: 170px;
    }
	div.believe-container {
		padding:0px;
		width:100%;
		
	}
	div.text-con {
    margin-top:0px;
   }
   
   div.totalamount span{
        font-size: 22px;
        
    }
	div.friend-buttons-home button {
      transform: none;
    }
	div.homegroup-img-friend .homegroup-img, div.homegroup-img-groups .homegroup-img {
		width:93px;
		height:85px;
	}
	.homegroup-parainfo {
      min-height: 112px;
    }
	.homegroup-img-friend h2,.homegroup-img-groups h2 {
		text-align:center;
	}
	.searchResultInnerBox div.facustomeI {
       display: none;
   }
    div.homegroupdiv {
      padding-top: 10px;
    }
	h2.ourwhy-text {
		margin-top:0;
	}
	body.homepage .borderinline {
       min-height: auto;
     }
	div.stated-btn button {
		position:static;
	}
	div.card-container{
		margin-bottom:0;
	}
	.createnewGroup .header1+.container,.purchase-page .header1+.container,
	.servepaymenthistory .header1+.container,.yourgroup .header1+.container,
	.serveyour-resource .header1+.container,.groupJoin .header1+.container,
	.servepage .header1+.container,.serveDashboard .header1+.container,
	.savepage .header1+.container,.suggestedyourGroup .header1+.container,
    .notification .header1+.container,.notificationSetting .header1+.container,
	.errorpage .header1+.container, .paymentsuccess .header1+.container, 
	.Ticket-page .header1+.container, .ticket-detail .header1+.container,
	.purchasepage .header1+.container,.bookasession .header1+.container,
	.createschedule .header1+.container, .serveDashboard .header1+.container,
	.servepaymenthistory .header1+.container,.sign-up .frm,
	.sessionhistory .header1+.container,
	.serveDashboard .header1+.container,
	.searchpage .header1+.container {
      height: 100%;
    }
	
	.engagelist .facustomeI .tootlippillerinfo {
		left: auto;
        right: 20px;
	}
	.engagelist .facustomeI .tootlippillerinfo:before {
		display:none;
	}
	
	
  }
 

 
 @media only screen and (max-width:767px) {
	 
	 .experience-input-div {
        padding: 9px 5px 9px 15px;
        margin: auto;
        margin-bottom: 12px;
        width: 100%;
    }

    .input-location .input-experienc {
        font-size: 12px;
        padding: 3px 0px 6px 0px;
    }
	.hidden-xs {
		display:none!important;
	}
	.explore-container {
      margin-top: 30px;
    }
	div.homegroupdiv-inner1 {
		margin-bottom:20px;
	}
	body.homepage .borderinline {
       min-height: auto;
     }
	div.stated-btn button {
		position:static;
	}
	.savepage div.innerboxalignment .u-content span {
		width:74%;
	}
	.serveyour-resource .serveResourceInner .viewing56{
		display: inline-block;
		width: 100%;
		margin-top: 10px;
    }
	.errorpage .header1+.container, .paymentsuccess .header1+.container, 
	.Ticket-page .header1+.container, .ticket-detail .header1+.container,
	.purchasepage .header1+.container,
	.createschedule .header1+.container, .serveDashboard .header1+.container {
      height: 100%;
    }
	div.applycoupan {
        padding-top:0px;
        padding-bottom:20px;
    }
    div.purcahse-information {
        max-width: 100%;
    }
	.subscriptiondataInfo {
      padding-bottom: 10px;
	}
	.subscriptiondataInfo-right strong {
		    color: #000;
		display: block;
	
      padding-bottom: 6px;
		padding-left: 5px;
	}
	#community-members .mnewmobilespc {
		padding-left:10px;
	}
	 .shareinspiration .inspireInnerBox .SummerCamp .resource-img,
    .shareinspiration .inspireInnerBox .SummerCamp .img-div2,
    .shareinspiration .inspireInnerBox .SummerCamp .images-organization {
        width: 50px;
        height:50px;
    }


 }
 

 
  @media only screen and (max-width:480px) {

    .sidebarlink li a{padding: 15px 9px;}
    
	  a.Imglogo {
         padding-top: 10px;
         margin: 0 auto 20px;
         float: none;
	   }
	div.banner {
       padding-top: 20px;
   }
   .homerightimgbox {
      text-align: center;
   }
	  div.totalamount span{
        font-size: 14px;
        margin-top: 1px;
    }
    div.totalamount{
        padding: 0px 0px 0px 176px;
		    display: inline-block;
    }
	div.header-account button {
		padding: 8px;
	}
	div.header-account button,
	div.container-fluid .helpcenter-header h4 {
		font-size:12px;
	}
	div.container-fluid .helpcenter-header h4 {
		    padding: 8px 6px;
	}
	.servepage div.servewithoutOuterBox .add-more-information, .savepage div.innerboxalignment .add-more-information {
       font-size: 16px;
	}
	div.modal-container, div.modal-container2 {
		 right:0px;
	}
	.savepage div.innerboxalignment .u-content span {
		     width: 80%;
	}
	.signup div.rember .form-check-label {
       width: 75%;
      line-height: 22px;
    }
	.homepage div.borderinline {
    min-height: auto;
    }
	div.stated-btn button {
		position:static;
		
	}
	.savepage .r-title-div .k-div{
    margin-left: 15px;
  }
  .servepage div.r-title-div p,
  .servepage div.texteditordescription h3, 
  .savepage div.r-title-div p,
  .savepage div.texteditordescription h3,
  div.save-type-of-r, div.mul-sel-div h3, div.r-title-div .k-div, div.first-div1 h3 {
    margin-left: 20px;
}
	.savepage .r-title-div h3{
		margin-left: 10px;
	}
	button.explore-all {
		 margin-bottom:10px;
	}
	.header1 .left-div .jane-deo {
    margin-right: 10px;
    }

    .css-13cymwt-control .css-b62m3t-container .css-wsp0cs-MultiValueGeneric, .css-b62m3t-container .css-wsp0cs-MultiValueGeneric{padding: 3px 5px 5px 12px !important;}



 }

