.member-container_1 {
    max-height: 350px;
    overflow-y: auto;
    
}
.member-container_12{
    max-height: 350px;
    overflow-y: auto;
}
.Group_mem{
    display: flex;
    justify-content: space-between;
    display: flex;
    /* flex-wrap: wrap; */
    /* margin-top: 80px; */
    cursor: pointer;
}
.see_all{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #5B5F62;
    cursor: pointer;
}
.grp_mam_head{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2d3134;
    text-align: left;
}
.mem_req_scroll{
    max-height: 350px;
    overflow-y: auto;
}
.member-request img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.joinType{
    background-color: white;
    border-radius: 10px;
}
.groupJoin .your-main-group .newgroupmemberselectbox .rightradiobtndiv input{
    border-radius: 100px;
    position: relative;
    top: 2px;
    margin-right: 6px;
	background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    }
    
    .groupJoin .your-main-group .newgroupmemberselectbox .radiosdiv input[type="radio"] {
        width: 20px;
        height: 20px;
        border: 1px solid #c1bdbd;
    }
    .groupJoin .your-main-group .newgroupmemberselectbox .radiosdiv input:checked[type=radio] {
        --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e);
    }
    .groupJoin .your-main-group .newgroupmemberselectbox .radiosdiv label{
        padding: 0px 0px 0px 15px;
    }
    .inviteGpopupinnersub .facustomeI {
        font-weight: 600;
        line-height: 15px;
        margin: 7px 5px;
    }
    
    .inviteGpopupinnersub .tootlippillerinfo ul li{
        list-style-type: none;
    }
    
    .groupJoin .newgroupmemberselectbox .tootlippillerinfo:before {
        transform: rotate(270deg) translate(40%, 100%);
        left: 255px;
        top: 17px;
    }
    .groupJoin .newgroupmemberselectbox .facustomeI .tootlippillerinfo {
        left: auto;
        top: -7px;
        right: 18px;
    }
    .groupJoin .your-main-group .group-member-div .group-mem-div .accordion span:target~.accordion-content{
    min-height: 150px;
    }