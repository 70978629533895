.eligible-public-funding {
    min-height: 30px;
}
div.text-curriculum11 img {
   display: inline-block;
}
/* .slick-initialized .slick-slide {
   padding: 0 10px;
} */
.main-card-container {
    position: relative;
}
.explore-container .main-card-container button:nth-of-type(1),
.explore-container .main-card-container button:nth-of-type(2) {
	 position: absolute;
	top: 45%;
    border: none;width: auto;
    background: transparent;
	cursor:pointer;    z-index: 2;
}
.explore-container .main-card-container button.slick-arrow {
  display:none!important;
}
.explore-container .main-card-container button:nth-of-type(1) {
    left: -20px;
}
.explore-container .main-card-container button:nth-of-type(2) {
    right: -20px;
}

.homepage .card-container .text-curriculum11 span{
    margin-right: 5px;
}
.slick-initialized div.slick-slide {
    display: inline-block;
}
.slick-slider .card-container {
	box-shadow:none
}
