@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap");
body {
    font-family: 'Inter';
}
section {
    padding-block: 3rem;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color:#2D3134;
    word-break: break-word;
}
/* p,span{
  word-break: break-all;
} */

h4 {
    font-size: 1.8rem;
}
.white-bg {
    background-color: #fff;
}
.blue-bg {
    background-color: #265472;
}
.gray-bg {
    background-color: #82A3A1;
}
.orange-bg {
    background-color: #F19357;
}
.tabbing {
    background-color: #fff;
    border: 1px solid #ddd;
    text-decoration: none;
    font-size:1rem;
    color:#2D3134;
    border-radius:.4rem;
    padding:0.5rem 1rem;
    /* width:200px; */
    box-shadow:0 0 8px 0 rgba(0, 0, 0, .05);
    display: inline-block;
    font-weight:600;
}
.tabbing:hover {
    color:#2D3134;
}
.subscription-tab {
   /*  background-color: #f5f5f5; */
    padding:10px 2rem 3rem 2rem;
   /*  margin:0 2rem; */
    border-radius:1rem;
}
.subscription-tab h5 {
  color:#5B5F62;
  text-transform: uppercase;
}
.subscription-tab h6 {
  font-size:0.8rem;
  margin-bottom:1rem;
  color: #65676B;
  font-weight:500;
}
.subscription-label {
    border-radius: 1rem 1rem 0 0;
    padding:1rem;
    color:#fff;
    font-size:1rem;
    font-weight:600;
    width:150px;
    margin-inline:auto;
}
.subscription-button {
    border-radius: 0 0 1rem 1rem;
    padding:1rem;
    color:#fff;
    font-size:13px;
    font-weight:600;
    width:178px;
    margin-inline:auto;
}
.content-block {
  border-radius:1rem;
}
ul.content-list li {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-block:0.7rem;
  list-style: none;
}
ul.content-list li:last-child {
  border-bottom:none;
}
ul.content-list li [class^=col] {
  text-align:center;
}
ul.content-list li p {
  font-size:0.9rem;
  display:inline;
  position: relative;
  font-weight: 400;
  color:#000;
}
ul.content-list li p a {
  text-decoration:none;
}
ul.content-list li p a i {
  font-size:1rem;
  color:#BFBCB8;
  margin-left:0.5rem;
  display:inline;
}
ul.content-list li i {
  font-size:1.4rem;
}
ul.content-list li i.fa-check-circle {
  color:#AAC0CE;
}
ul.content-list li i.fa-times-circle {
  color:#969696;
}

/* toggle-button */
.btn-toggle {
    margin: 0 5rem;
    padding: 0;
    position: relative;
    border: none;
    height: 1.5rem;
    width: 3rem;
    border-radius: 1.5rem;
    color: #fff;
    background: #F19357;
  }
  /* .btn-toggle:focus,
  .btn-toggle.focus,
  .btn-toggle:focus.active,
  .btn-toggle.focus.active {
    outline: none;
  } */
  .btn-toggle:before,
  .btn-toggle:after {
    line-height: 1.5rem;
    width: 4rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }
  .btn-toggle:before {
    content: 'Monthly';
    left: -5rem;
  }
  .btn-toggle:after {
    content: 'Yearly';
    right: -4.5rem;
    opacity: 0.5;
  }
  .btn-toggle > .handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    background: #fff;
    transition: left 0.25s;
  }
  .btn-toggle.active {
    transition: #F19357 0.25s;
  }
  .btn-toggle.active > .handle {
    left: 1.6875rem;
    transition: left 0.25s;
  }
  .btn-toggle.active:before {
    opacity: 0.5;
  }
  .btn-toggle.active:after {
    opacity: 1;
  }

  .btn-toggle:before,
  .btn-toggle:after {
    color: #2D3134;
  }
  .btn-toggle.active {
    background-color: #F19357;
  }
  .btn-toggle:hover, .btn-toggle:active, :not(.btn-check)+.btn:active {
    background-color: #F19357;
  }

  /* tooltip */
  [data-tooltip] {
    position:relative;
  }
  [data-tooltip]:hover:before, [data-tooltip]:hover:after {
    display: block;
    position: absolute;
    font-size: 0.8em;
    color: white;
  }
  [data-tooltip]:hover:before {
    border-radius: 0.2em;
    content: attr(data-title);
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.3em;
    top: 0;
    left: 110%;
    width:max-content;
   	max-width: 250px;
    line-height: 15px;
  }
  [data-tooltip]:hover:after {
    content: "";
    bottom: 50%;
    left: 100%;
    border-style: solid;
    border-color: transparent;
    border-top-color: rgba(0, 0, 0, 0.9);
    border-width: 0.5em 0.5em 0 0.5em;
    transform: rotate(90deg) translate(30%, 100%);
  }

  /* accordion */

  .my-accordion .accordion-body {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding-top: 0.5rem;
  }

  .my-accordion .accordion-item, .my-accordion .accordion-item:last-of-type, .my-accordion .accordion-item:first-of-type {
    border:none;
    margin-block:0.5rem;
    border-radius:1rem;
  }
  .my-accordion .accordion-item:first-of-type {
    margin-top:0;
  }
  .my-accordion .accordion-item:last-of-type {
    margin-bottom:0;
  }
  .my-accordion .accordion-button:not(.collapsed), .my-accordion .accordion-button:focus {
    box-shadow:none;
    color:#000;
    background-color: #fff;
    border-radius: 1rem;
  }
  .my-accordion .accordion-button, .my-accordion .accordion-item:first-of-type {
    border-radius:1rem;
    font-weight:600;
  }
    .my-accordion .accordion-button:not(.collapsed)::after {
    content:"\f056";
    background:none!important;
    transform:none;
  }
  .my-accordion button.accordion-button::after {
    font-family: FontAwesome;
    content:"\f055";
    background-image:none;
    font-size:1.6rem;
    color:#ddd;
  }


/* toggle switch */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 1.5rem;
  }
  .toggle-switch input[type="checkbox"] {
    display: none;
  }
  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #F19357;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 21px;
    height: 21px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    background-color: white;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: #F19357;
  }

  .selecetedBorder{
    border: 2px solid green;
  }

  .pointer-none{
    pointer-events: none;
    cursor: none;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
  .mysubscription table {
     background: #fff;
  }
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    /* background-color: #dddddd; */
  }
  .mysubscription .invoicebtn,
  .invoicebtn {
   width: 80px;
    padding: 8px 5px;
	height:38px;
	line-height: 24px;
	font-size:16px;

}
.mysubscription .addresource {
    height: 38px;
	margin-top: -22px;
}
.mysubscription .bookmoniterdiv h2{
  border-bottom: none;
}
#FriendsandFollowing.collapse:not(.show) {
    display: block;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box {
	float:left;
	padding-right:10px;
}
.mysubscription .bookmoniterdivbox ul li .sprash-name-rt {
	display:inline-block;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(1) {
    width:25%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(2) {
    width:12%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(3) {
    width:34%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:last-child {
	float:right;
	padding-right:0px;
	width:26%;
}
.mysubscription .bookmoniterdivbox ul li {
	  margin-bottom:0;
}
.subscription-tab h6.groupsindividuals {
	font-weight:600;
}
.chooseyourTitle {
	font-size:24px;
}
.chooseyourTitle small{
	display: block;
    font-size: 14px;
    color: orange;
    font-weight: 500;
}
.subscription .sidebar{
  display: none;
}

.subscription .marginboottom {
    margin-bottom: 0px;
}
.mobile-subscription-Page {
  display:none;
}
.subscriptionHideMobile .tabbing {
	cursor:pointer;
}
.subscription .accordion-body ul li .facustomeI {
  display: inline-block;
  line-height: 15px;
  float: none;
  font-weight: 600;
  font-size: 11px;
    width: 15px;
    height: 15px;
}
.subscription .subscriptionHideMobile .toggle-switch .switch{
  width: 50px;
}
.subs-community{
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width:1200px){
  .subs-community .lebel-basstanpre{
    width: 145px;
  }
	.bookmoniterdivbox ul li .sprash-name .sprash-name-rts h3 {
    font-size: 12px;
}
.bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box h4 {
    font-size: 12px;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-rts p {
    font-size: 14px;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(2) {
    width: 20%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(3) {
    width: 20%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:last-child {
    width: 34%;
}
div.subscription-button {
    font-size: 11px;
    width: 150px;
}
button.tabbing,a.tabbing {
     font-size: 13px;
}
.subscriptiondataInfo strong{
  font-size: 12px;
}
}


@media only screen and (max-width:991px){
  .content-list [data-tooltip]:hover:before {
    max-width: 230px;
  z-index: 1;
  }
	.subs-community{
    display: flex;
    justify-content: flex-end;
  }

  .subs-community h6{
    font-size: 14px;
  }

}
@media only screen and (max-width:767px) {

 .subscription-tab {
   padding:0;
   margin:0;
 }
 .mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(1) {
    width: 60%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(2) {
    width: 40%;
}

.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(3) {
    width: 55%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:last-child {

    width: 45%;
}
div.subscription-button {
    width: 150px;
	margin-bottom:10px;
}
button.tabbing,a.tabbing {
    font-size: 10px;
}
.btn1.d-flex {
	width: 150px!important;
}
 .bookmoniterdiv .d-flex h2 {
    display: inline-block;
    width: 100%;
}
.lastthreebuttons div.subscription-button {
    width: auto;
}
[data-tooltip]:hover:before {
      max-width:135px;
    position: absolute;
    left: -95px;
    top: 20px;
    z-index: 1000;
}
[data-tooltip]:hover:after {
  top: 20px;
  left: 13px;
  bottom: 0;
  transform: rotate(180deg) translate(30%, 100%);
}





/*============== Style for Mobile Design ===============*/
.mobile-subscription-Page {
	display: inline-block;
    width: 100%;
    padding: 0;
}
.mobile-subscription-Page .slick-prev:before,.mobile-subscription-Page .slick-next:before {
    opacity: 1;
    color: #F19357;
}

.mobile-subscription-Page .slick-next {
    right:76px;

}
.mobile-subscription-Page .slick-prev,.mobile-subscription-Page .slick-next  {
	  top:39px;
	  width: 40px;
     height: 40px;
}
.mobile-subscription-Page #group-members .slick-prev,
.mobile-subscription-Page #group-members .slick-next  {
	  top:61px;

}
.mobile-subscription-Page .slick-prev {
   left: 76px;
    z-index: 2;

}
.mobile-subscription-Page .slick-prev:before, .mobile-subscription-Page .slick-next:before {
    font-size: 30px;
}
.mobile-subscription-Page #community-members .mobilemembership-smalltext {
	margin-bottom:79px;
}
.mobilemembership-smalltext {
	margin-bottom:38px;
}
.vendormembershipMinHeight ul.content-list li:last-child {
  min-height:72px;
}
.vendormembershipMinHeight ul.content-list li:last-child,
.groupmembershipMinheight ul.content-list li:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.groupmembershipMinheight ul.content-list li:last-child {
	    min-height:91px;
}
.mobilemembership-smalltext h6.groupsindividuals {
   line-height: 12px;
}
.manageLeftsideAccordion .my-accordion .accordion-button::after {
	font-size:0;
}
#serve-members .mobilemembership-smalltext .monthlyBox {
	margin-bottom:51px;
}
.mobilemembership-smalltext .monthlyBox {
	margin-bottom:56px;
}
.subsciptionMobilePaddingRight {
	padding-right:0;
}
.subsciptionMobilePaddingLeft {
		padding-left:0;
}
.subsciptionMobilePaddingLeftRight {
	padding-left:0;
	padding-right:0;
}
.manageLeftsideAccordion ul.content-list li,
.mobilecomunitymembershiprightside ul.content-list li {
	border-bottom:none;
}
.manageLeftsideAccordion #subscription-accordion
.accordion-item:nth-of-type(1) .accordion-header button {
	line-height: 21px;
}

.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(1) ul.content-list li{
	min-height:58px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(2) ul.content-list li{
	min-height:73px;
}

.manageLeftsideAccordion #subscription-accordion .accordion-item:nth-of-type(2) .accordion-header button {
	line-height: 19px;
    padding-bottom: 15px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(3) #Searches ul.content-list li{
	min-height:85px;
}
.manageLeftsideAccordion #subscription-accordion .accordion-item:nth-of-type(3) .accordion-header button {
	line-height: 18px;
    padding-bottom: 16px;
}
.manageLeftsideAccordion #subscription-accordion .accordion-item:nth-of-type(4) .accordion-header button {
	line-height:23px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(4) ul.content-list li{
	min-height:82px;
}
.manageLeftsideAccordion #subscription-accordion .accordion-item:nth-of-type(5) .accordion-header button {
	line-height:20px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(5) ul.content-list li{
	min-height:92px;
}
.manageLeftsideAccordion #subscription-accordion .accordion-item:nth-of-type(6) .accordion-header button {
	line-height:23px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(6) ul.content-list li{
	min-height:66px;
}
.subscriptionHideMobile {
	display:none;
}
.arrowTopspace {
	margin-top:35px;
}
.mobile-subscription-Page .my-accordion .accordion-body {
	border-top:none;
}
.mobile-subscription-Page .content-block,
.mobile-subscription-Page .my-accordion .accordion-item {
	border-radius: 0;
}

#community-members  #AccountUsers{
min-height: 238px;
}

#community-members #FriendsFollowing {
  min-height: 345px;
}
#community-members #Searches{
  min-height: 500px;
}
#community-members #Serve{
    min-height: 435px;
}
#community-members #Share{
    min-height: 307px;
}
#community-members #Saves{
     min-height: 180px;
}
}
@media only screen and (max-width:480px){
	.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(1) {
    width: 60%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(2) {
    width: 40%;
}

.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(3) {
    width: 45%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:last-child {

    width: 55%;
}

/*============== Style for Mobile View Design ===============*/
.mobilemembership-smalltext p {
	font-size:12px;
	font-weight: bold;
}
.mobile-subscription-Page ul.content-list li p {
    display: inline-block;
    line-height: 17px;
}
.groupmembershipMinheight ul.content-list li:last-child {
     min-height: 102px;

}
.manageLeftsideAccordion .mobilemembership-smalltext {
	margin-bottom: 60px;
}
.lastthreebuttons div.subscription-button,
.vendormembershipbuttonadjustment div.subscription-button,
.mobilecomunitymembershiprightside .subscription-label,
.mobilegroupmembershipRightSide .subscription-label {
	width:150px;
}
.mobilemembership-smalltext .monthlyBox {
    margin-bottom: 20px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(2) ul.content-list li{
	min-height:78.5px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(3) #Searches ul.content-list li{
	min-height:82px;
}
.manageLeftsideAccordion #subscription-accordion .accordion-item:nth-of-type(4) .accordion-header button {
	line-height: 16px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(4) ul.content-list li{
	min-height:79px;
}
.manageLeftsideAccordion #subscription-accordion .accordion-item:nth-of-type(5) .accordion-header button {
	line-height: 16px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(5) ul.content-list li{
	min-height:91.5px;
}
.manageLeftsideAccordion #subscription-accordion .accordion-item:nth-of-type(6) .accordion-header button {
	line-height: 16px;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(6) ul.content-list li{
	min-height:70px;
}
.vendormembershipMinHeight ul.content-list li:last-child {
    min-height: 85px;
}
#serve-members .mobilemembership-smalltext .monthlyBox {
    margin-bottom: 40px;
}
.mobilemembership-smalltext {
    margin-bottom: 60px;
}
.mobile-subscription-Page .toggle-switch .switch {
    width: 45px;
    height: 20px;
}
.mobile-subscription-Page .slick-prev {
    left: 46px;
}
.mobile-subscription-Page .slick-next {
    right: 45px;
}
/*  #community-members .slick-prev {
       left: 55px;
    }
   #community-members .slick-next {
    right: 55px;
} */
.mobile-subscription-Page .subsciptionMobilePaddingRight .my-accordion  .accordion-button{
font-size: 10px;
font-weight: 800;
}
.manageRightsideAccordion #subscription-accordion .accordion-item:nth-of-type(1) ul.content-list li {
    min-height: 58px;
  }
#community-members  #AccountUsers{
min-height: 208px;
}

#community-members #FriendsFollowing {
  min-height: 345px;
}
#community-members #Searches{
  min-height: 530px;
}
#community-members #Serve{
  min-height: 490px;
}
#community-members #Share{
  min-height: 310px;
}
#community-members #Saves{
  min-height: 265px;
}


div.subscriptiondataInfo {
  margin-top: -29px;
}
div.subscriptiondataInfo-right strong{
  font-size: 10px;
}
#headingTwo .accordion-button, #headingThree .accordion-button {
  font-size: 10px;
  font-weight: 800;
}

}
@media only screen and (max-width:360px){
.bookmoniterdiv h2 {
    font-size: 14px;
}
.addresource {
    font-size: 10px;
	}
	.mysubscription .addresource {
    height: 32px;
    margin-top: -5px;
	}
	.bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box h4 {
    font-size: 11px;
}
.bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box p {
    font-size: 12px;
}
.invoicebtn {
    width: 65px;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box {
     padding-right: 0px;
}
.bookmoniterdiv .profileupcomings ul li .sprash-name-rt .sprash-name-box:nth-of-type(3) {
    width: 41%;
}
.mysubscription .profileupcomings ul li .sprash-name-rt .sprash-name-box:last-child {
    width: 59%;
}


.mobile-subscription-Page .slick-prev {
  left: 30px;
}
.mobile-subscription-Page .slick-next {
  right: 30px;
}

#community-members #AccountUsers {
  min-height: 245px;
}
#community-members #FriendsFollowing {
  min-height: 354px;
}
#community-members #Searches {
  min-height: 579px;
}
#community-members #Serve {
  min-height: 522px;
}
#community-members #Share {
  min-height: 397px;
}

  .groupmembershipMinheight ul.content-list li:last-child {
      min-height: 85px;
  }


}

