.purchasebartop {
    text-align: left;
    margin-top:20px;
    width: 100%;
    float: left;
}

.bookmoniterdiv h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #2d3134;
    padding-bottom: 16px;
}

.accpectbuttoncover .friendaddbt {
    background: #f5f5f5;
    color: #65676b;
}

.purchasebartop .purchasebarsearch .searchbarlist {
    width: 100%;
    position: relative;
}

.purchasebartop .purchasebarsearch .searchbarlist input {
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    box-shadow: 0px 28px 56px rgb(246 111 77 / 5%);
    position: relative;
    height: 48px;
    width: 100%;
    font-size: 18px;
    border-radius: 38px;
    padding-left: 15px;
    color: #2d3134;
    font-family: inter;
}
.purchasebartop .purchasebarsearch .searchbarlist input::placeholder {
    color: #65676B;
}
.purchasebartop .purchasebarsearch .searchbarlist button {
    position: absolute;
    border: none;
    border-radius: 30px;
    width: 34px;
    height: 34px;
    background-color: #f19357;
    color: white;
    margin: 5px 0px 0px 0px;
    right:10px;
    top: 2px;
}

.purchasebartop .purchasebarbuttons .vieworder-button {
    background: #ffffff;
    float: right;
    border: 0.5px solid #bfbcb8;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 5%);
    border-radius: 6px;
    /* width: 200px; */
    float: left;
    text-decoration: none;
    padding: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #2d3134;
    margin-bottom: 10px;
}

.bookmoniterdiv .sellallbutton {
    float: right;
    margin-top: 12px;
}

.bookmoniterdiv .sellallbutton a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #265472;
    text-decoration: none;
}

.teampurchasepages {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    text-align: left;
}

.teamboxinnercontent {
    width: 100%;
    float: left;
}

.teamboxinnercontent .teamboxinnerpart .dots ul {
    right: 0;
    justify-content: flex-end;
    left: auto;
    width: 100%;
}

.teamboxinnercontent ul li .imgcircle img {
    width: 70px;
    object-fit: cover;
    aspect-ratio: 1/1;
    margin: 0px auto;
    display: block;
    text-align: center;
    float: none;
}

.paddingbottomcss {
    padding-bottom: 70px;
}

.bookmoniterdiv5 {
    margin-top: 36px;
}

.teamboxinnercontent .teamboxinnerpart {
    padding: 10px 10px 20px 10px;
    float: left;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 19px;
   min-height: 259px;
    position: relative;
    width: 100%;
}

.teamboxinnercontent .teamboxinnerpart .imgcircle {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 10px;
    display: block;
}

.teamboxinnercontent .teamboxinnerpart .imgcircle img {
    width: 70px;
    object-fit: cover;
    aspect-ratio: 1/1;
    margin: 0px auto;
    display: block;
    text-align: center;
    float: none;
    border-radius: 50px;
}

.teamboxinnercontent .teamboxinnerpart h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
    text-align: center;
    color: #000000;
	white-space: normal;
    word-wrap: break-word;
}

.teamboxinnercontent .teamboxinnerpart p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #949698;
    text-align: center;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.teamboxinnercontent .teamboxinnerpart .accpectbuttoncover {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.teamboxinnercontent .accpectbuttoncover .accpectbutton {
    background: #265472;
    border: none;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    /* White */
    width: 100%;
    color: #ffffff;
    height: 36px;
    text-align: center;
    text-decoration: none;
    justify-content: center;
}

.teamboxinnercontent .accpectbuttoncover .declinebutton {
    background: #e8f0f4;
    width: 100%;
    border: none;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    /* Blue 1 */
    color: #265472;
    justify-content: center;
    height: 36px;
    text-decoration: none;
    margin-top: 10px;
}

.teamboxinnercontent .teamboxinnerpart .dots ul li:last-child,
.teamboxinnercontent .teamboxinnercontent ul li:last-child {
    margin-right: 0px !important;
}

.teamboxinnercontent .accpectbuttoncover .friendaddbt {
    background: #f5f5f5;
    color: #65676b;
}

.teamboxinnercontent .teamboxinnerpart .dots ul li {
    background: transparent;
    box-shadow: none;
    padding-right: 20px;
    margin-right: 10px !important;
    width: auto;
}

.teamboxinnercontent .teamboxinnerpart p span {
    padding-left: 10px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.teamboxinnercontent .teamboxinnerpart p span img.short1 {
    position: absolute;
    left: 46px;
    width: 18px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.teamboxinnercontent .teamboxinnerpart p span img.short2 {
    position: absolute;
    left: 34px;
    width: 18px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.teamboxinnercontent .teamboxinnerpart .accpectbuttoncover .friendaddbt {
    background: #f5f5f5;
    color: #65676b;
}

.purchasebartop .purchasebarsearch .searchbarlist input:focus {
    outline: none;
    box-shadow: none;
}

.teamboxinnercontent .teamboxinnerpart .dots {
    position: relative;
}


.teamboxinnercontent .teamboxinnerpart .dots i {
    position: absolute;
    right:0px;
    font-size: 19px;
    top: 0;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
	line-height: 23px;
	width: 30px;
    text-align: center;
    height: 30px;
}
.teamboxinnercontent .teamboxinnerpart .dots i:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.friendRequestpermission .sharepopupOuter {
    position: fixed;
}

.teamboxinnerpart .dotspop a {
    display: block;
    text-decoration: none;
}

.teamboxinnerpart .dotspop {
   left: auto;
    right: 20px;
}

.paddingcontrighs {
    padding: 0 50px;
}

/* pagination css start */
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: #265472;
    border-color: #265472;
    color: #fff;
}

.pagination>li>a {
    border: 1px solid #265472;
    padding: 5px 10px;
    margin: 0px 5px;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #265472;
    border-color: #265472;
    outline: none;
}

.pagination>li>a,
.pagination>li>span {
    color: #265472
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border: none;
}
.show-more{
    font-weight: 800;
    cursor: pointer;
}
.purchasepagefriend .create-link-btn {
    width:100%;
	text-align:center;
}
.purchasepagefriend .create-link-btn .sendinvite {
   padding: 12px;
    line-height: 10px;
    height: 40px;
    width: auto;
}
/* end */

.chat-popup {
    position: fixed;
    bottom: 0;
    right: 0;
    transform: none;
    left: auto;
    top: auto;
}
.purchasepagefriend .purchasebartop div.purchasebarbuttons .vieworder-button {
/* width: 160px;
font-size: 11px; */
padding: 8px;
}
.purchasepagefriend .purchasebarbuttons  .addresource:hover .resourceNormalImg,
 .purchasepagefriend .purchasebarbuttons .resourcehoverImg{
    margin-left: 0;
 }
 .purchasepagefriend .paddingcontrighs .teampurchasepages .teamboxinnercontent .teamboxinnerpart h2{
    min-height: 150px;
}
.profilepage .profileCardContainer{
    text-align: center;
}
.teamboxinnercontent div.teamboxinnerpart p{
    min-height: 18px;
}

@media screen and (max-width: 1200px) {
   .purchasebartop .purchasebarbuttons .vieworder-button {
        font-size: 14px;
        float: right;
        width: 150px;
    }


}

@media screen and (max-width: 991px) {
	div.paddingcontrighs {
      padding: 0;
   }
    .teamboxinnercontent ul li p span {
        padding-left: 40px;
    }

    .teamboxinnercontent .teamboxinnerpart h2 {
        font-size: 13px;
    }

    .teamboxinnercontent .teamboxinnerpart p span img.short2 {
        left: 14px;
    }

    .teamboxinnercontent .teamboxinnerpart p span img.short1 {
        left: 26px;
    }

}

@media screen and (max-width: 767px) {
    .purchasebartop .purchasebarsearch .searchbarlist input {
        width: 100%;
    }

    .purchasebartop .mobbilecslines {
        width: 100%;
        max-width: 100%;
    }

    .purchasebartop .purchasebarbuttons .vieworder-button {
        font-size: 14px;
        float: none;
        width:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
        margin-top: 14px;
    }

    .bookmoniterdiv h3 {
        font-size: 16px;
    }

    .bookmoniterdiv .sellallbutton a {
        font-size: 13px;
    }

    .accpectbuttoncover .accpectbutton {
        width: 70%;
        margin: 0px auto;
    }

    .accpectbuttoncover .declinebutton {
        width: 70%;
        margin-top: 10px !important;
        margin: 0px auto;
    }


    .teamboxinnercontent .teamboxinnerpart p span img.short2 {
        left: 84px;
    }

    .teamboxinnercontent .teamboxinnerpart p span img.short1 {
        left: 96px;
    }
	.purchasebartop .purchasebarsearch .searchbarlist button {
    position: absolute;
    margin: 5px 0px 0px 4px;
    right: 10px;
    top: 2px;
}
.purchasebartop .purchasebarsearch .searchbarlist {
    width: 100%;
  }
  .teamboxinnercontent .accpectbuttoncover .accpectbutton {
	  font-size:13px;
  }
}


@media screen and (max-width: 480px) {
	
	.paddingcon {
     padding: 0px;
	 
     }
    .purchasebartop .purchasebarbuttons .vieworder-button {
        width: 100%;
	}
.purchasebartop .purchasebarsearch .searchbarlist button {
    right: 10px;
}

.purchasebartop .purchasebarsearch .searchbarlist button {
    position: absolute;
    top: 8px;
}
.purchasebartop .purchasebarsearch .searchbarlist button {
	margin:0px;
}

}
.chat-count{
    position: relative;
}
.chat-count span{
    position: absolute;
    right: -5px;
    top:-5px;
    width: 18px;
    height: 18px;
    background-color: #f19357;
    border-radius: 50%;
    color: #fff;
}



.shareinspiration .kayword-associatedNew .css-1kvlupi-MuiAutocomplete-root,
.purchasepagefriend .kayword-associatedNew .css-1kvlupi-MuiAutocomplete-root {
    width: unset;
}
.shareinspiration .sharepopupOuter .create-link-btn{
    display:flex;
    justify-content: center;
}
.shareinspiration .sharepopupOuter .create-link-btn .sendinvite {
    padding: 6px 24px;
}