.profile-page-card {

    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 16px;
    padding: 21px 0px 20px 13px;
    width: 100%;
    display: inline-block;
    position: relative;
}

.profile-container-11 {
    padding: 0px 40px 0px 54px;
}

.chlid-pro-butttnss button {
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    height: 47px;
    max-width: 212px;
    width: 100%;
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    margin-bottom: 10px;
}

.chlid-pro-butttnss button.delete {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #2D3134;
    border: none;
    background: none;
    box-shadow: none;

}

.graph-img,
.child-profile-content {
    float: left;
}

.profile-page-card .graph-img {
    width: 30%
}

.profile-page-card .child-profile-content {
    width: 70%
}

.graph-img img {
    width: 215px;
    float: left;
    margin-right: 36px;
}

.graph-img img.image-c-p {
    width: 101px;
    height: 101px;
    position: absolute;
    left: 64px;
    top: 74px;
    border-radius: 50%;
}

.child-profile-content h3 {
    margin-bottom: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
    text-align: left;

}

.child-profile-content ul li {

    list-style: none;
}

.child-profile-content ul li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    text-align: left;
}

.doot1 {
    color: #265472;
    padding: 0px 10px;
}

.doot2 {
    color: #F19357;
    ;
    padding: 0px 10px;
}

.doot3 {
    color: #F0B285;
    ;
    padding: 0px 10px;
}

.doot4 {
    color: #AAC0CE;
    ;
    padding: 0px 10px;
}

.margint {
    margin-top: 50px;
    margin-bottom: 40px;
}

.handle-heading-text-child h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
    margin-bottom: 20px;
}

.profile-page-card .sharepopupOuter {
    position: fixed !important;
    height: 100% !important;
}

.chlid-pro-butttnss .pop-up-container button.close-btn {
    width: 37px;
    height: 37px;
    background: #D9D9D9;
    float: right;
    border-radius: 50%;
    color: #5B5F62;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    padding: 0px;
}

.chlid-pro-butttnss .pop-up-container .create-link-btn button {
    width: auto;
    padding: 12px 24px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    /* font-family: 'effra_trialregular'; */
    font-family: 'Rubik', sans-serif;
    color: #2D3134;
    margin-right: 12px;
    height: 45px;
}

.chlid-pro-butttnss .pop-up-container .create-link-btn button.sendinvite {
    background: #F19357;
    color: #fff;
}

.profile-div-user1 .sharepopupOuter,
.chlid-pro-butttnss .sharepopupOuter {
    height: 100% !Important;
}

.email-text-option .error {
    top: 3px;
}

.profilepage .viewableFriend h5 {
    color: #82A3A1;
    font-family: "Inter";
    font-weight: 600;
    font-size: 12.41px;
    text-align: left;
    line-height: 19px;
}

.profilepage .switch {
    width: 47px;
    height: 18px;
}

.profilepage .slider:before {
    height: 15px;
    width: 15px;
}

.profileCardContainer a {
    text-decoration: none;
}

.profilepage .viewableFriend h5 {

    font-size: 14px;
    color: #82A3A1;
}

.profilepage .bookmark .fa-bookmark {
    color: #4f4c4c;
}

.starDigitProfile,
.starReviewProfile {
    color: #5b5f62;
}

.profilepage .fa-ellipsis-v {
    color: rgba(0, 0, 0, 0.54);
}

.handle-star .css-1lauo1g-MuiRating-root {
    position: relative;
    top: 3px;
    /* color: #6f6e6e; */
}

.profileCardContainer .profile-description p {
    height: 80px;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.profileCardContainer .bookmark {
    margin-top: -25px;
    margin-right: 0;

}

.profileCardContainer .text-curriculum11 .heart,
.profileCardContainer .text-curriculum11 .soul,
.profileCardContainer .text-curriculum11 .mind,
.profileCardContainer .text-curriculum11 .strength {
    font-weight: 600;
    font-size: 12.41px;
    line-height: 19.31px;
}

.profilepage .search-exp-sec-div1 select {
    width: 18%;
}

.profilepage .search-exp-sec-div1 h3 {
    min-width: 130px;
    text-align: left;
}

.profilepage .profileCardContainer a,
.profilepage h3 a {
    color: #2d3134;
}

.profilepage .img-divsr1 {
    width: 96px;
    height: 96px;
    text-align: center;
}

.profilepage .showpiePercentage>div {
    top: -6px !important;
    font-weight: 700;
    padding-top: 10px;
}

.profilepage .search-exp-sec-div1 .css-b62m3t-container {
    width: 210px;
    /*  overflow: hidden; */
}

.img-icon .remove-res h5 {
    position: relative;
    /* bottom: 7px;
	left: 2px; */
    color: black;
}

.remove-res {
    background: #fff;
    padding: 11px;
    width: 137px;
    position: absolute;
    left: auto;
    right: 0;
    top: 28px;
    z-index: 1;
    color: black;
    /*  height: 28px; */
    border-radius: 10px;
}

.childprofilegradeRange {
    float: left;
    width: 20%;
    margin-right: 10px;
}

.childprofilegradeRange .multiSelectContainer input {
    background: transparent;
    border: none;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    height: 27px;
}

.childprofilegradeRange .form-select {
    height: 31px;
    border-radius: 50px;
    padding-top: 1px;
    margin: 0;
    border: 0.21px solid #BFBCB8;
}

.childprofilegradeRange div.multiSelectContainer input[type="checkbox"] {
    width: 15px;
    float: left;
    margin-top: -5px;
}

.childprofilegradeRange div.searchWrapper {
    overflow: hidden;
}

.profilepage .input-btn-con {
    float: left;
}

.profilepage .search-exp-sec-div1 button {
    position: relative;
    float: right;
    border-radius: 24px;
    width: 31px;
    height: 31px;
    padding: 0px;
    margin: 0px;
}

.profilethreedots {
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    font-size: 17px;
    border-radius: 50%;
    position: relative;
    line-height: 38px;
}

.profilethreedots:hover {
    background-color: rgba(0, 0, 0, 0.04);

}

.cancel-butn button,
.savee button {
    background: transparent;
    border: 0;
    height: auto;
    width: 100px;
    color: #fff;
    margin: 0;
    box-shadow: none;
}

.cancel-butn button {
    color: #2D3134;

}

.savee button {
    color: #fff;
}

.user-profileinnerBox .child-profile-content {
    width: 70%;
}

.userprofileDescBox {
    max-height: 206px;
    overflow-y: auto;
}

.userprofilerightscroll {
    max-height: 175px;
    overflow-y: auto;
    height: 100%;
    display: inline-block;
    width: 100%;
    min-height: 175px;
}

.userprofileDescBox p {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: justify;
}

button.childListButton {
    width: 100%;
    margin-bottom: 20px;
}

.text-capitalize-custome::first-letter {
    text-transform: uppercase;
}

.profilepage .viewing56 select {
    padding-left: 5px;
}

.profilepage .searchres-div11 .search-exp-sec-div1 .saveResourceListgradeRange,
.search-exp-section-div1 select {
    width: 18%;
}

.profilepage .searchres-div11 .saveResourceListgradeRange div.searchWrapper {
    overflow-y: hidden;
}

.profilepage .saveResourceListgradeRange .form-select {
    padding-left: 0px;
    background-image: none;
}

.profilepage .search-exp-sec-div1 .saveResourceListgradeRange .form-select input {
    height: auto;
}

.profilepage .search-exp-sec-div1 .form-select {
    height: auto;
}

.profilepage .search-exp-sec-div1 .form-select {
    background-image: none;
    padding-right: 0px;
}

.profilepage .search-exp-sec-div1 .saveResourceListgradeRange {
    padding: 0px;
}

.profilepage .search-exp-sec-div1 .form-select .multiselect-container .search-wrapper img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
}

.profilepage .searchres-div11 .childprofilegradeRange .multiSelectContainer input::placeholder {
    color: #000000;
}

.profilepage .searchres-div11 .childprofilegradeRange .multiSelectContainer input {
    padding-bottom: 4px;
}
.childprofilegradeRange.childprofilegradeRangeNew div.searchWrapper {
    width: 90%;
    overflow-y: auto;
}
.childprofilegradeRange.childprofilegradeRangeNew div.searchWrapper {
	   height: 27px;
}
.childprofilegradeRange.childprofilegradeRangeWidth #multiselectContainerReact input,
.childprofilegradeRange.childprofilegradeRangeHeight #multiselectContainerReact input {
	padding-bottom:0;
}
.childprofilegradeRange.childprofilegradeRangeNew #multiselectContainerReact:after {
	background:url("data:image/svg+xml,%3Csvg%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232%22%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%20%20%20%3Cg%20id%3D%22background%22%3E%20%20%20%20%20%20%20%20%3Crect%20fill%3D%22none%22%20height%3D%2232%22%20width%3D%2232%22%2F%3E%20%20%20%20%3C%2Fg%3E%20%20%20%20%3Cg%20id%3D%22arrow_x5F_down%22%3E%20%20%20%20%20%20%20%20%3Cpolygon%20points%3D%222.002%2C10%2016.001%2C24%2030.002%2C10%20%20%22%2F%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E") center top no-repeat;
	content:"";
	position: absolute;
    right:10px;
    top: 6px;
    width: 14px;
    height: 14px;
    background-size: 14px;	
}
.childprofilegradeRange.childprofilegradeRangeNew div.searchWrapper img {
	display:none;
}
.profilepage .search-exp-sec-div1 .form-select.grademanage{
    height: 31px;
}

.grademanage .multiSelectContainer .searchWrapper input{
    position: absolute;
    bottom: 3px;
}
.InviteFriendInspiration .kayword-associatedNew button {
	height:auto;
}
.InviteFriendInspiration .kayword-associatedNew button {
    border: none;
    background: #fff;
    box-shadow: none;
}

.profilepage .showpiePercentage.blackAddBorder .image-c-p.image-c-border{
	top: 66px;
	left:52px;
}
.profilepage .showpiePercentage.blackAddBorder > div {
    border-radius: 50%;
    border: 3px solid #b8b8b8;
	width: 180px !important;
    height: 180px !important;
	top: 4px !important;
	background:#b8b8b8;
}

.profilepage .child-profile-popupBg .child-profile-popup button{
  width: 200px;
  height: 35px;
  line-height: 100%;
  border: 1px solid #f19357;
  background: #f19357;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
  height: 45px;
}
.profilepage .user-request div.child-profile-popup {
  min-height: 200px;
}
.showpiePercentage svg:not(:root) {
    overflow: visible;	
}
@media only screen and (max-width:1200px) {
    .profilepage .search-exp-sec-div1 select {
        width: 14%;
    }
	 .profilepage .searchres-div11 div.search-exp-sec-div1 div.input-btn-con input {
        width: 140px;
    }
}

@media only screen and (max-width:991px) {
    .profilepage .search-exp-sec-div1 h3 {
        width: 100%;
    }

    .profilepage .search-exp-sec-div1 select {
        width: 49%;
        margin-bottom: 5px;
        float: left;
    }

    .profilepage .search-exp-sec-div1 .childprofilegradeRange {
        float: left;
        width: 22%;
        margin-right:5px;
    }

    .profilepage .search-exp-sec-div1 .input-btn-con {
        width: 44%;
    }
	.profile-page-card div.graph-img {
        width: 35%;
    }
    .profile-page-card div.child-profile-content {
        width: 62%;
    }
	.profilepage div.search-exp-sec-div1 .input-btn-con {
       width: 22%;
	}
	.profilepage .searchres-div11 div.search-exp-sec-div1 div.input-btn-con input {
		width: 140px;
	}
	.profilepage .searchres-div11 .search-exp-sec-div1 .saveResourceListgradeRange{
    width: 22%;
  }

}


@media only screen and (max-width:767px) {
.profilepage .child-filter-page .search-exp-sec-div1 .saveResourceListgradeRange{
    width: 100%;
}
.profilepage .child-filter-page .search-exp-sec-div1 input {
    width: 100%;
}
.saveResourceListgradeRange .form-select input[type="checkbox"].checkbox {
	width: auto;
}
.profile-page-card div.child-profile-content {
        width: 100%;
}
.profilepage .search-exp-sec-div1 select {
    width: 49%;
    margin-bottom: 5px;
    float: left;
}

.profilepage .search-exp-sec-div1 .childprofilegradeRange {
    float: left;
    width: 100%;
    margin-bottom:15px ;
}


.profilepage .search-exp-sec-div1 div.input-btn-con {
    width: 90%;
}
.profilepage .searchres-div11 div.search-exp-sec-div1 div.input-btn-con input{
    width: auto;
}

}

@media only screen and (max-width:576px) {
    .profilepage .css-10tlgzi{width: auto;padding: 30px;}
    .profilepage .css-10tlgzi h2{
        font-size: 18px!important;
        line-height: 25px!important;
        font-weight: normal;
    }



    .chlid-pro-butttnss button {
        width: 100%;
        margin-top: 10px;
    }

    .childprofilegradeRange {
        margin-bottom: 10px;
    }

    .childprofilegradeRange,
    .profilepage .search-exp-sec-div1 select {
        width: 100%;
    }

    .card-container-handle {
        padding: 15px 0;

    }

    .profile-page-card .graph-img,
    .profile-page-card div.child-profile-content {
        width: 100%;
        margin: 0;
    }

    .profilepage .search-exp-sec-div1 .childprofilegradeRange,
    .profilepage .search-exp-sec-div1 h3 {
        width: 100%;
    }

    .profilepage .search-exp-sec-div1 select {
        width: 100%;
        margin-bottom: 10px;
    }

    .profilepage .search-exp-sec-div1 .input-btn-con {
        width: 88%;
    }

    .chlid-pro-butttnss button {
        max-width: 100%;
    }

}