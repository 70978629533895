.ticket-popup-filter,
.ticket-popup {
		display: block;
    position: fixed;
    top: 60px;
    background-color: rgba(0,0,0,0.6);
    left: 0;
    z-index: 5;
    padding-top: 50px;
	height: 100%;
	}
.ticket-popup-filter .createticketpopup,
.ticket-popup .createticketpopup{
    max-width: 550px;
    height: auto;
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
	margin: 0 auto;
}
.ticket-popup .pop-up-container,
.ticket-popup .createticketpopup{
    max-width:950px;
}
.ticket-popup .ticket-list-popup{
    padding:20px;
    display: inline-block;
    width: 100%;
}
.ticket-popup {
    z-index: 333;
}
.createticketpopup .ticket-popup-inner,
.ticket-popup .ticket-popup-inner{
    display: flex;
    justify-content: space-between;
    padding:20px;
    border-bottom: 1px solid #D9D9D9;
}
.createticketpopup .ticket-popup-inner h3,
.ticket-popup .ticket-popup-inner h3{
    font-size: 30px;
    font-weight: 700px;
    font-family: 'Inter';
    line-height: 36px;
    color: #000000;
}
.ticket-popup .ticket-popup-inner span i{
    font-size: 28px;
    color: #000000;
}
.ticket-popup .ticket-list-popup .ticket-upload-page label,
.ticket-popup .ticket-list-popup .ticket-comments label,
.ticket-popup .ticket-list-popup .ticket-subject label{
 font-size:18px;
 font-weight:600;
 line-height: 20px;
 font-family: 'Inter';
 margin-bottom:5px;
 color: #000000;
}
.ticket-popup .ticket-list-popup .ticket-subject input{
 width: 100%;
 border-radius: 50px;
 border: 1px solid #D9D9D9;
 height: 52px;
 padding: 0px 0 0 15px;
   }
.ticket-popup .ticket-list-popup .ticket-comments textarea{
    width: 100%;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
        height: 70px;
		padding: 5px 0 0 15px;
      }
.upload-file-flex{
       /*  display: flex;
        justify-content: space-between; */
      }
.ticket-list-pupup-submit{
        display: inline-block;
		clear: both;
		width: 100%;
		text-align: center;
		padding-top: 25px;
    }
.ticket-list-pupup-submit button{
        width:170px;
        height: 52px;
        border-radius: 50px;
        color: #ffffff;
        background-color: #265472;
        border: none;
        font-family: 'Inter';
        font-size: 22px;
        font-weight: 500;
  }
/*=============== Popup filter  style Here ============*/
.search-ticket .search-ticket-flex{
        display: flex;
    }
    .search-ticket .search-ticket-flex input{
        border: 1px solid #D9D9D9;
        height: 52px;
        border-radius: 50px;
        width: 100%;
    }
    .search-ticket .search-ticket-flex input::placeholder{
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    padding-left: 15px;
    }
    .search-ticket .search-ticket-flex span {
        position: absolute;
        right: 25px;
        top: 13px;
    }
    .search-ticket .search-ticket-flex span i{
       font-size: 25px;
       color: #000000;
    }

    .search-ticket .ticket-button-clear{
        float: right;
    }
    .search-ticket .ticket-button-clear button{
 width: 90px;
    height: 35px;
    background-color: #F19357;
    font-family: 'Inter';
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    color: white;
    border-radius: 50px;
    border: none;
    }
.ticket-status label{
        font-family: 'Inter';
        font-weight:600;
        font-size: 18px;
        color: #000000;
        padding: 5px 0px 6px 20px;
    }
 .ticket-status select {
       padding-left: 18px;
	   font-size: 14px;
    }
.search-ticket-inner{
        padding-bottom: 20px;
    }
    .search-ticket{
    padding: 25px 20px 20px 20px;;
    }
 .filter-one-div{
        padding-bottom: 20px;
    }
    .ticket-date-inner{
padding-bottom: 60px;
    }
.cancel-apply-ticket{
    text-align: center;
    }
.cancel-apply-ticket .apply-ticket,
    .cancel-apply-ticket .cancel-ticket{
       width: 170px;
    height: 52px;
    border-radius: 50px;
    border: none;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 500;

    }
.cancel-apply-ticket .cancel-ticket{
    color: #000000;
    margin-right: 60px;
    }
.cancel-apply-ticket .apply-ticket{
	background-color: #265472;
	color:#ffffff;
    }
.ticket-popup-inner .ticketClose{
        border: none;
        background: none;
        font-size: 25px;
		    color: #333;
    font-weight: 600;
    }
.ticket-list-popupScroll {
		max-height: 375px;
		overflow-y: auto;
	}