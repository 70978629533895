.dash-h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 41px;
    /*  margin-left: -60px; */
}

.dash-h5 a {
    text-decoration: none;
    color: #2D3134;
}

.lefticon {
    font-size: 20px;
    padding: 0px 15px 0px 0px;
}

.your_resorce h5 {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2D3134;
    /* margin-left: -60px; */
}

.serve-div-22 {
    width: 100%;
    margin-bottom: 10px;
}

.your_resorce {
    width: 30%;
    float: left;

}

.select-boxes-serv {
    float: right;

}

.select-boxes-serv h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    color: #2D3134;
    padding: 0px 22px 0px 0px;
}

.select-boxes-serv h5,
.select-boxes-serv select,
.select-op-serve {
    float: left;
    height: 31px;
}

.select-boxes-serv select,
.select-op-serve {
    padding: 0px 5px;


}

.select-op-serve {
    float: left;
    width: 183px;
    height: 31px;
}

.viewing56 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2D3134;
    margin-bottom: 47px;
    text-align: left;
}

.img-divsr1 {
    background-color: #F9C976;
    ;
    padding: 11px 9px 11px 7px;
    border-radius: 16px;
}

.img-divsr1 img {
    width: 78px;
}

.img-divsr3 {
    background-color: #57886C;
    padding: 13px 17px 18px 14px;
    border-radius: 16px;
}

.img-divsr2 img {
    width: 75px;
}

.img-divsr2 {
    background-color: #F19357;
    padding: 20px 9px 13px 10px;
    border-radius: 16px;
}

.img-divsr3 img {
    width: 63px;
}

.img-divsr4 {
    background-color: #265472;
    padding: 11px 13px 14px 12px;
    border-radius: 16px;
}

.img-divsr4 img {
    width: 68px;
}

.img-divsr5 {
    background-color: #AAC0CE;
    padding: 10px 20px 9px 20px;
    border-radius: 16px;
}

.img-divsr5 img {
    width: 56px;
}

.img-divsr6 {
    background-color: #82A3A1;
    padding: 11px 12px 14px 11px;
    border-radius: 16px;
}

.img-divsr6 img {
    width: 69px;
}

.img-divsr7 {
    background-color: #91CBCE;
    padding: 12px 22px 13px 21px;
    border-radius: 16px;

}

.img-divsr7 img {
    width: 51px;
}

.img-divsr8 {
    background-color: #F19357;
    padding: 20px 9px 13px 10px;
    border-radius: 16px;
}

.img-divsr8 img {
    width: 74px;
}

.img-divsr9 {
    background-color: #F19357;
    padding: 20px 9px 13px 10px;
    border-radius: 16px;
}

.img-divsr9 img {
    width: 74px;
}

.select-op-serve select {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2D3134;
    padding-left: 10px;
}

.reviews a {
    color: #000000;
}

.reviews a:hover {
    color: #000000;
}

.serveyour-resource .viewableFriend h5 {
    color: #82A3A1;
    font-family: "Inter";
    font-weight: 600;
    font-size: 12.41px;
    text-align: left;
    line-height: 19px;
}

.serveyour-resource .switch {
    width: 47px;
    height: 18px;
}

.serveyour-resource .slider:before {
    height: 15px;
    width: 15px;
}

.serveTotalReview,
.starRatingNumber {
    padding-top: 10px;
    display: inline-block;
}

.servemultiStar,
.starRatingNumber {
    float: left;
}

.serveyour-resource .serve-desc-article {
    min-height: 80px;
    padding-bottom: 20px;
}

.serve-desc-article p {
    margin-bottom: 0px;
}

.serveyour-resource .bookmark {
    margin-right: 0;
    margin-top: -15px;
}

.serveyour-resource .main-card-container {
    padding-left: 50px;
}

.serveyour-resource .eligible-content {
    min-height: 30px;
}

.serveyour-resource .show-more {
    text-align: center;
}

.serveResourceInner .dash-h5 {

    margin-left: 55px;
}

.serveResourceInner .your_resorce h5 {
    margin-left: 52px;
}

.serveyour-resource .serveResourceInner .select-boxes-serv .form-select .searchWrapper .icon_down_dir {
    right: -24px;
}

.serveyour-resource .serveResourceInner .select-boxes-serv .form-select .search-wrapper .chip {
    font-size: 14px;
}

.serveyour-resource .serveResourceInner .select-boxes-serv .form-select {
    height: auto;
    background-image: none;
}

.serveyour-resource .serveResourceInner .select-boxes-serv .multiSelectContainer input::placeholder {
    color: #000000;
}

.serveyour-resource .serveResourceInner .select-boxes-serv .multiSelectContainer input {
    margin-top: -1px;
    font-size: 14px;
}

.serveyour-resource .serveResourceInner .main-card-container .saveResourceListgradeRange .form-select {
    background-image: none;
}

.serveyour-resource .serveResourceInner .select-boxes-serv .form-select .searchWrapper .icon_down_dir,
.serveyour-resource .serveResourceInner .main-card-container .saveResourceListgradeRange .form-select .searchWrapper .icon_down_dir {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
}

.serveyour-resource .serveResourceInner .main-card-container .saveResourceListgradeRange .form-select .searchWrapper .icon_down_dir {
    right: 10px;
}
.serveyour-resource .serveResourceInner .addresource{
    height: 36px;
}
.serveyour-resource .img-icon .resource-img {
	padding:0 
}
.serveResourceInner .input-btn-contain {
	width:24%;

}
.serveResourceInner .input-btn-contain input {
   height:36px;
   border: 1px solid #dee2e6;
}

.serveResourceInner .savebtnsearch button {
    width: 31px;
    height: 31px;
    background: #F19357;
    display: block;
    text-align: center;
    border-radius: 24px;
    font-size: 21px;
    border: none;
}
.serveyour-resource .resource-img .resource-image2 {
    max-width: 100%;
    height: 100%;
    width:auto;	
}




@media only screen and (max-width:1200px) {
    .your_resorce h5 {
        font-size: 25px;

    }

    .serveyour-resource .main-card-container {
        padding-left: 0px;
    }

    .select-op-serve {
        width: 173px;
        margin-top: 10px;
    }

    .serveResourceInner .dash-h5 {
        margin-left: 10px;
    }

    .serveResourceInner .your_resorce h5 {
        margin-left: 10px;
    }


}



@media only screen and (max-width:991px) {}



@media only screen and (max-width:767px) {
    .serveResourceInner .select-op-serve {
        width: 100%;
    }

    .addresource {
        width: 100%;
    }

    .select-boxes-serv {
        float: none;
    }

    .your_resorce {
        width: 100%;
        text-align: left;
    }

    .your_resorce h5 {

        text-align: left;
        margin-left: 0;
        margin-bottom: 10px;

    }

    .serveResourceInner .dash-h5 {
        margin-left: 10px;
    }

    .serveResourceInner .your_resorce h5 {
        margin-left: 10px;
    }

    .serveResourceInner .viewing56 select {
        width: 48%;
        margin-left: 5px;
    }

    .select-boxes-serv select,
    .select-op-serve {
        padding: 0px 0px;
    }

    .serveyour-resource .serveResourceInner .main-card-container .saveResourceListgradeRange .form-select div.searchWrapper img {
        right: 6px;
    }
	.serveyour-resource .select-boxes-serv .savebtnsearch{
	    float: right;
		position: relative;
		bottom: 24px;
	}
	.serveyour-resource .serveResourceInner .input-btn-contain {
		width: 90%;
		position: relative;
		float: none;
		top: 10px;
	}
	
}

@media only screen and (max-width:576px) {
    .dash-h5 {
        margin-left: 0px;
    }

    .your_resorce h5 {
        margin-left: 0px;
    }

    .your_resorce {
        width: 100%;
        margin-bottom: 10px;
    }

    .select-boxes-serv h5 {
        width: 100%;
        text-align: left;
    }

    .select-boxes-serv {
        margin-bottom: 50px;
    }

    .main-card-container {
        padding: 0px 10px;
    }
}

@media only screen and (max-width:480px) {
    .dash-h5 {
        margin-left: 0px;
    }

    .your_resorce h5 {
        margin-left: 0px;
    }

    .your_resorce {
        width: 100%;
        margin-bottom: 10px;
    }

    .select-boxes-serv h5 {
        width: 100%;
        text-align: left;
    }

    .select-boxes-serv {
        margin-bottom: 50px;
    }

    .main-card-container {
        padding: 0px 10px;
    }

    .select-op-serve {
        width: 100%;
        margin-top: 10px;
    }

    .serveyour-resource .main-card-container {
        padding-left: 0px;
    }

    .addresource {
        width: 100%;
    }

    .viewing56 select {
        margin-top: 20px;
    }

    .serveResourceInner .dash-h5 {
        margin-left: 10px;
    }

    .serveResourceInner .your_resorce h5 {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 360px) {
    .your_resorce h5 {
        font-size: 25px;

    }
}