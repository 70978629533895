.main-sidebar{
    width: 100%;

    background-color: white;
}
.main-sidebar .side-inner-div{
    text-align: left;
    padding: 1px 10px 10px 44px;
}
.main-sidebar .side-inner-div a h3{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 20px;
color: #65676B;
margin-top: 35px;
}
.main-sidebar .my-profile-div{
  
    text-align: left;
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 20px;
color: #2D3134;

}
.main-sidebar .my-profile-div h2{
    padding: 15px 15px 15px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
   
}
.main-sidebar .my-profile-inner-div{
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
}
.main-sidebar .my-profile-inner-div .my-friends-div{
    display: flex;
    padding: 16px 16px 16px 0px;
    gap: 10px;
}
.main-sidebar .my-profile-inner-div .my-friends-div h3{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 20px;
color: #2D3134;
}
.main-sidebar .my-profile-inner-div .my-friends-div h6{
    font-size: 8px;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #F19357;
    color: white;
    position: absolute;
    left: 170px;
}
.main-sidebar .my-profile-inner-div .my-friends-div span i{
    font-size: 20px;
}
.main-sidebar .my-profile-inner-div p{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #F19357;
margin-top: 16px;
margin-right: 12px;
}
.main-sidebar .my-profile-inner-div p a{
	color: #F19357;
	text-decoration:none;
}
.main-sidebar .gropping-div h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    text-align: left;
    padding: 16px 16px 16px 0px;
}
.main-sidebar .gropping-div input{
    font-family: 'Inter';
    background: rgba(170, 192, 206, 0.2);
width: 100%;
height: 29px;
border: 0.25px solid #BFBCB8;
border-radius: 4px;
padding: 10px;
}

.main-sidebar .gropping-div button{
    background: #FFFFFF;
width: 100%;
height: 47px;
border: 0.5px solid #BFBCB8;
box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
border-radius: 6px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 20px;
text-align: center;
color: #2D3134;
margin-top: 24px;

}
.main-sidebar .main-inner-gropping{
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    padding-right: 16px;
}
.main-sidebar .main-inner-gropping h2{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 20px;
color: #2D3134;
}
.main-sidebar .main-inner-gropping p{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #F19357;
}

.main-sidebar .main-gropping-div .main-image-flex{
    display: flex;
    gap: 25px;
}
.main-sidebar .main-gropping-div .main-image-flex img{
    width: 40px;
    height: 40px;
}
.main-sidebar .main-gropping-div .main-image-flex p{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 15px;
color: #2D3134;
margin-top: 11px;
cursor: pointer;
}
.main-sidebar .main-gropping-div{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.main-sidebar .main-gropping-div span i{
    margin-top: 11px;
    margin-right: 16px;
}
.main-sidebar .main-inner-gropping p a,
.main-sidebar .main-inner-gropping p a{
color: #F19357;
text-decoration:none;
}

@media screen and (max-width: 480px) {
    .main-sidebar .my-profile-inner-div .my-friends-div h6 {
        left: 135px;
    }
    
}
