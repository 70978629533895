.overflowloader, .circle-square {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.overflowloader {
    z-index: 9998;
    width: 100vw;
    height: 100vh;
    background-color: rgba(245,245,245,0.4);
}
.circle-square {
    z-index: 9999;
    width: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    animation: circle-rotate 1.5s infinite linear;
}
.red,.green,.yellow,.blue{
    position: relative;
    width: 18px;
    height:18px;
    border-radius: 30px;
    margin: 5px;
}
.red {
    background-color:#AAC0CE;
}
.blue {
    background-color:#265472;
}
.green {
    background-color:#F19357;
}
.yellow {
    background-color:#F0B285;
}
/* CSS ANIMATION */
@keyframes circle-rotate {
    from {
        transform: translate(-50%, -50%);
    }
    to{
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
.loading{
 /* font-size: 23px;
  color: #FFF;
  text-align: center; */
font-size: 23px;
  font-style: oblique;
  color: #FFF;
  text-align: center;
  margin-top: 290px;
 vertical-align: middle;

}