.Itwork {
    width: 100%;
    padding: 220px 10px 40px 10px;
    display: inline-block;
}

.Itwork h2 {
    position: relative;
    font-size: 45px;
    color: #2D3134;
    font-weight: 700;

    font-family: 'Inter';
    padding-top: 40px;
}

.Itwork a {
    text-decoration: none;
}

.imagediv {
    /*  display: inline-block; */
    background-color: #F19357;
    border-radius: 10px;
    padding: 25px 27px;
}

.imagediv img,
.imagediv1 img {
    width: 26px;
    height: 26px;
}

.imagediv1,
.imagediv2 {
    /*  display: inline-block; */
    background: #AAC0CE;
    border-radius: 10px;
    padding: 30px;

}

.imagediv2 {

    background: #265472;

}

.imagediv3 img,
.imagediv2 img {
    width: 40px;
    height: 40px;
}

.imagediv3 img {
    width: 66px;
    height: 76px;
}

.imagediv3 {
    /*  display: inline-block; */
    background: #F0B285;
    border-radius: 10px;
    padding: 10px;

}

.imagediv,
.imagediv1,
.imagediv2,
.imagediv3 {
    margin: 0 auto;
    width: 93px;
    height: 93px;
}


.borderinline {
    position: relative;
    padding: 15px 10px 10px 10px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 24px 90px rgba(192, 188, 161, 0.22);
    border-radius: 16px;
    width: 100%;
    min-height:600px;
}

.borderinline h3 {
    margin-top: 28px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #2D3134;
    margin-bottom: 25px;
}

.Itwork .borderinline ul {
    list-style-type: disc;
    padding-left: 20px;
}

.borderinline ul li {
    text-align: left;
    list-style: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #4F4F50;
    /* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
}

.iconsearchhome {
    font-size: 45px;
    color: white;
}

.sidebar ul li .searchboldcolor,
.borderinline ul li .searchboldcolor {
    font-weight: bold;
    color: #F19357;
}

.sidebar ul li .saveboldcolor,
.borderinline ul li .saveboldcolor {
    font-weight: bold;
    color: #AAC0CE;

}

.sidebar ul li .shareboldcolor,
.borderinline ul li .shareboldcolor {
    font-weight: bold;
    color: #265472;
}

.sidebar ul li .serveboldcolor,
.borderinline ul li .serveboldcolor {
    font-weight: bold;
    color: #F0B285;
}

.stated-btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.stated-btn button {

    padding:10px;
    background: #265472;
    border-radius: 100px;
    height: 44px;
    color: #fff;
    min-width: 219px;
    border: none;
    margin-bottom: 0px;
    /* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
	position:absolute;
	bottom:15px;
}

/*================ Group styling Here =========*/

.homegroupdiv-inner1{
    background-color: #ffffff;
     width: 100%;
     padding:25px 10px 15px 10px;
     border-radius: 16px;

		 position:relative;
 }
 .homegroup-img-friend img{
 width: 42px;
    height: 62px;

 }
 .homegroup-img-groups img,
  .homegroup-img-friend img{
	border-radius:0;
  }
 .homegroup-img-groups img{
   width: 70px;
    height:65px;

    }
 .homegroup-img-groups h2,
 .homegroup-img-friend h2{
 padding-bottom: 15px;
    /*  text-align: center; */
     font-size: 30px;
     font-weight: 700;
     line-height: 36px;
     position: static;
     color:#2D3134;
	 padding-top:0;
 }
 .homegroup-img-groups button,
 .homegroup-img-friend button,
 .homegroup-img-groups h2,
 .homegroup-img-friend h2,
  .homegroup-img-groups p,
 .homegroup-img-friend p{
	    font-family: 'Inter';
 }
 .homegroup-img-groups p,
 .homegroup-img-friend p{
     margin-bottom:10px;
     font-size: 16px;
     color:black;
     font-weight: 400;
     }
.homegroup-img-friend a,
.homegroup-img-groups a{
	color:#2D3134;
}
.homegroup-img-friend .homegroup-img,
.homegroup-img-groups .homegroup-img {
	margin: 0 auto 30px;
    width:110px;
   height: 84px;
    border-radius: 10px;
    padding: 10px;
}
.homegroup-img-friend .homegroup-img {
	    background-color: #F19357;


}
.homegroup-img-groups .homegroup-img{
	 background-color:#265472;
}
.homegroup-img-groups button,
 .homegroup-img-friend button{
     width: 219px;
     border: none;
     background:#265472;
     color: white;
     height: 44px;
     border-radius: 50px;

     font-size: 20px;

     font-weight: 600;

 }
 .homegroup-img-groups button,
 .homegroup-img-friend button,
 .group-buttons-home,
.friend-buttons-home{
    text-align: center;
}
.friend-buttons-home button{
	/* position: absolute;
    bottom: 30px;
    left: 50%; */
    transform: translateX(-50%);
}
 .homegroupdiv {
	 padding-top: 75px;
 }
.homegroup-parainfo {
	min-height:70px;
}

.friend-buttons-home {
 padding-top:15px;
}