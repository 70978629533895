.save-resource-list .bookmoniterdiv .form-select,
.save-resource-list .search-exp-section-div1 .form-select{
    background-image: none;
}
::placeholder {
  color: #000;
}
.save-resource-list div.pop-up-container {
    max-width: 950px;
}
.save-resource-list .sharepopupOuter div.pop-up-container {
    position: relative;
    left: 0;
    transform: translateX(-0%);
}
.save-resource-list div.sharepopupOuter .checkccc .centered {
    max-height: 150px;
    overflow-y: auto;
}
.save-resource-list .searchresource-div11 .saveResourceListgradeRange .form-select .multiSelectContainer .search-wrapper img{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
}
.save-resource-list .bookmoniterdiv .multiselect-container .searchWrapper img{
    float: right;
    position: absolute;
    right:0;
    top: 9px;
}
.save-resource-list .bookmoniterdiv .saveResourceChildSelect .saveresource-list i{
    font-size: 20px;
    position: absolute;
    right: 26px;
    top: 5px;
    color: black;
}
.save-resource-list .bookmoniterdiv .multiselect-container .searchWrapper input{
margin-top: 0px;
cursor: pointer;
}

.save-resource-list .saveResourceChildSelect,
.save-resource-list .saveResourceListgradeRange{
    display: flex;
}
.save-resource-list .saveresource-list i{
    font-size: 20px;
    position: relative;
    right: 27px;
    top: 5px;
}

.myrating small{
	font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
}
.myrating small a{
	color:#000;
	text-decoration:none;;
}
.starColor{
	color:#F19357;
}
.stargray {
	color:#949698;
}
.searchresource-div11 {
    margin-top:50px;
    border-top: 1px solid #BFBCB8;
    border-bottom: 1px solid #BFBCB8;
    margin-bottom: 27px;
    width: 100%;
}
.search-exp-section-div1 {
    display: inline-block;
    padding: 24px 0px;
	width: 100%;
}
.search-exp-section-div1 h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
    color: #2D3134;
    margin-right: 12px;
    float: left;
}
.saveResourceListgradeRange,
.search-exp-section-div1 select {
    width: 20%;
    height: 31px;
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 32.5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2D3134;
    padding: 0px 12px;
    margin-right: 12px;
    display:inline-block;
}
.search-exp-section-div1 select,
.saveResourceListgradeRange {
	float:left;
}
select option {
    font-family: 'Inter';
}
.input-btn-contain {
    float: left;
    position: relative;
	width:20%;
}
.input-btn-contain input{
    width: 100%;
    height: 31px;
    background: #FFFFFF;
    border: 0.21px solid #BFBCB8;
    border-radius: 32.5px;
    padding: 0px 12px;
    outline: none;
    float: right;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
	font-family: 'Inter';

}
.input-btn-contain input::placeholder{
    font-family: 'Inter';
    color: #212529!important;
}
.search-exp-section-div1 button {
    width: 31px;
    height:31px;
    background: #F19357;
   display:block;
   text-align:center;
    border-radius: 24px;
    font-size: 21px;
    border: none;
    position: relative;
    padding: 0px;
    margin: 0px;
}
.iconsearch {
    color: white;
    font-size: 18px;
}
.saveResourceList h2{
	border-bottom:none;
}

.rating {
    justify-content: left;
  }

  .rating .star.unfilled{
      color:gray !important;
  }

  .rating .star {
      color : #F19357 !important;
	  float:left;
  }
.addresource {
	background: #f19357;
    border: 1px solid #f19357;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    border-radius: 50px;
    height: 31px;
    line-height: 16px;
}
.addresource:hover {
	background:#fff;
	border: 1px solid #cdcdcd;
}
.addresource:hover .resourceNormalImg,
.resourcehoverImg {
	display:none;
	 margin-left:4px;
}
.addresource:hover .resourcehoverImg {
	display:block;
}

.bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box p {
    color: #000;
}
.paddingRight10{
  padding-right:10px;
}
.sortByresourceType,
.saveResourceSelect {
	font-size: 12px;
    font-weight: 600;
    line-height: 16px;
	font-family:inter;
}
.saveResourceSelect {
  	height:31px;
}
.saveResourceOuter h2{
	border-bottom:none;
}
.input-btn-contain .iconsearch {
	position:absolute;
	left:4px;
	top:2px;
}
.saveResourceList .bookmoniterdivbox .sprash-name-lt {
	cursor:pointer;
}
.gobackcontainer a {
	color:#2D3134;
	text-decoration:none;
}
.gobackcontainer a:hover {
	color:#f19357;
}
.sharingAction button .fa {
	color:#65676B;
}
.sharingAction button:active {
	background: transparent!important;
    border: none;
}
.saveResourceList .form-select {
	height: 31px;
    font-size: 12px;
    font-weight: 600;
	border: 1px solid #dfdfdf;
}
.viewrattinglist {
	color:#000;
	text-decoration:none;
	float:right;
}
.addresourceratting {
 float:left;
 margin-top:-10px;
}
.namestarRatting strong {
	display:block;
	text-align:left;
}
.save-resource-list .pop-up-container {
	max-width: 700px;
}
.save-resource-list .popup-btn-div {
   margin: 30px auto 0;
}
.save-resource-list .bookmoniterdivbox .img-divsr1,.save-resource-list .bookmoniterdivbox .img-divsr3,
.save-resource-list .bookmoniterdivbox .img-divsr1,.save-resource-list .bookmoniterdivbox .img-divsr4,
.save-resource-list .bookmoniterdivbox .img-divsr5,.save-resource-list .bookmoniterdivbox .img-divsr7,
.save-resource-list .bookmoniterdivbox .img-divsr2,.save-resource-list .bookmoniterdivbox .img-divsr6 {
   width: 69px;
    text-align: center;
    padding: 10px 5px;
}
.save-resource-list .bookmoniterdiv,
.save-resource-list .tobarbackcontee .gobackcontainer {
	margin-top:0;
}
.save-resource-list .bookmoniterdivbox ul li .sprash-name .sprash-name-lt img {
   max-width: 100%;
    height: auto;
    width: auto;
}
.save-resource-list .bookmoniterdivbox ul li .sprash-name .sprash-name-lt .img-divsr3 img {
    height:45px;
}
.save-resource-list .bookmoniterdivbox ul li .sprash-name .sprash-name-lt .img-divsr5 img,
.save-resource-list .bookmoniterdivbox ul li .sprash-name .sprash-name-lt .img-divsr7 img {
    width: 40px;
}
.save-resource-list .reviewlistcnt .rating .star {
    font-size: 16px;

}
.save-resource-list .bookmoniterdivbox ul li .sprash-name .sprash-name-rts {
    padding-left:80px;
}
.ratingImgBox {
	float:left;
	width: 21%;
}
.starWithDescription {
 width:76%;
 float:left;
 text-align: left;

}
.childDescription {
	display:inline-block;
	text-align:left;
}
.statusPublicUnpublish {
	padding-top:5px;
}

.save-resource-list.removescroll {
	/* overflow:hidden; */
}

/* .save-resource-list.removescroll {
	overflow:hidden;
} */

.saveResourceChildSelect {
	width: 45%;
	float:right;
}
.saveResourceChildSelect .multiSelectContainer input {
    margin-top: 0;
    font-weight: 600;
    line-height: 12px;
	color:#212529;
}
.saveResourceChildSelect .multiSelectContainer input::placeholder {
color:#212529;
}
.saveResourceListgradeRange {
   padding: 0 0 0 12px;
    font-size: 12px;
    line-height: 16px;
    color: #2D3134;
}

.saveResourceListgradeRange .form-select {
    width: 100%;
    height: 26px;
    border-radius: 50px;
    border: none;
    padding: 0;
}
.saveResourceListgradeRange .form-select #search_input {
	margin-top:0;
    cursor: pointer;

}
.saveResourceListgradeRange .form-select #search_input::placeholder {
	 font-size: 12px;
	 color: #2D3134;
	 font-weight:600;
}
.saveResourceList .bookmoniterdivbox .loadmorebatn {
	background:transparent;
}
.saveResourceListgradeRange div.searchWrapper {
    overflow: hidden;
	margin-top: 1px;
    overflow-y: auto;
    width: 100%;
    height: 26px;
}
.savebtnsearch {
 float:right;
}
.minGradeHeight {
	max-height: 62px;
    overflow: hidden;
    overflow-y: auto;
}
.saveResourceChildSelect div.multiSelectContainer li {
    padding: 5px 0px;
   font-weight: normal;
}
.saveResourceChildSelect .form-select {
  padding-right: 10px;
}
.savepage .innerboxalignment input::placeholder,
.input-btn-contain input::placeholder {
	color: #65676B;
}
.save-resource-list .bookmoniterdivbox ul li {
	background:transparent;
	padding: 15px 5px;
    margin-bottom: 0;
}
.save-resource-list .bookmoniterdivbox ul li .sprash-name {
    width: 30%;
}
.save-resource-list .bookmoniterdivbox ul li .sprash-name-rt {
    width:68%;
}
.save-resource-list .sharepopupOuter .pop-up-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.save-resource-list .sharepopupOuter .pop-up-container .public-btn-save-p {
 	float:none;
	color:#fff;
	font-weight:600;
}
.save-resource-list .sharepopupOuter .pop-up-container .checkccc li {
    margin-top: 0;
    position: relative;
	margin-bottom: 10px;
}
.save-resource-list .sharepopupOuter .pop-up-container .checkccc li .error-span {
    position: absolute;
    left: 0;
    bottom: -17px;
}
.selectGroupouter .css-3w2yfm-ValueContainer {
	height: 40px;
    overflow-y: auto;
}
.nodataFoundinfo {
	 position:relative;
}
.nodataFoundinfo img {
	 border-radius:12px;
}
.nodataFoundinfo strong{
	display: block;
    text-align: center;
    background-color: rgba(255,255,255,0.6);
    padding: 20px 0px;
    border-radius: 12px;
    position: absolute;
    width: 96%;
    top: 20%;
    font-size: 34px;
    left: 20px;
}

.save-resource-list .bookmoniterdiv .bookmoniterdivbox .viewableFriend input:checked + .slider:before {
    transform: translateX(17px);
}
.save-resource-list .bookmoniterdiv .bookmoniterdivbox .viewableFriend .slider.round:before {
    width: 12px;
    border-radius: 50%;
    height: 12px;
	
}
.save-resource-list .bookmoniterdiv .bookmoniterdivbox .viewableFriend .slider.round {
    width: 33px;
    border-radius: 34px;
    height: 16px;
}
.save-resource-list .bookmoniterdiv .bookmoniterdivbox .viewableFriend .slider:before {
    position: absolute;
    content: "";
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
}
.save-resource-list .bookmoniterdiv .bookmoniterdivbox .viewableFriend .slider {
	 background-color: #ccc;
}
.save-resource-list .bookmoniterdiv .bookmoniterdivbox .viewableFriend input:checked + .slider {
  background-color: #f19357;
 
}
.save-resource-list .bookmoniterdivbox .addresourceratting {
    margin-top: 0px;
}
.sharingAction {
	 position:relative;
}
.sharingActionBox {
	position: absolute;
    left: -5px;
    bottom: -10px;
	width: 100%;
    display: flex;
	gap:5px;
}
.sharingActionBox .viewableFriend  {
      width: 40px;
}
.save-resource-list .bookmoniterdiv .multiselect-container .searchWrapper input::placeholder{
    color: #000;
}

.save-resource-list .bookmoniterdiv div.form-select {
    padding: 0.375rem 10px 0.375rem 0.75rem;
}
.save-resource-list .searchresource-div11 .savebtnsearch{
    float: left;
    margin-left: 10px;
}

.save-resource-list .searchresource-div11 .saveResourceListgradeRange,
 .save-resource-list .searchresource-div11 .search-exp-section-div1 select{
    width: 18%;
 }
div.multiSelectContainer .saveResourceChildSelect li,
div.multiSelectContainer.saveResourceSelectss li {
  font-size:11px;
}
 .save-resource-list .bookmoniterdiv  .saveResourceStudent .searchWrapper{
   overflow-y: auto;
  overflow-x: hidden; 
}
div.search-wrapper span.chip {
    font-size: 12px;
	font-weight:600;
}
div.input-btn-contain input{
    color: #000;
}
.saveResourceListgradeRange.gradeLevelDataSelect div.searchWrapper {
	width:88%;
	position:unset;
}
.serveyour-resource .saveResourceSelectss div.search-wrapper span.chip,
.save-resource-list .saveResourceSelectss div.search-wrapper span.chip {
    width: 90%;

}
.serveyour-resource .saveResourceSelectss div.search-wrapper span.chip,
.save-resource-list .saveResourceSelectss div.search-wrapper span.chip,
.servepaymenthistory .saveResourceSelectss div.search-wrapper span.chip{
    overflow: hidden;
   text-overflow: ellipsis;
    display: inline-block;
}

.servepaymenthistory .saveResourceSelectss div.search-wrapper span.chip{
    width: 88%;
}

.save-resource-list .bookmoniterdiv .bookmoniterdivbox .sprash-name .sprash-name-rts h3,
.servepaymenthistory .bookmoniterdiv .bookmoniterdivbox .sprash-name .sprash-name-rts h3,
.save-resource-list .saveResourceList .bookmoniterdivbox .sprash-name .sprash-name-rts h3{
    word-break: break-word;
}

@media only screen and (max-width:1200px){
.saveResourceListgradeRange, .search-exp-section-div1 select {
    width: 18%;
 }
 div.input-btn-contain,
 .save-resource-list .searchresource-div11 div.saveResourceListgradeRange, .save-resource-list div.searchresource-div11 .search-exp-section-div1 select {
    width: 17%;
}
.save-resource-list div.bookmoniterdivbox ul li .sprash-name-rt {
    width: 65%;
}
.bookmoniterdiv h2 {
   font-size: 23px;
}
}
@media only screen and (max-width:991px){
.saveResourceListgradeRange, .search-exp-section-div1 select {
    width: 16%;
}
.saveResourceListgradeRange, .search-exp-section-div1 select {
    width: 16%;
}
div.saveResourceListgradeRange, div.search-exp-section-div1 select {
    margin-right:8px;
}
.save-resource-list div.bookmoniterdivbox ul li .sprash-name-rt {
    width: 64%;
}
.save-resource-list div.searchresource-div11 .savebtnsearch {
    margin-left: 5px;
}
}
@media only screen and (max-width:767px){

.saveResourceList .form-select {
margin-bottom: 6px;
}
.save-resource-list .addresource {
    margin-bottom: 10px;
}
.search-exp-section-div1 h3 {
	float:none;
}
.search-exp-section-div1 .input-btn-contain,
.saveResourceListgradeRange, .search-exp-section-div1 select {
    width: 45%;
    margin-bottom: 15px;
}
.saveResourceListgradeRange .form-select {
    height: 27px;
}

.save-resource-list .searchresource-div11 div.saveResourceListgradeRange,
.save-resource-list .searchresource-div11 div.search-exp-section-div1 select {
    width: 32%;
}
}
@media only screen and (max-width:480px){

    .save-resource-list .searchresource-div11 .saveResourceListgradeRange .form-select .multiSelectContainer .search-wrapper img{
        position: absolute;
        right:5px;
    }
    .save-resource-list .saveresource-list i {
        font-size: 20px;
        position: relative;
        right: 5px;
    }

	body.save-resource-list {
		background:#f6f8fa;
	}
.save-resource-list .searchresource-div11 div.search-exp-section-div1 select, .save-resource-list .searchresource-div11 div.saveResourceListgradeRange {
    margin: 10px 0;
	width:100%;
	padding:0 10px;
}
.search-exp-section-div1 .input-btn-contain {
    width:73%;
}
.save-resource-list .bookmoniterdivbox ul li .sprash-name-rt {
    width: 96%;
}
.saveResourceList .form-select {
    margin-bottom: 6px;
    }
    .save-resource-list .addresource {
        margin-bottom: 10px;
    }
    .save-resource-list .bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box {
        width: 50%;
    }
    .save-resource-list .bookmoniterdivbox ul li .sprash-name {
        width: 100%;
    }
    /* .save-resource-list .bookmoniterdivbox .sprash-name-rt .sprash-name-box:last-child,
     .save-resource-list .bookmoniterdivbox ul li .sprash-name-rt {
       display: inline-block;
    } */


   .save-resource-list .bookmoniterdivbox .viewrattinglist{
    font-size: 12px;
    position: relative;
    right: 15px;
}
.save-resource-list div.bookmoniterdivbox ul li .sprash-name-rt {
    width: 100%;
}
div.myrating small {
    margin-left: 3px;
}

.save-resource-list .sprash-name-box .minGradeHeight{
    display:inline-block ;
}

}
