.pop-up-container {
  background: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  max-width: 610px;
  border-radius: 16px;
  padding:10px 20px 20px 20px;
  width: 100%;
  margin:15px auto;
}
.pop-up-container.InviteFriendInspiration,
.pop-up-container.reportPopupContainer,
.pop-up-container.saveresourcePopupLarge {
	max-width: 950px;
}

.pop-up-container.saveresourcePopupLarge .sharediv-popups,
.pop-up-container.saveresourcePopupLarge .savepopup-content{
  max-height: 380px;
}
.pop-up-container.saveresourcePopupLarge .sharediv-popups {
  	 min-height:220px;
	overflow-y: auto;
    overflow-x: hidden;
}

.pop-up-container.saveresourcePopupLarge .savepopup-content .viewableFriend {
	padding-left:20px;
}
.pop-up-container.saveresourcePopupLarge .savepopup-content .input-popup1,
.pop-up-container.saveresourcePopupLarge .sharediv-popups .input-popup1 {
  padding: 0 5px;
}
.pop-up-container .input-popup1 .save-popup-top .multiSelectContainer .searchWrapper{
  overflow-y: auto;
  min-height: 30px;
}
.pop-up-container.saveresourcePopupLarge .sharediv-popups .input-popup2 {
	margin-bottom:5px;
}

.pop-up-container.saveresourcePopupLarge .sharediv-popups .input-popup1 .css-1nmdiq5-menu,
.pop-up-container.saveresourcePopupLarge .savepopup-content .save-popup-top .css-1nmdiq5-menu,
.pop-up-container.saveresourcePopupLarge .savepopup-content .input-popup3 .css-1nmdiq5-menu,
.pop-up-container.saveresourcePopupLarge .sharediv-popups .input-popup2 .css-1nmdiq5-menu {
	margin-top:-4px;

}
.pop-up-container.saveresourcePopupLarge .sharediv-popups .input-popup1 .css-1nmdiq5-menu > div,
.pop-up-container.saveresourcePopupLarge .savepopup-content .save-popup-top .css-1nmdiq5-menu > div,
.pop-up-container.saveresourcePopupLarge .savepopup-content .input-popup3 .css-1nmdiq5-menu > div {
	max-height:97px;

}
.saveresourcePopupLarge .savepopup-content .input-popup3 {
  padding: 0 5px;
}
.saveresourcePopupLarge .savepopup-content .input-popup3,
.saveresourcePopupLarge .savepopup-content .input-popup2 {
	margin-bottom:10px;
}

.remove-popup-container h3,
.pop-up-container h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #2d3134;
  margin-bottom: 15px;
}
.pop-up-container h2{
  font-size: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  text-align: left;
  line-height: 36px;
  color: #2d3134;
}
.pop-child {
     margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2d3134;
    width: 100%;
    text-align: left;

}
.pop-label {
  float: left;
  margin: 0px 0px 10px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2d3134;
  width:100%;
}
.pop-grade {
   margin: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2d3134;
  text-align:left;
}

.input-popup1,
.input-popup2,
.input-popup3 {
	display: inline-block;
    width: 100%;
	margin-bottom: 15px;
	text-align: left;
}
.popup-btn-div {
  display:inline-block;
  width: 100%;
  text-align:center;
   margin-top: 15px;
}
.popup-btn-div button {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-transform: capitalize;
  color: #ffffff;
  border: none;
  background: none;
  outline: none;
   background: #f19357;
  border: 1px solid #f19357;
  border-radius: 8px;
   padding: 10px;
   height: 44px;
   min-width: 139px;
    line-height: 22px;
}

.closecs {
  font-size: 20px;
  line-height: 34px;
    cursor: pointer;
}
.text-inner {
  margin-bottom: 20px;
}
.cancel-butn button {
	  margin: 0px 25px 0px 0px;
}
.save-cancel-btn .savee button,
.save-cancel-btn .cancel-butn button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #2d3134;
  height: 44px;
  line-height: 28px;
  border-radius: 8px;
  padding: 8px;
   font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-transform: capitalize;
}

.cancel-butn {
  margin-right:10px;

}
.savee,.cancel-butn  {
      display: inline-block;
}

.cancel-butn:hover,.cancel-butn:hover button,
.savee:hover,.savee:hover button {
	background: #f19357;
	color:#fff;
	border-radius: 8px;
}
.select-form {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #5b5f62;
}

.create-btn {
  width: 139px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 10px 0px 10px 0px;
  display: inline-block;
  margin: 0px 25px;
}
.create-btn button {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: #2d3134;
  border: none;
  background: none;
  display: inline-block;
}
.send-link-btn {
  width: 139px;
  height: 44px;
  background: #f19357;
  border: 1px solid #f19357;
  border-radius: 8px;
  display: inline-block;
  padding: 6px;
}
.send-link-btn button {
  font-family: "effra trialregular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-transform: capitalize;
  color: #ffffff;
  border: none;
  background: none;
}
.create-link-btn {
    display: inline-block;
    text-align: center;
    width: 100%;
}
.create-link-btn button {
    /* width: 140px;
    padding: 10px; */
}
.create-link-btn .copyLink{
margin: 0px;
}
.pop-h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2d3134;
  text-align: left;
}
.radio-check-div {
  text-align: left;
  margin-bottom: 95px;
}
.radio-check-div label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #2d3134;
  margin: 10px 10px;
}
.Rankingg-div input[type="checkbox"] {
  width: 20px;
  height: 20px;
  background: #f19357;
  border-color: #f19357;
}

.pop-label Select {
  text-align: left;
  float: left;
}
.pop-label-div {
  margin: 0px 0px 18px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2d3134;
  float: left;
}
select option, select {
  font-family: "Inter";
}

.css-b62m3t-container .css-13cymwt-control {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #5b5f62;
  width: 100%;
}
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
  margin: 0 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f19357;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f19357;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.viewableFriend h5 {

  text-align: left;

}
.viewablebyFriend-p{
  color: #82A3A1!important;
}
.searchpage .input-popup2 .error,
.searchpage .input-popup3 .error{
	top:0;
}
 .input-popup2 .css-3w2yfm-ValueContainer {
	height: 40px;
}
.servepaymenthistory .css-3w2yfm-ValueContainer,
.save-resource-list .css-3w2yfm-ValueContainer,
.your-main-group .css-3w2yfm-ValueContainer,
.shareinspiration .css-3w2yfm-ValueContainer, .profilepage .css-3w2yfm-ValueContainer,
.input-popup2 .css-3w2yfm-ValueContainer,
.searchpage .css-3w2yfm-ValueContainer {
	   overflow-y: auto;
}
  .pop-up-container .select-one{
    font-size: 13px;
}
.savepopup-content .select-one{
    position: relative;
}
.pop-up-container .searchWrapper input::placeholder{
  color:#212529 ;
}
.pop-up-container .pop-child,
.pop-up-container .pop-grade{
  font-size: 15px;
}
.pop-up-container .pop-grade,
.pop-up-container .pop-child{
  margin-left: 13px;
}
.savepopup-content .pop-child:first-letter,
.savepopup-content .pop-grade:first-letter {
	text-transform:uppercase;
}
.pop-up-container .search-wrapper .chip{
  font-size: 1rem;
  line-height: 31px;
  color: #212529;
  font-weight: 400;
}
.savepopup-content+.viewableFriend h5 {
	margin-left:15px;
}
.savepopup-content .error {
	margin-left:15px;
	top: auto;
}
.your-main-group .pop-up-container .input-popup1 .error-span,
.searchpage .pop-up-container .input-popup1 .error-span,
.shareinspiration .pop-up-container .input-popup1 .error-span,
.your-main-group .input-popup2 .about-div h2,
.shareinspiration .input-popup2 .about-div h2,
.searchpage .input-popup2 .about-div h2,
.your-main-group .input-popup1 .pop-label,
.searchpage .input-popup1 .pop-label,
.shareinspiration .input-popup1 .pop-label{
  margin-left: 10px;
}
.sharepopupOuter .saveresourcePopupLarge .input-popup3 div.searchWrapper,
.pop-up-container.saveresourcePopupLarge .search-wrapper .chip {
    font-size: 14px;
}

.pop-up-container.saveresourcePopupLarge .striptableList li:hover {
	background:transparent;
	color:#5B5F62;
}
.saveresourcePopupLarge .striptableList {
 display: inline-block;
    width: 100%;
}
.saveresourcePopupLarge .striptableList li {
	width:33.3%;
	min-height: 50px;
}
.striptableListInfo {
	margin-top:-4px;
}
.saveresourcePopupLarge ul.striptableList.striptableListHeading {
	margin-top:5px;
}
.saveresourcePopupLarge ul.striptableList.striptableListHeading li:hover,
.saveresourcePopupLarge ul.striptableList.striptableListHeading li {
	font-weight: bold;
    color: #fff;
    background: #F19357;
	display: flex;
    align-items: center;
}
.reportPopupContent {
	max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    padding-right: 10px;
}
.reportPopupContent .form-select:focus {
   box-shadow: none;
}
.reportPopupContent .about-div div {
    height: 100px;
}
.reportPopupContent .input-popup1,.reportPopupContent .input-popup2 {
    margin-bottom: 10px;
}
.form-check-input:checked {
  background-color: #f19357 !important;
  border-color: #f19357 !important;
}
.save-popup-top .chip {
	position:relative;
}
.saveresourcePopupLarge .save-popup-top .search-wrapper .closeIcon {
    display: block;
	width:0px;
}
.saveresourcePopupLarge .save-popup-top .chip:before {
   content: " , ";
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    font-size: 20px;
}
.saveresourcePopupLarge .save-popup-top .multiSelectContainer .searchWrapper span.chip:last-child {
   font-size:0;
}
@media only screen and (max-width:767px){
    .saveresourcePopupLarge ul.striptableList li {
        min-height: 80px;
    }
}
@media only screen and (max-width: 576px) {
  .pop-up-container{ margin: 20px auto;}
  .css-b62m3t-container .css-13cymwt-control{height: 52px;}
  .remove-popup-container h3, .pop-up-container h3 {
	  font-size: 20px;
  }
  .reportPopupContent .about-div div {
    height: 75px;
  }
  .save-cancel-btn {
    margin: 10px 0;
    width: 100%;
    display: inline-block;
  }
}
