.containerparent .owncontainer {
    width: 1000px;
    margin: -150px auto 0;
}

.comunity {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
    color: #2D3134;
    text-align: left;
    margin-top: 63px;
}

.parent-form-container {
    background-color: #F5F5F5;

}

.ant-upload-list-item {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50px;
}

.ant-upload .ant-upload-select {
    margin-left: 150px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container {
    float: left;
    margin: 0;
}

.parent-form-para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #5B5F62;
    margin-top: 8px;
    text-align: left;
    margin-bottom: 12px;
}

.parent-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #F19357;
    margin-top: 3px;
    margin-bottom: 20px;
    text-align: left;
    float: left;
}

.parentragistrationform .css-t3ipsp-control,
.parentragistrationform .css-13cymwt-control {
    height: 52px;
}

.parentragistrationform .radio-div-3 {
    margin-top: 10px;
}

.parentragistrationform div.css-3w2yfm-ValueContainer {
    height: 40px;
    overflow-y: auto;
}

.parentragistrationform .css-t3ipsp-control {
    height: none;
}

.img-fluid {
    border-radius: 50px;
    background-repeat: no-repeat;
    background-size: cover;
}

.profile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin-top: 50px;
    text-align: left;

}

.input-file1 .profile-div,
.childinfo .profile-div,
.parentragistrationform .profile-div {
    position: relative;
}
.parentragistrationform .facustomeI{
position: absolute;
    right: 19px;
    bottom: 39px;
    font-weight: 800;
}
.parentragistrationform .facustomeI div.tootlippillerinfo {
right: 22px;
left: auto;
}
.parentragistrationform .tootlippillerinfo:before {
    transform: rotate(270deg) translate(30%, 100%);
    left: 255px;
    top: 17px;
    border-top-color: #F19357;
}

.input-file1 {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    width: 244px;
    height: 44px;
    color: #2D3134;
    padding: 13px;
}

.childinfo .input-file1,
.parentragistrationform .input-file1 {
    position: absolute;
    left: 140px;
    text-align: center;
}

.childinfo .input-file1,
.parentragistrationform .input-file1 {
    top: 71px;
}

.childinfo .input-file1 {
    top: 30px;
}

.parentragistrationform .input-file1 {
    top: 30px;
}

.upload11 {
    line-height: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    font-family: 'Rubik', sans-serif;
    text-transform: capitalize;
}

.profile-div {
    display: inline-block;
}

.file-handle {
    position: relative;
    bottom: 18px;
    opacity: 0;
}
.phone-num-inner{
    margin-left: 15px;
}
.phone-num-inner,
.form-label0 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-top: 32px;
}
.form-label0 {
    float: left;
}

.form-label11 {
    float: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-top: 8px;
    margin-bottom: 6px;
}

.form-label2 {
    float: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-top: 8px;
    margin-bottom: 0px;
}

.start-color {
    color: #F04923;
}

#input1 {
    padding: 15px 24px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 1000px;
}

#input2 {
    padding: 15px 24px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 1000px;
}

#input3 {
    padding: 15px 24px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 1000px;
}

.parentragistrationform #input3,
.parentragistrationform #input2,
.parentragistrationform #input1 {
    padding: 15px;
}

.parentragistrationform .form-label2,
.parentragistrationform .form-label0 {
    margin-left: 15px;
}

.first-child-detail-con {
    background: #E8F0F4;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    padding: 0px 20px 20px 20px;
    margin-top: 26px;
}

.first-child-h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
    margin-top: 28px;
    text-align: left;
    text-transform: capitalize
}

.radio-div {
    text-align: left;
    margin-bottom: 30px;
}

.radio-div1 {
    text-align: left;
    margin-bottom: 20px;
}

.container-2 {
    background: #E8F0F4;
    border: 0.5px solid #BFBCB8;
    border-radius: 20px;
    padding: 15px 20px 28px 20px;
    text-align: left;
}

.form-h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    text-align: left;
    margin-bottom: 10px;
}

.radio-btn-handle {
    width: 100%;
}

input[type="radio"].form-check-input {
    border: 1px solid #c1bdbd;
}

.form-h31 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-top: 26px;
    margin-bottom: 15px;
    text-align: left;
}

.form-h32 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-top: 22px;
    margin-bottom: 15px;
    text-align: left;
}

.handle-radio {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    height: 28px;
    color: #5B5F62;
    margin: 0px 35px 0px 10px;
}

.radio-div-3 {
    text-align: left;
}

input[type="radio"]:checked {
    background-color: #F19357;
    border-color: #F19357;
}

.save-btn-parent {
    float: right;
    margin: 0 0 40px 0;
}

.save-button11 {
    padding: 12px;
    width: 139px;
    height: 44px;
    border: none;
    border-radius: 8px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #F19357;
    text-align: center;
}

.select-one {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1%;
    color: #5B5F62;
}

.error1 {
    color: red;
    font-family: Inter;
    font-size: 12px;
    position: relative;
    top: 5px;
    display: block;
    text-align: left;

}

.parentragistrationform .error1 {
    left: 15px;
}

.f-con {
    text-align: left;
}

.last-container {
    text-align: left;
}

.uploaddesign {
    border-radius: 200px;
    border: 1px solid #7a1717;
    margin-left: 120px
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
    border: none;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list .ant-upload-list-item-container {
    width: 15% !important;
    height: 15% !important;
    top: 0;
    right: auto;
    left: 0 !important;
    display: flex;
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-name {
    display: none;
    text-align: center;
}

.ant-upload.ant-upload-select {
    text-align: center;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px dashed #faf7f7;
    border-radius: 8px;
    cursor: pointer;

}

::-moz-placeholder {
    color: pink;
}

:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail img, 
:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-thumbnail img,
:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item .ant-upload-list-item-thumbnail img,
:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail img,
:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail {
    object-fit: initial !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
    margin-inline-end: unset !important;
    transition: none !important;
    float: left;
    margin: 0;
    position: relative;
    left: 10px !important;
    top: 10px !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container {
    margin-block: 0;
    margin-inline: 0;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper.ant-upload-picture-card-wrapper,
:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper.ant-upload-picture-circle-wrapper {
    position: relative;
}

.ant-upload-list-item {
    height: 120px !important;

}

:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container,
:where(.css-dev-only-do-not-override-yp8pcc) a,
:where(.css-dev-only-do-not-override-ed5zg0).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container,
:where(.css-dev-only-do-not-override-ed5zg0).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-ed5zg0).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-ed5zg0).ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
    transition: none !important;
}

.ant-upload-list-item-container,
.ant-upload.ant-upload-select {
    float: left;
}

.ant-upload-list-item-container {
    position: absolute;
    left: 0;
    top: 0;
}

.parentragistrationform form,
.profileStripSetings {
    display: inline-block;
    width: 100%;
}

.profileStripSetings h2.profile {
    float: left;
}

.strip-activation {
    float: right;
    margin-top: 50px;
    background: #f19357;
    border: 0.21px solid #f19357;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    border-radius: 50px;
    height: 31px;
    line-height: 16px;
    padding: 5px 20px;
}

.parentragistrationform .strip-activation {
    float: right;
}

.strip-activation:hover {
    border: 0.21px solid #BFBCB8;
    background: #fff;
    color: #000;
}

.biotextarea .form-check {
    padding-left: 0;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-card-wrapper,
:where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper {
    display: inline-block;
    width: 100%;
    min-height: 100px;
}
/* :where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, 
:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select,
:where(.css-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {

    position: absolute;
    left: 141px;
    transition: none !important;
    display: block !important;
    height: 44px;
    width: 242px;
    border-radius: 8px;
    top: 30px;
    border: 1px solid #D9D9D9;
    background-color: rgba(255, 255, 255, 0);
}
.form-container-parent{
    margin-top: 50px;
} */

:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, 
:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select,
:where(.css-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
    position: absolute;
    left: 141px;
    transition: none !important;
    display: block !important;
    height: 44px;
    width: 242px;
    border-radius: 8px;
    top: 30px;
    border: 1px solid #D9D9D9;
    background-color: rgba(255, 255, 255, 0);
}
.form-container-parent{
    margin-top: 50px;
}

.childinfo :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
    left: 141px;
}

.childinfo .ant-upload .text,
.parentragistrationform .ant-upload-select .ant-upload .text {
    font-size: 0;
}

.parentragistrationform .form-container-parent .css-1dyz3mf {
    height: 40px;
    overflow-y: auto;
}

.parentragistrationform .react-tel-input input{
    width: 100%;
    height: 50px;
    border-radius: 50px;
}

.parentragistrationform .react-tel-input .flag-dropdown{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.parentragistrationform .react-tel-input .selected-flag:hover,
.parentragistrationform .react-tel-input .selected-flag:focus{
   border: 1px solid #dfdfdf;
   border-top-left-radius: 50px;
   border-bottom-left-radius: 50px;
 }



 
 .parentragistrationform .react-tel-input .flag-dropdown.open .selected-flag {
    background: #fff;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border: 1px solid #dfdfdf;
}

.parentragistrationform .zip-code-inner .facustomeI {
    position: absolute;
    right: 19px;
    bottom: 16px;
    font-weight: 800;   
}


:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail img, 
:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-thumbnail img,
:where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item .ant-upload-list-item-thumbnail img,
:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail img,
:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail {
    object-fit: initial !important;
}

/* .sign-up .signupouter .form-group .react-tel-input{
    position: absolute;
    height: 50px;
    width: 0;
} */
/* .parentragistrationform .form-container-parent .react-tel-input {
    position: absolute;
    height: 52px;
    width: 0;
  
} */
/* .sign-up .signupouter .addinput-style,
.parentragistrationform .form-container-parent .addinput-style{
    padding: 1px 15px 0px 100px;
} */
/* .parentragistrationform .form-container-parent .dialcode-sapn{
font-size: 1rem;
position: absolute;
top: 76px;
left: 56px;
z-index: 11;
} */
/* .sign-up .signupouter .dialcode-sapn{
font-size: 1rem;
position: absolute;
top: 15px;
left: 42px;
z-index: 11;
} */


/* .sign-up .signupouter .react-tel-input .selected-flag,
.parentragistrationform .form-container-parent .react-tel-input .selected-flag {
    width: 95px;
} */


@media only screen and (max-width:991px) {
    .parent-form-container {
        padding: 27px;
    }

    .containerparent div.owncontainer {
        max-width: 1000px;
        margin: 50px auto 0;
        width: 100%;
    }
}

@media only screen and (max-width:480px) {

    .profilestripSetings .strip-activation {
        margin-top: 50px;
    }

    .parent-form-container {
        padding: 20px;
    }

    .parent-form-para {
        width: 100%;
        margin-bottom: 38px;
    }

    .profile-div {
        margin-top: 0px;
        padding: 0px 0px 2px 0px;
    }

    .input-file1 {
        width: 50%;
    }

    .form-label0 {
        text-align: left;
    }

    .upload11 {
        font-size: 19px;
    }
}