.create-new {
  padding-left: 75px;

}

.create-new .create-new-group h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #2D3134;
  margin-top: 41px;
}
.createnewGroup .create-new-form .jodit-react-container .jodit-wysiwyg{
  max-height: 156px;
}
.createnewGroup .create-new-group .deshbord span i {
  margin-right: 10px;
}


.create-new .create-new-group p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #2D3134;
  margin-top: 9px;
  width: 60%;
  margin: 0px auto;
}

.create-new .create-inner {
  display: flex;
  margin-top: 51px;
   margin-bottom: 25px;
}

.create-new .icon-outer {
  width: 100px;
  height: 100px;
  border-radius: 16px;
}

.create-new .icon-outer img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.create-new .upload .input-file-div {
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  margin-top: 30px;
  width: 100%;
  max-width: 218px;
  height: 44px;
  color: #2D3134;
  padding: 11px;
  cursor: pointer;
  margin-left: 38px;
}

.create-new .upload .input-file-div .upload-div {
  /* font-family: 'effra_trialregular'; */
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  cursor: pointer;
}

.file-handle-div {
  position: relative;
  bottom: 18px;
  opacity: 0;
  cursor: pointer;
}

.create-new .css-b62m3t-container .css-13cymwt-control {
  text-align: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  /* padding-left: 15px; */
  font-size: 18px;
  line-height: 28px;
  color: #5B5F62;
  width: 100%;
  height: 52px;

}

.createnewGroup .css-b62m3t-container .css-t3ipsp-control {
  box-shadow: none;
  border: 1px solid hsl(0, 0%, 80%) !important;
  height: 52px;
  border-radius: 50px;
  width: 100%;
}

.create-new .about-group textarea {
  width: 100%;
  height: 144px;
  background: #FFFFFF;
  border-radius: 20px;
  border: none;
  font-family: 'Inter';
  padding: 15px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 1.5rem;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #F19357;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked+.switch::before {
  transform: translateX(25px);
  background-color: white;
}

.toggle-switch input[type="checkbox"]:checked+.switch {
  background-color: #F19357;
}

.selecetedBorder {
  border: 2px solid green;
}

.create-new .permission-group {
  text-align: left;
}

.create-new .group-chat-permission .form-h3 {
  margin-left: 15px;
}

.create-new .permission-group .form-h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-top: 28px;
  text-align: left;
  margin-bottom: 10px;

}

.permission {
  position: relative;
  width: 100%;
}


.create-new .label-form-div {
  margin:15px 0 0 15px;
  float: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2D3134;

}

.text-start {
  float: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2D3134;
}

.create-new .label-form-div span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1%;
  color: #5B5F62;
}

.create-new .label-form-div strong {
  color: red;
}

.create-new .permission {
  display: flex;

}

.create-new .r-title-div div {
  padding: 10px 15px 15px 15px;
}


.create-new .MuiAutocomplete-tagSizeMedium,
.create-new .css-1q79v3g-MuiButtonBase-root-MuiChip-root {
color: #fff;
  background-color: #F19357;
}


.create-new .MuiAutocomplete-tagSizeMedium .MuiChip-deleteIcon,
.create-new .css-1q79v3g-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  color: #fff;
}
.create-new .css-b62m3t-container .css-13cymwt-control {
 height:52px;
 overflow:hidden;
}
.create-new .plus-div {
  width: 27px;
  height: 27px;
  background: #F19357;
  border-radius: 50px;
}

.admin-group {
  display: flex;
  margin-top: 29px;
}

.create-new .group-chat-permission .permission h3,
h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.2px;

  color: #545454;
}

.create-new .group-chat-permission .permission label {
  margin-left: 15px;
}

.create-new .group-chat-permission .permission h4 {
  margin-left: 15px;
}

.create-new .admin-group h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2D3134;

}

.create-new .admin-group .plus {
  width: 27px;
  height: 27px;
  border-radius: 50px;
  background: #F19357;
  color: white;
  font-size: 17px;
  font-weight: 400;
  margin-top: 5px;
  position: relative;
  bottom: 4px;
  left: 10px;
  padding: 5px;
}

.create-new .last-space {
  display: flex;
  justify-content: space-between;
  width: 97%;
  margin-top: 30px;
  margin-bottom: 100px;
}

.create-new .last-space .email {
  margin-right: 30px;
}

.create-new .last-space .minus {
  width: 39px;
  height: 39px;
  border-radius: 50px;
  border: 2px solid #D9D9D9;
  padding: 10px 6px 10px 7px;
  margin-top: 45px;
}

.slider.round {
  width: 50px;
  border-radius: 34px;
  height: 21px;
}

.slider.round:before {
  width: 18px;
  border-radius: 50%;
  height: 18px;
}

.title-group .form-control,
.location-group .form-select,
.last-space .form-control,
.url-link .form-control {
  /* padding: 1px 24px 1px 10px; */
  padding: 1px 24px 1px 15px;
}

.create-new .css-t3ipsp-control ::placeholder {
  margin-left: 15px;
}

/* .location-group .form-select{
	padding-left:24px;
} */
.createnewGroup .css-1fdsijx-ValueContainer,
.createnewGroup .css-t3ipsp-control,
.createnewGroup .css-3w2yfm-ValueContainer {
  padding-left: 10px;

}
.createnewGroup .create-new-form .css-t3ipsp-control .css-1dyz3mf,
.createnewGroup  .create-new-form .css-13cymwt-control .css-1dyz3mf,
.createnewGroup .css-1fdsijx-ValueContainer,
.createnewGroup .css-3w2yfm-ValueContainer {
   height:40px;
  overflow-y: auto;
}
.seleter-div .create-new .css-b62m3t-container .css-13cymwt-control,
.seleter-div .create-new .css-b62m3t-container .css-13cymwt-control {
  padding: 0 !Important;
}

.createnewGroup .css-13cymwt-control .css-1fdsijx-ValueContainer,
.createnewGroup .css-t3ipsp-control,
.createnewGroup .css-b62m3t-container .css-t3ipsp-control {
  padding: 0 !Important;
}

.createnewGroup .css-1jqq78o-placeholder,
.createnewGroup .css-1jqq78o-placeholder {
  margin-left: 10px !Important;
}

.createnewGroup .css-13cymwt-control .css-b62m3t-container .css-wsp0cs-MultiValueGeneric {
  padding: 5px !important;
}

.createnewGroup .css-13cymwt-control .css-b62m3t-container .css-wsp0cs-MultiValueGeneric,
.createnewGroup .css-b62m3t-container .css-wsp0cs-MultiValueGeneric {
  padding: 5px 5px 5px 12px !important;

}

.create-new-form .val-span {
  color: red;
  margin-left: 15px;
}
.create-new-form .error-span {
   margin-left: 15px;
}

.create-new-form .form-label1 {
  margin: 15px 15px;
  margin-bottom: 10px;
}

.create-new-form .form-control {
  padding: 1px 15px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 50px;
  font-family: 'Inter';
}
.group-chat-permission .label-form-div,
.about-group .label-form-div {
	margin-left:0;
}
.about-group {
	position: relative;
    z-index: 0;
}
.ant-tooltip-inner
{
  display: none!important;
}
.createnewGroup .profile-div {
    position: relative;

}
.createnewGroup .input-file1 {
    position: absolute;
    left: 140px;
    text-align: center;
	top:30px;
}
.create-new-form .grade-range .css-t3ipsp-control .css-1dyz3mf,
.create-new-form .grade-range .css-13cymwt-control .css-1dyz3mf {
	height: 40px;
    overflow-y: auto;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail img {
   
  object-fit:cover !important;
}


/* .create-new .ant-upload-list-item-container :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-card-wrapper 
.ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail, :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail, :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img, :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img, :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail img, :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper 
.ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail img{
object-fit: cover!important;
} */

.createnewGroup .profileStripSetings{
	position:relative;
}
.createnewGroup .profileStripSetings button.deactive-group-update {
	position: absolute;
    right: 0px;
    top: -48px;	
}
.createnewGroup .profileStripSetings .strip-activation {
  margin-top: 35px;
}
.createnewGroup .create-new .profileStripSetings .strip-activation{
  width: 195px;
}
.createnewGroup .profileStripSetings button.deactive-group-update a {
	color:#fff;
	text-decoration:none;
}
.createnewGroup .profileStripSetings button.deactive-group-update:hover a,
.createnewGroup .profileStripSetings button.deactive-group-update a:hover {
	color:#000!Important;
}
.chating-all ul li {
  position: relative;
}
.delete-massage-outer {
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.createnewGroup .create-new .create-new-form{
  padding-top: 115px;
}
.createnewGroup .create-new .profile-div{
  display: inline-block;
  padding: 0;
}

@media only screen and (max-width:1280px) {
  .create-new {
    padding-left: 0px;

}

.createnewGroup .create-new .respprofile{
  position: relative;
  top: 50px;
  }
}
@media only screen and (max-width:991px){
.createnewGroup .create-new .respprofile{
  position: relative;
  top: 60px;
  }
 
}

@media only screen and (max-width:767px) {
  .createnewGroup .create-new {
    padding-left: 0;
  }
  :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
	  opacity:0;
	  top:0;
  }
  .create-new .create-new-group p {
   width:100%;
   }

}


@media only screen and (max-width:480px) {
  .create-new .create-new-group p {
    margin: 10px auto;
  }

  .strip-activation {
    margin-top: 0;
  }
  :where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select,
  .input-file1 {
    width: 170px;
   }

 :where(.css-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
	 width:168px;
 }
.createnewGroup .input-file1 {
  position: absolute;
  top:30px;
  }
  .createnewGroup .create-new .create-new-group p {
    padding-bottom: 45px;
  }
  .createnewGroup .create-new .profile-div{
    margin-top: 20px;
  }
  .newgroupmemberselectbox .share-multiple-form-select {
    margin-right: 0px;
    }

.yourgroup .your-main-groups .invite_button{
    padding: 10px 10px;
}
.createnewGroup .create-new div.resstripset{
  position: absolute;
  left: -25px;
  top: auto;

}

  
}


