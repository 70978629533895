.content-inspiration {
    height: auto;
    margin-top: 50px;
}

.like span i.fa.fa-thumbs-up {
    color: #fff;
}

.header-div h2 {
    text-align: left;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
    margin-bottom: 10px;
}

.inspiration-div .resource-img .resource-image2 {
    max-width: 100%;
    width: auto;
}

.header-down-div {
    margin-bottom: 25px;
    height: 124px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    background-color: white;
}

.sharewithheading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2D3134;
}

.down-div {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
}

.header-down-div img {
    margin-left: 10px;
    width: 52px;
    height: 52px;
}

.header-down-div .sharewantsComunity,
.header-down-div input {
    margin-left: 13px;
    margin-right: 25px;
    width: 100%;
    height: 52px;
    border: 1px solid #BFBCB8;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #FFFFFF;
    padding: 10px 10px 10px 10px;
    font-size: 18px;
    border-radius: 50px;
}

.header-down-div .sharewantsComunity {
    line-height: 31px;
    color: #65676B;
}

.multiSelectContainer input::placeholder,
.jane-image-div input::placeholder,
.header-down-div input::placeholder {
    color: #65676B;
}

.css-t3ipsp-control .css-9jq23d {
    -webkit-appearance: none;
    appearance: none;
    color: #fff
}


.header-shared h3 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
    text-align: left;
    margin-bottom: 20px;
}

.header-shared span {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #5B5F62;
}

.inspiration-inner-content {
    margin-bottom: 20px;
}

.inspiration-inner-content ul li.actives,
.inspiration-inner-content ul li:hover {
    color: white;
    text-align: left;

    background-color: #265472;
    text-decoration: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

.inspiration-inner-content ul {
    justify-content: space-between;
    display: flex;
}

.inspiration-inner-content ul li {
    float: left;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #5B5F62;
    text-align: left;
    width: 100%;
    list-style: none;
}

.nav-tabs .nav-link {
    padding: 8px 10px 0px 10px;
    border: none;
    color: #5B5F62;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #265472;
    border-color: none;
}

.bottomline {
    border: 1px solid #BFBCB8;
}

.inspiration-inner-content h2:hover {
    color: white;
    background: #265472;
    border-radius: 6px 6px 0px 0px;
    padding: 2px 6px 6px 6px;

}

.inspiration-inner-content .input-popup2 h2:hover {
    color: #2d3134;
    background: transparent;
    border-radius: 0;
    padding: 0;
}

.inspiration-div {
    border: 1px solid #BFBCB8;
    background-color: white;
    margin-bottom: 20px;

}

.inspiration-inner-div {
    padding: 15px 0px 5px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CED0D4;
    margin: 0 20px;
}

.inspiration-inner-div img {
    width: 50px;
    height: 50px;

}

.inspiration-inner-div h3 {
    text-align: left;

}

.inspiration-inner-div span {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    opacity: 0.5;

}

.lisa-header h3 {
    font-family: inter;
    text-overflow: ellipsis;
    width: 80px;
}

.inspiration-inner-div label,
.inspiration-inner-div h3 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin: 3px 0px;
    color: #2D3134;
}

.SummerCamp {
    display: block;
    justify-content: space-between;
    padding: 20px 20px 10px 20px;
}

.green-icon {
    width: 119px;
    height: 90px;
    background-color: green;
    border-radius: 16px;
    padding: 13px 13px
}

.inspirationthreedotsOuterdiv button svg {
    transform: rotate(90deg);
}

.green-icon img {
    width: 62px;
    height: 62px;

}

.summer-text h3 {
    cursor: pointer;
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: #2D3134;
}

.summer-text p {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #808080;
}
.summer-text-description-data .text-curriculum11 span {
    font-weight: 600;
}

.summer-text h5 {
    font-family: 'effra_trialregular';
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #808080;
}

.shareicon-img img {
    max-width: 19px;
    height: 24px;
}

.summer-text {
    text-align: left;
    padding: 0px 10px 5px 12px;
    width: 80%;

}

.text-curriculum111 {
    font-family: "Effra";
}

.like {
    display: flex;
}

.like span {

    background: #F19357;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 21px;
    text-align: center;
}

.comments {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 5px 0px;
    border-bottom: 1px solid #CED0D4;
    margin: 0 20px;
}

.comments .like label,
.like label {
    margin-left: 10px;
    font-size: 14px;
}

.comments1 {
    display: flex;
}

.comments1 span {
    margin-right: 20px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.comments1 label {

    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.comments-div {
    display: flex;
    justify-content: space-between;
    padding: 10px 92px 10px 94px;
    border-bottom: 1px solid #CED0D4;
    margin: 0 20px;
}

.comments-div .pointer {
    cursor: pointer;
}

.like-box {
    display: flex;
    font-size: 16px;
}

.like-box label {
    cursor: pointer;
    margin-left: 8px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.comments-box {
    display: flex;
    font-size: 16px;
}

.comments-box label {
    cursor: pointer;
    margin-left: 8px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.share-box {
    display: flex;
    font-size: 16px;
}

.share-box label {
    cursor: pointer;
    margin-left: 8px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.comment-div-box {
    display: flex;
    margin-top: 10px;
}

.comment-div-box img {
    width: 32px;
    height: 32px;
    text-align: left;
    margin-right: 10px;
}

.See-more-div {
    padding: 11px 20px 25px 20px;
    cursor: pointer;
}

.inspiration-inner-content .MuiPaper-elevation1 {
    box-shadow: none;
}

.inspiration-inner-content .MuiPaper-root {
    background-color: transparent
}

.See-more-div h4 {
    text-align: left;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #5B5F62;

}

div.joana-comments {
    width: 100%;
    height: 100%;
    background: #F5F5F5;
    border-radius: 18px;
    padding: 7px 0px 5px 10px;
}

.joana-comments h3 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #2D3134;
}

.joana-comments span {
    /* text-align: left;
    margin-right: 15px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px; */
    display: flex;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5F62;
    word-break: break-word;
}

.comments-time {
    width: 164px;
    display: flex;
    justify-content: space-between;
    margin: 4px 56px;
    margin-bottom: 10px;
}

.comments-time span {
    font-size: 14px;
    font-family: 'Inter';

    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #65676B;
}

.jane-image-div {
    display: flex;
}

.jane-image-div img {
    width: 32px;
    height: 32px;
}

.jane-image-div input {
    width: 100%;
    height: 36px;
    /* font-size: 18px; */
    background: #F5F5F5;
    border-radius: 30px;
    padding: 6px 15px 7px 13px;
    margin-left: 10px;
    border: none;
}



.inspiration-inner-div1 {
    width: 100%;
    background: #E8F0F4;
    border-radius: 16px;
    padding: 20px 10px 40px 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inspiration-inner-div1 .sharesidebarimgBox {
    width: 276px;
    height: 276px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 20px;
}

.inspiration-inner-div1 img {

    height: 100%;
    width: 100%;
}

.inspiration-inner-div1 h3 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 17px;
    line-height: 30px;
    text-align: center;
    color: #265472;
}

.button-div button {
    width: 100%;
    height: 47px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-top: 5px;
}

.messanger-inner {
    width: 95%;
    padding: 20px 0px 0px 0px;
    background-color: white;
    border: 1px solid #BFBCB8;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 6px 6px 0px 0px;
}

.messanger-inner-div img {
    max-width: 40px;
    height: 40px;
}

.messanger-inner-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 5px 10px;
}

.Messanger .messanger-inner-div {
    margin-bottom: 0px;
}

.messanger-inner-div .user-name {
    color: #000000;
    font-weight: 700;
    font-size: 14px;
    margin: 5px 0px;
    text-transform: capitalize;
    font-family: 'Inter';
    width: 75%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 33px;
}

.messagethreedostpopup {
    z-index: 5;
}

.inspiration-div1 {
    margin-top: 50px;
}

.edit-button {
    display: flex;
}

.edit-button span {
    margin-right: 13px;
    cursor: pointer;
}

.four {
    background: #F19357;
    border-radius: 50%;
    font-size: 16px;
    width: 22px;
    height: 22px;
    color: white;
    display: inline-block;
    margin-right: 4px;
    padding: 0;
    font-weight: 100;
    line-height: 23px;
    text-align: center;
}

.icons-inner {
    text-align: left;
    position: relative;
}

.icons-inner input {
    width: 100%;
    height: 46px;
    padding: 6px 8px 6px 50px;
    background: #F3F3F0;
    border-radius: 6px;
    border: none;
    font-size: 18px
}

.icons-inner input::placeholder {
    color: #65676B;
}

.icons-inner span {
    font-size: 30px;
    position: absolute;
    bottom: 5px;
    left: 13px;
}

.green-icon1 {
    background-color: #F19357;
    width: 119px;
    height: 90px;
    border-radius: 16px;
    padding: 13px 13px;
}

.green-icon1 img {
    max-width: 62px;
    height: 62px;
}

.boy-images img {
    width: 100%;
    height: 300px;
    padding: 10px;
}

.boy-images p {
    padding: 17px 10px 10px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
}

.messanger-image img {
    max-width: 55px;
    height: 55px;
    border-radius: 20px;
}

.lisa-header {
    text-align: left;
}

.lisa-header h1 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #2D3134;
}

.lisa-header p {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #65676B;
}

.time-div label {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12.4615px;
    line-height: 15px;
    align-items: center;
    color: #5B5F62;

}

.time-div-inner span {
    background: #F19357;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    display: inline-block;
    line-height: 16px;
    text-align: center;
    font-size: 11px;
    color: white;
}

.table td {
    border-bottom: 1px solid #cdcdcd;
    border-top: 1px solid #cdcdcd;
}

.button-div {
    padding: 0px 0px 30px 0px;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #2D3134;
}

.inspiration-inner-div1:hover {
    border: 1px solid black;
}



/* chat */
.toggle-of-chat {
    color: #2D3134;
    font-size: 12px;
}

.form-check {
    margin-right: 0px !important;
}

.form-check-input {
    clear: right;

}

.form-switch.form-switch-sm {
    margin-bottom: 0;
}

.form-switch.form-switch-sm .form-check-input {
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem;
    cursor: pointer;
}

.sendBtn {
    height: 30px;
    width: 35px;
    background: lightgray;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    border: none;
}

.PrivateTabIndicator-root-1 {
    height: 0px !important;
}

.PrivateTabIndicator-colorPrimary-2 {
    background-color: none !important;
    height: 0px;
}

.MuiTab-textColorPrimary.Mui-selected {
    background: #265472;
    color: white !important;
    border-radius: 10px 10px 0px 0px;
}

.servepaymenthistory .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize;
}

.inspiration-inner-content .MuiTabs-flexContainer .MuiTab-root {
    min-height: 35px;
}

.PrivateTabIndicator-colorPrimary-10 {
    background-color: none !important;
}

.PrivateTabIndicator-colorPrimary-6 {
    background-color: none !important;
}

.friendsFild {
    width: 100%;
    display: inline-block;

}

.border-bottom-inspire {
    border-bottom: 1px solid #BFBCB8;
}

.friendsFild .text {
    margin-top: 10px;
    color: #000000;
    font-weight: 600;
    text-align: left;
    float: left;
    margin-right: 20px;
}

.friendsFild .friendSelect {
    margin-top: 0px;
    margin-bottom: 10px;
    float: left;
}

.friendsFild .form-select {
    width: 100%;
    height: 31px;
    border-radius: 50px;
    padding: 0 15px;
    font-size: 12px;
    line-height: 23px;
    font-weight: 600;
    color: #2D3134;
    font-family: 'Inter';
}

.friendsFild .form-select input::placeholder {
    font-weight: 600;
    color: #2D3134;
}

.friendsFild .multiSelectContainer input {
    margin-top: 0;
}



.friendsFild div.searchWrapper {
    line-height: 17px;
    overflow-y: auto;
    height: 31px;
    overflow-x: hidden;
    width: 90%;
    padding: 8px 0px 0px 0px;


}


.friendsFild .form-select ul li:hover,
.friendsFild .form-select ul li {
    float: left;
    width: 100%;
    text-align: left;
}

.friendSelect button {
    margin: 0px 2px;
    border: none;
    border-radius: 50%;
    padding: 8px;
    background-color: #F19357;
    width: 37px;
    float: left;
}

.shareicon-img .fa {
    font-size: 25px;
}

.inspiration-inner-content .friendSelect .form-select {
    height: 34px;
    width: 178px;
    float: left;
}

.inspiration-inner-content .input-popup1,
.inspiration-inner-content .input-popup2,
.inspiration-inner-content .input-popup3 {
    position: relative;
}

.shareinspiration .input-popup1 .save-popup-top .multiselect-container .search-wrapper {
    overflow-y: auto;
    min-height: 30px;
}

.shareinspiration .MuiTab-root {
    padding: 6px 20px;
    font-size: 14px;
    text-transform: capitalize;
    min-width: auto;
}

.inspireInnerBox .inspiration-div {
    border-radius: 4px;
    margin-top: 10px;
}

.shareinspiration .service-inner-container .post {
    cursor: pointer;
}

.inspiration-inner-content .MuiTabs-flexContainer {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.inspiration-div .See-more-div .comment-div-box .joana-comments {
    width: 100%;
    height: 100%;
    background: #F5F5F5;
    border-radius: 18px;
    padding: 7px 0px 5px 10px;
    position: relative;
    word-wrap: break-word;
    overflow-y: auto;
}

.joana-comments .delete-massages,
.inspiration-div .See-more-div .comment-div-box .joana-comments .delete-massages {
    display: none;
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 20px;
}

.joana-comments:hover .delete-massages {
    top: 20px;
}

.joana-comments:hover .delete-massages,
.inspiration-div .See-more-div .comment-div-box .joana-comments:hover .delete-massages {
    display: block;
}

.shareinspiration #long-menu .MuiButtonBase-root {
    display: inline-block;
    padding: 5px 10px;
    width: 100%;
}

.summer-text-description-datas,
.summer-text-description-data {
    height: auto;
    overflow-y: auto;
    word-wrap: break-word;
}

.summer-text-description-data {
    margin-bottom: 5px;
}

.summer-text-description-datas {
    max-height:250px;
}

.share-spiner {
    position: relative;
    height: 100px;
    width: 100%;
}

.share-spiner .overflowloader {
    width: 0;
}

.inspiration-div1 .chat-popup {
    position: fixed;
    top: auto;
    left: auto;
    transform: none;
    z-index: 2222;
    bottom: 0;
    right: 0;
    width: 350px;
}
.servepaymenthistory .savepopup-content .input-popup2 select.form-select,
.servepaymenthistory .css-b62m3t-container .css-13cymwt-control,
.save-resource-list .css-b62m3t-container .css-13cymwt-control,
.your-main-group .css-13cymwt-control,
.fulldescription .css-b62m3t-container .css-13cymwt-control,
.shareinspiration .css-b62m3t-container .css-13cymwt-control,
.searchpage .css-b62m3t-container .css-13cymwt-control,
.profilepage .css-b62m3t-container .css-13cymwt-control {
    height: 52px;
}
.servepaymenthistory .css-1hb7zxy-IndicatorsContainer,
.save-resource-list .css-1hb7zxy-IndicatorsContainer,
.your-main-group .css-1hb7zxy-IndicatorsContainer,
.fulldescription .css-1hb7zxy-IndicatorsContainer,
.shareinspiration .css-1hb7zxy-IndicatorsContainer,
.searchpage .css-1hb7zxy-IndicatorsContainer,
.profilepage .css-1hb7zxy-IndicatorsContainer {
    height: 45px;
}
.servepaymenthistory .css-3w2yfm-ValueContainer,
.save-resource-list .css-3w2yfm-ValueContainer,
.your-main-group .css-3w2yfm-ValueContainer,
.shareinspiration .css-3w2yfm-ValueContainer,
.profilepage .css-3w2yfm-ValueContainer,
.searchpage .css-3w2yfm-ValueContainer {
    height: 40px;
}

.sent .message-time {
    line-height: 80%;
    /* margin-top: 5px; */
}

.messages ul li.sent p {
    padding: 3px 10px;
}

.shareinspiration .SummerCamp .summerCamp-content {
    display: flex;
    width: 100%;
}

#chatsearchListmember .boublechedcksign {
    width: 15px;
    position: relative;

}

#chatsearchListmember .boublechedcksign .fa {
    color: #07b907;
}

#chatsearchListmember .boublechedcksign .fa:nth-of-type(2) {
    position: relative;
    top: -13px;
}

#chatsearchListmember .user-search {
    position: relative;
    justify-content: left;
    gap: 10px
}

.groupresourceedit {
    position: absolute;
    right: 75px;
    top: 20px;
    left: auto;
}



.shareinspiration .content-inspiration .header-down-div {
    margin-bottom: 0px;
}

.share-multiple-form-select {
    height: 34px;
    width: 178px;
    float: left;
    border-radius: 50px;
    padding: 0 10px;
    font-size: 12px;
    line-height: 23px;
    font-weight: 600;
    color: #2D3134;
    font-family: 'Inter';
    background: #fff;
    border: 1px solid #dfdfdf;
}

.share-multiple-form-select div.searchWrapper {
    width: 100%;
}

.share-multiple-form-select .search-wrapper .chip:first-child {
    display: inline;
}


.shareinspiration .friendSelect .share-multiple-form-select .multiSelectContainer .searchWrapper {
    position: unset;
    width: 90%;
}

.shareinspiration .friendSelect .share-multiple-form-select .multiSelectContainer .searchWrapper .icon_down_dir {
    position: absolute;
    right: 0px;
}

.inspiration-container .SummerCamp .summer-text .description-share p {
    line-height: 16px;
}

.inspiration-container .SummerCamp .summer-text .description-share {
    height: auto;
}

.share-multiple-form-select.showOneSelect,
.share-multiple-form-select.showMultipleSelect {
    height: 38px;
}

.share-multiple-form-select.showMultipleSelect div.searchWrapper {
    height: 36px;
}

.shareinspiration .content-inspiration .inspireInnerBox .showMultipleSelect .search-wrapper .chip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    display: inline-block;
}
.shareinspiration .friendSelect .share-multiple-form-select {
	width: 210px;
}
.shareinspiration .content-inspiration .inspireInnerBox .showOneSelect .search-wrapper .chip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: block; */
    width: 100px;
}
.inspiration-div .text_capital .friendInspirationcapital{
    text-transform: capitalize;
	font-weight: 600;
}
.content-inspiration .inspiration-div .comment-div-box .joana-comments{
    overflow-y: auto;
    max-height: 200px;
}
.inspiration-div .summer-text .spanrightsidecard {
    float: right;
    font-weight: 600;
	color:#F19357;
	cursor:pointer;
	text-decoration:underline;
}
.content-inspiration .summer-text .spanrightsidecard:hover{
	text-decoration:none;
}
.inspiration-div .inspirationwid{
    width: 90%;
}
.shareinspiration .inspiration-div .description-sharee p{
    line-height: 24px;
    font-size: 15px;
}
.shareinspiration .content-inspiration .inspireInnerBox .showOneSelect .search-wrapper .chip {
    padding-top: 25px;
}

.pop-up-container .reportPopupContent .input-popup2 .about-div{
    overflow-y: auto;
    max-height: 220px;
}

.profilepage .gradeLevelDataSelect .form-select .multiSelectContainer .searchWrapper{
    overflow-y: auto;
    position: unset;
    width: 88%;
}

@media only screen and (max-width:1200px) {
.inspiration-inner-div1 div.sharesidebarimgBox {
    width: 210px;
    height: 210px;
}

}
@media only screen and (max-width:767px) {

    .sharewithheading {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .content-inspiration {
        margin-top: 0px;
    }

    .shareinspiration .MuiTab-root {
        padding: 6px 10px;
    }

    .inspirationthreedotsOuterdiv {
        position: absolute;
        right: -11px;
    }

    .inspiration-inner-content .MuiTabs-flexContainer {
        overflow-x: scroll;
    }

    .inspiration-inner-content .MuiTabs-root {
        display: inline-block;
        overflow-x: auto;

    }

    .inspiration-inner-content .MuiTabs-fixed {
        width: 100%;
        overflow-x: auto !important;
        display: inline-block;
    }

    .inspiration-inner-content .MuiTabs-scroller {
        white-space: normal;
    }

    div.comments-div {
        padding: 10px;

    }

    .SummerCamp .img-funding,
    .SummerCamp .img-div4 {
        width: 62px;
        height: 62px;
        padding: 10px;
    }

    .SummerCamp .curriculum-image2,
    .SummerCamp .student-image1 {
        width: 45px;
        height: 45px;
    }

    .img-Facilities {
        width: 75px;
        height: 75px;
    }

    .resource-image2 {
        width: 100%;
        height: 100%;
    }

    .img-Facilities {
        padding: 10px;
    }

    .inspiration-div1 .button-div {
        padding: 0px 0px 20px 0px;
    }

    .inspiration-div1 .Messanger {
        margin-bottom: 20px;
    }

    .header-down-div input::placeholder {
        font-size: 14px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .sharewantsComunity,
    .header-down-div input {
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .shareinspiration .multiselect-container div.search-wrapper {
        overflow-y: auto;
        min-height: 30px;
    }
	.inspiration-div .SummerCamp .summer-text p{
        line-height: 17px;
    }

    .inspiration-div .inspiration-inner-div .groupresourceedit {
        position: absolute;
        right: 10px;
        top: 19px;
        left: auto;
    }
}