.successfulltext {
    font-weight: bold;
    font-family: 'Inter';
    color: green;
    font-size: 36px;
}
.signup {
    width: 100%;
    background: url(../../assets/Images/homepage2.jpg) left top no-repeat;
    background-size: cover;
    display: inline-block;
    height: 100%;
}

.signupouter {
    position: relative;
    max-width: 622px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 10px 30px;
    margin: 60px auto;

}
.signupouter h1 {
    font-size: 45px;
    font-weight: 700;
    color: #2D3134;
    text-align: center;
    margin-top: 25px;
    line-height: 20px;
    padding-bottom: 30px;
    font-family: 'Inter';
}
.signupouter .css-1jqq78o-placeholder {
    font-weight: bold;
}
.signupouter input[type="text"],
.signupouter input[type="email"],
.signupouter input[type="password"] {
	  height: 50px;
}
.signupouter input {
    width: 100%;
  
    border-radius: 50px;
    margin: 15px auto;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    padding: 6px 36px 6px 22px;
}
.signupouter select {
    width: 100%;
    font-weight: 600;
    height: 45px;
    border-radius: 50px;
    margin: 15px auto;
    font-size: 18px;
    font-family: 'Inter';
    padding-left: 22px;
}
.signupouter p {
    text-align: center;
    font-family: 'Inter';
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.signupouter p span {
    text-decoration: underline;
    font-weight: bold;
    font-family: 'Inter';
}
.signupouter .text-start {
    padding-left: 24px;
}
.btn1 {
    max-width: 350px;
    height: 50px;
    font-weight: 500;
    border-radius: 10px;
    background-color: #F19357;
    margin: 10px 0;
    font-size: 18px;
    color: white;
    border: none;
    width: 100%;
    margin-top: 20px;
    font-family: 'Inter';

}
.btn2 {
    max-width: 350px;
    height: 50px;
    border-radius: 10px;
    margin: 10px 0;
    font-size: 18px;
    background: #FFFFFF;
    border: 2px solid #D9D9D9;
    font-family: 'Inter';
    width: 100%;
    font-weight: 500;
}
.signupouter .bottomText {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    padding: 9px 10px 30px 10px;
    font-family: 'Inter';
}
.signupouter .bottomText a {
    font-family: 'Inter';
    color: #2D3134;
    text-decoration: underline;
    font-weight: 700
}
.signupouter .bottomText span {
    text-decoration: underline;
    font-weight: bold;
    color: #2D3134;
}
.signupouter p a {
    color: #2D3134;
    text-decoration: underline
}
.signupouter input::placeholder {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
}
.freepaidSwitch {
   padding-top: 10px;
    position: relative;
    padding-bottom: 20px;
}

.signup .toggle-switch .switch::before {
    width: 18px;
    height: 18px;
}
.signup .switch {
    width: 48px;
    height: 22px;
    margin-top: 4px;
}
.signup .marginboottom {
    margin-bottom: 0px;
}
.signup .sidebar {
    display: none;
}
.signupouter .error {
    padding-left: 25px;
}
.signup .rember .form-checks {
    width: 100%;
}
.signup .rember .form-check-input {
    padding: 9px;
    position: static;
    margin-left: 24px;
}
.signup .rember .form-check-label {
    width: 88%;
    font-size: 14px;
    color: #000;
    margin-left: 10px;
}
.signup .css-t3ipsp-control .css-1jqq78o-placeholder,
.signup .css-13cymwt-control .css-1jqq78o-placeholder {
    padding-left: 10px;
    color: #2D3134;
    font-size: 18px;
}
.signup .css-1u9des2-indicatorSeparator {
    margin-bottom: 22px;
}
.signup .css-qbdosj-Input {
    padding-left: 10px;
}
.sign-up .signup .css-13cymwt-control {
    height: 52px;
}
.sign-up .signup .css-1fdsijx-ValueContainer {
    height: 52px;
    overflow-y: auto;
}
.signupouter .selectChild .css-b62m3t-container .css-t3ipsp-control  .css-1dyz3mf,
.signupouter .selectParent .css-13cymwt-control .css-1dyz3mf {
  overflow-y: hidden;
}
.signupouter .selectParent .css-b62m3t-container .css-t3ipsp-control  .css-1dyz3mf,
.signupouter .selectParent .css-13cymwt-control .css-1dyz3mf  {
	height: 38px;
    overflow-y: auto;
    padding-top: 0;
}

.sign-up .signup .css-qbdosj-Input input {
    height: auto;
}
.benefits,
.benefits:hover,
.benefits:visited {
    color: #5b5f62;
    margin-left: 10px;
    text-decoration: underline;
    font-weight: bold;
    font-family: 'Inter';
    font-size: 14px;
}
.signupouter .css-b62m3t-container .css-hlgwow {
   height: 51px;
}
.signupouter .css-13cymwt-control .css-1dyz3mf {
    height: 50px;
    padding-top: 7px; 

}
.signupouter .selectChild .css-13cymwt-control .css-1dyz3mf {
 	overflow-y:hidden;
}
.signupouter .css-19bb58m input {
	height: auto;
}
.signup .selectParent .css-t3ipsp-control .css-3w2yfm-ValueContainer,
.signup .selectParent .css-13cymwt-control .css-3w2yfm-ValueContainer {
    height: 40px!important;
    overflow-y: auto;
}
.signupouter .selectParent .css-13cymwt-control .css-1dyz3mf {
 	overflow-y:auto!important;
}
.loginouter .form-group input{
    margin: 20px 0 0 0;
}
.signupouter .form-group input{
    margin: 15px 0 0 0;
}
.loginouter span.error,
.signupouter span.error{
    position: relative;
    top: auto;
}
.loginouter .form-group,
.signupouter .form-group{
    margin-bottom: 0;
}
.loginouter div.rember,
.signupouter .rember .form-checks {
margin-top: 10px;
}
.signupouter .mul-sel-div{
    margin-bottom: 0;
}
.sign-up .signup .react-tel-input input{
    width: 100%;
    height: 50px;
    border-radius: 50px;
}
/* .sign-up .signup .react-tel-input{
    margin-top: 15px;
} */
.sign-up .signup .react-tel-input .flag-dropdown{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.sign-up .signup .react-tel-input .selected-flag:hover,
 .sign-up .signup .react-tel-input .selected-flag:focus{
   border: 1px solid #dfdfdf;
   border-top-left-radius: 50px;
   border-bottom-left-radius: 50px;
 }
 
 .sign-up .signup .react-tel-input .flag-dropdown.open .selected-flag {
    background: #fff;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border: 1px solid #dfdfdf;
}
.sign-up .signupouter .selectChild{
    z-index: 1111;
 }

@media only screen and (max-width:576px) {
    h3.text-start {
        font-size: 14px;

    }
	.freepaidSwitch .benefits {
	    position: absolute;
    top: 42px;
    left: 50%;
    margin: 0;
    display: inline-block;
    transform: translate(-50%);
   }

}