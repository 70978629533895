.save-main-container {
    margin-top: 50px;
}
.save-container-3 .form-check-input {
    width: 25px;
    height: 25px;
}
.save-container-2 .form-check-input {
    width:20px;
    height:20px;
}
.save-pop-up-container-inner .pop-up-container .input-popup2 audio{
    width: 100%;
}
.save-container-3 .radiosdiv input[type=checkbox],
input[type=radio] {
    margin: 0px 0 0;

}
.savepage .save-main-container .radiosdiv input{
    margin-top:3px;
}
div.leavfeedbackreviews {
    float: left;
    margin-top: 0px;
    text-align: left;
    width: 100%;
}
.leavfeedbackreviews h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-bottom: 6px;
}
.savepage .css-b62m3t-container .css-9jq23d {
    color: #fff !important;
}
.add {
    width: 100%;
}
.savepage .publice-btn-save-t button,
.middlediv .publice-btn-save-t button {
    width: 215px;
    float: right;
    margin-top: 14px;
    margin-left: 10px;
}
.savepage .publice-btn-save-p button,
.savepage .publice-btn-save-t button,
.middlediv .publice-btn-save-t button {

    height: 44px;
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 8px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 10px;
}
.leavfeedbackreviews .form-group {
    display: block;
    margin-bottom: 15px;
    width: 33%;
    float: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
}
.leavfeedbackreviews .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
.leavfeedbackreviews .form-group label {
    position: relative;
    cursor: pointer;
}
.leavfeedbackreviews .form-group label:before {
    content: '';
    appearance: none;
    border-radius: 4px;
    background-color: #F19357;
    border: 2px solid #F19357;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
}
.leavfeedbackreviews .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 7px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.leavfeedbackreviews .innerBoxList li,
.leavfeedbackreviews .innerBoxList .centered {
    display: inline-block;
}
.leavfeedbackreviews .innerBoxList li label {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}
.leavfeedbackreviews .innerBoxList li {
    padding-bottom: 11px;
}
.leavfeedbackreviews .innerBoxList li input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    float: left;
    margin-top: 5px;
}
.f-select {
    padding-left: 20px;
}
.savepage .leavfeedbackreviews .innerBoxList .centered {
    width: 100%;
    float: left;
}
:focus-visible {
    outline: none;
    box-shadow: none;
}
.f-select:focus-visible {
    outline: none;
    box-shadow: none;
}
.save-main-container h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
    text-align: left;
    margin-bottom: 10px
}
.radiosdiv {
    text-align: left;
    margin-bottom: 25px
}
.radiosdiv label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    padding: 0px 70px 0px 26px;
}
.radiosdiv input[type="radio"] {
    width: 20px;
    height: 20px;
}
.save-radio-div2 input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-top: 8px;
}
.save-radio-div3 input[type="radio"] {
    width: 20px;
    height: 20px;
}
.save-heading h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
}
.save-div-name {
    display: flex;
    gap: 12px;
    margin-bottom: 50px;
}
.save-jackey-div1 {
    height: auto;
    width: 213px;
    padding: 9px 10px 9px 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #dfdfdf;
    border-radius: 14px;
    display: flex;
}
.save-jackey-div1 div {
    width: 56px;
    margin-right: 14px;
    height: 56px;
    background: #BFBCB8;
    border-radius: 50%;
}
.save-jackey-div1 h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #265472;
    margin-top: 15px;
}
.save-div-name .save-jackey-div1 h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 18px;
    margin-top: 0;
}
.save-jackey-div2 {
    height: auto;
    width: 213px;
    padding: 11px 10px 11px 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    display: flex;
    margin-right: 14px;
}
.save-jackey-div2 div {
    width: 56px;
    height: 56px;
    background: #BFBCB8;
    border-radius: 50%;
}
.active {
    border: 2px solid #265472;
}
.save-jackey-div2 h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #265472;
    margin-top: 15px;
}

.save-type-of-r {
    float: left;
    margin: 0px 0px 5px 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134
}
.f-select {
    width: 100%;
    height: 54px;
    border: 1px solid #dfdfdf;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    margin-bottom: 10px;
}
.css-1fdsijx-ValueContainer {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
}
.css-t3ipsp-control {
    border-color: none;
    outline: 0;
    box-shadow: none;
    text-align: left;
}
.save-container-3 {
    padding: 22px 24px 46px 24px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 20px;
    margin-bottom: 25px;
}
.save-container-3 h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    text-align: left;
    margin-bottom: 15px;
}
.save-radio-div2 {
    text-align: left;
    margin-bottom: 5px;
}
.instructionStylecontetWithRadio {
    max-height: 250px;
    overflow-y: auto;
}
.save-radio-div2 label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    padding: 4px 28px;
}
.time-fram-div {
    display: flex;
    gap: 12px;
    margin-bottom: 15px;
}
.time-fram-div div {
    padding: 2px 10px;
    width: 173px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF;
    border-radius: 14px;
    border: 2px solid #e4e4e4
}
.time-fram-div div.active {
    border: 2px solid #265472;
}
.savetext1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2D3134;
    padding: 10px;
}
.savetext2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    color: #2D3134;
}
.date-h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    text-align: left;
    margin-bottom: 7px;
}
.date-picker-div {
    width: 362px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 8px;
}
.date-picker-main-div {
    display: flex;
    gap: 12px;
    margin-bottom: 15px;
}
.date-picker-div input {
    width: 100%;
    padding: 0px 6px;
    margin: 10px 0px;
    border: none;
    outline: none
}
.date-picker-div h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    padding: 7px;
}
.clockicon {
    font-size: 20px;
    padding: 0px 15px;
}
.first-div1 {
    width: 50%;
}
.first-div1 div {
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border: 1px solid #dfdfdf;
    border-radius: 100px;
}
.first-div1 h3 {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
}
.resource-url-div {
    display: flex;
    gap: 16px;
}
.r-title-div h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-bottom: 5px;
    text-align: left;
}
.r-title-div div input {
    font-family: 'Inter';
}
.r-title-div {
    margin-bottom: 20px;
}
.texteditordescription h3,
.about-div h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-bottom: 5px;
    text-align: left;
}
.about-div div {
    height: 144px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 20px;
}
.about-div textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 10px;
}
.about-div {
    margin-bottom: 15px;
}
.mul-sel-div h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-bottom: 5px;
    text-align: left;
}
.mul-sel-div {
    margin-bottom: 18px;
}
.save-radio-div3 h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-bottom: 5px;
    text-align: left;
}
.save-radio-div3 div {
    text-align: left;
    margin-bottom: 25px;
}
.save-radio-div3 label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    margin: 0px 46px 0px 28px
}
.loc-div {
    padding: 28px 22px 18px 24px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 20px;
    margin-bottom: 29px;
}
.artifact h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-bottom: 5px;
    text-align: left;
}
.artifact {
    margin-bottom: 30px;
}
.public-funding {
    margin-bottom: 15px;
}
.margin-bt {
    margin-bottom: 30px;
}
.Rankingg-div h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-bottom: 5px;
    text-align: left;
}
.Rankingg-div {
    text-align: left;
    margin-bottom: 13px;
}
.Rankingg-div label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    padding: 0px 8px 0px 25px;
}
.save-radio-div2 input[type="checkbox"] {
    width: 18px;
    height: 15px;
    background: #F19357;
    border-color: #F19357;
}
.star1 {
    color: #F19357;
    margin-right: 30px;
}
.input-file-upload {
    position: relative;
    bottom: 21px;
    opacity: 0;
}
.css-b62m3t-container .css-13cymwt-control {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
}
.uploadicon {
    font-size: 25px;
    padding-right: 15px;
    position: relative;
    margin-top: 2px;
    float: left;
}
.publice-btn-save-p {
    float: right;
    margin-top: 15px;
    margin-bottom: 102px;
    text-align: center;
}

.savepage .publice-btn-save-p button {
    width: 113px;
}
.public-btn-save-p {
    width: 140px;
    height: 44px;
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 8px;
    float: right;
    margin-top: 20px;

}
.public-btn-save-p button {

    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-transform: capitalize;
    color: #FFFFFF;
    border: none;
    background: none;
    padding: 13px 4px;
    width: 100%;
}

.first-div1 input {
    width: 100%;
    margin-top: 14px;
    padding: 3px 20px 3px 20px;
    border: none;
    outline: none;
    background: none;
}

.innerBoxList i {
    color: #F19357;
}

.date-picker-div input[type="date"],
input[type="time"] {
    font-size: 18px;
}

.savepage .viewableFriend .viewableSwitchtag {
    display: inline-block;
    width: 260px;
}
/*============== Switch radio button =============*/
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f19357;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #F19357;
}


input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.savepage .viewableFriend h5 {
    padding-left: 0px;
}
.r-title-div,
.mul-sel-div,
.save-type-of-Outer,
.time-fram-divOuter,
.leavfeedbackreviews li,
.save-div-name,
.save-div-name-outer {
    position: relative;
}

.error-span {
    font-family: inter;
    font-size: 13px;
    width: 100%;
    text-align: left;
}

.rankingStar .MuiRating-root {
    top: 4px;
}

.r-title-div .error-span,
.mul-sel-div .error-span,
.save-type-of-Outer .error-span,
.time-fram-divOuter .error-span,
.save-div-name-outer .error-span,
.leavfeedbackreviews li .error-span {
    position: absolute;
    left: 0;
    bottom: -20px;
}
.save-container-3 .save-radio-div2 {
    display: inline-block;
    width: 100%;
}

.save-container-3 .save-radio-div2 input[type="radio"] {
    float: left;
    margin-right: 5px;
    left: 5px;
}
.save-container-3 .save-radio-div2 label {
    float: left;
    width: 95%;
    padding-left: 10px;
    padding-right: 10px;
}
.save-div-name-outer .save-div-name {
    width: 100%;
}

.savepage .leavfeedbackreviews li .error-span {

    bottom: -3px;
}

.margin-bt.kayword-associated {
    background: #fff;
    border-radius: 50px;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
}

.kayword-associated .css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    border-radius: 50px;
    background: #fff;
}

.kayword-associated .css-1wuilmg-MuiAutocomplete-root {
    overflow-y: auto;
    max-width: 100%;
    width: 100%;
}

.kayword-associatedNew .css-1kvlupi-MuiAutocomplete-root,
.kayword-associatedNew .css-1pfgy4j,
.kayword-associatedNew .css-1wuilmg-MuiAutocomplete-root {
    max-width: 100%;
    width: 100% !important;
}

.kayword-associatedNew label.MuiFormLabel-root {
    display: none;
}


.kayword-associatedNew .MuiAutocomplete-hasPopupIcon.css-1kvlupi-MuiAutocomplete-root .MuiOutlinedInput-root, 
.kayword-associatedNew .MuiAutocomplete-hasClearIcon.css-1kvlupi-MuiAutocomplete-root .MuiOutlinedInput-root,
.kayword-associatedNew .css-1pfgy4j .MuiOutlinedInput-root,
.kayword-associatedNew .MuiAutocomplete-hasClearIcon.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    height: 52px !important;
    overflow-y: auto !important;
    background: #fff !important;
    border: none !important;
    border-radius: 50px !important;
}

.kayword-associatedNew .css-1pfgy4j .MuiOutlinedInput-root.css-1si533m {
    width: 98%;
}

.kayword-associatedNew .css-1pfgy4j .MuiOutlinedInput-root {
    padding: 5px 9px !important;
}

.css-la0a2z .MuiChip-deleteIcon {
    color: #fff;
}

.kayword-associatedNew .css-la0a2z .MuiChip-deleteIcon {
    color: #fff !important;
}

.kayword-associatedNew .css-feqhe6,
.kayword-associatedNew .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    border: 1px solid #cdcdcd !important;
    background: #fff !important;
    border-radius: 50px !important;
}
.savepage .kayword-associatedNew .MuiAutocomplete-root.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding:4px 15px 1px 15px;
}
.kayword-associatedNew .MuiAutocomplete-root.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 5px 15px;
}

.kayword-associatedNew .css-1pfgy4j .MuiAutocomplete-tag {
    background: #F19357 !important;
    color: #fff !important;
}

.kayword-associatedNew fieldset {
    border: none;
}

.kayword-associatedNew .css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;

}

.save-div-name .save-jackey-div1 div {
    min-height: 52px;
}

.save-div-name-outer .save-jackey-div1 {
    min-height: 70px;
    border: 2px solid #dfdfdf;
}

.save-div-name-outer .save-jackey-div1.active {
    border: 2px solid #265472;

}

.save-div-name-outer .save-jackey-div1.div.active {
    border: 2px solid #265472;
    background: url(../../assets/Images/check.png) center top no-repeat;
}

.kayword-associated .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,
.css-1d3z3hw-MuiOutlinedInput-notchedOutline legend {
    display: none;
}

.date-picker-main-div .date-picker-div {
    position: relative;
}

.date-picker-main-div .date-picker-div .error-span {
    bottom: -19px;
    position: absolute;
    left: 0;
}

.savepage .r-title-div div input {
    padding: 3px 5px;
    height: 30px;
}

.savepage .r-title-div div input.input-file-upload {
    position: absolute;
    top: 0px;
    cursor: pointer;
    height: 45px;
}

.savepage .about-div textarea {
    padding: 10px 15px;
}

.dlocation {
    position: relative;
}

.dlocation .selectcontent1 {
    margin-bottom: 0;
}

.savepage .input-popup2 img {
    width: 100%;
}

.savepage .innerboxalignment,
.savepage .save-main-container {
    padding: 0 50px;
}

.savepage .r-title-div div {
    padding: 10px 15px 0px 15px;
}
.savepage .css-1q79v3g-MuiButtonBase-root-MuiChip-root {
    color: #fff;
    background-color: #F19357;
}
.savepage .css-1q79v3g-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    color: #fff;
}
.savepage .css-b62m3t-container .css-13cymwt-control {
    height: 52px;
    overflow: hidden;
}
.leavfeedbackreviews .innerBoxList {
    max-height: 100%;
    overflow-y: hidden;
}
.savepage .about-div textarea,
.savepage .innerboxalignment input[type="text"],
.savepage .save-main-container input[type="text"] {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    margin-top: 0;
    padding: 10px 10px;

}

.savepage .innerboxalignment input::-webkit-input-placeholder,
.savepage .save-main-container input::-webkit-input-placeholder {
    color: #808385;
    font-size: 18px;
    font-weight: 400;
}
.savepage .pop-up-container {
    max-width: 650px;
}
.savepage .sharepopupOuter {
    z-index: 3000;
}
.savepage .sharepopupOuter .pop-up-container {
    margin: 20px auto;
}

.savepage .pop-up-container.saveconfirmatoinPopup {
    margin: 120px auto;
}
.savepage .pop-up-container.saveconfirmatoinPopup .input-popup2 {
    font-size: 19px;
    text-align: center;
}
.saveconfirmatoinPopup .popup-btn-div {
    margin: 15px auto;
}
.MuiAutocomplete-root.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 7px 9px;
    height: 52px;
}
.save-pop-up-container-inner {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
}
.savepage .pop-up-container .input-popup2 {
    max-height: 100%;
    overflow: hidden;
    height: auto;

}
.save-pop-up-container-inner .pop-up-container h3 {
    margin-bottom: 20px;
}
/* .savepage.removescroll {
    overflow: hidden;
} */
.savepage .loc-div div.locationcomunity .error-span,
.savepage .save-type-of-Outer .error-span {
    margin-left: 20px;
}
.savepage .innerboxalignment .error-span {
    margin-left: 15px;
}
.savepage .innerboxalignment .loc-div .error-span {
    margin-left: 0px;
}
.savepage .resource-url-div .first-div1 input {
    height: 47px;
}
.savepage .css-1jqq78o-placeholder {
    margin-left: 10px;
}
.kayword-associated .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    left: 5px;
}
.savepage .innerboxalignment input[type="text"],
.locationDefine {
    border-radius: 24px;
}
.save-page .css-3w2yfm-ValueContainer {
    height: 52px;
}
.save-resource-list .bookmoniterdivbox .addresourceratting .star {
    font-size: 12px;
}
.savepage .css-3w2yfm-ValueContainer {
    height: 40px;
    overflow: auto;
}
.savepage .pop-up-container.serveConfirmationbox {
    max-width: 550px;
}

.saveConfirmationbox p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.saveConfirmationbox .popup-btn-div button {
    font-size: 16px;
}
#start_date,
#end_date {
    margin-top: 9px;
}
.jodit-react-container ol,
.jodit-react-container ul {
    padding-left: 15px;
}
.MuiAutocomplete-root.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 7px 15px;

}
.save-type-of-r,.servepage .servewithoutOuterBox .texteditordescription h3,
.servepage .servewithoutOuterBox .r-title-div h3,
.servepage .servewithoutOuterBox .wouldlike h5,
.servepage .servewithoutOuterBox .about-div h3,
.savepage .about-div h3,
.savepage .leavfeedbackreviews,
.savepage .wouldlike h5,
.savepage .r-title-div h3,
.savepage .texteditordescription h3,
.mul-sel-div h3,
.r-title-div .k-div,.artifact h3,
.first-div1 h3 {
    margin-left: 15px;
}
.first-div1 input {
    padding: 3px 20px 3px 15px;
}
.kayword-associated .css-1wuilmg-MuiAutocomplete-root {
    z-index: 0;
    position: relative;
}
.profilepage .childprofilegradeRange .form-select,
.saveResourceSelectss,
.form-select.w-100,
.saveResourceListgradeRange .form-select {
    background-position: right center;
}

.zipcodeaddress .error-span {
    left: 35px;
    bottom: 0;
}

.typeofexprience {
    position: relative;
}

.typeofexprience .error-span {
    margin-left: 23px;
    bottom: -18px;
    position: absolute;
}

.savepage .innerboxalignment .upload-scroll {
    padding: 0;
    position: relative;
}

.thumbuploadImgz {
    position: relative;
}

.savepage .innerboxalignment .u-content {
    font-size: 14px;
    width: 100%;
}

.savepage .innerboxalignment .u-content span {
    line-height: 26px;
    max-height: 30px;
    display: inline-block;
    width: 94%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.servepage .servewithoutOuterBox .add-more-information,
.savepage .innerboxalignment .add-more-information {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
}

.servepage .servewithoutOuterBox .add-more-information,
.savepage .innerboxalignment .add-more-information {
    background: #265472;
    color: #fff;
    padding: 11.5px 15px;
    border-radius: 8px;
}
.savepage .innerboxalignment .add-more-information {
	clear:left;
	float:left;
}
.servepage .servewithoutOuterBox .add-more-information:hover,
.savepage .innerboxalignment .add-more-information:hover {
    background: #F19357;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    height: 200px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    height: 200px;
}

.jodit-status-bar {
    display: none !important;
}
.save-resource-list .resource-img {
    width: 69px;
}
.savepage .css-b62m3t-container .css-wsp0cs-MultiValueGeneric {
    color: #fff !important;
}
.servepage .thumbuploadImgz div.ant-upload,
.savepage .thumbuploadImgz div.ant-upload {
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 100%;
    opacity: -1;
}
.savepage .thumbuploadImgz div.ant-upload {
    top: -21px;
}

.servepage .thumbuploadImgz div.ant-upload {
    top: -11px;
}

.servepage .thumbuploadImgz div.ant-upload button,
.savepage .thumbuploadImgz div.ant-upload button {
    width: 100%;
    height: 50px;
}

.savepage .thumbuploadImgz .u-content {
    padding: 0px 0px 0px 6px
}

.savepage .innerboxalignment .r-title-div p {
    margin-left: 23px;
}

.savepage .save-main-container .save-div-name .error-span {
    margin-left: 10px;
}

.savepage .innerboxalignment .save-radio-div3 h3 {
    margin-left: 15px;
}

.savepage .innerboxalignment .save-radio-div3 input {
    margin-top: 4px;
}

.savepage .save-main-container .accre-span span.error-span,
.savepage .save-main-container .save-container-3 .error-span {
    margin-left: 20px;
}

.savepage .save-main-container .save-container-3 .save-type-of-r {
    margin-left: 0px;
}

.wouldlike h5 {
    font-weight: 600;
}

.savepage .save-main-container .r-title-div .error-span {
    margin-left: 30px;
}

.engagelist li {
    list-style: none;
    float: left;
}

.gradeRangeMultiSelectbuild .css-1dyz3mf {
    height: 40px;
    overflow-y: auto;
}
.css-v7duua,
.gradeRangeMultiSelectbuild .css-1p3m7a8-multiValue .css-v7duua {
    background-color: #ffffff;
    color: #F19357;
    width: 18px;
    height: 18px;
    border-radius: 50% !important;
    margin-top: 7px;
}
.servepage .radiosdiv .engagelist label,
.savepage  .radiosdiv .engagelist label {
    padding:0px 0px 0px 26px
}
.servepage .radiosdiv .engagelist .facustomeI,
.savepage  .radiosdiv .engagelist .facustomeI {
margin-top: 6px;
margin-left:10px;
line-height: 16px;
font-weight: 600;
}
.servepage .radiosdiv .engagelist li {
	margin-right:15px;
}

.resource-isbn,.save-container-2.wouldlike {
	    margin-bottom: 20px;
}
.save-container-2.wouldlike {
    display: inline-block;
    width: 100%;

}

.servepage .radiosdiv .engagelist .facustomeI .tootlippillerinfo p,
.savepage  .radiosdiv .engagelist .facustomeI .tootlippillerinfo p{
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}
.stripe-link{
cursor: pointer;
text-decoration: underline;
font-weight: 600;
color: black;
}
.stripe-Activate{
    color: #265472;
}
.savepage .date-picker-main-div #end_date,
.savepage .date-picker-main-div #start_date {
	height:24px;
}
.childProfileUpdateView .sharepopupOuter .savepopup-content .viewableFriend .switch .slider,
.savepage .viewableFriend .switch .slider,
.profilepage .sharepopupOuter .savepopup-content .viewableFriend .switch .slider,
.profilepage .sharepopupOuter .sharediv-popups .input-popup1 .striptableList .switch .slider,
.groupJoin .sharepopupOuter .savepopup-content .viewableFriend .switch .slider,
.groupJoin .sharepopupOuter .sharediv-popups .input-popup1 .striptableList .switch .slider,
.servepaymenthistory .sharepopupOuter .savepopup-content .viewableFriend .switch .slider,
.createnewGroup .create-new .group-chat-permission .permission .switch .slider,
.fulldescription .sharepopupOuter .sharediv-popups .striptableList .switch .slider,
.fulldescription .sharepopupOuter .savepopup-content .viewableFriend .switch .slider,
.searchpage .sharepopupOuter .savepopup-content .viewableFriend .switch .slider,
.searchpage .sharepopupOuter .sharediv-popups .striptableList .switch .slider,
.shareinspiration .content-inspiration .sharepopupOuter .sharediv-popups .striptableList .switch .slider,
.save-resource-list .sharepopupOuter .savepopup-content .viewableFriend .switch .slider,
.shareinspiration .sharepopupOuter .savepopup-content .viewableFriend .switch .slider,
.servepage .middlediv .servewithoutOuterBox .switch .slider,
.savepage .innerboxalignment .switch .slider,
.savepage .save-main-container .save-container-3 .viewableFriend .slider,
.servepage .servewithOuterBox .viewableFriend .slider{
    background-color: #ccc;
}
.childProfileUpdateView .sharepopupOuter .savepopup-content .viewableFriend .switch input:checked + .slider,
.savepage .viewableFriend input:checked + .slider,
.profilepage .sharepopupOuter .savepopup-content .viewableFriend .switch .switch input:checked + .slider,
.profilepage .sharepopupOuter .sharediv-popups .input-popup1 .striptableList .switch input:checked + .slider,
.groupJoin .sharepopupOuter .savepopup-content .viewableFriend .switch input:checked + .slider,
.groupJoin .sharepopupOuter .sharediv-popups .input-popup1 .striptableList .switch input:checked + .slider,
.servepaymenthistory .sharepopupOuter .savepopup-content .viewableFriend .switch input:checked + .slider,
.createnewGroup .create-new .group-chat-permission .permission .switch input:checked + .slider,
.fulldescription .sharepopupOuter .sharediv-popups .striptableList .switch input:checked + .slider,
.fulldescription .sharepopupOuter .savepopup-content .viewableFriend .switch input:checked + .slider,
.searchpage .sharepopupOuter .savepopup-content .viewableFriend .switch input:checked + .slider,
.searchpage .sharepopupOuter .sharediv-popups .striptableList .switch input:checked + .slider,
.shareinspiration .content-inspiration .sharepopupOuter .sharediv-popups .striptableList .switch input:checked + .slider,
.save-resource-list .sharepopupOuter .savepopup-content .viewableFriend .switch input:checked + .slider,
.shareinspiration .sharepopupOuter .savepopup-content .viewableFriend .switch input:checked + .slider,
.servepage .middlediv .servewithoutOuterBox .switch input:checked + .slider,
.savepage .innerboxalignment .switch input:checked + .slider,
.savepage .save-main-container .save-container-3 .viewableFriend input:checked + .slider,
.servepage .servewithOuterBox .viewableFriend input:checked + .slider {
    background-color: #f19357;
}
.savepage .innerboxalignment .manyresourceBox h5,
.servepage .middlediv .manyresourceBox h5{
    margin-left: 15px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    color: #2D3134;
}
.save-resource-list .saveResourceChildSelect .saveResourceStudent .search-wrapper{
    width: 88%;
    position: unset;
}

.texteditordescription .flexreadios{
    display: flex;
}
.texteditordescription .flexreadios .facustomeI{
    margin-top: 3px;
    margin-right: 25px;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
}
.texteditordescription .flexreadios .facustomeI .tootlippillerinfo {
    font-weight: 400;
    text-align: left;
    font-size: 14px;
    left: 20px;
}
.texteditordescription .flexreadios label{
    padding: 0px 0px 0px 26px;
}
.texteditordescription .flexreadios input{
  margin-top: 3px;
}

 

.servepage .zipcodeaddress .facustomeI,
.savepage .zipcodeaddress .facustomeI{
right: 20px;
bottom: 33px;
}
.servepage .tpar-subp div.facustomeI,
.savepage .tpar-subp div.facustomeI,
.servepage .std-supb div.facustomeI,
.savepage .std-supb div.facustomeI,
.servepage .zipinners div.facustomeI,
.savepage .zipinners div.facustomeI,
.servepage .zipcodeaddresss div.facustomeI,
.savepage .zipcodeaddresss .facustomeI{
    right: 20px; 
    bottom: 17px;
}
.savepage .locationcomunity .facustomeI{
    right: 13px;
    bottom: 17px;
}
.servepage .locationcomunity .facustomeI{
    right: 53px;
    bottom: 71px;

}
.createnewGroup .create-new-form .facustomeI,
.servepage .zipcodeaddresss .facustomeI,
.savepage .zipcodeaddresss .facustomeI,
.servepage .zipcodeaddress .facustomeI,
.savepage .zipcodeaddress .facustomeI,
.servepage .locationcomunity .facustomeI,
.savepage .locationcomunity .facustomeI{
height: 18px;
background: none;
padding: 0;
width: 18px;
position: absolute;
font-weight: 800;
}
.createnewGroup .create-new-form .facustomeI div.tootlippillerinfo,
.servepage .zipcodeaddress .facustomeI div.tootlippillerinfo,
.savepage .zipcodeaddress .facustomeI div.tootlippillerinfo,
.servepage .zipcodeaddresss .facustomeI div.tootlippillerinfo,
.savepage .zipcodeaddresss .facustomeI div.tootlippillerinfo,
.servepage .locationcomunity .facustomeI div.tootlippillerinfo,
.savepage .locationcomunity .facustomeI div.tootlippillerinfo{
    right: 22px;
    left: auto;
    padding-bottom: 10px;
    text-align: left;
}
.createnewGroup .create-new-form .facustomeI{
    bottom: 16px;
    right: 22px;
}

.createnewGroup .create-new-form .tootlippillerinfo:before,
.servepage .zipcodeaddresss .tootlippillerinfo:before,
.savepage .zipcodeaddresss .tootlippillerinfo:before,
.servepage .zipcodeaddress .tootlippillerinfo:before,
.savepage .zipcodeaddress .tootlippillerinfo:before,
.servepage .locationcomunity .tootlippillerinfo:before,
.savepage .locationcomunity .tootlippillerinfo:before {
    transform: rotate(270deg) translate(30%, 100%);
    left: 255px;
    top: 17px;
    border-top-color: #F19357;
}
.servepage .zipcodeaddresss .tootlippillerinfo,
.savepage .zipcodeaddresss .tootlippillerinfo,
.servepage .zipcodeaddress .tootlippillerinfo,
.savepage .zipcodeaddress .tootlippillerinfo,
.servepage .locationcomunity .tootlippillerinfo,
.savepage .locationcomunity .tootlippillerinfo{
height: auto;
}

.servepage .kayword-associatedNew .css-1kvlupi-MuiAutocomplete-root,
.savepage .kayword-associatedNew .css-1kvlupi-MuiAutocomplete-root {
    max-width: 100%;
    width: 100% !important;
}
.servepage .kayword-associatedNew .css-1kvlupi-MuiAutocomplete-root .MuiAutocomplete-inputRoot,
.savepage .kayword-associatedNew .css-1kvlupi-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    height: 52px;
    overflow-y: auto;
    padding: 4px 15px 1px 15px;
}
.servepage .kayword-associatedNew .css-38raov-MuiButtonBase-root-MuiChip-root,
.savepage .kayword-associatedNew .css-38raov-MuiButtonBase-root-MuiChip-root{
    background-color: #F19357;
    color: #ffffff;
}
.servepage .kayword-associatedNew .css-38raov-MuiButtonBase-root-MuiChip-root .css-i4bv87-MuiSvgIcon-root,
.savepage .kayword-associatedNew .css-38raov-MuiButtonBase-root-MuiChip-root .css-i4bv87-MuiSvgIcon-root{
    color: #ffffff;
}
.servepage .kayword-associatedNew .css-38raov-MuiButtonBase-root-MuiChip-root .css-i4bv87-MuiSvgIcon-root:hover,
.savepage .kayword-associatedNew .css-38raov-MuiButtonBase-root-MuiChip-root .css-i4bv87-MuiSvgIcon-root:hover{
color: rgba(0, 0, 0, 0.4);
}
.servepage .kayword-associatedNew .css-38raov-MuiButtonBase-root-MuiChip-root span.css-6od3lo-MuiChip-label,
.savepage .kayword-associatedNew .css-38raov-MuiButtonBase-root-MuiChip-root span.css-6od3lo-MuiChip-label{
    font-family: 'Inter';
}



@media only screen and (max-width:1200px) {
    .save-container-3 {
        padding: 22px 12px 46px 24px;
    }
}
@media only screen and (max-width:991px) {
    .savepage .innerboxalignment,
    .savepage .save-main-container {
        padding: 0px;
    }
    .save-jackey-div1,
    .save-jackey-div2,
    .save-jackey-div3 {
        width: 30%;
    }
    .time-fram-div {

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .time-fram-div div h3 {
        font-size: 14px;

    }
    .time-fram-div div {
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .savetext2 {
        min-height: 48px;
        padding-top: 6px;
    }
    .savepage .texteditordescription .flexreadios div.facustomeI .tootlippillerinfo {
        left: auto;
        right: -96px;
        top: 24px;
       
    }
    .savepage .texteditordescription .flexreadios div.facustomeI .tootlippillerinfo:before {
        transform: rotate(180deg) translate(100%, 100%);
        top: 1px;
        left: 169px;
}
}
@media only screen and (max-width:768px) {

    .savepage .css-1t1lumd {
        /* width: auto; */
    }
    .save-div-name {
        display: flex !IMPORTANT;
        width: 100%;
        flex-wrap: wrap;
    }
    .savepage .css-1t1lumd h1 {
        font-size: 18px !important;
        line-height: 25px !important;
        font-weight: normal;
    }
    .radiosdiv input[type="radio"] {
        width: 20px;
        height: 20px;
        margin-top: 2px;
    }
    .savetext2 {
        padding: 14px;
    }
    .save-container-3 {
        padding: 22px 16px 46px 16px;
    }
    .first-div1 div {
        width: 100%;
    }
    .star1 {
        margin-right: 7px;
    }
    .save-radio-div3 label {
        margin: 0px 50px 0px 24px;
    }
    .engagelist li,
    .engagemobilelist {
        width: 100%;
    }
    .engagelist li {
        margin-bottom: 10px;
    }
    .engagemobilelist {
        display: inline-block;
    }
    .engagelist label {
        padding-right: 0;
    }
}
@media only screen and (max-width:767px) {
    .save-jackey-div1,
    .save-jackey-div2,
    .save-jackey-div3 {
        width: 100%;
    }
    .savepage div.innerboxalignment,
    .savepage div.save-main-container {
        padding: 0px;
    }
    .save-container-3 .save-radio-div2 label {
        width: 90%;
        font-size: 13px;
        left: 0;
        bottom: 3px;
        padding: 4px 0px 0px 10px;
        right: 0;
        position: relative;

    }
    .save-container-3 .form-check-input {
        position: relative;
        left: 0;
        width: 16px !important;
        height: 16px !important;
    }
    .time-fram-div {

        flex-wrap: wrap;
    }
    .time-fram-div div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 47%;
    }
    .date-picker-main-div {

        flex-wrap: wrap;
    }
    h3.savetext2 {
        padding: 8px;
        min-height: 42px;
        text-align: center;
    }
    .mul-sel-div h3,
    .save-type-of-r,
    .select-one {

        text-align: left;
        font-size: 13px;

    }
    .save-radio-div3 .form-check-input {
        position: relative;
        left: 0;
    }
    .save-radio-div3 label {
        margin: 0px 10px 0px 10px;
        font-size: 13px;
    }
    .loc-div {
        padding: 18px 10px 8px 10px;
    }
    .Rankingg-div label {
        font-size: 13px;
        padding: 0px 8px 0px 5px;
    }
    .leavfeedbackreviews .form-group {
        font-size: 15px;
        width: 100%;
    }
    .r-title-div div span.u-content {
        font-size: 14px;
    }
    .r-title-div div span.u-content spam {
        padding-left: 10px;
        margin-top: -2px;
        float: left;
    }
    .save-main-container .radiosdiv .form-check-input {
        position: relative;
        left: 0;
    }
    .save-main-container .radiosdiv label {
        padding: 0px 6px 0px 6px;
        font-size: 14px;
        line-height: 22px;
    }
    .save-main-container h4 {

        font-size: 18px;
    }
    .first-div1 h3,
    .artifact h3,
    .save-radio-div3 h3,
    .save-main-container h4 {
        font-size: 15px;
        margin-bottom: 6px;
    }
    .artifact {
        margin-bottom: 0;
    }
    .leavfeedbackreviews {
        margin-top: 0;
    }
    .save-container-3 .save-radio-div2 input[type="radio"] {
        left: 3px;
    }

    div.time-fram-div div h3 {
        font-size: 12px;
    }
}
@media only screen and (max-width:576px) {
    .servewithOuterBox .radiosdiv label {
        padding: 0px 0px 0px 26px;
    }
    .r-title-div div span.u-content {
        font-size: 12px;
        width: 100%;
    }
    .u-content span {
        line-height: 15px;
        display: inline-block;
        width: 87%;
        overflow-y: auto;
        overflow-x: hidden;
        height: 30px;

    }
    .thumbuploadImgz .u-content span {
        padding-top: 5px;

    }
}
@media only screen and (max-width:480px) {
    .savepage .css-b62m3t-container .css-9jq23d {
        margin-top: 2px;
    }

    .thumbuploadImgz .u-content span {
        padding-top: 2px;

    }
    .savepage .save-radio-div3 {
        margin-left: 20px;
    }

    .savepage .first-div1 input {
        padding: 3px 20px 3px 20px;
    }
    .savepage .first-div1 h3 {
        margin-left: 20px;
    }
    .savepage div.texteditordescription {
        margin-top: 20px;
    }
    .savepage .texteditordescription textarea {
        padding: 10px 20px;
    }
	   .servepage .middlediv .servewithoutOuterBox .resourceinfoTool .select-one .facustomeI .tootlippillerinfo,
   .savepage .innerboxalignment .mul-sel-div .resourceinfoTool .select-one .facustomeI .tootlippillerinfo {
        position: absolute;
        right: -12px;
        top: 20px;
        left: auto;
    }
	 .servepage .middlediv .servewithoutOuterBox  .resourceinfoTool .select-one .facustomeI .tootlippillerinfo:before,
    .savepage .innerboxalignment .mul-sel-div .resourceinfoTool .select-one .facustomeI .tootlippillerinfo:before {
        transform: rotate(180deg) translate(30%, 100%);
        position: absolute;
        right: 8px;
        top: 0px;
        left: auto;

    }
    .savepage .css-b62m3t-container .css-t3ipsp-control {
        overflow-y: scroll;
    }
	
	.texteditordescription div.flexreadios {
        display: unset;
    }
    .texteditordescription .flexreadios div.facustomeI {
    margin-right:0;
    }
    .texteditordescription div.flexreadios label {
        padding: 0px 0px 0px 20px;
    }
    .texteditordescription div.flexreadios input {
       float: left;
    }
    .savepage .texteditordescription .flexreadios div.facustomeI .tootlippillerinfo {
        left: auto;
        right: 23px;
        top: -4px;
    }

    .savepage .texteditordescription .flexreadios div.facustomeI .tootlippillerinfo:before {
        transform: rotate(270deg) translate(30%, 100%);
        top: 14px;
        left: 255px;
    }
    
	
	
}
@media only screen and (max-width:360px) {
    .radiosdiv input[type="radio"] {
        width: 15px;
        height: 15px;
    }
    .save-main-container .radiosdiv label {

        font-size: 11px;
        line-height: 15px;
    }
    .save-radio-div3 input[type="radio"] {
        width: 15px;
        height: 15px;
        margin-top: 6px;
    }
    .save-radio-div3 label {
        margin: 0px 10px 0px 3px;
        font-size: 12px;
    }
}