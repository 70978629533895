.purcahse-information {
    max-width: 400px;
    width:100%;
    margin:65px auto;
}

.icontext {
    display: flex;
}

.fileicon {
    color: #2D3134;
    border-radius: 1.5px;
    margin-top: 3px;
    font-size: 16px;
    padding: 10px;
}

.pinformation {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 11px;
    letter-spacing: 0.01em;
    color: #2D3134;
}

.sprice {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.mentor-session {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    /* margin-left: 12px; */
    margin-top: 10px;
    padding: 8px;
}

.sprice label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    padding: 0px 0px 0px 6px;

}
.sprice span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    padding: 0px;

}

.pricetext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    margin-right: 8px;
    margin-top: 10px;
    padding: 10px 12px;
}

hr {
    border: 1px solid #D9D9D9;
}

.totalamount {
    display: inline-block;
    padding: 0px 0px 0px 0px;
    text-align: right;
}

.dinvoice {
        border: 1px solid #D9D9D9;
    background: #FFFFFF;
     color: #2D3134;
    margin-bottom: 10px;
}
.dinvoice1,.dinvoice {
    padding: 5px;
    max-width: 400px;
	width:100%;
    height: auto;
	border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}
.dinvoice1 {
    background: #F19357;
    border: 1px solid #D9D9D9;
    color: #FFFFFF;
}

.purchase-inner-information {
    padding: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    background: #F5F5F5;
    text-align: right;
    margin-bottom: 32px
}

.totalamount label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 4px;
    color: #2D3134;
}

.totalamount span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
}
/* ----------------purchasepage3 css here---------------- */
.textname1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    padding: 0px 0px 0px 6px;
    width: 156px;
}
/* ----------------------------------------------------- */

