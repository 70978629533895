.child-inner h1 {
    margin-top: 63px;
    text-align: left;
    font-weight: 700;
    color: #2D3134;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
}
.space1 {
    display: flex;
    justify-content: space-between;
    width: 97%;
}
.controlinput {
    margin-left: 23px;
}
.select-input {
    width: 100%;
    height: 56px;
}
.profile-div {
    display: flex;
    padding: 40px 0px 40px 0px
}
.childinfo .profile-div {
	display:inline-block;
	min-height: 150px;
}
.input-file {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    margin-top: 30px;
    width: 244px;
    height: 44px;
    color: #2D3134;
    padding: 9px;
    cursor: pointer;
}
.labelname {
    margin-left: 23px;
}
.form-check .form-check-input {
    width: 20px;
    height: 20px;
}
.form-control {
    padding: 1px 15px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 50px;
    font-family: 'Inter';
}
.childform .form-label {
    color: #2D3134;
    margin-bottom: 2px;
}
.childform .form-label,
.childform .form-label1 {
    margin-left: 15px;
}
.learning {
    margin-bottom: 20px;
}
.child-inner h4 {
    margin-top: 25px;
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    font-family: 'Inter';
    color: #2D3134;
    bottom: 10px;
}
.form-check {
    text-align: left;
    margin-right: 53px;
}
.child-inner h3 {
    margin: 17px auto 0;
    text-align: left;
    font-size: 16px;
    line-height: 28px;
    font-family: 'Inter';
    color: #2D3134;
}
.space {
    margin-top: 20px;
    margin-bottom: 20px;
}
.checkcontainer {
    text-align: left;
}
.form-select {
    width: 100%;
    height: 52px;
    border-radius: 50px;
}
.chectbutton input[type="radio"]:checked {
    background-color: #F19357;
    border-color: #F19357;
}
.button-inner {
    display: flex;
}
.save-btn1 {
    float: right;
    align-items: center;
    padding: 10px 10px 10px 10px;
    margin-bottom: 50px;
}
.complete-later {
    color: black;
    text-decoration: none;
}
.childsave {
    color: white;
    text-decoration: none
}
.form-check .form-check-input {
    float: left;
    margin-left: -2.5em;
}
.starr {
    color: red;
}
.add-remove {
    width: 30px;
    float: none;
    border: none;
    background: #d9d9d9;
    color: white;
    border-radius: 50px;
    margin-top: 10px;
    height: 30px;
	text-align:center;
	padding:0;
}
.add {
    text-align: center;
}
.add-add {
    border: none;
    background: #f8f9fa;
    color: black;

}
.css-b62m3t-container .css-13cymwt-control {
    min-height: 31px;
    border-radius: 50px;
    color: white;
}
.css-b62m3t-container .css-wsp0cs-MultiValueGeneric {
    padding: 7px;
    padding-left: 10px;
    border-radius: 50px;
    color: white;
}
.css-b62m3t-container .css-1p3m7a8-multiValue {
    background-color: #F19357;
    color: white;
    border-radius: 50px;
}
.childinfo .css-b62m3t-container .css-13cymwt-control {
    height: 52px;
    overflow: hidden;
    overflow-y: auto;
}
.childinfo .css-b62m3t-container .css-t3ipsp-control {
    box-shadow: none;
    border: 1px solid hsl(0, 0%, 80%) !important;
    height: 52px;
    border-radius: 50px;
    overflow: hidden;
    overflow-y: auto;
}
.childform h3 span.select-one {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #5B5F62;
}
@media screen and (max-width:576px) {
    .input-file {
        width: 200px;
    }
}
.error-span {
    font-size: 16px;
    float: left;
    color: red;
}
.error-span1 {
    font-size: 16px;
    float: left;
    color: red;
    margin-left: 25px
}
.space22 {
    margin-top: 35px;
}
.child-inner {
    width: 100%;
    float: left;
}
/* .child-inner h2.jackheading {
    float: left;
    width: 50%;
} */
.edit-child {
    font-size: 20px;
    font-weight: 700;
    padding: 5px 0px;
}
.edit-vtn {
    align-items: center;
    padding: 12px 24px;
    width: 182px;
    height: 44px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 700;
    float: right;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    position: relative;
    bottom: 10px;
}
.remove {
    background: transparent;
    border: none;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 700;
}
.child-delete,
.child-cancel {
    padding: 5px 25px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-weight: 500;
   /*  font-family: 'effra_trialregular' */
   font-family: 'Rubik', sans-serif;
}
.MuiBox-root button.child-cancel:hover,
.MuiBox-root button.child-delete:hover
/*.child-delete */ {
	background: #F19357;
	color:#fff;
    /* background-color: white; */
}
.MuiBox-root button.child-delete,
.MuiBox-root button.child-cancel,
.child-cancel {
    background-color: white;
    color: #000;
	border:1px solid #D9D9D9;
}
:where(.css-dev-only-do-not-override-ed5zg0).ant-upload-wrapper {
    position: relative;
    height: 110px;
}
:where(.css-dev-only-do-not-override-ed5zg0).ant-upload-wrapper .ant-upload-list .ant-upload-list-item,
:where(.css-dev-only-do-not-override-ed5zg0).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
    position: absolute;
    left: 0;
}
.react-datepicker-wrapper {
    width: 100%;
}
div.MuiBox-root .child-delete,
div.MuiBox-root .child-cancel {
    /* padding: 5px;
    width: 74px;
    line-height: 34px; */
    height: 41px;
    display: block;
    text-align: center;
	font-size:16px;
}
.child .css-1q79v3g-MuiButtonBase-root-MuiChip-root {
    color: #fff;
    background-color: #F19357;
}

.child .css-1q79v3g-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    color: #fff;
}
.childinfo .chilintofirstname .error-span,
.childinfo .chilintolastname .error-span{
    margin-left: 15px;
}
.pop-up-container .form-control {
    padding: 6px 15px;
    border-radius: 10px;
}
.childinfo .kayword-associated .MuiFormControl-fullWidth {
	height: 54px;
    background: #fff;
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    overflow-y: auto;
}
.childinfo  .MuiAutocomplete-hasClearIcon.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
   background: transparent;
}
.childinfo .kayword-associated .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
