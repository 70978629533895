.icontext {
    display: flex;
}
.fileicon {
    color: #2D3134;
    border-radius: 1.5px;
    margin-top: 3px;
    font-size: 16px;
    padding: 10px;
}
.pinformation {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 11px 11px 11px 5px;
    letter-spacing: 0.01em;
    color: #2D3134;
}
.sprice {
    display: flex;
    justify-content: space-between;
    text-align: left;
}
.mentor-session {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    margin-top: 10px;
    padding: 8px;
}
.sprice label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    padding: 0px 0px 0px 6px;
    width: 75%;
}
.sprice span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    padding: 0px;

}
.pricetext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    margin-right: 8px;
    margin-top: 10px;
    padding: 10px 12px;
}
hr {
    border: 1px solid #D9D9D9;
}
.totalamount {
    display: inline-block;
    padding: 0px 0px 0px 0px;
    text-align: right;
}
.dinvoice {
        border: 1px solid #D9D9D9;
    background: #FFFFFF;
     color: #2D3134;
    margin-bottom: 10px;
}
.dinvoice1,.dinvoice {
    padding: 16px;
    max-width: 400px;
	width:100%;
    height: 60px;
	border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
}
.applycoupan .btn,
.dinvoice1 {
    background: #F19357;
    border: 1px solid #D9D9D9;
    color: #FFFFFF;
	height: auto;
}
.applycoupan .btn {
	width:150px;
	font-weight:600;
}
.applycoupan input::placeholder {
	font-size:14px;
}
.purchase-inner-information {
    padding: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    background: #F5F5F5;
    text-align: right;
    margin-bottom: 32px
}

.totalamount label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 4px;
    color: #2D3134;
}

.totalamount span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
}
.onlinepaymentactiveUser {
	padding-top: 59px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: red;
}
/* ----------------purchasepage3 css here---------------- */
.textname1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    padding: 0px 0px 0px 6px;
    width: 156px;
}
/* ----------------------------------------------------- */
.servepaymenthistory .tobarbackcontee .jackey {
	border: 0.21px solid #BFBCB8;
    border-radius: 32.5px;
    padding: 5px 12px;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    color: #2D3134;
    min-width: 155px;
    height: 31px;
	margin-right: 0;
}
.applycoupan {
	padding-top:61px;
}
.purchasepagefriend .sharepopupOuter .innerBoxList ul li .form-check-input{
    border: 1px solid #c1bdbd;
    margin-top: 2px;
}
 .pop-up-container .sharescrollfix,
 .pop-up-container .sharescrollfix{
    max-height: 280px;
    overflow-y: auto;
}

.servepaymenthistory .bookmoniterdiv .bookmoniterdivbox .sprash-name .sprash-name-rts h3{
    max-width: 107px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-align: left;
    word-break: break-word;
}
.servepaymenthistory .bookmoniterdiv .bookmoniterdivbox .sprash-name .sprash-name-rts p{
    text-align: left;
}
.servepaymenthistory .bookmoniterdiv .bookmoniterdivbox .sprash-name-rt .sprash-name-box p{
    width: 100px;
    word-break: break-word;
    /* text-align: left;  */
}
.servepaymenthistory .bookmoniterdiv .bookmoniterdivbox .sprash-name-rt .sprash-name-box h4{
   text-align: center;
}