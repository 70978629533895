
.email-text-option label {
    display: block;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134
}
.close-div span {
    width: 37px;
    height: 37px;
    background: #D9D9D9;
    float: right;
    border-radius: 50%;
}

.closebutton1 {
    border: none;
    border-radius: 50%;
    padding: 8px 10px;
    font-size: 20px;
    float: right;
}

.email-text-option textarea {
    width: 100%;
    height: 144px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 20px;
}

.createlink {
    padding: 12px 24px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
	font-family: 'Rubik', sans-serif;
    color: #2D3134;
    margin-right: 12px;
}
.sendinvite {
    padding: 12px 24px;
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 8px;
   font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-transform: capitalize;
    color: #FFFFFF;

}
.email-text-option {
	margin-bottom:15px;
}
.remove-popup-container  {
	   background: #FFFFFF;
    border: 2px solid rgba(255, 255, 255, 0.9);
    border-radius: 16px;
   max-width: 550px;
    border-radius: 16px;
    padding: 35px 50px;
    width: 100%;
    margin: 0 auto;
    margin: 125px auto;
   }




