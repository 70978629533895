.chatscreendesgin {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    width: 100%;
    margin-top: 60px;
}
.Messanger .messanger-inner .messagethreedostpopup span{
    margin-right: 5px;
}
div.css-10tlgzi{
    padding: 48px;
}

.MuiBox-root .list-searches,
.css-1t1lumd .list-searches{
height: 150px;
}
    .search-list .search-list-popup h1{
        text-align: center;
        font-size: 30px;
        vertical-align: middle;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
    }

.messages ul li img.chatimagesfull {
    width: 100% !important;
    border-radius: 0 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 12px;
    object-fit: contain;
}

.chatscreendesgin .chatbox {
    width: 100%;
    margin-bottom: 50px;
    display: inline-block;
}

.chatscreendesgin .chatbox h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #d9d9d9;
}

.chatboxinner .chatboxinnerfdf {
    padding-bottom: 15px;
}

.chatboxinner {
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #bfbcb8;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 6px 6px 0px 0px;
    padding-bottom: 0px;
}

.chatboxinner .chatboxinnertp {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    padding-top: 10px;
}

.chatboxinner .chatboxinnertp .img-circlediv {
    position: relative;
}

.chatboxinner .chatboxinnertp .img-circlediv span {
    width: 10px;
    height: 10px;
    display: block;
    background: #337447;
    border: 1px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    right: 6px;
    bottom: 0;
}

.chatboxinner .chatboxinnertp .img-circlediv img {
    width: 60px;
    position: relative;
    height: 60px;
    object-fit: cover;
}

.chatboxinner .chatboxinnertp .img-circlediv img {
    width: 40px;
    position: relative;
    height: 40px;
    object-fit: cover;
}

.chatboxinner .chatboxinnertp .toprigntschat {
    display: inline-block;
    width: 100%;
}

.chatboxinner .chatboxinnertp .toprigntschat span {
    background: #f19357;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13.8462px;
    line-height: 17px;
    color: #ffffff;
    border-radius: 50%;
    float: left;
    padding: 4px 0px 0px 0px;
    display: block;
    text-align: center;
}

.chatboxinner .chatboxinnertp .toprigntschat .toprigntschatlist {
    float: left;
    margin-right: 25px;
    position: relative;
}

.toprigntschatmiddle {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.toprigntschatmiddle ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.toprigntschatmiddle ul li {
    margin-right: 20px;
}

.toprigntschatmiddle ul li a {
    color: #65676b;
    font-size: 20px;
    background-color: transparent;
    border: none;
}

.toprigntschatmiddle button,
.toprigntschatmiddle ul li button {
    color: #65676b;
    font-size: 20px;
    background-color: transparent;
    border: none;
}


.chatboxinner .searchbox {
    width: 93%;
    display: inline-block;
    position: relative;
}

.chatboxinner .searchbox input.name {
    background: #f3f3f0;
    border-radius: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #5b5f62;
    border: none;
    padding: 14px 15px 14px 50px;
    width: 100%;
    margin: 10px 5px 0px 5px;
}

.chatboxinner .searchbox button {
    position: absolute;
    left: 10px;
    top: 21px;
    border: none;
}

.chatboxinner .searchbox button i {
    font-size: 22px;
}

.chatboxinner .searchbox input:focus-visible {
    outline: none;
    box-shadow: none;
}

.chatnames {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-bottom: 0px;
}

.chatnames .chatnamemiddle {
    text-align: left;
    width: 85%;
    padding: 10px 10px;
    border-top: 1.38462px solid #f3f3f0;
    margin-left: 10px;
}



.chatnames .chatnamesimg {
    margin-top: 14px;
    padding-left: 5px;
    margin-left: 0;
}

.chatnames .chatnamemiddle .chatnamemiddlelt p span {
    display: block;
}

.chatnames .chatnamesimg img {

    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.chatnames .chatnamemiddle .chatnamemiddlelt {
    float: left;
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chatnames .chatnamemiddle .chatnamemiddlelt h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #2d3134;
    width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 20px;
    text-transform: capitalize;
}

.chatnames .chatnamemiddle .chatnamemiddlelt p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5b5f62;
    margin-top: 5px;
}

.chatnames .chatnamemiddle .chatnamemiddlert {
    float: right;
}

.chatnames .chatnamemiddle .chatnamemiddlert h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12.4615px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #5b5f62;
    margin-bottom: 10px;
}

.chatnames .chatnamemiddle .chatnamemiddlert p {
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.chatnames .chatnamemiddle .chatnamemiddlert p span {
    background: #f19357;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13.8462px;
    line-height: 17px;
    color: #ffffff;
    border-radius: 50%;
    float: left;
    padding: 4px 0px 0px 0px;
    display: block;
    text-align: center;
}

.chatnames .chatnamemiddle .chatnamemiddlert p.circle {
    border: none;
    background: #e8f0f4;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    float: left;
    margin-left: 20px;
}

.chatnames .chatnamemiddle .chatnamemiddlert p.circle i {
    text-align: center;
    padding: 0;
    margin: 0;
}

.searchlistbox {
    width: 100%;
    display: flex;
    margin-top: 8px 0px;

}

.searchlistbox span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin-right: 14px;
    color: #2d3134;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchlistbox ul {
    overflow: hidden;
    list-style-type: none;
    /* display: flex; */
    margin: 0;
    padding: 0;

}

.searchlistbox ul li {
    width: 40px;
    overflow: hidden;
    margin-right: 1px;
    width: auto;
    float: left;
}

.searchlistbox ul li a {
    width: 10%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
    display: flex;
    align-items: center;
    color: #ffffff;
    background: #f19357;
    border-radius: 100px;
    padding: 5px;
    text-decoration: none;
    display: block;
    width: auto;
}

.searchlistbox ul li a i {
    margin-left: 8px;
    font-weight: 400;
}


.messages ul li {
    display: flex;
    clear: both;
    margin: 8px 0px;
    width: calc(100% - 25px);
    font-size: 0.9em;
    width: 100%;
}

.messages ul li.sent .user-profile {
    margin: 6px 8px 0 5px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    float: left;
    align-self: end;

}

.messages ul li.sent p {
    max-width: 100%;
    padding: 5px 10px;
    margin-bottom: 0px;
    font-family: "Inter";
    text-align: left;
    background: #dce8ff;
    border-radius: 10px 10px 10px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    /* margin-right: 10px; */
    float: left;
    white-space: normal;
    white-space: normal;
    word-wrap: break-word;
    clear: both;
}

.d-flexa.flex-columns {
    display: inline-block;
    width: 83%;
}

.d-flexa.flex-columns .pdf-image {
    clear: both;
    float: left;
}

.messages ul h5 {
    text-align: center;
    width: 100%;
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px !important;
    padding-bottom: 0;
}

.messages ul li.replies p {
    float: right;
    max-width: 100%;
    text-align: left;
    margin-bottom: 0px;
    padding: 5px 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    color: #ffffff;
    background: #265472;
    border-radius: 10px 10px 0px 10px;
    white-space: normal;
    word-wrap: break-word;
}

.messages ul li.replies .user-profile {
    margin: 6px 8px 0 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    float: right;
}

.messages ul li.sent h3 {
    font-family: "Spartan";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 18px;
    letter-spacing: 0.599287px;
    text-transform: uppercase;
    color: #000000;
    margin-top: 7px;
    margin-bottom: 0px;
}

.messages ul li.replies p span {
    display: block;
}

.messages ul li.sent p span {
    display: block;
	word-wrap: break-word
}

.messages ul li.replies {
     margin: 10px 0px 0px 0px;
    width: 100%;
    display: inline-block;
    position: relative;
}

.messages ul li.replies .delete-massage {
    position: absolute;
}

.flex-column-own {
    float: right;
    width: 75%;
}

.flex-column-own p,
.flex-column-own img {
    clear: both;
}

.flex-column-own svg,
.flex-column-own img {
    float: right;
}

.messages ul li.replies h3 {
    font-family: "Spartan";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 18px;
    letter-spacing: 0.599287px;
    text-transform: uppercase;
    color: #000000;
    margin-top: 7px;
}
div.left-div .well-chat-icon + .modal-container .Messanger .messanger-inner .chatList {
    height: 404px;
}

.messages ul li.replies h3 {
    font-family: "Spartan";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 18px;
    letter-spacing: 0.599287px;
    text-transform: uppercase;
    color: #000000;
    margin-top: 7px;
    text-align: right;
    margin-bottom: 0px;
    padding-right: 62px !important;
    width: 100%;
}

.messages ul li.sent p date {
    display: block;
    font-family: "Spartan";
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 18px;
    letter-spacing: 0.599287px;
    text-transform: uppercase;
    color: #000000;
}

.messages ul li.replies p date {
    display: block;
    font-family: "Spartan";
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 18px;
    letter-spacing: 0.599287px;
    text-transform: uppercase;
    color: #000000;
}

.chat-new {
    border-bottom: 1px solid #aaa;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.chat-messages-upload {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    background: #e8f0f4;
    padding: 10px 20px;
}

.chat-messages-upload img {
    width: 90px;
    height: 90px;
}

.cros_btn {
    border: none;
    margin-left: 100px;
    height: 30px;
}

.chat-messages-textarea {
    width: 100%;
    display: flex;
    position: relative;

    background: #e8f0f4;
    padding: 20px 10px;
}

.chat-messages-textarea .chat-messages-textarea-lt {
    width: 15%;
    float: left;
}

.chat-messages-textarea .chat-messages-textarea-rt {
    width: 85%;
    position: relative;
    float: left;
}

.chat-messages-textarea .chat-messages-textarea-rtt {
    width: auto;
    float: right;
    margin-top: 8px;
}

.chat-messages-textarea-rtt button {
    border: none;

}

.chat-messages-textarea .chat-messages-textarea-rtt i {
    background: #f19357;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 21px;
    transform: rotate(45deg);
    text-align: center;
    line-height: 40px;
}

.chatscreendesgin .chatbox h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    text-align: left;
    margin-bottom: 20px;
}

.chat-messages-textarea .chat-messages-textarea-lt img {
    width: 60px;
    height: 60px;
}

.chat-messages-textarea .chat-messages-textarea-rt textarea.form-control {
    border: none;
    background: #ffffff;
    border-radius: 30px;
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    padding-top: 14px;
    align-items: center;
    color: #949698;
    text-overflow: ellipsis;
}

.chat-messages-textarea .chat-messages-textarea-rt textarea.form-control:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.chat-messages-textarea .chat-messages-textarea-rt .button-area {
    position: absolute;
    padding: 18px 12px 10px 16px;

    top: 0px;
    right: 0px;
}

.chat-messages-textarea .chat-messages-textarea-rt .button-area i {
    margin-right: 5px;
    font-size: 16px;

}

.chat-messages-textarea .chat-messages-textarea-rt .button-area .arrow-dd svg {
    color: #6a0273;
    transform: rotate(45deg);
    width: 26px;
}

.chat-messages-textarea .chat-messages-textarea-rt .button-area button.submit {
    border: none;
    background: transparent;
}

.chat-messages-textarea .chat-messages-textarea-rt .button-area .arrow-dd span {
    float: left;
    left: 0;
}

.chat-messages-textarea .chat-messages-textarea-rt .button-area span.paperclip svg {
    color: #6a0273;
    cursor: pointer;
}

.customerschatcontent .messages {
    max-height: 560px;
    min-height: 560px;
}

.chatboxinner .searchbox .haldrsearch {
    width: 70%;
    float: left;
}

.chatboxinner .searchbox .haldrsearchrt {
    float: right;
    margin-top: 16px;
}

.chatboxinner .searchbox .haldrsearchrt ul {
    display: flex;
}

.chatboxinner .searchbox .haldrsearchrt ul li {
    margin-right: 12px;
}

.chatboxinner .searchbox .haldrsearchrt ul li a {
    color: #000;
    font-size: 30px;
}

.chatnames .chatnamesimg .form-group {
    display: block;
    margin-bottom: 15px;
    margin-top: 10px;
    float: left;
    text-align: center;
    margin-left: 20px;
}

.chatnames .chatnamesimg .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.chatnames .chatnamesimg .form-group label {
    position: relative;
    cursor: pointer;
}

.chatnames .chatnamesimg .form-group label:before {
    content: "";
    background-color: transparent;
    border: 2px solid #bfbcb8;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 4px;
}

.newcirclwe i.fa.fa-check {
    color: #f19357;
}

.chatnames .chatnamesimg .form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #bfbcb8;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.togglecssindex .chatbox h4 {
    display: none;
}

.inspiration-inner-div {

    flex-wrap: wrap;
}

.togglecssindex {
    position: relative;
    margin-top: -130px;


}

.togglecssindex .chatboxinner .chatboxinnertp ul li:hover {
    background: transparent;
}

.togglecssindex .chatboxinner .searchlistbox ul li {
    background: transparent;
}

.togglecssindex span {
    opacity: 1;

}

.togglecssindex span.ellipostr {
    top: 136px;
    position: absolute;
    right: 0px;
}


.togglecssindex .chatbox {
    width: 100%;
    top: -80px;
    display: inline-block;
    position: absolute;
    left: 20px;
}


.togglecssindex .chatboxinner .chatboxinnertp .toprigntschat .toprigntschatlist i {
    transform: rotate(90deg);
    font-size: 22px;
    color: #000;
    margin-left: 8px;
}

.togglecssindex .middle-chat-part .messages li:hover.sent {
    background: transparent;
}

.togglecssindex .middle-chat-part .messages li:hover.replies {
    background: transparent;
}

.togglecssindex .mobilechatest {
    width: 100%;
}

.togglecssindex .chatboxinner {

    width: 100%;
}

.togglecssindex .chatbox .messages ul {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
}

.togglecssindex .messages ul li {
    display: inline-block;
    clear: both;
    margin: 15px 15px 15px 15px;
    width: calc(100% - 0px) !important;
    font-size: 0.9em;
    max-width: 100% !important;
    width: 100%;
}

.togglecssindex .messages ul li.sent p,
.togglecssindex .messages ul li.replies p {
    max-width: 81%;
}


.togglecssindex .messages ul li img {
    height: auto;
}


.togglecssindex .messages ul li.replies p span {
    display: block;
    color: #fff;
}


.togglecssindex .chatscreendesgin {

    margin-left: 360px;
}

.chatManuiocns aside {
    width: 100% !important;
}

.message-time {
    font-size: 10px;
    float: right;
    justify-content: flex-end !important;
}

.message-time-r {
    font-size: 10px;
    float: right;
    color: white;
    line-height: 80%;
}

.doc_div {
    padding: 5px 5px;
    border-radius: 5px;
    background: #dce8ff;
    width: 90px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 20px;
    margin-bottom: 2px;

}

.doc_div_r {
    color: white;
    border-radius: 5px;
    background: #265472;
    width: 90px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
    padding: 2px 0;
    margin-bottom: 2px;
}

.chat-popup {
    right: 0px;
    position: absolute;
    top: 376px;
    transform: translate(0%, -50%);
    z-index: 2222;
}

.chat-popup {
    position: fixed;
    top: auto;
    left: auto;
    transform: none;
    z-index: 2222;
    bottom: 0;
    right: 0;
    width: 380px;
}


.cssanimation,
.cssanimation span {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.cssanimation span {
    display: inline-block
}

.fadeInBottom {
    animation-name: fadeInBottom
}

.viewMemberchatpopup #chatsearchListmember .delete-massages {
   position: absolute;
    right: 15px;
    top: 0; 
}

.your-main-groups .viewMemberchatpopup  .search-list  .user-search h2,div.chatboxview h2.goback {
    color: black;
    font-weight: 700;
}

div.messanger-inner-div img {
    width: 40px;
    height: 40px;
}

div.search-list{
    max-height: 250px;
    height: auto;
}

@keyframes fadeInBottom {
    from {
        opacity: 1;
        transform: translateX(100%);
    }

    to {
        opacity: 1
    }
}
@media screen and (max-width:1200px) {
    div.chat-popup {
        position: fixed;
        top: 70px;
     }
     div.left-div .well-chat-icon + .modal-container {
        top: 65px;
        position: absolute;
    }
    div.messages ul.chatlistautotext {
        height: 325px;
    }
div.left-div .well-chat-icon + .modal-container .Messanger .messanger-inner .chatList {
        height: 345px;
    }
}

@media screen and (max-width:991px) {
    div.chat-popup {
        width: 380px;
        position: fixed;
        top: 93px;
        right: 0;
        bottom: auto;
        transform: none;
    }
    div.modal-container{
        width: 380px;
    }
	div.chatboxview.chatboxviewNew {
                    margin: 10px 0px 10px 0px;
                    max-width: 95%; 
                }
	  div.left-div .well-chat-icon + .modal-container {
		right: 0;
		filter: none;
		top: 74px;
		bottom: auto;
		position: absolute;
	}
    div.left-div .well-chat-icon + .modal-container .Messanger .messanger-inner .chatList {
        height: 360px;
    }
}

@media screen and (max-width: 767px) {
    div.left-div .well-chat-icon + .modal-container {
        top: 45px;
    }
    div.messages ul.chatlistautotext {
        height: 340px;
    }
}


@media screen and (max-width:480px) {
    div.left-div .well-chat-icon + .modal-container {
        top: 46px;
        position: absolute;
    }
    div.chat-popup {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0%;
        transform: none;
        bottom: 0;

    }
	 .chat-content-setting .r-title-div .inner-chat span.u-content {
        display: flex;
        }
        .chat-content-setting .r-title-div .inner-chat span.u-content span{
          line-height: 25px;
            }
            .chat-content-setting .r-title-div .inner-chat{
                width: 190px;
                position: absolute;
            top: 30px;
            left: 20px;
                  }
	div.chatboxview.chatboxviewNew {
                    margin: 10px 0px 10px 0px;
                    max-width: 95%; 
                }
	   div.css-1t1lumd {
        width: 95%;
        padding: 15px;
        }
		div.chatsettingfront span {
            font-size: 14px;
          
        }
		div.css-10tlgzi{
				padding:10px;
				width:95%;
      }
	   div.chat-popup {
        bottom: auto;
      }
      div.chat-popup {
        position: fixed;
        top: 96px;
    }
    div.left-div .well-chat-icon + .modal-container .Messanger .messanger-inner .chatList {
        height: 335px;
    }
}


.sender-name {
    color: floralwhite;
    font-size: 11px;
    line-height: 90%;
}

.chat-box .chating-person .replies .message-time {
    margin-top: 5px;
}

.chat-box .chating-person .replies .message-time-r {
    margin-top: 5px;
}

.group_image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.group_image img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

/*=========== New styling Here ==============*/

div.chatboxview{
    width: 44%;
    max-width: 950px;
    padding: 10px 20px 20px 20px;
}
div.chatboxview .grouplist button{
padding: 8px 20px;
}
div.chatboxview .grouplist button{
    padding: 8px 40px;
}
.chatboxview  .add-title{
font-size: 30px;
}
div.chatboxview .grouplist button:hover,
div.chatboxview .grouplist button:hover{
    background-color: #f19357;
    color: #fff;
}
div.chatboxview .grouplist button,
div.chatboxview .grouplist button{
border-radius: 8px;
font-size: 20px;
    border: 1px solid #ced4da;
    color: black;
    background: none;
    font-weight: 700;
}
div.chatboxview {
    position: absolute;
    top: 310px;
    left: 50%;
}
.chatboxview.chatboxview2 {
	 top:285px;
}
.chatboxview.chatboxview1 {
	 top: 355px;
}
.chat-content-setting{
    display: flex;
    margin-top: 20px;
}

 div.chatboxview .goback{
     font-size: 19px;

 }
.chatsettingfront span{
    font-size: 20px;
    font-weight: 700;
}
.chatsettingfront .inner-chat{
width: 250px;
border-radius: 8px;
position: absolute;
top: 30px;
left: 35px;
}
.chat-content-setting .group_image img {
    width: 100px;
    height: 100px;
}
div.chatboxview .list-searches,
div.chatboxview .list-searches {
  height: 250px;
}
.chatboxview #chatsearchListmember {
    height: 195px;
}
.chatboxview .search-list .search-list-popup h1{
    position: relative;
    top: 80px;
}

.viewMemberchatpopup {
	z-index:11111;
}
.chatboxview.chatboxviewNew{

    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    top: 0;
    background: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.9);
    border-radius: 16px;
	margin: 15px auto;
	
}
