.modal-container,
.modal-container2 {
    position: absolute;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2));
    border-radius: 4px;
    max-width: 100%;
    height: auto;
    top: 74px;
    right: 169px;
    background: #FFFFFF;
    z-index: 1;
    margin: 0px auto;
    display: block;
    text-align: center;
    width: 380px;
    text-align: left;
}

.modal-container2 {
    width: 280px;
    
}
.modal-container2 h1 {
    font-family: 'Inter';
    text-align: center;
    font-size: 16px;
	padding: 10px 0px;
}
.modal-container2 .text-capitalize{
    font-family: 'Inter';
    font-size: 14px;
}

.modal-container2 .modal-div-inner{
 
    max-height: 200px;
    overflow-y: auto;

}
.modal-div-1 {
    background: #FFFFFF;
    padding: 13px 13px 12px 12px;
}

.modal-div-inner {
    max-height: 450px;
    overflow-y: auto;
}

.modal-div-2 {
    width: 100%;
    padding: 8px 8px 40px 8px;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #E4E8EE;
    margin-bottom: 10px;
}

.modal-div-3 {
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #E4E8EE;
    padding: 8px 0px 60px 8px;
    margin-bottom: 10px;
    width: 100%;

}

.modal-div-4 {
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #E4E8EE;
    padding: 8px 0px 43px 8px;
    margin-bottom: 10px;

}

.div4-para {
    width: 349px;
    margin: 10px 0px 0px 61px;
}

.modal-chat {
    padding: 5px;
    box-shadow: 0px 1px 0px #E4E8EE;
}

.modal-chat img {
    border-radius: 50px;
    width: 40px;
    height: 40px;
}

.div4-para-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5F62;
    text-align: left;
}

.notification-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #2D3134;
    text-align: left;

}

.noti-para {
    display: inline-block;
    width: 82%;
    margin-left: 16px;
}

.noti-para p {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;

}

.image-noti {
    width: 32px;
    height: 32px;
    float: left;
    border-radius: 50%;
}

.jackey-profile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
}

.modal-btn-div {
    display: flex;
    gap: 8px;
    margin: 16px 0px 0px 60px;
}

.time {
    margin: 13px 63px;
    float: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #BFBCB8;
}

.Accept-btn {
    padding: 4px 8px;
    color: #FFFFFF;
    width: 64px;
    height: 28px;
    background: #F19357;
    border-radius: 4px;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    float: left;
}

.reject-btn {
    padding: 4px 8px;
    gap: 10px;

    width: 58px;
    height: 28px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background: #FFFFFF;
    border: 1px solid #BFBCB8;
    border-radius: 4px;
    color: #2D3134
}


.text-container-noti {
    display: inline-block;
    width: 100%;
}

.text-container-noti .circle {
    float: left;
}

.circle {
    width: 10px;
    height: 10px;
    background: #F0B285;
    border: 1px solid #F19357;
    border-radius: 100%;
}

.mark-as {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    color: #2D3134;
    text-align: right;

}

.all {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    color: #BFBCB8;
    padding: 8px;

}

.all a {
    text-decoration: none;
    color: #BFBCB8;
}

.notificatonThreedostsInnerbox a {
    text-decoration: none;
    display: block;
}

.notificatonThreedostsInnerbox .dotspop {
    padding: 0px;
}

.notificatonThreedostsInnerbox .dotspop a {
    padding: 5px;
}

.notificatonThreedostsInnerbox .dotspop a:hover {
    background: #F19357;
    color: #fff;
}

.notificatonThreedostsInnerbox .three-dots {
    width: 23px;
    height: 23px;
    display: block;
    margin: 0;
    right: 7px;
    line-height: 23px;
    border-radius: 50%;
}

.notificatonThreedostsInnerbox .three-dots:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.notificatonThreedostsInnerbox .dotspop {
    left: auto;
    right: 30px;
    top: 15px;
}

.notificatonThreedostsInnerbox .dotspopOuter {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
}

@media only screen and (max-width:820px) {
    .modal-container {
        position: absolute;
        filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2));
        border-radius: 4px;
        width: 100%;
        top: 85px;
        right: 0px;
        background: #FFFFFF;
        z-index: 1;
    }

    .div4-para {
        width: 79%;
    }
}

@media only screen and (max-width:767px) {
    .noti-para {
        width: 77%;
    }
}

@media only screen and (max-width:576px) {
    .modal-container {
        position: absolute;
        filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2));
        border-radius: 4px;
        width: 100%;
        top: 110px;
        right: 0px;
        background: #FFFFFF;
        z-index: 1;
    }

    .div4-para {
        width: 79%;
    }
}