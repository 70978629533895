.login{
    background: url(../../assets/Images/homepage2.jpg) left top no-repeat;
    background-size: cover;
	display: inline-block;
    width: 100%;
	height:100%;
}
.rember {
	width:100%;
	margin:0 auto;
	    display: inline-block;
}
.form-checks {
	float:left;
}
.rember .form-check-input {
	float:left;
	width: 20px;
    height: 20px;
	margin:0;
}
.rember .form-check-label {
     margin-left: 25px;
	 margin-top: 0;
}
.loginouter {
    position: relative;
    max-width: 622px;
    background-color: white;
    border-radius: 10px;
    padding: 10px 30px;
    margin: 80px auto;
    width: 100%;
}

.loginouter h1,.restPass h1{
    color: #2D3134;
    margin-top: 20px;
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 45px;
line-height: 54px;
text-align: center;
}
.loginouter input{
   width: 100%;
    position: relative;
    border-radius: 50px;
    font-size: 18px;
    margin: 20px auto;
}
.loginouter input::placeholder{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 28px;
color: #2D3134;
}
.form-control{
    width: 100%;
    height: 52px;
}
.loginouter select{
    width: 100%;
    height: 45px;
    border-radius: 50px;
    margin: 15px 20px;
    font-size: 18px; 
}
.btn22,.btn11{
	   position: relative;
    max-width: 400px;
    height: 56px;
    border-radius: 10px;
 	 color: white;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
       width: 100%;
}
.btn11{ margin: 20px 0 10px 0;  background-color: #F19357;}
.btn22{    margin: 10px 0;   background: #FFFFFF;   border: 2px solid #D9D9D9;}
.loginouter .bottomText{
    text-align: center;
    padding: 25px 28px 40px 10px;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.01em;
color: #2D3134;
}
.loginouter .bottomText span a{
    text-decoration: underline;  
     font-weight: bold;    
     color: #2D3134;
     font-family: 'Inter';
font-size: 16px;
line-height: 20px;
}
.form-check-input{
    position: relative;   
     left: 16px
}
.or{
    font-family: 'Inter';
    margin-top: 15px; 
     position: relative;
font-weight: 400;
font-size: 18px;
line-height: 28px;
color: #2D3134;
  }
.remember{
    position: relative;
    float: left;
    bottom: 4px;
}
.forgotpass{
      float: right;
    color:#265472;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 28px;
}
.signup .sharepopupOuter,
.login .sharepopupOuter {
	position: fixed;
    height: 100%!important;
}
.signin .marginboottom {
    margin-bottom: 0px;
}
.signin  .sidebar{
  display: none;

}
.btn11.forgetbtn {
  width: 200px;
    margin: 0 auto;
    float: none;
    display: block;	
}
.loginouter .error{
	margin-left:15px;
}
@media only screen and (max-width:767px){
.loginouter .bottomText {
     padding: 10px;
}
.loginouter h1 {
   
    font-size: 30px;

}

}