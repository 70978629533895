.dotspop {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 5px;
    width: 147px;
    position: absolute;
    left: 15px;
    right: auto;
    top: 0;
    z-index: 1;
}
.dotspop a {
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #2D3134;
    margin: 0;
    padding: 2px 4px;
}
.border-bottom {
    border-bottom: 0.25px solid #5B5F62;
}
.serch-popup {
    height: 75px;
    width: 147px;
    border-radius: 6px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;

}
.serch-popup11 {
    width: 127px;
    border-radius: 6px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 6px;
    position: absolute;
    left: 118px;
    top: 18px;
}
.serch-popup11 a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    color: #2D3134;
    text-decoration: none;
}
.bt {
    border-bottom: 0.25px solid #5B5F62;
    padding: 4px 0px;
}