.create-schedule .create-icon{
  margin-top: 50px;
}
.schedule-padding{
  padding-left:50px;
}
.createschedule .create-schedule .schedule-padding .createResourceFilters .duration-text select,
.createschedule .create-schedule .schedule-padding .createResourceFilters .form-select select{
  background-color: white;
  padding-left: 10px;
}
.black-filed {
  text-align: center;
  font-size: 31px;
  font-family: 'Inter';
  padding: 70px 10px;
  color: #000000;
}
.myshedule-content-box-info{
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}
.create-schedule .create-schedule-content h5 span{
  margin-right: 8px;
}
.create-schedule .create-schedule-content h5 span i{
  font-weight: 800;
}
.create-schedule .create-schedule-content h5{
  float: none;
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 20px;
align-items: center;
color: #2D3134;
margin-left: 40px;
}
.create-schedule .create-schedule-content h1{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 36px;
text-align: left;
color: #2D3134;
margin:30px 0px 30px 50px;
}
.sche-dule .time-picker .e-input-group-icon,
.createschedule .schedule-content .e-input-group .e-input-group-icon{
  position: absolute;
  width: 100%;
  opacity: -1;
  height: 35px;
}
.sche-dule .time-picker .time-picker-com .e-input-group .e-clear-icon,
.createschedule .schedule-content .e-input-group.e-control-wrapper .e-clear-icon{
opacity: -1;
display: none;
}
.create-schedule .create-schedule-div{

  background: #FFFFFF;
  box-shadow: 0px 3px 4px rgba(109, 108, 108, 0.1);
  border-radius: 12px;
  padding: 15px 0px;
}
.create-schedule .create-schedule-div .schedule-content .content{
  display: flex;
  justify-content: space-between;
  text-align: left;
margin-bottom: 15px;
}

.create-schedule .create-schedule-div .schedule-content .content h2{
font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
line-height: 30px;
align-items: center;
color: #000000;
width: 125px;
}
.create-schedule .create-schedule-div .schedule-content .content button{
  width: 144px;
height: 40px;
background: #F5F5F5;
border-radius: 10px;
border: none;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #2D3134;
}
.create-schedule .create-schedule-div .schedule-content .content .createaddplus{
  padding: 4px 12px;
  background: #F19357;
  border-radius: 6px;
  color: white;
  width: auto;
  height: 40px;
}
.create-schedule .create-schedule-div .schedule-content .content .createaddplus i{
  margin-top: 0px;
  font-size: 17px;
}
.create-schedule .create-schedule-div .schedule-content ul li{
  margin-top: 30px;
  list-style-type: none;
}
.schedule-button-save button,
.schedule-button button{
width: 212px;
height: 44px;
background: #F19357;
border: 1px solid #F19357;
border-radius: 10px;
font-family: 'Inter';
font-weight: 700;
font-size: 16px;
line-height: 20px;
align-items: center;
letter-spacing: 0.01em;
float: right;
margin-top: 30px;
color: #FFFFFF;
margin-bottom: 40px;
}
.schedule-date h3{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 30px;
display: flex;
align-items: center;
color: #2D3134;

}
.schedule-date.bookingrecuring h3{
	 margin-top:0;
}
.schedule-date .schedule-date-box,
.schedule-button {
  width:100%;
}
.createResourceFiltersReoccuringbooking
 {
 max-width:652px;
  width:100%;
}

.createResourceFiltersReoccuringbooking,
.schedule-button-save {
display:inline-block;
width:100%;
}
.schedule-date .schedule-date-box{
padding: 0;
border-radius: 12px;
margin-top: 30px;
}
.createResourceFilters {
margin-top:15px;
margin-bottom: 25px;
}
.schedule-button-save button{
  margin-bottom: 30px;
}
.schedule-date-box .e-calendar {
max-width:100%;
}
.create-schedule-div span.e-input-group,
.create-schedule-div .e-input-group .e-timepicker {
 /*  width: 144px!important; */
  height: 40px;
  background: #F5F5F5;
  border-radius: 10px;
  border: 1px solid #F5F5F5;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
 font-size:16px!important;
  line-height: 24px;
  text-align: center;
  color: #2D3134!important;
      padding-left: 5px;
}

.createResourceFilters .div.searchWrapper input::placeholder,
.create-schedule-div .e-input-group .e-timepicker::placeholder{
color: #2D3134!important;
font-size:16px!important;
}
.create-schedule-div .e-input-group.e-input-focus .e-timepicker:focus {
border:1px solid #F19357;
}
.schedule-content .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.schedule-content .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.schedule-content .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
  border-color: transparent!important;
  width: 136px!important;
    height: 40px;
    background: #F5F5F5;
    border-radius: 10px;
    border: 1px solid #F5F5F5;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px!important;
    line-height: 24px;
    text-align: center;
    color: #2D3134!important;
    padding-left: 5px;
}
.create-schedule .schedule-padding .createResourceFilters .form-select {
  background: none;
  border: none;
  padding: 0;
  height: auto;
}
.createResourceFilters .form-select select{
  width: 100%;
    height: 34px;
    border-radius: 50px;
    border: 1px solid #BFBCB8;
    font-weight: 500;
    font-size: 13px;
    padding: 0px 6px;
}
.createResourceFilters .optionContainer .notFound {
 font-size: 14px;
}
.createResourceFilters .e-input-group,
.duration-text input {
 width:100%;
     height: 34px;
  background: #FFFFFF;
  border: 0.21px solid #BFBCB8!important;
  border-radius: 32.5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2D3134;
  padding: 0px 12px;

}
.duration-text input {
 margin-right: 12px;

}
.create-schedule-div .toggle-switch .switch {
background-color: #ccc;
width: 50px;
height: 25px;
}
.createResourceFilters h3{
  font-family: 'Inter';
  font-size: 16px;
  margin-left: 10px;
text-align:left;
line-height: 26px;
}
.createResourceFilters .multiSelectContainer input {
  margin-top: 0;
  font-size: 13px;
  font-weight: 500;
}
.createResourceFilters div.searchWrapper {
  overflow: hidden;
  /* overflow-y: auto; */
}
.schedule-date span.e-input-group,
.create-schedule-div .e-input-group .e-timepicke{
  height: 40px;
background: #F5F5F5;
border-radius: 10px;
border: 1px solid #F5F5F5;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px!important;
line-height: 24px;
text-align: center;
color: #2D3134!important;

}
.create-schedule-div .e-input-group .e-timepicke {
width: 95px!important;

}
.createResourceFilters .sch-select{
padding-left: 6px;
width: 100%;
height: 34px;
border-radius: 50px;
border: 1px solid #BFBCB8;
font-weight: 500;
    font-size: 12px;
}
.schedule-date .schedule-date-box .e-content table tr th{
border: none;
}
.schedule-date .sche-dule{
padding: 0;
display: flex;
}
.schedule-date .e-calendar .e-content span.e-day, .e-bigger.e-small .e-calendar .e-content span.e-day{
font-size: 13px;
  font-weight: normal;
  height: 30px;
  line-height: 30px;
}
.createResourceFilters .res-select{
  font-size: 14px;
width: 200px;
border: none;
}
.schedule-date .e-cell{
border: none;
}

.schedule-date .e-calendar .e-content td.e-today span.e-day, .e-calendar .e-content td.e-focused-date.e-today span.e-day{
color: none;
}
.schedule-date .e-calendar .e-content td.e-focused-date.e-today span.e-day{
color: none;
}
.schedule-date .e-calendar .e-content td.e-selected span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
background-color: #F19357;
border: none;
border-radius: 10px;
color: #fff;
}
.schedule-date .e-calendar .e-content td.e-selected span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day:hover{
background-color: #F19357;
}

.schedule-date .e-calendar .e-content td.e-today span.e-day{

 border: none;
 border-radius: 10px;
box-shadow: none;
color: white;

}
.schedule-date .e-calendar .e-content td.e-today span.e-day:hover{
background: #F19357;
 border: none;
 border-radius: 10px;
box-shadow: none;
color: white;

}

.schedule-date .e-calendar .e-content td.e-today span.e-day{
background-color:none;
color: black;
}
.e-calendar .e-content td .e-focused-date .e-today span.e-day:hover{
background: #F19357;
border: none;
 box-shadow: none;
 color: white;
}
.schedule-date .e-control{ border-radius: 10px; }
.schedule-date .e-calendar{
border: 1px solid #130A2E21;
}
.e-btn .e-today .e-flat .e-primary .e-css{
content: none;
}
.e-calendar .e-content td:hover span.e-day, .e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day {

background-color: #F19357;
border: none;
border-radius: 10px!important;
color: white;
}
.reason{
padding: 1px 24px;
height:40px;
width: 100%;
background: #FFFFFF;
border: 1px solid #D9D9D9;
box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
border-radius: 50px;
font-family: 'Inter';
}
.MuiTypography-h6{
font-size: 18px;
}
.schedule-date .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  color: none;
}
.schedule-date .e-calendar .e-content td.e-today span.e-day {
  background-color: none;
  color: black;
}
.schedule-date .e-calendar .e-content td.e-today span.e-day {
  border: none;
  border-radius: 10px;
  box-shadow: none;
  color: black;
}
.schedule-date .e-calendar .e-content td.e-today span.e-day, .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  color: none;
}
.e-calendar .e-content td.e-focused-date.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: #F19357;
  border: 1px solid #F19357;
  box-shadow: none;
  color: black;
}
.time-coms{
border: 1px solid #130A2E21;
margin-top: 30px;
margin-left: 15px;
background-color: white;
border-radius: 10px;
height: max-content;
padding-bottom: 10px;
max-width: 290px;
width: 100%;
}

.schedule-date .time-picker {
border: 1px solid #130A2E21;
 margin-top:10px;
background-color: white;
border-radius: 10px;
height: max-content;
padding-bottom: 10px;
display:inline-block;
width: 100%;
}
.time-pickerscroll {
	overflow: hidden;
    max-height: 300px;
    overflow-y: auto;
}
.selectsessionspd ul{
   max-height: 300px;
   overflow-y: auto;
}
.createaddplus {
	margin-bottom:10px;
}
.schedule-date .e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
border: none;
border-width: 0 0 1px 0;
}
.schedule-date .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) .e-input:focus{
border-bottom-color: none;
border-top-color: none;
}
.schedule-date .e-input:focus:not(.e-success):not(.e-warning):not(.e-error){
border-color: none;
}
.schedule-date .e-calendar .e-btn.e-today.e-flat.e-disabled, .e-calendar .e-btn.e-today.e-flat.e-disabled:hover, .e-calendar .e-btn.e-today.e-flat.e-disabled:active, .e-calendar .e-btn.e-today.e-flat.e-disabled:focus, .e-calendar .e-btn.e-today.e-flat.e-disabled:hover:active {
background: transparent;
border-color: transparent;
box-shadow: none;
color: rgba(0, 0, 0, 0.26);
cursor: default;
opacity: 0.35;
outline: none;
pointer-events: none;
-ms-touch-action: none;
    touch-action: none;
}
.schedule-date .createaddplus{
height: 37px;
padding: 2px 12px;
background: #F19357;
border-radius: 6px;
color: white;
float: right;
width: auto;
border: none;
margin-left: 10px;
}
.time-coms {
float: left;
display: flex;
justify-content: space-between;
padding: 10px 10px 1px 10px;

}
.schedule-date .time-picker-com{

padding: 10px 10px 0px 10px;
width:100%;

}
.schedule-date .e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
background: transparent;
border-color: transparent;
color: white;
}

.schedule-date .e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover {
 background: none;
 border-color: none;
 color: white;
 cursor: none;
}
.schedule-date .e-calendar .e-content td.e-today:hover span.e-day, .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {

background: #F19357;
border: none;
box-shadow: none;
color: white;
border-radius: 10px;
}
.MuiBox-root .child-cancel{
	background-color: #F19357;
	color: white;
	padding: 5px 25px;
	  border: none;
  border-radius: 8px;
 /* font-weight: 700; */
  font-family: 'Rubik', sans-serif;
}

.createResourceFilters h3 span{
  color: red;
}

.schedule-padding .create-span-sechule{
  margin-left: 12px;
}
.createschedule .MuiBox-root .MuiTypography-h2{
  text-align: center;
}

.schedule-date .schedule-date .e-calendar .e-content td.e-today span.e-day:focus{
  border-radius: 10px;
}
.schedule-date .e-calendar .e-content td.e-today span.e-day, .e-calendar .e-content td.e-focused-date.e-today span.e-day{
  border-radius: 10px;
}

.schedule-date .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: #F19357 !important;
}

.schedule-date .time-picker .time-pick{
  padding: 0;
  margin-right: 10px;
}

.schedule-date .time-picker .time-pick4{
  padding: 0;
}

.schedule-date .e-input-group .e-input-group-icon:last-child,.e-input-group.e-control-wrapper .e-input-group-icon:last-child{
  margin-right: 6px;
  font-weight: 900;
}
.bookingRecuring h3 {
	margin-top:0;
}
.react-tabs .react-tabs__tab-list {
    border-bottom: 1px solid #130A2E21;
}
.react-tabs .react-tabs__tab--selected {
   background: #265472;
    color: white !important;
    border-radius: 10px 10px 0px 0px;
   padding: 8.5px 16px;

}
.react-tabs .react-tabs__tab-list li h3 {
  	font-size: 14px;

}
.react-tabs .react-tabs__tab--selected h3 {
    color: #fff;

}
.sessionhistory .e-schedule {
  overflow: visible!important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: transparent!important;
}
.e-float-input textarea:-webkit-autofill ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-autofill textarea:-webkit-autofill ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-autofill textarea:not(:focus):valid:-webkit-autofill ~ label.e-float-text.e-label-bottom,
.e-timepicker input::selection {
  background: #fff!important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
 }
 .react-tabs .react-tabs__tab--selected:after {
	border:none;
 }
.createResourceFiltersReoccuringbooking .react-datepicker-wrapper #date {
	border-radius: 8px;
    height: 40px;
     padding: 0 10px;
    line-height: 40px;
    font-weight: 500;
	border: 1px solid #130A2E21;
}
.ipopup{
  width: 20px;
    border-radius: 50px;
    height: 20px;
    background:#F19357;
    margin-left: 10px;
}

.ipopup span{
  padding: 8px;
  color: white;
  font-size: 15px;

}
.myschedulerightBox .fa-info-circles {
	font-size:20px;
}
@media screen and (max-width:992px) {
	div.createResourceFiltersReoccuringbooking {
		max-width: initial;
	}
	.schedule-date .time-picker-com {
      padding: 10px 10px 0px 10px;

     }
	 .createResourceFilters .duration-text {
		 height:auto;
	 }
   .create-schedule .create-schedule-content h5 {
    margin-left: 0;
    width: 100%;
    padding: 10;
    }
    .create-schedule .create-schedule-content h1 {
      margin: 30px 0px 30px 10px;
    }
    .create-schedule .schedule-padding {
       padding: 0px;
    }
}
@media screen and (max-width:480px) {
.schedule-date .createaddplus{
float: left;
margin-left: 0px;

}
.createschedule .create-schedule .schedule-date h3 {
    font-size: 16px;
  }

  .create-schedule .create-schedule-div .schedule-content .content h2 {
    font-size: 15px;
    padding: 0;
  }
  .create-schedule-div .toggle-switch .switch {
    width: 45px;
    height: 20px;
  }
  .toggle-switch .switch::before {
    width: 16px;
    height: 16px;
}
.create-schedule .create-schedule-content h1 {
  margin: 30px 0px 30px 10px;
  font-size: 23px;
}
.create-schedule .create-schedule-content h5 {
  margin-left: 0px;
}
  .toggle-switch input[type="checkbox"]:checked+.switch::before {
    transform: translateX(25px);
    background-color: white;
    width: 16px;
    height: 16px;
}
  .create-schedule .schedule-padding {
    padding-left: 0px;
}
.create-schedule .create-schedule-div {
  padding: 10px;
}
.schedule-date .time-picker, .time-coms {
  max-width: none;
  margin-left: 0;
}
.time-coms, .schedule-date .time-picker-com{
  float: unset;
}
.create-schedule .duration-text {
height: auto;
}
.createResourceFilters .end-date {
  margin-left: 0px;
}
.createResourceFilters .form-select select{
    margin-bottom: 10px;
}
.createResourceFilters .sch-select{
  margin-bottom: 10px;
}
.createResourceFilters .e-input-group,
.duration-text input {
  margin-bottom: 10px;
}
.create-schedule .schedule-date h3{

    font-weight: 500;
    font-size: 18px;
}
.schedule-date .sche-dule {
  padding:0px;
}
.schedule-date .sche-dule {
   display:inline-block;
}
}
.custom-marker {
  position: relative;
}
.marker-dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
}
span.e-icons.highlight-day, span.e-icons.highlight-day:before {
  color: rgb(0, 0, 255);
 }

.special {
  position : relative
}

span.e-icons.highlight-day {
  display: block;
  height: 30px;
  border-radius: 25%;
  border: 1px solid green;
     width: 33%;
    margin-top: 5px;
    position: absolute;
    top: -3px;
    left: 25%;
    transform: translateX(28%);
}

.special {
  border: none !important;
}

.createschedule .create-side-box{
  border: 1px solid #130A2E21;
  width: 100%;
    background: white;
    padding: 15px 10px;
    border-radius: 10px;
    margin-top: 30px;
	max-height:335px;
	overflow:hidden;
    overflow-y: auto;
    height: 100%;
	position: relative;
}
.createschedule .create-side-text{
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.createschedule .create-side-text span{
    width: 100px;
  height: 40px;
  background-color: #F5F5F5;
  color: #2D3134;
  padding: 10px 5px;
  border-radius: 8px;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  margin-left: 5px;
}
.myschedulerightBox h6{
	text-transform:capitalize;
	margin-bottom:10px;
}
.createschedule .create-side-text button{
    height: 40px;
    border: 1px solid #F19357;
    margin-left: 6px;
    background: #F19357;
    color: white;
    border-radius: 8px;
	font-weight: 600;
    padding: 0px 7px;
	font-family:Inter;
	font-size:13px;
}
.createschedule .create-side-text button i{ 
  width:15px;

}
.createschedule .create-side-box .text-center{
   font-weight: 450;
}
.createschedule .create-schedule .time-picker input.e-input,
.createschedule .time-picker #timepicker0,
  .createschedule .time-picker #timepicker10{
    font-size: 16px;
    text-align: center;
    font-family: 'Inter';
    font-weight: 400;
}
.mannualdatesection .datebackground .e-calendar .e-content span.e-day{
    font-weight: bold;
}
.schecule-date-calendarIcon {
	position:relative;
}
.schecule-date-calendarIcon .fa-calendar {
	position: absolute;
    right: 10px;
    top: 12px;
}
@media screen and (max-width:1200px) {
	span.e-icons.highlight-day {
      left: 9px;
      transform: translateX(11px);
	  width: 44%;
   }

}
@media screen and (max-width:992px) {
	span.e-icons.highlight-day {
      left: 17px;
      transform: translateX(20px);
	      width: 30%;
   }
  }
@media screen and (max-width: 767px){
	span.e-icons.highlight-day {
		left: 13px;
		transform: translateX(15px);
		width: 37%;
	}
}
@media screen and (max-width:567px) {
	span.e-icons.highlight-day {
      left: 0px;
      transform: translateX(8px);
	  width: 64%;
   }

}
