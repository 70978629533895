
.footer {
  background:#265472;
  color:#fff;
  text-align: left;
    padding: 25px 0px;
	position: relative;
    z-index: 2;
}
.footer h6 {
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
    margin-bottom: 15px;
	font-family: 'Roboto', sans-serif;
	color:#fff;
}
.footer li {
  list-style:none;
}
.footer li a {
	color: #fff;
    text-decoration: none;
   	padding: 5px 0px;
    display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	font-family: 'Roboto', sans-serif;
}
.bottomLine {
	border: 1px solid #f19357;
    margin-top: 20px;
}
.copyright {
	padding: 25px 0px 0px 0;
    width: 100%;
    display: inline-block;
}

.copyright ul,.copyright-text {
 float: left;
}
.copyright-text {
	margin-right:50px;
   }
   .copyright-text span  {
	line-height: 26px;
	/* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
   }
.copyright ul li {
	display: inline-block;
	list-style:none;
}
.copyright ul li a {
	display: inline-block;
	padding: 5px;
    margin-right: 15px;
	/* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
}
.padleft100 {
   padding-left:100px;
}
@media screen and (max-width: 767px) {
   .col-lg-3.padleft100 {
      padding-left: 10px;
    }
}