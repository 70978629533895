.sharepopupOuter {
    position: fixed;
    width: 100%;
   background-color: rgb(27 20 20 / 70%);
    left: 0;
    z-index: 300;
    top:94px;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.css-b62m3t-container .css-13cymwt-control .css-1p3m7a8-multiValue div.css-9jq23d{
    color: white;
    }


.backgroundOpacity {
    background-color: rgb(247 247 247 / 70%);
}
.save-resource-list .css-b62m3t-container .css-t3ipsp-control div.css-1dyz3mf,
.save-resource-list .css-b62m3t-container .css-13cymwt-control div.css-1dyz3mf,
.shareinspiration .css-b62m3t-container .css-t3ipsp-control div.css-1dyz3mf,
.shareinspiration .css-13cymwt-control .css-1dyz3mf,
.shareinspiration .css-b62m3t-container .css-13cymwt-control div.css-1dyz3mf{
	overflow:hidden;
    overflow-y: auto;
    height: 40px;
}
.post-popup-container {
    max-width: 741px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 2px solid rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    padding:15px 33px;
    width: 100%;
    margin:20px auto;
}

.post {
    display: inline-block;
}

.post-popup-container h1 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #2D3134;
}

.close-btn {
    border: none;
    line-height: 35px;
    text-align: center;
}

.post-inner-div {
    display: inline-block;
    width: 100%;
}

.post-inner-div h3 {
    clear: both;
    text-align: left;
}

.post-inner-div img {
    width: 50px;
    height: 50px;
}

.titlewithselectbox {
    padding-left: 15px
}

.imgpopupshara,
.titlewithselectbox {
    float: left;
    text-align: left;
}

.post-inner-div h2 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    padding: 10px 10px 0px;
}

.post-popup-container select {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #2D3134;
    margin-bottom: 27px;
    border: none;
    background: #e7e7e7;
    padding: 3px 5px;
    border-radius: 4px;
}

.post-popup-container h3 {
    margin-bottom:5px;
}

.service-container {
    background: #E8F0F4;
    border-radius: 26px;
    padding:20px 10px 0px 10px;
    text-align: center;
    display: inline-block;
    width: 100%;
}

.post-image {
    background-color: #F19357;
    width: 58px;
    height: 58px;
    margin: 5px;
    border-radius: 16px;
}

.post-image,
.post-image1,
.post-image2,
.post-image3,
.post-image4,
.post-image5,
.post-image6 {
    position: relative;
}

.post-image .tooltip,
.post-image1 .tooltip,
.post-image2 .tooltip,
.post-image3 .tooltip,
.post-image4 .tooltip,
.post-image5 .tooltip,
.post-image6 .tooltip {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.post-image:hover .tooltip,
.post-image1:hover .tooltip,
.post-image2:hover .tooltip,
.post-image3:hover .tooltip,
.post-image4:hover .tooltip,
.post-image5:hover .tooltip,
.post-image6:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

.post-popup-container h3,
.service-container h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-top: 10px;
}
.post-popup-container h3 {
    margin-top:10px;
}

.service-container button {
    padding: 10px 40px 8px 40px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
   /*  font-family: 'effra_trialregular'; */
   font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #2D3134;
}

.post-button button {
    padding: 7px 10px;
    background: #F19357;
    color: white;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    /* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-top:10px;
    min-width: 139px;
}

.post-image {
    background-color: #F19357;
}

.post-image1 {
    background-color: #91CBCE;
}

.post-image2 {
    background-color: #82A3A1;
}

.post-image3 {
    background-color: #F9C976;
}

.post-image4 {
    background-color: #AAC0CE;
}

.post-image5 {
    background-color: #265472;
}

.post-image6 {
    background-color: #57886C;
}

.post-image,
.post-image1,
.post-image2,
.post-image3,
.post-image4,
.post-image5,
.post-image6 {
    width: 58px;
    height: 58px;
    margin: 5px;
    border-radius: 12px;
    border: 2px solid #f9f9f9;
}

.post img {
    width: 42px;
    height: 42px;
    margin-top: 5px;
    border-radius: 0;
}

.publicprivatepost {
    width: 100%;
    margin: 10px 0 0 0;
    display: inline-block;
}

.post.active {
    border: 2px solid #F19357;
}
.publicprivatepost .css-b62m3t-container .css-13cymwt-control {
    margin: 0;
}
/*========= Right Top three dot styling Here ===========*/

.parentrightchildlitBox .img-icon .remove-res {
    width: 145px;
    right: 20px;
}

.parentrightchildlitBox .img-icon .remove-res h5:first-child {
    border-top: none;
}

.parentrightchildlitBox .img-icon .remove-res h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12.4138px;
    line-height: 19px;
    color: #000;
    border-top: 0.21px solid #BFBCB8;
    margin-bottom: 0px;
    text-align: left;
    float: left;
    padding: 3px 0;
    width: 100%;
}

.profilethreedots {
    cursor: pointer;
}
.post-scroll-div {
	margin-top: 15px;
	max-height: 340px;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
	padding-right: 10px;
}

.mul-sel-div .share-with-group {
	margin-bottom:10px;
}
.pop-up-container .sharediv-popup{
    overflow-x: hidden;
    overflow-y: auto;
   max-height:200px;
}
.pop-up-container .sharediv-popup table{
	margin-bottom:0;
}