.notification-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #2D3134;
    margin-top: 70px;

}

.notification-ul {
    width: 80%;
    text-align: center;
    padding-top: 20px;
	margin: 0 auto;
    overflow: hidden;
}
.notification-ul .notification-header {
	float: left;
	margin-top: 35px;
}
.notification-ul ul {
	float:right;
	padding-top: 35px;
}
.notification-ul li {
    display: inline-block;
    margin: 0 0px 0 10px;
}

.notification-ul ul li .btn-read,
.notification-ul ul li .btn-unread,
.notification-ul ul li .btn-all {
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #2D3134;
    margin-top: 17px;
    text-align: center;
}

.notification-ul ul li .btn-all {
    width: 94px;
    margin-top: 0;
    padding: 0.5rem 1rem;
    text-align: center;
}

.notification-ul ul li .btn-unread {
    width: 134px;
   margin-top: 0;
    padding: 0.5rem 1rem;
    text-align: center;
}

.notification-ul ul li .btn-read {
    width: 143px;
   margin-top: 0;
    padding: 0.5rem 1rem;
    text-align: center;
}

.notification-div {
    width: 80%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #E4E8EE;
    margin:30px auto 0;
}

.notification-div .point {
    border-radius: 50px;
    width: 8px;
    height: 8px;
    background: #F0B285;
    border: 1px solid #F19357;
    position: absolute;
    top: 0px;
    left: 4px;
}

.notification-div-flex ul {
    /*  justify-content: space-between; */
    display: inline-block;
    margin-top: 7px;
    gap: 5px;
    padding-top: 10px;
    width: 100%;
}

.notification-div-flex li {
    position: relative;
    float: left;
}

.notification-div-flex li:first-child {
    padding-left: 15px;
    list-style-type: none;
}

.notification-div-flex li:nth-of-type(2) {
    width: 75%;
    list-style-type: none;
}

.notification-div-flex li:last-child {
    float: right;
    list-style-type: none;
}

.notification-div-flex .notification-img-box {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    float: left;
    border: 1px solid #c9daf4;
}

.notification-div-flex h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    margin-right: 390px;

}

.notification-div-flex {
    display: inline-block;
    width: 100%;
}

.acceptReject {
    width: 152px;
    float: right;
    text-align: right;
    padding-right: 5px;
}

.notification-div-flex h5 span {
    font-weight: 800;
}

.notification-div-flex .reject-btn,.modal-div-inner .modal-btn-div .acceptReject .accept-btn,
.notification-div-flex .accept-btn {
    width: 64px;
    height: 28px;
    background: #F19357;
    border-radius: 4px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    border: none;
    margin-right: 5px;

}
.modal-div-inner .modal-btn-div .acceptReject .accept-btn{
    float: left;
}
.notification-div-flex .reject-btn {
    width: 58px;
    height: 28px;
    background: #FFFFFF;
    color: #2D3134;
    margin-right: 5px;
    border: 1px solid #BFBCB8;
}

.notification-div-time h6 {
    float: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #BFBCB8;
    margin: 16px 60px;
}

.notification-div-flex p {
    text-align: justify;
    padding-left: 7px;
    padding-right: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;

}

.notification-div-flex h6 {
    text-align: left;
    margin-top: 40px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #BFBCB8;
    margin: 16px 60px;
}

.loadmore {
    width: 100px;
    height: 30px;
    margin: 30px auto;
    border: none;
    margin-bottom: 20px;
    background-color: #F19357;
    color: white;
    border-radius: 5px;
}

.notificationsettingBox {
  width: 90%;
    margin: 25px auto;
}
.notificationsettingInnerBox {
	 background: #fff;
    min-height: 200px;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
	margin: 0px auto 20px;
	box-shadow:0px 2px 4px #cdcdcd;
}

.notificationsettingBox .accordion-title {
    width: 100%;
    text-align: left;
    border: none;
    background: transparent;
    color: #000;
    font-weight: bold;
    padding: 5px 0;
    position:relative;
}
.notificationsettingBox .accordion-title-main{
	background: #fff;
    padding: 10px 10px;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 1px 2px 3px #cdcdcd;
}
.notificationsettingBox .accordion-title-main:before,
.notificationsettingBox .accordion-title-main:after{
	content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    position: absolute;
    right: 5px;
    top: 15px;
    z-index: 222;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}
.notificationsettingBox .accordion-title-main.accordion-active:after {
	display:none;
}
.notificationsettingBox .accordion-title-main.accordion-active:before {
    border-top: 5px solid #333;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.notificationsettingBox .accordion-body-main {
	padding: 0px 20px;
    background: #fff;
    margin-bottom: 10px;
}
.accordion-body-main .accordion-title  {
 font-weight: normal;
     padding: 0;
}
.accordion-body-main .accordion-title:after,.accordion-body-main .accordion-title:before {
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  position: absolute;
  right: 5px;
  top: 15px;
  z-index: 222;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  
}
.accordion-body-main .accordion-title.accordion-active:after {
  display:none;	
}
.accordion-body-main .accordion-title.accordion-active:before {
	 border-top: 5px solid #333;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
/*============== Accordion===============*/
/* .notificationsettingBox [id*="open-accordions3"],
.notificationsettingBox [id*="close-accordions3"],
.notificationsettingBox [id*="open-accordions2"],
.notificationsettingBox [id*="close-accordions2"],
.notificationsettingBox [id*="open-accordions1"],
.notificationsettingBox [id*="close-accordions1"] {

    line-height: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    position: relative;
    width: 99%;
}
.notificationsettingBox [id*="close-accordions2"],
.notificationsettingBox [id*="close-accordions3"],
.notificationsettingBox [id*="close-accordions1"] {
    display: none;
}

.notificationsettingBox .accordion a {
    color: #000;
    text-decoration: none;
    text-shadow: none;
}
.notificationsettingBox [id*="open-accordions3"]:after,
.notificationsettingBox [id*="close-accordions3"]:after,
.notificationsettingBox [id*="open-accordions2"]:after,
.notificationsettingBox [id*="close-accordions2"]:after,
.notificationsettingBox [id*="open-accordions1"]:after,
.notificationsettingBox [id*="close-accordions1"]:after {
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    position: absolute;
    right: 5px;
    top: 15px;
    z-index: 222;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);

}

.notificationsettingBox .target-fix {
    display: block;
    top: 0;
    left: 0;
    position: fixed;
}

.notificationsettingBox .accordion-content {
    height: 0;
    position: relative;
    overflow: hidden;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
}

.notificationsettingBox .accordion span:target~.accordion-content {
    display: block;
    height: auto;
    padding-bottom: 15px;
    padding-top: 10px;
}
.notificationsettingBox .accordion span:target~[id*="close-accordions3"],
.notificationsettingBox .accordion span:target~[id*="close-accordions2"],
.notificationsettingBox .accordion span:target~[id*="close-accordions1"] {
    display: block;
}
.notificationsettingBox .accordion span:target~[id*="open-accordions2"],
.notificationsettingBox .accordion span:target~[id*="open-accordions3"],
.notificationsettingBox .accordion span:target~[id*="open-accordions1"] {
    display: none;
}
.notificationsettingBox .accordion span:target~[id*="close-accordions3"]:after,
.notificationsettingBox .accordion span:target~[id*="close-accordions2"]:after,
.notificationsettingBox .accordion span:target~[id*="close-accordions1"]:after {
    border-top: 5px solid #333;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
} */

/*============== End Accordion===============*/

.accordion-data-notification li {
    margin-bottom:0px;
    overflow: hidden;
	
}
.accordion-data-notification li .text-infos {
	color:#5b5f62;
	float:left;
}

.notification-switch {
    float: right;
    padding-right: 15px;
}
.accordion-data-notification {
	padding:10px 20px 20px 20px;
}
.accordion-body-main .accordion-titles {
 padding: 10px;
font-weight: 600; 
color:#5b5f62;
border-bottom: 1px solid #f2ecec;
}
.accordion-body-main .accordion-titles:last-child {
	/* border-bottom:none; */
}

@media screen and (max-width: 991px) {

    .notification-ul ul li .btn-all {
        width: 74px;
        height: 47px;
    }

    .notification-ul ul li .btn-unread {
        width: 94px;
        height: 47px;
    }

    .notification-ul ul li .btn-read {
        width: 103px;
        height: 47px;
    }

    .notification-div-flex h5 {
        margin: 0 8px;
    }

    .notification-div {
        margin-bottom: 30px;
    }
	.notificationsettingBox {
     width: 100%;
     margin: 25px auto;
   }
   div.notification-div,
   div.notification-ul {
    width: 100%;
   }
}



@media screen and (max-width: 767px) {

    .notification-ul {
        margin-left:0px;
    }

    .notification-div-flex img {
        width: 32px;
        height: 30px;
    }

    .notification-div-flex .reject-btn {
        margin-right: 3px;
        margin-left: 5px;
    }

    .notification-div-time h6 {
        margin: 16px 42px
    }

}