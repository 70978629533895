.craditdiv1{
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 16px;
    margin-top:65px;
    padding: 20px 29px 0px 29px;
    margin-bottom: 30px;
    }
    .main-heading-reg{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2D3134;
    margin-bottom: 25px;
    text-align: left;
    }
    .main-heading-reg span{
        font-size:20px;
        padding: 0px 14px 0px 0px;
    }
    .vertalign{
        vertical-align: text-top;
    }
    .card-row-2{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .card-row-2 label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #5B5F62;
    }




.table-div .selected-Date-table table tr td{
    padding: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    border:1px solid #D9D9D9!important;
}


.purchase-card-content .table-div .selected-Date-table table tr td{
    height: 20px;
}

.purchase-card-content .table-div .selected-Date-table table{
    border:1px solid #D9D9D9!important;
    margin-bottom: 10px;
}

    .ccircle{
        font-size: 24px;
        color: #F19357;
    }
    .card-row-3{
        display:flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }
    .card-row-4{
        display:flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }
    .card-row-4 span{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #5B5F62;
    }
    .card-row-3 span{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #2D3134;

    }
    .iconca-1{
        font-size: 18px;
    }
    .hr-tag1{
        margin-top: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    }
    .ccircle1{
        font-size: 24px;
    }
    .carddiv-4{

    background: #E8F0F4;
    border: 1px solid #D9D9D9;
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 26px 15px 24px 26px;
    }
    .carddiv-4 h3{
    text-align: left;
    font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #2D3134;
    }
    .add-card-1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    }

    .add-card-1 h3{
        font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2D3134;
    position: absolute;
        padding: 2px 30px;
    }

    .addnew-div{
       display: inline-block;
        justify-content: space-between;
        margin-bottom: 30px;
        width: 100%;
    }
    .card-num-div {
        width: 40%;
        text-align: left;
        float: left;
        }
    .addnew-div2{
          margin-bottom: 32px;
    }
    .card-num-div h3{
        font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2D3134;
    }
    .card-num-div label{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #5B5F62;
    }
    .iconboxcircle{
            float: left;
        width: 10%;
        text-align: center;
        margin: 0 auto;
    }
    .add-card-input-div{
         padding: 14px 12px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        height: 46px;
        width: 48%;
        float: left;
    }
    .add-card-input-divs{

        width: 48%;
        float: left;
    }
    .add-card-input-div input{
        border:none;
        width:100%;
        outline: none;
        background: none;
    }
    .inputcard{
        margin-top: 1px;
        padding: 0px 13px 0px 0px;
        font-size: 18px;
    }
    .icon-flex{
    display: flex;
    }
    .add-card-input-div22{
        padding: 15px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        width: 45%;
        height: 48px;
    }
    .add-card-input-div22 input{
        border:none;
        width:100%;
        outline: none;
        background: none;
    }
    .setdefault{
    float: right;
    margin-right: 0;
    }
    .defaultlabel{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    margin-top: 2px;
    }
    .card-right-container{
    border: 2px solid #D9D9D9;
    margin-top:70px;
    margin-left: 10px;
    border-radius: 8px 8px 0px 0px;
    padding:19px 10px 0px 10px;
	background: #fff;
    }
    .registered{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    margin-bottom: 18px;
        text-align: left;
    }
    .card-right-container span{
        padding: 0px;
    }
	.card-right-container .registered span{
        padding-right: 10px;
    }
    .mentor-session{
        display: flex;
        justify-content: space-between;
    }
    .mentor-session label{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    }
    .mentor-1{
        display: flex;
        justify-content: space-between;
    }
    .mentor-1 label{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    text-align: left;
    margin-bottom: 10px;
    }
    .mentor-1 span{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    text-align: left;
    margin-bottom: 10px;
    }
    .totaal{
           text-align: right;
    border: 2px solid #D9D9D9;
    padding: 16px 10px;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 40px;
    margin-left: 10px;
    background: #fff;
    border-top: 0;

    }
    .totaal h3{
        font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #2D3134;
    }
    .totle span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #5b5f62;
    }
	.confirm-order-bttns,
    .confirm-order-bttn{
        margin-left: 10px;
		text-align: center;
		display:inline-block;

    }
	.confirm-order-bttns{
      	 width: 100%;
    margin: 0;
    padding-left: 10px;
    }
	.confirm-order-bttn .cartinvoicedownload {

        background: #F19357;
        border-radius: 8px;
	  padding:11px 16px;
	width: 100%;
	}
    .confirm-order-bttn button{
     /*    margin-left: 98px; */
    border:none;
    background: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
	text-align: center;
    }
    .master-card .img1{
        width: 48px;
        height: 28px;
    }
    .master-card .img2{
        width: 40px;
        height: 28px;
    }
    .cir2{
        font-size: 24px;
        color: #F19357;

    }
    .cir55{
        font-size: 32px;
        color: white;
        margin-top: 6px;
    }

    .exp-inp input{
        width: 40px;
        border: none;
        outline: none;
        padding: 0px 6px;
    }
    div.inp1-exps {
         float:right;
    }
    .inp1-exp{
        padding: 15px 2px;
        width: 50px;
        height: 45px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    float:left;
    }
    .cvv h3{
        font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2D3134;
    }
    .cvv h5{
        font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 17px;
    color: #5B5F62;
    }
    .for-slash{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 31px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #2D3134;
        padding: 0px 5px;
        height: 48px;float:left;
    }
    .cvv{
        padding: 0px 7px;
        float:left;
    }
    .exp-div1 label{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #5B5F62;
    }
    .invoicebtn {
        width: auto;
        margin-left:10px;
    }
    .purcahse-information > h2{
        color: green;
        font-size: 26px;
        padding-bottom: 25px;
    }
    .purchase-card-content{
        display: flex;
		margin-bottom: 15px;
    }
    .delete-th-butt{
        text-align: center;
    }
    .purchase-card-content .totaal .totle{
        float: left;
       /*  margin-left: 23px; */
    }
    .purchase-card-content .table-div{
        margin-top: 70px;
        border-radius: 10px;
    background: #fff;
    border: 2px solid #D9D9D9;
}
    .purchase-card-content .table-div table{
        width: 100%;
        border: hidden!important;
    }
    .purchase-card-content .table-div table tr.paticular-tr{
      background-color: #F19357;
      color: white;
    }
    .purchase-card-content .table-div table tr th{
        height: 60px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 28px;
        color: white;
        background:#f19357;
    }
    .purchase-card-content .table-div table tr td{
    height: 100px;
}
    .purchase-card-content .table-div table tr .marge-td{
        width: 400px;
    }
    .purchase-card-content .clear-shopping,
    .purchase-card-content .conti-btn{
    padding: 12px;
        width: 215px;
        height: 50px;
        border: none;
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        text-align: center;
        margin-top: 10px;
    }
    .purchase-card-content .conti-btn{
        background: #F19357;
        color: #FFFFFF;
    }
    .purchase-card-content .clear-shopping{
        float:right;
        color: #2D3134;
        border: 0.5px solid #BFBCB8;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    }
	.purchase-card-content .clear-shopping.clear-shopping-success {
		 width: 100%;
	}
    .purchase-card-content .clear-shopping:hover{
        background: #F19357;
        color: #FFFFFF;
    }
    .card-right-container .tax,
    .card-right-container .titlesub{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .card-right-container .tax label,
    .card-right-container .titlesub label{
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: block;
        color: #5b5f62;
    }
    .purchase-card-content .removetimes{
        margin-left: 10px;
    }
    .purchase-card-content .icons0{
        position: relative;
        top: 100px;
        left: 13px;
        font-size: 15px;
    }
    .purchase-card-content .table-div .pati h1{
        font-size: 20px;
        text-align: center;
        color: white;
    }
    .purchase-card-content .table-div .tray{
        display: flex;
    }
    .purchase-card-content .table-div .tray img{
        width: 50px;
		    border-radius: 50px;
			margin-top: 7px;
    }
    .purchase-card-content .table-div .tray .all-set{
        margin-left:15px;
		width: 100%;
    }
	.purchase-card-content .table-div .tray .all-set h3,
    .purchase-card-content .table-div .tray .all-set h1{
        margin-top: 10px;
    }
    .purchase-card-content .table-div .selected-time .date-time{
        display: flex;
       margin-bottom:5px;
	   position: relative;
    }
	.purchase-card-content .table-div .selected-time .date-time .c-pointer {
		 position: absolute;
		right: 10px;
		left: auto;
		top: 0;
	   }
    .purchase-card-content .table-div .selected-time{
        margin-bottom:10px;
    }
    .purchase-card-content .table-div .selected-time h1{
        font-size: 16px;
    }
    .purchase-card-content .table-div .selected-time .date-time h2{
    margin-left: 20px;
    }
    .purchase-card-content .table-div .selected-time .date-time span{
        margin-left: 20px;

    }
    .empty-div-cart{
    margin-top: 80px;
    display: grid;
    place-items: center;
}
.empty-div-cart .emptyCart h1{
    margin-left: 26px;
    margin-bottom: 20px;
    font-size: 27px;
    margin-top: 10px;
}
.empty-div-cart .emptyCart img{
    width: 362px;
    height: 270px;
}
.empty-div-cart .emptyCart button{
border: 1px solid #F19357;
    font-size: 18px;
    margin-left: 77px;
     padding: 12px;
    border-radius: 8px;
    background-color: #F19357;
    color: white;
}
.selected-time .accordion-title  {
  border: none;
    background: transparent;
  position:relative;
  /*  display: block;*/
    width: 100%;
    text-align: left;
    padding: 5px 0;
}
.accord_title{
    border: none;
    background: transparent;
  position:relative;
  /*  display: block;*/
    width: 100%;
    text-align: left;
    /* padding: 5px ;  */
}
.conti-btn2{
    padding: 8px;
    background: #F19357;
        color: #FFFFFF;
    /* height: 50px; */
    border: none;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* text-transform: capitalize; */
    text-align: center;
    /* margin-top: 10px; */
}
.selected-time button.accordion-title:after  {
	content: "";
   /*  border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333; */
    position: absolute;
    right: -25px;
    top: 18px;
    z-index: 222;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}
.selected-time button.accordion-active:before {
   content: "";
   /*  background: #fff; */
    position: absolute;
    right: -25px;
    top: 16px;
    width: 12px;
    z-index: 2222;
    border: none;
    height: 10px;
    text-align: center;
}
.selected-time button.accordion-active h2:after{
	content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
	 position: absolute;
    right: -25px;
    top:14px;
    z-index: 2222;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.firstcol {
	width:65px;
	display:inline-block;
}
.pati {
		display:inline-block;
}
.purchasepage .confirm-order-bttns .paynows,
.purchasepage .confirm-order-bttn .paynows{
    width: 321px;
    height: 50px;
    border: 1px solid #F19357;
    background: #F19357;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: white;
    text-align: center;
    border-radius: 8px;
}
.cartbgcolor th{
	background: #F19357;
}
.cartbgcolor th:first-child{
    border-radius: 8px 0px 0px 0px;
}
.cartbgcolor th:last-child {
    border-radius: 0px 8px 0px 0px;
}
.servepaymenthistory .bookmoniterdiv .gobackcontainer select,
.servepaymenthistory .resourcetypess select.restype,
.servepaymenthistory .resourcetypess .css-b62m3t-container .css-13cymwt-control,
.servepaymenthistory .resourcetypess .css-b62m3t-container .css-t3ipsp-control {
    height:42px;

}
.servepaymenthistory .resourcetypess .css-b62m3t-container .css-13cymwt-control,
.servepaymenthistory .resourcetypess .css-b62m3t-container .css-t3ipsp-control {
        margin: 0;
		width:250px;
}
.servepaymenthistory .resourcetypess {
	margin-left: 10px;

}
.resourcetypess .css-t3ipsp-control {
	 min-height:31px;
}
.resourcetypess .css-1fdsijx-ValueContainer {
    font-weight: 600;
    font-size: 12px;
}
.resourcetypess .css-1dimb5e-singleValue {
    line-height: 15px;
}
.resourcetypess .css-1xc3v61-indicatorContainer {
    padding: 5px 8px;
}
.sessiondateTime {
	max-height:250px;
	overflow-y:auto;
	height:100%;
}
.servepaymenthistory .tobarbackcontee.allchildSelect .gobackcontainer {
	margin-top:0;
}
.salesHistoryrightSelect {
	border: 1px solid #dee2e6;
    height: 52px;
    background: #fff;
    border-radius: 50px;
    padding: 15px 5px 10px 10px;
	margin-bottom:15px;
	width:225px;
}
.salesHistoryrightSelect .search-wrapper .chip {
	/* word-wrap: break-word; */
    white-space: normal;
    width: 181px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 15px;	
}
.purchasepage .purchase-card-content .table-div .tray .all-set h3{
    word-break: break-word;
}
.purchasepage .container{
    height: auto;
}


@media only screen and (max-width:991px){
    .selected-time .accordion-title h2{
        font-size: 15px;
    }
    .purchase-card-content .table-div .selected-time .date-time h2{
        margin-left: 5px;
        font-size: 14px;
    }
}
    @media only screen and (max-width:480px){
       .selected-time .accordion-title h2{
            font-size: 15px;
        }
        .purchase-card-content .clear-shopping, .purchase-card-content .conti-btn {
           font-size: 14px;
            width: 155px;
        }
        .purchase-card-content .table-div .selected-time .date-time h2{
            margin-left: 5px;
            font-size: 14px;
             }
            .purchase-card-content .table-div .selected-time .date-time{
          display: flex;
          justify-content: space-between;
             }
            .purchase-card-content .table-div .selected-time .date-time span{
                margin-left:0px;

            }
            .purchase-card-content .table-div table{
                width: max-content;
            }
    }
