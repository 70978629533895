.textname1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    padding: 0px 0px 0px 6px;
    width: 156px;
}
.success-msg{
    padding : 20px;
    background-color: aquamarine;
    border-radius: 10px;
    margin-bottom: 20px;
}