.inviteGpopup {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  left: 0;
  z-index: 300;
  top: 0;
  width: 100%;
  height: 100%;

}
.inviteGpopupinner {
  background: #FFFFFF;
  border: 2px solid rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  max-width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 25px;
  width: 100%;
  margin: 100px auto;
  position: relative;
}

.inviteGpopupinner h1 {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #2D3134;
}

.inviteGpopupinnersub {
  display: flex;
  margin-top: 45px;
}

.labelFriend {
  color: #2D3134;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;

}

.inviteGpopupinnerlabel {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #2D3134;
}

.oneSelect {
  color: #5B5F62;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;

}

.selectGroupouter {
  text-align: left;
  margin-top: 15px;
}

.framworkIner {
  height: 52px;
  width: 100%;
  border-radius: 64px;
  padding: 8px 8px 8px 24px;

}
.astrickStar {
  color: #F04923;
}
.buttonOuter {
  margin-top: 25px;
  display: inline-block;
  width: 100%;

}
.cancleBt span {
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #2D3134; */
  margin-right: 10px;
}

.inviteBtn span {
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  /*  color: #FFFFFF; */ 
}

.inviteGpopup .closed-popup,
.your-group .closed-popup {
  width: 37px;
  line-height: 16px;
  text-align: center;
  background: #D9D9D9;
  height: 37px;
  padding: 0;
  color: #5B5F62;
  border: 1px solid #D9D9D9;
  font-weight: normal;
  position: absolute;
  right: 10px;
  top: 15px;
  margin: 0;
  border-radius: 50%;
}

.inviteGpopup .cancleBt,
.inviteGpopup .inviteBtn,
.your-group .cancleBt,
.your-group .inviteBtn {
  border-radius: 8px;
  line-height: 20px;
  padding: 12px 24px;
  margin: 15px 5px;
}
div.inviteGpopup .inviteBtn, div.your-group .inviteBtn,
.inviteGpopup .cancleBt,
.your-group .cancleBt {
  border: 1px solid #D9D9D9;
  background: #fff;
  color: #2D3134;
}
div.inviteGpopup button.cancleBt:hover,
div.your-group button.cancleBt:hover,
.inviteGpopup .inviteBtn:hover,
.your-group .inviteBtn:hover {
  background: #F19357;
  color: #fff;
}

.inviteGpopup .inviteBtn {
  border: none;
}

.inviteGpopup .inviteBtn,
.inviteGpopup .cancleBt {
  width: 148px;
  height: 44px;

}
.Joingroup2_mid_div .css-b62m3t-container .css-13cymwt-control,
.inviteGpopupinner .css-b62m3t-container .css-13cymwt-control {
  height: 52px;
  margin-top: 0;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: 8px;
  padding: 0px 12px;
  min-height: 38px;
  cursor: text;
  border: 1px solid #ccc;
  border-radius: 30px;
}

.input-container input {
  border: none;
  outline: none;
  background: transparent;
  flex: 1;
  height: 100%;
  padding: 5px 0;
}

.email-item {
  background-color: #F19357;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 6px;
  margin: 6px 1px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.2s ease;
  border-radius: 30px;
  color: white;
}

.email-item button {
  background-color: white;
  border: none;
  color: #F19357;
  cursor: pointer;
  margin-left: 3px;
  transition: color 0.2s ease;
  border-radius: 50%;
}



