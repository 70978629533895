.gropping-div h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    text-align: left;
    padding: 16px 16px 16px 0px;
}

.gropping-div input {
    font-family: 'Inter';
    background: rgba(170, 192, 206, 0.2);
    width: 100%;
    height: 29px;
    border: 0.25px solid #BFBCB8;
    border-radius: 4px;
    padding: 10px;
}

.gropping-div button {
    background: #FFFFFF;
    width: 100%;
    height: 47px;
    border: 0.5px solid #BFBCB8;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #2D3134;
    margin-top: 24px;

}

.main-inner-gropping {
    display: inline-block;
    width: 100%;
    margin-top: 5px;
    border-bottom: 1px solid #D9D9D9;
    padding-top: 5px;
}

.main-inner-gropping h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    cursor: pointer;
}

.main-inner-gropping p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #F19357;
}

.main-inner-gropping p a,
.main-inner-gropping p a {
    color: #F19357;
    text-decoration: none;
}


.main-gropping-div .main-image-flex {
    display: flex;
    gap: 5px;
}

.main-gropping-div .main-image-flex img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.main-gropping-div .main-image-flex p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2D3134;
    margin-top: 11px;
    cursor: pointer;
    width: 72%;
    padding-right: 10px;
    white-space: normal;
    word-wrap: break-word;
}

.groupseeall a:hover {
    text-decoration: underline;
}

.main-gropping-div .main-image-flex p:hover {
    color: #f19357;
}

.main-gropping-div {
    display: inline-block;
    margin-top: 0;
    width: 100%;
    /* min-height: 170px; */
}

.main-gropping-div span i {
    margin-top: 5px;
    /*  margin-right: 16px; */
}

.new-group-innerBox {
    float: left;
    width: 96%;
    position: relative;
    margin-bottom: 10px;

}

.three-ellipis {
    position: absolute;
    right: -5px;
    top: 0px;
    text-align: center;
    width: 23px;
    cursor: pointer;
    height: 23px;
    border-radius: 50%;
}

.main-gropping-div .three-ellipis {
    top: 5px;
}

.three-ellipis:hover,
.three-ellipis-group:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

/*============== Accordion===============*/
.search-div-2 hr {
    margin: 5px 0;
}

.sidebar .accordion .my-friend,
.sidebar .accordion .my-profile-h {
    line-height: 33px;
}

.sidebar [id*="open-accordion"],
.sidebar [id*="close-accordion"] {
    /* 
  border-bottom: 1px solid #fff; */
    line-height: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    position: relative;
    width: 99%;
}

.sidebar [id*="close-accordion"] {
    display: none;
}

.sidebar .accordion a {
    color: #000;
    text-decoration: none;
    text-shadow: none;
}

.sidebar [id*="open-accordion"]:after,
.sidebar [id*="close-accordion"]:after {
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    position: absolute;
    right: 18px;
    top: 15px;
    z-index: 222;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);

}

.sidebar .target-fix {
    display: block;
    top: 0;
    left: 0;
    position: fixed;
}

.sidebar .accordion-content {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
}

.sidebar .accordion span:target~.accordion-content {
    display: block;
    height: auto;
}

.sidebar .accordion span:target~[id*="close-accordion"] {
    display: block;
}

.sidebar .accordion span:target~[id*="open-accordion"] {
    display: none;
}

.sidebar .accordion span:target~[id*="close-accordion"]:after {
    border-top: 5px solid #333;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.sidebar .accordion .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0;
}

.myprofileInneraccordion button {
    width: 100%;
    background: transparent;
    border: none;
    margin-bottom: 10px;
}

.accordion-content .dotspop {
    left: auto;
    right: 20px;
}

.myprofileInneraccordion .accordion-title.accordion-active {
    width: 100%;
    border-radius: 6px;
    background: #265472;
    padding: 5px 3px;
}

.myprofileInneraccordion .accordion-title.accordion-active a,
.myprofileInneraccordion .accordion-title.accordion-active {
    color: #fff;

}

.sidebarlink,
.myprofilelist,
.myfriendaccordion {
    border-bottom: 1px solid #D9D9D9;
}

.myprofilelist,
.myfriendaccordion {
    margin-bottom: 5px;
}

div.profile-division {
    gap: 5px;
    margin-bottom: 4px;
}

.mysidebrscroll {
    max-height: 300px;
    overflow-y: auto;
}

.myfriendaccordion .mysidebrscroll {
    margin-bottom: 15px;
    /*position: relative;
     min-height: 100px; */
}

.accordion-body.content-div1 ul li a {
    margin-left:35px;
}

.icon-handle .profile-division1 p:hover,
.accordion-body.content-div1 ul li a:hover {
    color: #f19357;
}

h5.jackey-name {
    margin-top: 10px;
	font-size:14px;
}

.sidebarlink .fa-info-circles {
    float: right;
    position: relative;
    background: #fff;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    color: #265472;
    font-family: 'Inter';

    text-align: center;
    font-weight: bold;
    line-height: 25px;
}

.sidebarlink .tooltipinfo {
    position: absolute;
    left: 29px;
    top: -5px;
    width: 350px;
    display: none;
    z-index: 2;
    background: #fff;
    padding: 10px;
    border-radius: 12px;
    color: #000;
    /* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
    font-size: 15px;
    box-shadow: 0px 0px 5px #bbb8b8;

}

.sidebarlink .tooltipinfo ul {
    padding-left: 15px;
    text-align: left;
}

.sidebarlink .tooltipinfo ul li {
    list-style: none;
    line-height: 18px;
    width: 100%;
    font-weight: 500;
    background: transparent;
}

.sidebarlink .tooltipinfo:before {
    position: absolute;
    left: 0px;
    content: "";
    border-style: solid;
    border-color: transparent;
    border-top-color: #fff;
    border-width: 0.5em 0.5em 0 0.5em;
    transform: rotate(90deg) translate(30%, 100%);
    position: absolute;
    left: -3px;
    top: 10px;
}

.sidebarlink .fa-info-circles:hover .tooltipinfo {
    display: block;
}

.new-group-innerBox .dotspop {
    left: auto;
    right: 20px;
    text-align: left;
    top: 10px;
}

.new-group-innerBox .dotspop a {
    display: block;
}

.dummy {
    font-size: 16px;
    font-weight: 400;
}

.main-inner-groppingouter .mysidebrscroll {
    min-height: 200px;
}

.groupemultipleheadings button.accordion-title {
    border: none;
    background: transparent;
    border-top: 1px solid #D9D9D9;
    display: block;
    width: 100%;
    padding: 15px 0px;
    text-align: left;
    position: relative;
}

.groupemultipleheadings button.suggestedGroupTitleadd:after,
.groupemultipleheadings button.ihavejoinadd:after,
.groupemultipleheadings button.mygroupadd:after {
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    position: absolute;
    right: 5px;
    top: 18px;
    z-index: 222;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}

.groupemultipleheadings button.suggestedGroupTitleadd.accordion-active:before,
.groupemultipleheadings button.ihavejoinadd.accordion-active:before,
.groupemultipleheadings button.mygroupadd.accordion-active:before {
    content: "";
    background: #f3f6ff;
    position: absolute;
    right: 4px;
    top: 14px;
    width: 16px;
    z-index: 2222;
    border: none;
    height: 12px;
}

.groupemultipleheadings button.accordion-active h2 {
    position: relative;
}

.groupemultipleheadings button.accordion-active h2:after {

    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    position: absolute;
    right: 5px;
    top: 10px;
    z-index: 2222;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);

}
.sidebar.sidebarfix {
    position: fixed;
    top: 50px;
    width:189px;
    z-index: 1;
    height:100vh;
}
.sidebar.sidebarfix:before,
.sidebar.sidebarfix {
	 background: linear-gradient(0deg, rgba(170, 192, 206, 0.1), rgba(170, 192, 206, 0.1)), #ffffff;	
}
.sideListAccordianTitle {
    height:50vh;
    overflow-y: auto;
	
}
.search-div-2 .profile-div-user1 .card-body{
margin-top:20px;
}
.search-div-2 .profile-div-user1 .card-body .dotspop{
    width: 140px;
}
.accordion-content div.dotspop {
    right:15px;
    top: -30px;
}
.profile-div-user1 span.three-dots {
    right: -5px;
}
div.profile-division1 {
    display:inline-flex;

    }
    div.mysidebrscroll {
        overflow-x: hidden;
    }
	
	
	

/*=========== End Accordion ===============*/

@media only screen and (max-width:1200px) {

    .sidebar .side-pad {
        padding-left: 11px;
        cursor: pointer;

    }

    button.newgroupsidebar {
        font-size: 13px;
        padding: 10px 5px;
    }

    .profile-div-user1 .friend-invite button {
        font-size: 14px;
    }

    .sidebarlink li a {
        font-size: 13px;
    }

    .sidebar .main-inner-gropping,
    .sidebar .accordion {
        background: white;
    }
     .sidebar.sidebarfix {
        position: static;
        width:100%;
		height:auto;
    }
}

@media only screen and (max-width:992px) {
    .accordion-body.content-div1 ul li a {
        margin-left: 0px;
    }

    .profile-div-user1 .icon-handle {
        width: 100%;
    }

    .sidebarlink {
        display: inline-block;
        width: 100%;
    }

    .sidebarlink li {
        width: 32%;
        float: left;
        background: #fff;
        margin-right: 10px;
        border-radius: 8px;
        box-shadow: 0px 3px 2px rgb(31 41 55 / 8%);
    }

    .sidebarlink li a {
        font-size: 14px;
        font-weight: 700;
        border: 2px solid #fff;
    }

    .sidebar .accordion a#open-accordion1,
    .sidebar .accordion a#close-accordion1,
    .sidebar .accordion #open-accordion2,
    .sidebar .accordion a#close-accordion2,
    .sidebar .accordion .accordian-link {
        background: #fff;
        margin: 0;
        width: 100%;
        padding-left: 10px;
    }

    .sidebarlink li:nth-of-type(3) {
        margin-right: 0px
    }

    .sidebarlink li:nth-of-type(3) .tooltipinfo {
        position: absolute;
        left: -319px;
        top: 28px;

    }

    .sidebarlink li:nth-of-type(2) .tooltipinfo {
        width: 230px;
    }

    .sidebarlink li:nth-of-type(3) .tooltipinfo:before {
        transform: rotate(184deg) translate(30%, 100%);
        position: absolute;
        left: auto;
        top: 0;
        right: 12px;
    }
	.sidebarlink .tooltipinfo ul {
		padding-left:0;
	}
	.sidebarlink .tooltipinfo ul li {
		box-shadow:none;
	}
	div.sideListAccordianTitle {
       max-height:100%!important;
	       height: 100%;
	}
	.mysidebrscrolls {
       max-height: 200px;
      overflow-y: auto;
    }
}

@media only screen and (max-width:767px) {
    div.search-div-2 {
        margin-top: 20px;
    }

    .profile-div-user1 .icon-handle {
        width: 100%;
    }

    .sidebar .accordion a#open-accordion1,
    .sidebar .accordion a#close-accordion1,
    .sidebar .accordion #open-accordion2,
    .sidebar .accordion a#close-accordion2 {
        background: #fff;
        margin: 0;
        width: 100%;
        padding-left: 10px;
    }

    .sidebarlink li:nth-of-type(3) {
        margin-right: 0px
    }

    .sidebarlink li:nth-of-type(2) .tooltipinfo {
        width: 230px;
    }

    .sidebarlink li:nth-of-type(3) .tooltipinfo {
        position: absolute;
        left: -319px;
        top: 28px;

    }

    .sidebarlink li:nth-of-type(3) .tooltipinfo:before {
        transform: rotate(184deg) translate(30%, 100%);
        position: absolute;
        left: auto;
        top: 0;
        right: 12px;
    }
	

}

@media only screen and (max-width:480px) {
    .sidebarlink li {
        width: 31%;
    }

    .sidebar .side-pad {
        padding-left: 11px;
        cursor: pointer;

    }

    .sidebarlink .tooltipinfo:before {
        position: absolute;
        content: "";
        border-top-color: #fff;
        border-width: 0.5em 0.5em 0 0.5em;
        transform: rotate(180deg) translate(30%, 100%);
        top: 0px;
        left: 88px;

    }

    .sidebarlink .tooltipinfo {
        /* position: absolute; */
        left: -83px;
        top: 28px;
        width: 200px;
        font-size: 12px;
    }

    .sidebarlink li:nth-of-type(3) .tooltipinfo,
    .sidebarlink .tooltips {
        position: absolute;
        left: -172px;
        top: 28px;

    }

    .sidebarlink .tooltips:before {
        position: absolute;
        content: "";
        border-top-color: rgba(0, 0, 0, 0.9);
        border-width: 0.5em 0.5em 0 0.5em;
        transform: rotate(180deg) translate(30%, 100%);
        top: 0px;
        left: 180px;

    }

    .sidebar .main-inner-gropping,
    .sidebar .accordion {
        background: white;
    }

}

.accordian-link {
    width: 20% !important;
    margin-top: -25px !important;
    float: right !important;
    margin-right: 3px !important;
}

.three-ellipis-group {
    position: absolute;
    right: -5px;
    top: 0px;
    text-align: center;
    width: 23px;
    cursor: pointer;
    height: 23px;
    border-radius: 50%;
    margin-left: 15px;
}

.accordian-link2 {
    width: 20% !important;
    margin-top: -42px !important;
    float: right !important;
    margin-right: 3px !important;
}

.chat-btn {
    margin-top: 2px;
    border: none;
    background-color: #265472;
    color: #FFFFFF;
    border-radius: 5px;
    font-size: 12px;
    padding: 3px 9px;
}
.sidebar .myprofilelist .card-body h5.jackey-name{
    text-align: left;
}