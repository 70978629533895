.serve-dash-image img {
    /* background-image: url(/public/Vectorman.png); */
    position: absolute;
    width: 350px;
    height: 350px;
	 left: auto;
    right: -15px;
    top: -35px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.serve-dash .serve-dash-image {
    position: relative;
}

.serve-dash h1 {
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
    color: #2D3134;
    text-align: left;
    margin-top: 86px;
}

.serve-dashboard {
    text-align: left;
    padding-top: 310px;
    position: relative;
}

.serve-inner-image,
.serve-inner-image1,
.serve-inner-image2,
.serve-inner-image3,
.serve-inner-image4,
.serve-inner-image5 {
    width: 73px;
    height: 73px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.serveimage {
    width: 42px;
    height: 40px;
    margin: 13px;
}

.serve-inner-image {
    background: #82A3A1;
}

.serve-inner-image,
.serve-inner-image1,
.serve-inner-image2,
.serve-inner-image3,
.serve-inner-image5 {
    padding: 10px;
}

.serve-inner-image1 {
    background: #57886C;
}

.serve-inner-image2 {
    background: #265472;

}

.serve-inner-image3 {
    background: #F0B285
}

.serve-inner-image4 {
    background: #F19357;
    padding: 17px 8px 13px 8px;
}

.serve-inner-image5 {
    background: #AAC0CE
}
.serverboxtem img{
  border-radius:0;
}
.serve-inner-dashboard {
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.serverboxtem {
    width: 100%;
    background: white;
    min-height: 290px;
    padding: 26px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 19px;
}
.serverboxtem a:hover {
	text-decoration:none;
}
.serverboxtem h4 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 15px;
}

.serverboxtem p {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}

.serve-inner-dashboard ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}

.womanimage {
    position: relative;
    position: relative;
    z-index: -1;
    bottom: 40px
}
.servedashboardbottomImg {
	width: 350px;
    height: 350px;
	border-radius: 50%;
	overflow:hidden;
}
.womanimage img {
   height:100%;
}

.link-style{
    text-decoration: none ;
}

@media screen and (max-width: 1200px) {
    
    .serve-dash h1 {
        font-size: 30px;
    }
    .serve-dashboard {
        padding-top: 360px;
    }

    .serve-dash-image img {
        position: absolute;
        right: -15px;
        top: 35px;
    }
	div.serverboxtem {
    min-height: 340px;
  }
  
  
}
@media screen and (max-width: 991px) {

    .serve-inner-dashboard {

        margin-bottom:20px;
    }
	div.serverboxtem h4 {
       font-size: 15px;
	}
	div.serverboxtem p {
       font-size: 13px;
	}

}
@media screen and (max-width: 767px) {

    .serve-dash h1 {
        font-size: 18px;
        position: relative;
        width: 100%;
    }
    
   
	.serverboxtem {
      padding: 10px;
	}
	.serverboxtem h4 {
     font-size: 17px;
		line-height: 23px;
		margin-bottom: 10px;
		min-height: 47px;
	}
}

@media only screen and (max-width:480px) {
    
    .chat-popup .bg-white{position: relative;}

    .serve-dash h1 {
        font-size: 29px;
        position: relative;
        width: 100%;
    }

    .serve-dash-image img {
        position: absolute;
        right:0px;
        top: 35px;
    }

    .serve-dashboard {
        padding-top: 360px;
    }

}

