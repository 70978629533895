.inviteGpopup{
    position: fixed;
    background-color:rgba(255, 255, 255, 0.9);
    left: 0;
    z-index: 300;
    top: 0;
    width: 100%;
    height: 100%;
    
  }
  .inviteGpopupinner {
      background: #FFFFFF;
      border: 2px solid rgba(255, 255, 255, 0.9);
      border-radius: 16px;
      max-width:950px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 25px;
      width: 100%;
     margin: 100px auto;
     position:relative;
  }
  
  .inviteGpopupinner h1{
    font-family: 'Inter';
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #2D3134;
  }

  .inviteGpopupinnersub{
    display: flex;
    margin-top: 45px;
  }


  
   .cls_btn {
      width: 37px;
      /* line-height: 16px; */
      text-align: center;
      background: #D9D9D9;
      height: 37px;
      border-radius: 50%;
      /* padding: 0; */
      color: #5B5F62;
      border: 1px solid #D9D9D9;
      font-weight: normal;
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 15px;
     
  }
.reviewWrite {
	height: 44px;
    border-radius: 8px;
    padding: 10px;
    display: block;
    line-height: 23px;
    background: #f19357;
    border: 1px solid #d9d9d9;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    width: 130px;
    margin: 0 auto;
}

@media only screen and (max-width:480px) {
.inviteGpopupinner h1 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 24px;
}

}