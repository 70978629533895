.your-main-groups .slider-page img {
    width: 280px;
    height: 260px;
    margin-top: 40px;
    border-radius: 8px;
}

.searchfrnd {
    margin-top: 40px;
    margin-left: -375px;
}

.your-main-groups .the-secret {
    display: inline-block;
    margin-top: 27px;
    overflow: hidden;
    width: 100%;
}
.yourgroup .your-main-groups .creataddpare{
    overflow-y: auto;
    max-height: 300px;
}

.your-main-groups .the-secret h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2D3134;
    padding-right: 10px;
}

.your-main-groups .the-secret p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #82A3A1;
    margin: 5px 0;
    margin-left: 0px;
}

/* .your-main-groups .chat-group {
    justify-content: flex-start;
    display: flex;
} */

.your-main-groups h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #F19357;
    margin-top: 5px;
    float: left;
}

.your-main-groups .chat-group button {
    width: 152px;
    height: 44px;
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 100px;
    font-family: 'Inter';
    padding: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #FFFFFF;
    position: relative;
    top: 38px;
}

.postTextlist {
    display: inline-block;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    width: 100%;
    border-bottom: 1px solid #130A2E21;
}

.postTextlist li {
    padding: 15px 12px 12px 12px;
    margin-top: 20px;
    border-radius: 10px 10px 0 0;
    font-size: 0.875rem;

}

.postTextlist li:hover {
    background: #265472;
    color: #fff;
}


.postTextlist .button {
    margin-top: 20px;
    padding: 15px 15px;
    height: 47px;
    background: #265472;
    border: none;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px 10px 0 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 0.867rem;
    line-height: 20px;
    color: #fff;
}

.your-main-groups .member-outer-main .member-inner-div {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 19px;
    height: 279px;
    margin-top: 30px;

}

.your-main-groups .member-outer-main .member-inner-div .imgcirclee {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 10px;
    display: block;
    margin-top: 7px;
}

.your-main-groups .member-outer-main .member-inner-div .imgcirclee img {
    width: 94px;
    height: 94px;
    object-fit: cover;
    aspect-ratio: 1/1;
    margin: 0px auto;
    display: block;
    text-align: center;
    float: none;
}

.your-main-groups .member-outer-main .member-inner-div .dots-three {
    position: relative;
}

.your-main-groups .member-outer-main .member-inner-div .dots-three i {
    position: absolute;
    right: 15px;
    font-size: 19px;
    top: 12px;
    border-radius: 50%;
    cursor: pointer;
}

.your-main-groups .member-outer-main .member-inner-div h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    margin-top: 6px;
    cursor: pointer;
}

.your-main-groups .member-outer-main .member-inner-div p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #949698;
    margin-top: 6px;
}

.your-main-groups .member-outer-main .member-inner-div .member-button {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin: 20px auto;
    max-width: 200px;
}

.your-main-groups .member-outer-main .member-inner-div .member-button .view-profile {
    background: #265472;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
    height: 36px;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    border: 0;
}

.invite_button{
    background:#265472;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;;
    float: right;
    text-decoration: none;
    padding: 10px 15px;
    border: 0;
}
.your-main-groups .member-outer-main .member-inner-div .member-button .remove-from-group {
    background: #F19357;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    height: 36px;
    text-decoration: none;
    margin-top: 10px;
    border: 0;
}

.your-main-groups .member-outer-main {
    margin-bottom: 100px;
}

.member-outer-main .dotspop a {
    display: block;
    text-decoration: none;
}

.member-outer-main .dotspop {
    left: auto;
    right: 20px;
}

.purchasebarsearch.groupchangeinfo .searchbarlist {
    width: auto;
}

.groupchangeinfo .newgroupmemberselectbox select {
    height: 48px;
    margin-right: 15px;
    width: 225px;
}
.your-main-groups .your-grp .creataddpare{
    overflow-y: auto;
    max-height: 300px;
}
@media screen and (max-width: 1200px) {
    .your-main-groups .member-outer-main .member-inner-div .member-button {
        padding: 12px;
    }
}
@media screen and (max-width: 991px) {
.your-main-groups .member-outer-main .member-inner-div .member-button {
    padding: 12px;
}
}


@media screen and (max-width: 480px) {
    .your-main-groups .the-secret h1 {
        font-size: 18px;

    }

    .searchfrnd {
        width: 100%;
        margin-left: -325px;
    }


    .your-main-groups .the-secret p {
        margin-top: 8px;
        margin-left: 6px;
        font-size: 10px;
    }

    .groupchangeinfo .newgroupmemberselectbox,
    .groupchangeinfo .newgroupmemberselectbox select,
    .purchasebarsearch.groupchangeinfo .searchbarlist {
        width: 100%;
    }

    .purchasebarsearch.groupchangeinfo .searchbarlist {
        margin-top: 15px;
    }

    .your-main-groups h2 {
        font-size: 16px;
    }

    .your-main-groups .member-outer-main .member-inner-div .member-button {
        max-width: 265px;

    }
}