.headerdelete,
.header {
    padding: 25px 0px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 9%);
    background: #fff;
    position: relative;
    z-index: 4;
}
.headerdelete ul li,
.header ul li {
    float: left;
    list-style: none;
}
.image-url{
    padding-left: 10px;
}
.delete-account div.header{
    display: none;
}
.headerdelete ul li a,
.header ul li a {
    text-decoration: none;
    padding: 8px 15px;
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #2D3134;
    font-family: 'Rubik', sans-serif;
}
.headerdelete ul li a:hover,
.header ul li a:hover {
    text-decoration: underline;
}

.Imglogo {
    display: block;
    text-align: left;
}

.Imglogo img {
    width: 301px;
    border-radius: 0;
}

.header-account button {
    border: none;
    background: transparent;
    line-height: 28px;
    padding: 8px 15px;
    margin-left: 5px;
    border-radius: 4px;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
}

.header-account button a {
    font-weight: 500;
    text-decoration: none;
    color: #2D3134;
}

.header-account button:hover,
.header-account button.button_active {
    background: #f19357;
    color: #fff;

}

.header-account button:hover a,
.header-account button.button_active a {
    color: #fff;

}

.header-account {
    float: right;
}

.headerscroll {
    transition: top 0.2s ease-in-out;
}

.headerfix {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    transition: top 0.2s ease-in-out;
}

.container-fluid .helpcenter-header h4 {
    padding: 8px 6px;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    line-height: 28px;
    text-decoration: none;
    color: black;
    font-weight: 500;
}

.container-fluid .helpcenter-header {
    float: right;
}

.helpcenter-header h4 a {
    color: #000;
    text-decoration: none;
}

.signin .header-account button.btnSignIn,
.signInActive .header-account button.btnSignIn {
    background: #f19357;
    color: #fff;
}

.signin .header-account a:nth-child(2) button {
    background: transparent;
    color: #000;
}

.sign-up .header-account a:nth-child(2) button {
    background: #f19357;
    color: #fff;
}
.delete-account .header-account a:nth-child(3) button {
    background: transparent;
    color: #000;
}

.delete-account .header-account a:nth-child(3) button {
    background: #f19357;
    color: #fff;
}


.header-account button {
    color: #000;
}

@media only screen and (max-width:767px) {
    .header {
        padding: 15px 0px;
    }
}