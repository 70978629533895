.Joingroup2_div {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
    left: 0;
    z-index: 300;
    top: 0;
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
}

input[type="radio"].radio_button {
    border: 1px solid #c1bdbd;
}

input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    outline: none;
}

.radio_button input[type="radio"] {
    width: 20px;
    height: 20px;
}
.stipe-account .striptableList:first-child{
	padding-right:5px;
}
.stipe-account .striptableList:first-child li {
	background:#F19357;
	color:#fff;
	font-weight: bold;
}
.striptableListloop {
	margin-top:-4px;
}
.Joingroup2_mid_div {
    background: #FFFFFF;
    border: 2px solid rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    max-width:950px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 20px;
    width: 100%;
    margin: 115px auto;
    position: relative;
}

.join_grp {
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #2D3134;
}

.joinas_div {

    text-align: left;
    margin-top: 20px;
    Left: 51749px;
}
.join_as {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #2D3134;
}
.select_one {
    color: #5B5F62;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
}
.radio_btn_div {
    text-align: left;
    display: flex;
}

.radio_button {
    height: 20px;
    width: 20px;
    border-radius: 0px;
}
.yourgroup .Joingroup2_div .Joingroup2_mid_div h2.heading1{
    float: left;
}

.label2 {
    margin-left: 56px;
}
.individual,
.subgroup {
    margin-left: 25px;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #5B5F62;
}
.group_name_div {
    margin-top: 11px;
    text-align: left;
    display: flex;
}
.group_name {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #2D3134;
}
.star-color {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #F04923;
}
.select_one1 {
    color: #5B5F62;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 4px;
}
.Text_box1 {
    height: 52px;
    width: 100%;
    top: -4699px;
    border-radius: 1000px;
    padding: 8px 8px 8px 24px;
    margin-top: 4px;
}
.bton_div {
    justify-content: center;
    text-align: center
}
.bton2 {
    height: 44px;
    width: 139px;
    border-radius: 8px;
    padding: 12px 24px 12px 24px;
    background-color: #F19357;
    margin-left: 25px;
    border: none;
    border: 1px solid #F19357;
}

.bton2_text {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}
.heading1 {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #2D3134;
}
.Text_box {
    height: 100px;
    width: 100%;
    border-radius: 20px;
    padding: 10px;
    border: 0.5px solid #BFBCB8;

}
.your-group .location-search .location-input input[type="text"] {
    border-radius: 0;
}
.your-group .location-search {
    position: relative;
}
.your-group .miles-from-s {
    position: absolute;
    right: -12px;
}
.your-group .searchpart .searchpart-div {
    width: 100%;
}
.Joingroup2_mid_div .radio_btn_div {
    width: 100%;
    display: inline-block;
    padding-top: 20px;
}
.Joingroup2_mid_div .radio_btn_div .strip-popup {
    margin-bottom: 10px;
	width: 100%;
}
.strip-header h2,
.joinas_div h2 span,
.strip-paragraph p {
    font-size: 16px;
}

.strip-header h2 {
    margin-bottom: 6px;
}
.strip-header,
.strip-paragraph {
    text-align: center;
}
.striptableList input[type="radio"].tableradio {
    border: 1px solid #c1bdbd;
    float: left;
    margin-right: 5px;
}
.striptableList .share-with-group {
    float: left;
    width: 82%;
    word-wrap: break-word;
}
.striptableList {

    display: inline-block;
    width: 100%;
}
.striptableList li {
    width: 33.3%;
    float: left;
    list-style: none;
    padding: 10px 5px;
    border: 1px solid #dee2e6;
    text-align: center;
}
.striptableListloop {
    height: 160px;
    overflow-y: auto;
}
.striptableListloop li {
    min-height: 60px;

}
.squareImage {
 padding-left:5px;
}
.groupJoin .MuiBox-root .radio_btn_div label input{
    position: relative;
    top: 4px;
}
.groupJoin .MuiBox-root .radio_btn_div label label{
    margin-left: 10px;
}
@media screen and (max-width: 992px) {
.striptableListloop li {
    min-height: 85px;
}
}
@media screen and (max-width: 600px) {
    .Text_box {
        width: 100%;
        margin-top: 0;
    }

    .Text_box1 {
        width: 100%;
        margin-top: 0;
    }
}

@media screen and (max-width: 480px) {
    .bton_div {
        margin-top: 10px;
    }

    .group_name_div {

        flex-flow: column;
    }

    .radio_btn_div {
        flex-flow: column;
    }

    .label2 {
        margin-left: 0px;
    }

    .cross_btn {

        margin-top: -42px;
        float: right;
        font-size: 15px;
        color: #5B5F62;
        margin-right: -40px;
    }

    .Joingroup2_mid_div div.radio_btn_div :nth-of-type(2),
    .Joingroup2_mid_div div.radio_btn_div :nth-of-type(3) {
        margin-left: 0px;
    }
	.striptableList li {
		min-height:60px;
	}
	ul.striptableListloop li  {
		min-height:145px;
	}
	div.Joingroup2_mid_div {
		padding:10px;
	}
	.individual, .subgroup {
		font-size:15px;
	}
	.join_grp {
       font-size: 22px;
	}
	.striptableList .share-with-group {
     font-size: 14px;
    color: #000;
    }
}