/* @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"); */

.stars-gray {
  position: relative;
  display: inline-block;
}

.stars-yellow {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;

}

.stars-gray::before {
  content: "\f005 \f005 \f005 \f005 \f005";
  font-family: 'FontAwesome';
  font-weight:400;
  color: #ccc;
}

.stars-yellow::before {
  content: "\f005 \f005 \f005 \f005 \f005";
  font-family: 'FontAwesome';
  font-weight:400;
  color: #F19357;

}