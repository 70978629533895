.your-group .your-group-inner h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #2D3134;
    margin-top: 20px;
}

.inviteGpopup .inviteGpopupinner .selectGroupouter .share-group{
    margin-bottom: 20px;
    }

    .selectGroupouter .kayword-associated .email-text-option {
        margin-bottom: 0;
    }

    .selectGroupouter .kayword-associated .email-text-option label{
        margin-left: 15px;
    }
    .selectGroupouter .share-group label{
        margin-left: 20px;
    }



.your-group .your-group-inner h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #2D3134;
    margin-top: 9px;
}

.your-group .searchpart {
    margin-top: 40px;
    border-top: 1px solid #BFBCB8;
    border-bottom: 1px solid #BFBCB8;
    margin-bottom: 27px;
    width: 100%;
}

.searchpart .searchpart-div {
    display: inline-block;
    margin: 24px 0px;
}

.searchpart .searchpart-div h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
    color: #2D3134;
    margin-right: 12px;
    float: left;
}

.searchpart .searchpart-div select {
    width: 150px;
    height: 31px;
    background: #FFFFFF;
    border: 0.21px solid #BFBCB8;
    border-radius: 32.5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2D3134;
    padding: 0px 12px;
    margin-right: 12px;
    float: left;
}

.your-group .searchpart .searchpart-div .keyword-select {
    width: 300px;
}

.your-group .location-search {
    height: 42px;
    background: #FFFFFF;
    border: 0.21px solid #BFBCB8;
    border-radius: 32.5px;
    margin: auto;
    width: 100%;
    max-width: 480px;
    padding: 9px 5px 9px 10px;
    margin-bottom: 27px;
}

.your-group .location-search .location-input {
    float: left;
    width: 64%;
}

.your-group .location-search .location-input input[type="text"] {
    border: none;
    /* border-right: 1px solid gray; */
    outline: none;
    padding: 3px 0px 3px 7px;
    width: 75%;
}

.your-group .location-search .location-input input ::placeholder {
    font-family: 'Inter';
    font-size: 12px;
}

.your-group .miles-from-s {
    display: flex;
    gap: 18px;
    float: right;
    text-align: right;
}

.your-group .miles-from-s h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2D3134;
    margin-top: 3px;
}

.your-group div.miles-from-s button {
    border: none;
    width: 34px;
    height: 34px;
    background: #F19357;
    box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
    border-radius: 100px;
    position: relative;
    bottom: 6px;
    right: 0;
    margin: 0;
}

.your-group div.miles-from-s .iconsearch22 {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 6px;
   /*  right: 7px; */
}

.your-group .main-group-div {
    margin-top: 52px;
}

.your-group .mygroub-div {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 24px 90px rgba(192, 188, 161, 0.22);
    border-radius: 16px;
    width: 100%;
    max-width: 250px;

}

.your-group .mygroub-div .group-div img {
    width: 100%;
    height: 145px;

}

.your-group .group-text {
    padding: 10px;
    width: 100%;
    height: 110px;


}

.copy-icon {
    position: relative;
    cursor: pointer;
}

.copy-icon .tooltip {
    position: absolute;
    top: -60%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 1px 10px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    font-size: 12px;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.copy-icon:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

.your-group .group-text .secret {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.your-group .group-text .secret h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    margin-top: 10px;
}

.your-group .group-text .secret i {
    font-size: 20px;
    position: relative;
    top: 10px;
}

.your-group .group-text h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    margin-top: 2px;
    float: left;
}

.group-text .css-i4bv87-MuiSvgIcon-root {
    font-size: 18px;
}

.your-group .group-text-button {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.your-group .group-text-button button {
    width: 108px;
    height: 44px;
    border-radius: 50px;
    border: none;

    border-radius: 100px;
}

.your-group .group-text-button .edit,
.your-group .group-text-button .invite {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.your-group .group-text-button .edit {
    background: #265472;
    border: 1px solid #265472;
    padding: 10px 10px 10px 40px;
}

.your-group .group-text-button .invite {
    padding: 10px 10px 10px 35px;
    background: #F19357;
    border: 1px solid #F19357;
}

.your-group .group-text-button .view-group,
.your-group .group-text-button .join-group {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.your-group .group-text-button .join-group {
    background: #F19357;
    border: 1px solid #F19357;
    padding: 15px;
}

.your-group .group-text-button .view-group {
    background: #265472;
    border: 1px solid #265472;
    padding: 12px;
}

.your-group .group-text-button .view-group {
    margin-right: 0;
}

.your-group .suggest-group h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #2D3134;
    margin-top: 47px;
}

.your-group .suggest-group h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #2D3134;
    margin-top: 9px;
}

.your-group .bottom-div {
    margin-bottom: 100px;
}

.suggestedGroup .owl-item.active {
    border: none;
}

.suggestedGroup.owl-theme .owl-dots .owl-dot {
    width: auto;
    margin-bottom: 0;
}

.suggestedyourGroup .your-group {
    /* padding-left: 45px; */
}

.suggestedyourGroup .your-group .owl-item .mygroub-div {
    box-shadow: 0px 2px 6px rgba(192, 188, 161, 0.22);
}

.your-group .owl-carousel .owl-dots {
    display: none;
}

.suggestedGroup.owl-carousel .owl-nav button.owl-prev,
.suggestedGroup.owl-carousel .owl-nav button.owl-next {
    width: auto;
    position: absolute;
    font-size: 40px;
}

.suggestedGroup.owl-carousel .owl-nav button.owl-prev {
    left: -21px;
}

.suggestedGroup.owl-carousel .owl-nav button.owl-next {
    right: 45px;
}

.suggestedGroup.owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
    color: #000;
}

.suggestedGroup.owl-theme .owl-nav {
    position: absolute;
    width: 100%;
    top: 40%;
    margin: 0;
}

.your-group .miles {
    width: 70%;
}

.your-group .miles-from-s {
    gap: 4px;
}

.groupImgBox {
    width: 100px;
    float: left;
}

.groupImgBox img {
    width: 87%;
    border-radius: 8px;
    height: 83px;
}
.sugestedyourGroup .groupImgBox img {
    width: 88px;
    height: 88px;
}

.background-bgColor {
    background: #fff;
    display: inline-block;
    width: 100%;
    min-height: 160px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 1px 5px #cdcdcd;
	border: 1px solid #dfdfdf;
}

.grouparticle {
    float: left;
    padding-left: 15px;
    width: 78%;
}

.your-group .customenewdesign .group-text-button {
    padding: 0;
    float: right;
    display: inline-block;
}

.your-group .customenewdesign .group-text-button button {
    text-align: center;
    float: left;
    margin-bottom: 0px;
    padding: 4px;
    display: block;
    width: 82px;
    height: 35px;
    line-height: 8px;
	margin-left: 5px;
}

.your-group .customenewdesign .group-text-button .invite {
    margin-right: 0;
}

.groupIconUser i {
    font-size: 20px;

}

.groupImgBoxdescriptionOuter {
    display: inline-block;
    width: 100%;
}

.customenewdesign .secret {
    display: inline-block;
    width: 100%;
    position: relative;
}

.customenewdesign .secret .groupIconUser {
    float: right;
    position: absolute;
    right: 15px;
}

.customenewdesign .secret h2 {
    padding-right: 55px;
    width: 100%;
    white-space: normal;
    /* word-wrap: break-word; */
}

.customenewdesign .secret h2,
.customenewdesign .content-text-bsvg,
.customenewdesign .group-text-button,
.content-text-bsvg p,
.content-text-bsvg .copy-icon {
    float: left;
    cursor: pointer;
}

.content-text-bsvg .copy-icon {
    margin-left: 10px;
}

.content-text-bsvg .copy-icon svg {
    font-size: 16px;
}

.customenewdesign h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    margin-bottom: 5px;
}

.customenewdesign p {
    margin-bottom:5px;
    white-space: normal;
    /* word-wrap: break-word; */
    word-break: break-word;
}
.yourgroup .your-main-groups .user-search h2{
    float: left;
}
.articleTextpara {

    margin-bottom: 10px;
    height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.justifyouter {
    padding-top: 8px;
    border-top: 1px solid #e7e7e7;
    align-items: center;
    justify-content: space-between;
}

.customenewdesign h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    margin-top: 5px;
    margin-bottom: 10px;

}

.Joingroup2_mid_div button.cross_btn,
.your-group .Joingroup2_mid_div button.cross_btn {
    border: none;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    background-color: #D9D9D9;
    float: right;
    font-size: 15px;
    color: #5B5F62;
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: auto;
    margin: 0;
}

.bton_div .bton2 {
    margin-top: 15px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
}

/*============== Group search bar ============*/
.group-search-bar {
    background: #FFFFFF;
    border: 0.2px solid #BFBCB8;
    border-radius: 100px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.group-search-bar .search-btn {
    display: block;
    width: 50px;
    height: 50px;
    background: #F19357;
    border-radius: 100%;
    position: absolute;
    bottom: 8px;
    right: 6px;
    border: none;
}

.group-search-bar .search-btn i {
    color: white;
    font-size: 20px;
}

.secret .three-ellipis-group {
    right: -16px;
    margin-left: 0;
    line-height: 23px;
}

.secret .three-ellipis-group .dotspop {
    left: auto;
    right: 20px;
    top: 0px;
}

.secret .three-ellipis-group .dotspop a {
    display: block;
    text-align: left;
    text-decoration: none;
}
.group-search-bar#groupfeedsearchbox {
    margin-top: 0px;
}
.create-group-btn {
    background-color: #F19357;
    color: white;
    border-radius: 20px;
    font-weight: 600;
    border: none;
}

.create-group-btn:hover {
    background-color: #F19357;
    color: white;
}

.your-group .css-3w2yfm-ValueContainer {
    height: 40px;
    overflow-y: auto;
}

.your-group .flex-d-div {
    display: flex;
}

.nodataFoundinfo {
    position: relative;
}

.nodataFoundinfo img {
    border-radius: 12px;
    box-shadow: 0px 1px 6px #7d7d7d;
}

.nodataFoundinfo strong {
    display: block;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 20px 0px;
    border-radius: 12px;
    position: absolute;
    width: 96%;
    top: 50%;
    font-size: 25px;
    left: 20px;
    font-family: "effraregular";
    transform: translateY(-50%);
    color: rgb(33, 37, 41);
}
.yourgroupFloat {
	float:left;
}
.newgroupmemberselectbox .share-multiple-form-select {
	height:48px;
	margin-right: 10px;
}
.newgroupmemberselectbox .share-multiple-form-select .multiSelectContainer {
	height: 45px;
    padding-top: 10px;
}

.share-group .inviteGpopupinnerlabel{
    margin-left: 17px;
}
.yourgroup .share-group .inviteGpopupinnerlabel span{
    margin-left: 16px;
}
.Joingroup2_mid_div .pop-label{
    margin-bottom: 0;
    margin-left: 22px;
    padding-top: 1rem;
}
.Joingroup2_mid_div .error-span{
   margin-left: 20px;
}
.strip-popup input{
    position: relative;
    top: 3px;
    left: 15px;
}

.yourgroup .purchasebartop .form-select .multiselect-container .search-wrapper{
margin-top: 5px;
}
.yourgroup .purchasebartop .form-select .multiselect-container .search-wrapper span{
    font-size: 16px;
    }
    .your-main-groups .purchasebartop .purchasebarsearch .searchbarlist input,
    .yourgroup .your-main-groups .purchasebartop .searchbarlist input{
    margin-left: 15px;
    padding-right: 55px;
    font-size: 14px;
}

.yourgroup .your-main-groups .purchasebartop .searchbarlist input::placeholder{
   color: #000;
}
.yourgroup .your-main-groups .purchasebartop .purchasebarsearch .searchbarlist button{
    position: absolute;
    right: 0;
}
.yourgroup .your-main-groups .purchasebartop .newgroupmemberselectbox  .form-select{
    background-image: none;
    padding-right: 0;
    height: 48px;
    width: 100%;
    min-width: 225px;
}
.servepaymenthistory .group-sale .float-start,
.yourgroup .group-sale .float-start{
    display: flex;
}

.servepaymenthistory .group-sale .select-exp-div,
.servepaymenthistory .group-sale  .multiselect-container,
.yourgroup .group-sale .float-start .select-exp-div,
.yourgroup .group-sale .float-start .multiselect-container{
    display: block;
    background: #ffffff;
    border: 0.21px solid #bfbcb8;
    border-radius: 32.5px;
    padding: 5px 6px 3px 12px;
    width: 155px!important;
    height: 42px;
    float: left;
    margin: 0 0 10px 8px;
}
.servepaymenthistory .group-sale .float-start .searchWrapper .icon_down_dir,
.yourgroup .float-start .searchWrapper .icon_down_dir{
    position: absolute;
    right: 10px;
    top: 14px;
    transform: translateY(-50%);
    width: 14px;
}

.fulldescription .aboutresourcesdiv .aboutresourcesdiv-lt .reviewssection .reviewssectionrtimg .img-fluid{
    width: 50px;
  
    height: 50px;
}
.yourgroup .MuiBox-root .radio_btn_div input{
    position: relative;
    top: 5px;
}
.yourgroup .MuiBox-root .radio_btn_div .individual,
.yourgroup .MuiBox-root .radio_btn_div .subgroup {
margin-left: 8px;
}
.inviteGpopupinnersub .facustomeI {
    font-weight: 600;
    line-height: 15px;
    margin: 15px 0px;
    list-style-type: none;
}

.inviteGpopupinnersub .tootlippillerinfo ul li{
    list-style-type: none;
}
.your-main-groups .member-outer-main .member-inner-div .member-button .view-profile{
    margin-top: 10px;
}
.suggestedyourGroup .group-search-bar .input-search-div{
    width: 30%;
}
.suggestedyourGroup .group-search-bar .input-search-div:nth-of-type(3):before{
	border-right:0;
}
.groupJoin .inspiration-div .description-sharee p{
    line-height: 24px;
    font-size: 15px;
}

.yourgroup .your-main-groups .Joingroup2_div .Joingroup2_mid_div .heading1{
    font-size: 18px;
    font-weight: 600;
    color: #2D3134;
    margin-left: 10px;
}
.yourgroup .your-main-groups .Joingroup2_div .Joingroup2_mid_div .radio_btn_div{
    padding-top: 0;
}
.yourgroup .your-main-groups .Joingroup2_div .Joingroup2_mid_div .joinas_div{
    margin-top: 18px;
    margin-left: 10px;
}
.yourgroup .your-main-groups .Joingroup2_div .Joingroup2_mid_div .error-span {
    margin-left: 10px;
}
.yourgroup .your-main-groups .Joingroup2_div .radio_btn_div input[type="radio"].form-check-input{
    margin-top: 3px;
}
.yourgroup .your-main-groups .Joingroup2_div .Joingroup2_mid_div .group_name_div{
    margin-left: 10px;
}

.yourgroup .your-main-groups .member-outer-main .member-inner-div h6 {
min-height: 150px;
}
.yourgroup .your-main-groups .member-outer-main .member-inner-div {
min-height: 300px;
}

/* .servepaymenthistory .saveResourceSelectss div.search-wrapper span.chip {
    line-height: 25px;
} */

.suggestedyourGroup .your-group .Joingroup2_mid_div .radio_btn_div {
    padding-top: 0px;
}
.suggestedyourGroup .your-group .Joingroup2_mid_div .radio_btn_div input{
    margin-top: 3px;
}
.suggestedyourGroup .your-group .Joingroup2_mid_div .heading1,
.suggestedyourGroup .your-group .Joingroup2_mid_div .group_name_div,
.suggestedyourGroup .your-group .Joingroup2_mid_div .joinas_div{
    margin-left: 10px;
}
.suggestedyourGroup .your-group .Joingroup2_mid_div .heading1{
    margin-top: 15px;
}
.yourgroup .saveResourceSelectss div.search-wrapper span.chip {
    line-height: 25px;
}
.yourgroup .your-main-groups h2,
.yourgroup .your-main-groups h2.goback{
    float: none;
    color: black;
    font-weight: 700;
    text-align: center
}
div.articletexthe{
    height: 40px;
    line-height: 19px;
}


.customenewdesign div.secret {
 min-height: 35px;
}
.yourgroup .bookmoniterdivbox ul li{
text-align: center;
}


@media screen and (max-width:1200px) {
    .groupImgBoxdescriptionOuter {
        display: flex;
    }

    .suggestedyourGroup .your-group .location-search {
        margin-right: 0;
    }

    .suggestedyourGroup .your-group .flex-d-div {
        display: flex;
    }

    .suggestedyourGroup .your-group .btn2-mygroup {
        margin-left: 10px;
    }

    .your-group .searchpart-div .saveResourceListgradeRange {
        margin-right: 10px;
    }

    .your-group .customenewdesign .group-text-button button {
        margin-top: 10px;
        margin-left: 5px;
		 font-size: 12px;
    }

    div.suggestedyourGroup div.your-group .content-text-bsvg {
        width: 65%;
        display: inline-flex;
    }

    .your-group .group-text-button .invite {
        font-size: 15px;
    }

    .your-group .content-text-bsvg {
        font-size: 12px;
    }

    .your-group .customenewdesign .group-text-button {
        padding: 0;
        display: inline-flex;
    }

    .your-group .customenewdesign .group-text-button button {
        margin-bottom: 8px;
        width: 64px;


    }
	

}

@media screen and (max-width:991px) {

    .groupImgBoxdescriptionOuter {
        display: flex;
    }

    .your-group .customenewdesign .group-text-button button {
        margin-top: 10px;
        margin-left: 5px;
    }

    /* .suggestedyourGroup .your-group .content-text-bsvg {
        width: 65%;
        display: inline-flex;
    } */

    .suggestedyourGroup .your-group {
        padding-left: 0;
    }
	div.customenewdesign .secret h2 {
       min-height: 50px;
     }
     .your-main-group .thre-point .three-ellipis .dotspop {
        left: auto;
        right: 19px;
    }
}


@media only screen and (max-width:767px) {
    .suggestedyourGroup .group-search-bar {
        border-radius: 8px;
        display: inline-block;
        width: 100%;
        flex-wrap: wrap;
    }

    .suggestedyourGroup .group-search-bar .input-search-div {
        width: 100%;
        border-bottom: 1px solid #cdcdcd;
        float: left;
		border-radius: 8px 8px 0 0;
    }
	.group-search-bar .input-search-div:hover {
       padding-bottom:10px;
    }
	.suggestedyourGroup div.your-group .location-search {
       margin-right: auto;
    }
	.suggestedyourGroup div.your-group .flex-d-div,
	.suggestedyourGroup div.your-group .btn2-mygroup {
       display: inline-block;
	   width: 100%
    }
	.suggestedyourGroup div.your-group .btn2-mygroup .yourgroupFloat {
		float:none;
	}
	.suggestedyourGroup .your-group .btn2-mygroup {
       text-align: center;
    }

.yourgroup .group-sale .float-start div.select-exp-div,
.yourgroup .group-sale .float-start div.multiselect-container{
    margin: 0 0 10px 0px;
    width: 100%!important;
}
.yourgroup .group-sale div.float-start {
    display: block;
    margin-bottom: 10px;
}
.yourgroup .your-main-groups .purchasebartop div.searchbarlist input {
    margin-left: 0px;
}

.yourgroup .your-main-groups .purchasebartop .purchasebarsearch div.searchbarlist button {
    position: absolute;
    right: 10px;
}
.yourgroup .purchasebartop .invite_button {
    margin-top: 15px;
}
}

@media only screen and (max-width:480px) {
  

    .suggestedyourGroup .group-search-bar .input-search-div {
        width: 100%;
        border-bottom: 1px solid #cdcdcd;
        /* border-right: none; */
    }

    .suggestedyourGroup .group-search-bar .input-search-div:nth-of-type(4) {
        border-bottom: none;
    }

    .suggestedyourGroup .your-group .input-btn-contain input {
        width: 350px;
        float: left;
    }

    .suggestedyourGroup .your-group {
        padding-left: 0;
    }

    .suggestedyourGroup .your-group .miles-from-s {
        display: flex;
        margin-right: 15px;
    }

    .your-group .location-search .location-input input[type="text"] {
        width: 61%;
    }
    .suggestedyourGroup .inviteGpopup .inviteBtn, .suggestedyourGroup .inviteGpopup .cancleBt {
        width: 135px;

    }

    .groupImgBoxdescriptionOuter {
        display: flex;
    }

    .your-group .customenewdesign .group-text-button button {
        margin-top: 10px;
        margin-left: 5px;
    }

    /* .suggestedyourGroup .your-group .content-text-bsvg {
        width: 65%;
        display: inline-flex;
    } */

    .your-group .group-text-button .invite {
        font-size: 15px;
    }

    .your-group .content-text-bsvg {
        font-size: 12px;
    }

    .your-group .customenewdesign .group-text-button {
        padding: 0;
        display: inline-flex;
    }

    .your-group .customenewdesign .group-text-button button {
        margin-bottom: 8px;
        width: 64px;
    }

    .suggestedyourGroup .your-group .flex-d-div {
        display: grid;
    }

    .input-search-div label {
        font-size: 15px;
    }
    .group-search-bar .search-btn {
        width: 50px;
        height: 50px;
        position: absolute;
        bottom: 7px;

    }
    button.yourgroupFloat {
       float: none;
   }
    .suggestedyourGroup .your-group .btn2-mygroup {
           margin-left: 0;
           text-align: center;
    }
	.customenewdesign h2 {
       font-size: 13px;
   }
   .nodataFoundinfo strong {
       width: 90%;
   }
   .nodataFoundinfo strong {
    width: 90%;
    font-size: 18px;
   }
   .yourgroup .your-main-groups .inviteGpopupinnersub .facustomeI .tootlippillerinfo {
    left: -41px;
    top: 20px;
  }
  .yourgroup .your-main-groups .inviteGpopupinnersub .facustomeI .tootlippillerinfo:before {
    transform: rotate(180deg) translate(30%, 100%);
    left: 46px;
    top: 0px;
 }
 .yourgroup div.group-sale{
    display: block;
 }
 .your-main-group .thre-point .three-ellipis .dotspop {
    left: auto;
    right: 19px;
}
}



@media only screen and (max-width:360px) {
    .your-group .customenewdesign .group-text-button {
        margin-left: 25px;
    }


}
