@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap");

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
/* ol,
ul,
li, */
fieldset,
form,
label,
legend,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
section,
time {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

/* HTML5 display-role reset for older browsers */
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

ol,
ul {

  padding: 0;
  margin: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-size: 14px;
  color: #5b5f62;
  background: linear-gradient(0deg,
      rgba(170, 192, 206, 0.1),
      rgba(170, 192, 206, 0.1)),
    #ffffff;
  font-family: "Inter";
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

.container {
  max-width: 1200px;
}

.sidebar ol li,
.sidebar ul li {
  list-style: none;
}

.childsintrests .header,
.updatepass .header,
.firstchildintrestpage .header,
.searchpage .header,
.servepage .header,
.searchexperiencepage .header,
.parentragistrationform .header {
  display: none;
}

.App {
  position: relative;
}

#root {
  overflow: hidden;
}

:where(.css-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill !important;
}

:where(.css-12jzuas).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

span.showhide,
span.hideshow {
  right: 10px;
  top: 3px;
  width: 55px;
  height: 42px;
  text-align: center;
  background: #fff;
  border-radius: 8px;
}

/*============== Navigation Style Here =================*/
.topnav {
  position: relative;
}

.topnav .icon {
  background: black;
  display: none;
  position: absolute;
  right: 0;
  top: 0;
}

/*===================== Left side bar styling Here ============================= */
.error {
  color: red;
  font-family: Inter;
  font-size: 12px !important;
  position: relative;
  top: -15px;
  display: block;
  text-align: left;
}

.search-div-2 {
  margin-top: 50px;
  float: left;
  text-align: left;
  width: 100%;
}

.my-profile-h {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #2d3134;
}

.profile-div-user {
  height: 47px;
  background: #265472;
  border-radius: 6px 6px 0px 0px;
  margin-top: 15px;
  padding: 7px 10px;
}

.content-div {
  height: 133px;
  width: 212px;
  background: white;
  border-radius: 0px 0px 6px 6px;
  padding: 12px 10px 0px 45px;
}

.form-group {
  position: relative;
}

.hideshow,
.showhide {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  width: 40px;
  height: 45px;
  font-size: 25px;
  display: inline-block;
  line-height: 45px;
}

.profile-div-user1 {
  margin-top: 15px;
  padding: 7px 0px 7px 0px;
}

.myfriendaccordion .profile-div-user1 {
  margin-top: 0px;
}

.profile-div-user1 .friend-invite {
  background-color: #f19357;
  border: none;
  padding: 12px 24px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
}

.profile-div-user1 .friend-invite button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  border: none;
  color: white;
  background-color: transparent;
}

.content-div1 a,
.content-div-h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #5b5f62;
  margin-bottom: 9px;
}

a.activex {
  font-weight: 600;
}

.content-div1 a {
  display: block;
}

.content-div-h44 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #2d3134;
  margin-bottom: 9px;
}

.input-handle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #2d3134;
  border: none;
  background: none;
  outline: none;
}

.input-div-sidebar {
  height: 29px;
  border-radius: 4px;
  background: #fff;
  border: 0.25px solid #bfbcb8;
  border-radius: 4px;
  padding: 6px;
  margin-top: 15px;
}

.accordion-content .input-div-sidebar {
  margin-top: 0;
}

#open-accordion2 h5,
#close-accordion2 h5 {
  position: relative;
}

.friend-div {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 25px;
  top: 0;
}

.request-count {
  position: relative;
}

.request-count .count {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 38%;
  top: 0px;
  border-radius: 50%;
  font-size: 11px;
  background: #f19357;
  padding: 2px;
  text-align: center;
  color: white;
  line-height: 18px;
  font-weight: 500;
}

.see-all {
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #f19357;
  margin-top: 3px;
}

.profile-division1 p,
.profile-div-user1 a,
.icon-handle a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5b5f62;
  margin-top: 8px;
  display: block;
}
.rating-inner-span{
  color: red;
}
.savepage .leavfeedbackreviews .innerBoxList li {
  padding-bottom: 0px;
}
.img-jackey {
  border-radius: 30px;
  width: 35px;
  height: 35px;
  float: left;
  margin-right: 5px;
}

.profile-div-user1 {
  position: relative;
}

.profile-division1 {
  display: inline-block;
  margin-bottom: 12px;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

.profile-div-user1 .icon-handle {
  display: inline-block;
  position: relative;
  width: 100%;
}

.profile-division1 img+div {
  float: left;
  width: 105px;
}

.profile-div-user1 .three-dots {
  position: absolute;
  right: 0px;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin-top: 5px;
  line-height: 24px;
}

.profile-div-user1 .three-dots:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.content-div1 {
  background: #f5f5f5;
  border-radius: 0px 0px 6px 6px;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.search-heading11 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: white;
  margin-bottom: 35px;
}

.sidebarlink li {
  margin-bottom: 10px;
}

.sidebarlink li a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  display: block;
  padding: 15px 11px;
  color: #5b5f62;
}

.sidebarlink li .active,
.sidebarlink li:hover a {
  border-radius: 6px;
  color: #fff;
  background: #265472;
}

.sidebarlink li .active {
  border: none;
}

/* .sidebarlink li .active {
   background: #265472;	
}
.sidebarlink li:hover a{
	 background:#F19357;
	 border:2px solid #F19357;
} */
.sidebarlink li:hover a {
  font-size: 17px;
  transition: transform .2s;
  width: 101%;
  background: #F19357;
}

.search-heading,
.sidebar h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.search-head-div h3 {
  color: #fff;
}

h3.search-heading {
  margin-bottom: 35px;
  color: #65676b;
}

.marginboottom {
  margin-bottom: 100px;
}

.myprofile {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #2d3134;
}

.profileuser {
  height: 47px;
  background: #265472;
  border-radius: 6px 6px 0px 0px;
  margin-top: 15px;
  padding: 7px 10px;
}

.searchdiv2 {
  margin-top: 65px;
  float: left;
  text-align: left;
}

.icon-handle {
  display: flex;
  justify-content: space-between;
}

.search-div-2 a {
  text-decoration: none;
}

.image-s1,
.image-s2,
.image-s4 {
  display: inline-block;
  width: 100%;
}

.image-s1,
.image-s4 {
  text-align: center;
}

/* =====================Search-content,Experience,Search3-CSS=========================== */

.main-search-container1 {
  margin-top: 40px;
  margin-bottom: 14px;
}

.searchres-div {
  border-top: 1px solid #bfbcb8;
  border-bottom: 1px solid #bfbcb8;
  margin-bottom: 27px;
  display: inline-block;
}

.search-exp-sec-div {
  /* margin: 24px 0px; */
  margin: 15px 0 5px 0px;
  display: inline-block;
  width: 100%;
}

.search-exp-sec-div h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2d3134;
  padding: 7px 0px;
  float: left;
}

.select-exp-div {
  background: #ffffff;
  border: 0.21px solid #bfbcb8;
  border-radius: 32.5px;
  padding: 5px 6px 3px 12px;
  /*  height: 31px; */
  width: 16%;
  float: left;
  margin: 0 0 10px 8px;
}

.select-exp-div select,
.select1 {
  width: 100%;
  border: none;
  display: flex;
  gap: 20px;
  outline: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2d3134;
}

.select-exp-div select {
  padding: 2px 0 0 0;
}

.experience-input-div {
  height: 42px;
  background: #ffffff;
  border: 0.21px solid #bfbcb8;
  border-radius: 32.5px;
  margin: auto;
  width: 55%;
  padding: 9px 5px 9px 10px;
  margin-bottom: 29px;
  position: relative;
}

.input-location {
  float: left;
}

.miles-from-s {
  display: flex;
  /* gap: 18px; */
  float: right;
  /* text-align: right; */
  width: 38%
}

.icon-div22 {
  border: none;
  width: 34px;
  height: 34px;
  background: #f19357;
  box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
  border-radius: 100px;
  position: relative;
  bottom: 6px;
}

.iconsearch22 {
  color: white;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 6px;
  left: 0;
}

/* .input-experienc {
  border: none;
  border-right: 1px solid gray;
  outline: none;
  padding: 3px 0px 6px 7px;
} */

.miles {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2d3134;
  margin-top: 0px;
  border: none;
  height: 25px;
  width: 82%;
}

.viewing {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2d3134;
  float: left;
}

.card-container-exp .viewableFriend h5,
.card-container-exp h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12.4138px;
  line-height: 19px;
  color: #82a3a1;
  margin-bottom: 10px;
}

.card-container-exp p {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #5b5f62;
  margin-bottom: 20px;
}

.description {
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.description-share {
  height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

}

.description-sharee {
  height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.share-title {
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
}

.text-curriculum11 {
  font-family: "Effra";
  margin-left: 1px;
  margin-bottom: 10px;
  display: flex;
}

.text-curriculum11 span {
  margin: auto 0px;
  margin-right: 8px;
  font-family: 'Inter';
  font-size: 12.41px;
  line-height: 19.31px;
}

.text-curriculum11 .heartBox,
.text-curriculum11 .strengthBox,
.text-curriculum11 .soulBox,
.text-curriculum11 .mindBox {
  font-weight: 600;
}

.text-curriculum11 .mindBox {
  color: #F19357;
}

.text-curriculum11 .heartBox {
  color: #265472;
}

.text-curriculum11 .strengthBox {
  color: #AAC0CE;
}

.text-curriculum11 .soulBox {
  color: #F0B285;
}

.checkc {
  padding: 0px 7px 0px 0px;
  font-size: 15px;
}

.downicon {
  font-size: 15px;
  color: #2d3134;
  padding: 0px 0px 0px 15px;
  cursor: pointer;
}

.hearticon {
  font-size: 15px;
  color: #2d3134;
  padding: 0px 4px 0px 0px;
  cursor: pointer;
}

.hearticon1 {
  padding: 0px 4px 0px 10px;
  font-size: 15px;
  color: #2d3134;
}

.bookmark {
  font-size: 25px;
  text-align: right;
  margin-right: 20px;
  cursor: pointer;
}

.img-icon {
  display: flex;
  justify-content: space-between;
}

.ellipsicon {
  margin-right: 20px;
  font-size: 25px;
}

.card-container-exp {
  min-height: 460px;
  width: 100%;
  padding: 11px 11px;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 24px 90px rgb(192 188 161 / 22%);
  border-radius: 16px;
  margin-bottom: 30px;
  text-align: left;
  position: relative;
  cursor: pointer;
}

.card-container-exp h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #2d3134;
  margin-bottom: 5px;
  margin-top: 20px;
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
}

.show-more {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin-top: 55px;
  color: #2d3134;
}

.curriculum-h5 a {
  color: #2D3134;
  text-decoration: none;

}

.img-div2 {
  background-color: #f19357;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 16px 18px; */
  border-radius: 16px;
}

.img-div4 {
  background-color: #265472;
  width: 96px;
  height: 96px;
  padding: 16px 18px;
  border-radius: 16px;
}

.img-Facilities {
  background-color: #91CBCE;
  width: 96px;
  height: 96px;
  padding: 16px 18px;
  border-radius: 16px;
}

.resource-img {

  width: 96px;
  height: 96px;
  padding: 0px;
  border-radius: 16px;
}


.img-Facilitiess {

  width: 96px;
  height: 96px;
  /* padding: 16px 18px; */
  border-radius: 16px;
}

.img-Facilitiess img {
  width: 88px;
  height: 88px;
  border-radius: 16px;

}

.img-divsr7 {
  background-color: #91cbce;
  padding: 12px 22px 13px 21px;
  border-radius: 16px;
}

.img-divsr7 img {
  width: 51px;
}

/* =================UPloaded File===================== */

.r-title-div div {
  height: 52px;
  background: #ffffff;
  /* border: 1px solid #d9d9d9; */
  border: 1px solid #dfdfdf;
  border-radius: 100px;
  padding: 15px;
}

.r-title-div div input {
  width: 100%;
  border: none;
  outline: none;
  padding: 3px 15px;
}

.u-content {
  float: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #5b5f62;
  padding: 0px 0px 0px 23px;
}

.input-file-upload {
  position: relative;
  bottom: 21px;
  opacity: 0;
}

.eligible-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12.4138px;
  line-height: 19px;
  color: #82a3a1;
  margin-bottom: 12px;
}

/* explore-card-css */

.explore-container {
  margin-top: 100px;
}

.explore-resources {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  color: #2d3134;
  margin-bottom: 50px;
  float: left;
  line-height: 54px;
}

.explore-all {
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: white;
  padding: 12px 24px;
  background: #265472;
  border: 1px solid #2d3134;
  border-radius: 8px;
  float: right;
  text-decoration: none;
}

.explore-all a {
  color: #fff;
  text-decoration: none;
}

.explore-all:hover {
  color: white;
}

.explore-container .curriculum-image {
  width: 65px;
}

.card-container {
  min-height: 450px;
  width: 100%;
  padding: 15px 0px 11px 11px;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 24px 90px rgb(192 188 161 / 22%);
  border-radius: 16px;
  margin-bottom: 30px;
  text-align: left;
}

.card-container h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #2d3134;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 48px;
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.img-div {
  background-color: #f19357;
  width: 96px;
  height: 96px;
  padding: 15px 10px;
  border-radius: 16px;
  text-align: center;
}

.img-div1 {
  background-color: #57886c;
  width: 96px;
  height: 96px;
  padding: 16px 18px;
  border-radius: 16px;
}

.img-funding {
  background-color: #82A3A1;
  width: 93px;
  height: 93px;
  padding: 16px;
  border-radius: 16px;
}

.img-div2 {
  background-color: #f19357;
  width: 96px;
  height: 96px;
  padding: 10px 10px;
  border-radius: 16px;
}

.img-div3 {
  background-color: #aac0ce;
  width: 96px;
  height: 96px;
  padding: 14px 15px;
  border-radius: 16px;
}

.curriculum-image {
  width: 74px;
}

#itemListDiv {
  display: none;
}

#itemListDiv.itemListstyle {
  display: block;
}

#itemListDiv.aprovStateList ul li>p {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 22px;
}

.curriculum-image1 {
  width: 74px;
  height: 60px;
}

.student-image1 {
  width: 60px;
  height: 60px;
}

.curriculum-image2 {
  width: 60px;
  height: 60px;
}

.resource-image2 {
  width: 94px;
  height: 94px;
}

.resource-image3 {
  width: 55px;
  height: 55px;
}

.curriculum-image3 {
  width: 60px;
  height: 60px;
}

.curriculum-paragraph {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #5b5f62;
  margin-bottom: 20px;
}

.text-curriculum {
  /* font-family: 'Effra'; */

  margin-top: 50px;
  margin-bottom: 10px;
}

.main-card-container {
  padding: 30px 0px;
}

.reviews {
  cursor: pointer;
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #000000;
  margin-left: 5px;
}

.star {
  color: #f19357;
  border-radius: 0px;
}

.handle-star {
  margin-bottom: 10px;
}

.heart {
  color: #265472;
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

/*========== Child inf0 ==========*/
.form-label {
  font-family: "Inter";
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}

.controlinput {
  margin-left: 23px;
}

.child-inner p {
  text-align: left;
  margin-top: 39px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #5b5f62;
}

.child-inner h6 {
  font-family: "Inter";
  font-style: normal;
  text-align: left;
  font-size: 14px;
  color: #f19357;
  line-height: 20px;
  margin-top: 16px;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.cursor-pointer {
  cursor: pointer;
}

.child-inner h2 {
  position: relative;
  right: 6px;
}

.child-inner h2 {
  font-family: "Inter";
  font-style: normal;
  text-align: left;
  font-size: 30px;
  line-height: 30px;
  color: #2d3134;
  font-weight: 700;
  margin: 26px 6px 0px 0px;
}

.child-inner h4 span {
  font-size: 18px;
  font-weight: bold;
}

.child-inner h3 span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.form-label1 {
  font-family: "Inter";
  font-style: normal;
  height: 19px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  float: left;
  color: #2d3134;
  margin-bottom: 5px;
}

.star {
  font-size: 24px;
  color: grey;
  cursor: pointer;
}

.star.filled {
  color: gold;
}

#itemListDiv {
  display: none;
}

#itemListDiv.itemListstyle {
  display: block;
}

#itemListDiv.aprovStateList ul li>p {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 22px;
}

.add {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #265472;
  text-align: left;
}

.s-button {
  border: none;
  background: #f19357;
  color: white;
  margin: 20px 10px;
  padding: 13px 35px 13px 35px;
}

.s-button1,
.s-button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 80%;
  align-items: center;
  border-radius: 8px;
}

.s-button1 {
  border: 1px solid #d9d9d9;
  background-color: white;
  text-transform: capitalize;
  color: black;
  width: 182px;
  height: 44px;

}

.upload {
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
}

.edit-button {
  border: none;
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  align-items: center;
  text-transform: capitalize;
  background: #ffffff;
  color: #2d3134;
}

.save-btn1 {
  float: right;
  align-items: center;
  padding: 10px 10px 10px 10px;
  margin-bottom: 50px;
}

.form-control::placeholder {
  color: #2d3134;
}

.heart,
.mind,
.soul,
.strength {
  color: #265472;
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: -5px 3px;
}

.mind {
  color: #f19357;
}

.soul {
  color: #f0b285;
}

.strength {
  color: #aac0ce;
}

/*============= Popup Button Sytling ===========*/

.close-btn {
  width: 37px;
  height: 37px;
  background: #d9d9d9;
  float: right;
  border-radius: 50%;
  font-family: "Inter";
}

.css-b62m3t-container {
  text-align: left;
  font-family: "Inter";
}

.css-b62m3t-container .css-t3ipsp-control {
  box-shadow: none;
  border: 1px solid hsl(0, 0%, 80%) !important;
  height: 52px;
  border-radius: 50px;
}

.save-cancel-btn {
  text-align: center;
}

.add p {
  color: #265472;
}

.form-check-label {
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #5b5f62;
  margin-left: 13px;
}

.text-curriculum11 img {
  width: 15px;
  margin-right: 4px;
  border-radius: 0;
}

.searchpage .text-curriculum11 img {
  border-radius: 0;
}

.heart,
.mind,
.soul,
.strength {
  color: #265472;
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: -5px 3px;
}

.mind {
  color: #f19357;
}

.soul {
  color: #f0b285;
}

.strength {
  color: #aac0ce;
}

.images-divv {
  background: #265472;
  width: 96px;
  height: 96px;
  padding: 16px 18px;
  border-radius: 16px;
}

.images-organization {
  background: #F9C976;
  width: 96px;
  height: 96px;
  padding: 16px 18px;
  border-radius: 16px;

}

.input-experienc {
  border: none;
  /* border-right: 1px solid gray; */
  outline: none;
  padding: 3px 0px 6px 7px;
  width: 100%;
}

.location-icon {
  border-right: 1px solid gray;
  cursor: pointer;
}

.input-location {
  float: left;
  width: 60%;
}

/* Start For accordian---------- */
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
  color: #5b5f62;
  position: unset !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 5px !important;
}

.content-div1 ul li a:active {
  color: #265472;
}

#itemListDiv {
  display: none;
}

#itemListDiv.itemListstyle {
  display: block;
}

#itemListDiv.aprovStateList ul li>p {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 22px;
}

.clearnone {
  clear: both;
}

/*============== Switch radio button =============*/

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
  margin: 0 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #f19357;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.viewableFriend h5 {
  color: #2d3134;
  font-family: "Inter";
  font-weight: 600;
  font-size: 18px;
  text-align: left !important;
}

.sharepopupOuter.fixedOuter {
  height: 100% !important;
}

.save-div-name-outer .save-div-name {
  display: inline-block;
}

.save-div-name-outer .save-jackey-div1 {
  margin: 0 10px 10px 0px;
  float: left;
}

.save-div-name-outer .save-jackey-div1 .div {
  margin-bottom: 0;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded .profile-division .jackey-name a {
  text-transform: capitalize;
  color: #fff;
}

.profile-division .jackey-name a {
  color: rgb(91, 95, 98);
}

.noData {
  min-height: 300px;
}

.noData h3 {
  font-size: 22px;
  padding-top: 100px;
}

.search-div-2 .input-div-sidebar input[type="text"] {
  width: 100%;
  padding: 0;
  line-height: inherit;
  font-size: inherit;
}

.servewithoutOuterBox input::placeholder,
.inputOuterBox input::placeholder,
.search-div-2 .input-div-sidebar input::placeholder {
  color: #65676B;
}

/*========= Css ForSelect animation remove ========== */
.css-13cymwt-control .css-b62m3t-container .css-wsp0cs-MultiValueGeneric {
  padding: 5px !important;
}

.css-13cymwt-control .css-b62m3t-container .css-wsp0cs-MultiValueGeneric,
.css-b62m3t-container .css-wsp0cs-MultiValueGeneric {
  padding: 5px 5px 5px 12px !important;

}

.css-b62m3t-container .css-1p3m7a8-multiValue {
  background-color: #F19357;
  color: white;
  border-radius: 50px;
  height: 32px !important;
  line-height: 23px !important;
  padding-right: 6px !important;
  font-size: 16px !important;
}

.css-12a83d4-MultiValueRemove {
  background-color: #ffffff;
  color: #F19357;
  width: 18px;
  height: 18px;
  border-radius: 50% !important;
  margin-top: 7px;
}

.innernotfoundcontainer {
  min-height: 400px;
  padding-top: 50px;
}

.innernotfoundcontainer h3,
.innernotfoundcontainer p,
.innernotfoundcontainer .large-text {
  color: #2D3134;
}

.innernotfoundcontainer .large-text {
  font-size: 90px;
}

.innernotfoundcontainer h3 {
  font-size: 26px;
}

.innernotfoundcontainer p {
  font-size: 16px;
}

.innernotfoundcontainer a {
  background: #f19357;
  border: 2px solid #f19357;
  font-weight: 600;
  line-height: 34px;
  padding: 5px 15px;
}

.innernotfoundcontainer a:hover {
  border: 2px solid #f19357;
  background: #fff;
  color: #2D3134;
}

.sidesearchtext {
  background: #AAC0CE;
  border: none;
  margin: 10px 0;
  height: 29px;
  padding: 0px 10px;
  color: #000;
  border-radius: 4px;
  width: 100%;
}

.sidesearchtext::placeholder {
  color: #000;
}

.newgroupsidebar {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #2D3134;
  background: #FFFFFF;
  border: 0.5px solid #BFBCB8;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  margin: 10px 0px;
}

.groupseeall {
  display: inline-block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.groupseeall h2 {
  float: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #2D3134;
}

.groupseeall a {
  float: right;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #F19357;
}

.homepage .marginboottom {
  margin-bottom: 0;
}

.homepage .sidebar {
  display: none;
}

.userprofileDescBox {
  position: relative;
  padding-right: 10px;
}

.chat-messages-textarea-rt .form-control {
  padding-right: 50px !important;
}


.message-content {
  display: flex;
}

.message-content .message-content-div {
  font-size: 14px;
  font-family: 'Inter';
  margin-left: 10px;
}

.kayword-associated .css-1q79v3g-MuiButtonBase-root-MuiChip-root {
  color: #fff;
  background-color: #F19357;
}

.kayword-associated .css-1q79v3g-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  color: #fff;
}

.savepopup-content {
  max-height: 185px;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;

}

.sharepopupOuter .savepopup-content {
  position: relative;
  z-index: 111;

}

.savepopup-content .multiSelectContainer ul {
  max-height: 130px;

}

.save-popup-top {
  z-index: 999999;
  position: relative;

}

.sharepopupOuter .input-popup3 div.searchWrapper {
  height: 30px;
  overflow: hidden;
  overflow-y: auto;
}

.left-div .well-chat-icon+.modal-container {
  right: 0;
  filter: none;
  top: auto;
  bottom: 10px;
  position: fixed;
}

.left-div .well-chat-icon+.modal-container .Messanger .messanger-inner {
  padding-top: 5px;
}

.left-div .well-chat-icon+.modal-container .Messanger .messanger-inner .chatList {
  height: 392px;
  overflow-x: hidden;
}


.left-div .well-chat-icon+.modal-container .Messanger .messanger-inner .chatList::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #F5F5F5;
}

.left-div .well-chat-icon+.modal-container .Messanger .messanger-inner .chatList::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
  display: block;
}

.left-div .well-chat-icon+.modal-container .Messanger .messanger-inner .chatList::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #59658e;
}

/*mohan css start*/

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #59658e #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #59658e;
  border-radius: 5px;
  /*border: 3px solid #ffffff;*/
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
}


.css-b62m3t-container .css-wsp0cs-MultiValueGeneric {
  color: #fff !important;
}

.css-wsp0cs-MultiValueGeneric {
  color: #fff;
}

select {
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button {
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

}

select {
  -webkit-appearance: none;
  appearance: none;
}

.yourgroup .profileStripSetings .strip-activation {
  float: none;

}

.yourgroup .profileStripSetings {
  text-align: center;
}

/*mohan css End*/

.ChangePassword .header1+.container,
.signin .frm {
  height: 100vh;
}

.childProfileUpdateView .header1+.container,
.profilepage .header1+.container,
.createschedule .header1+.container,
.createnewGroup .header1+.container,
.servepaymenthistory .header1+.container,
.yourgroup .header1+.container,
.save-resource-list .header1+.container,
.serveyour-resource .header1+.container,
.groupJoin .header1+.container,
.notificationSetting .header1+.container,
.servepage .header1+.container,
.serveDashboard .header1+.container,
.notification .header1+.container,
.savepage .header1+.container,
.suggestedyourGroup .header1+.container,
.purchase-page .header1+.container,
.searchpage .header1+.container {
  min-height: 100vh;
}

.sign-up .frm {
  height: calc(100vh - -310px);
}

.bookasession .header1+.container {
  min-height: 150vh;
}

.sessionhistory .header1+.container {
  height: calc(100vh - -150px);
}

.errorpage .header1+.container,
.paymentsuccess .header1+.container,
.Ticket-page .header1+.container,
.ticket-detail .header1+.container {
  height: calc(100vh - -150px);
}

.my-accordion .accordion-header .accordion-button .my-accordion .accordion-header:hover .accordion-button {
  background: transparent;

}

.subscriptiondataInfo {
  margin-top: -33px;
  padding-bottom: 5px;
}

div.my-accordion .accordion-button,
div.my-accordion .accordion-button:not(.collapsed),
div.my-accordion .accordion-button:focus {
  background-color: transparent;
}

.servepage .middlediv .texteditordescription .jodit-react-container .jodit-workplace,
.savepage .texteditordescription .jodit-react-container .jodit-workplace {
  max-height: 121px;
  overflow-y: auto;
}

#headingTwo .accordion-button,
#headingThree .accordion-button {
  padding: 15px;
}

.searchpage .header1.headerfix+.pac-container {
  top: 186px;
}
.reactEasyCrop_CropAreaRound {
  border-radius:0%!important;
}