.doc_div {
	padding-left: 5px;
    margin-bottom: 10px;
	padding-top:4px;
	height:auto;
}
.date-separator {
  text-align: center;
  color: #888;
  margin: 10px 0;
}
.middle-chat-part .chatManuiocns{
  position: absolute;
  top: 0;
  width: 95%;
}
.date-separator::before,
.date-separator::after {
  content: "";
  display: inline-block;
  width: 35%;
  margin-bottom: 4px;
  border-top: 1px solid #888;
}
.chat-content .chatList1 .sent{
  word-break: break-word;
}
div.chat-messages-textarea div.chat-messages-textarea-rt textarea.textareaautosize{
  font-size: 15px;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height:90px;
  overflow-y: auto;
}
.middle-chat-part .chatautotextarea{
  position: relative;
  width: 100%;
  border: 1px solid #d9d9d9;
  right: 0px;
  border-top: none;
  }
  
  div.chatpopupauto{
    top:auto;
	bottom:5px;
  }
  
  .messages ul.chatlistautotext{
    height: 371px;
  }
  
  div.chat-messages-textarea div.chat-messages-textarea-rt .areabtnauto{
  top: auto;
  bottom: -22px;
  }
  div.chat-messages-textarea .chatmessageautotext{
    position: absolute;
      right: 0;
      bottom: 21px;
  }
  div.left-div .well-chat-icon + .modal-container {
    bottom: 5px;
}