.decisionMaking-innerBox {
    background: #f19357;
    color: #fff;
    width: 82%;
    margin: 0 auto 40px;
    padding: 30px 50px;
    border-radius: 12px;
    text-align: center;
}
.decisionMaking-innerBox h4 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 15px;
    font-weight: 700;
    color: #fff;
    font-family: 'Inter', sans-serif;
}
.decisionMaking-innerBox button {
    padding:5px 10px;
    background: #265472;
    border-radius: 100px;
    height: 44px;
    color: #fff;
    min-width: 219px;
    border: none;
    margin-top: 15px;
    /* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}
.get-start {
    padding:5px 10px;
    background: #265472;
    border-radius: 100px;
    height: 44px;
    color: #fff;
    min-width: 219px;
    border: none;
    margin-top: 15px;
    /* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}
.decisionMaking-innerBox p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
}
.decisionMaking {
	margin-bottom:30px;
}
@media only screen and (max-width:991px){
div.decisionMaking {
	margin-bottom:0px;
}
}
@media only screen and (max-width:480px){
div.banner .my-3 {
	text-align:center;
}
}