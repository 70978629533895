.image-div-center{
    margin-top:50px;
	text-align:center;
}
.image-div-center img{
    width: 161px;
height: 161px;
margin-bottom: 20px;
border-radius:50%;
border:1px solid #BFBCB8;

}
.image-div-center h3{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 36px;
margin-bottom: 26px;
color: #2D3134;
}
.image-div-center button{
    background: #F19357;
border: 0.5px solid #BFBCB8;
box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
border-radius: 6px;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 20px;
color: #FFFFFF;
padding: 13px 40px 14px 40px;
margin-bottom: 49px;
}
.searchres-div11{
      border-top: 1px solid #BFBCB8;
    border-bottom: 1px solid #BFBCB8;
    margin-bottom: 27px;
    width: 100%;
}
.search-exp-sec-div1{
   display: inline-block;
    margin: 24px 0px;
	    width: 100%;
}
.search-exp-sec-div1 .css-b62m3t-container {
    float: left;
}
.search-exp-sec-div1 .css-b62m3t-container .css-13cymwt-control {
    margin: 0;
    height: 31px;
	overflow: hidden;
}
.search-exp-sec-div1 .css-b62m3t-container .css-t3ipsp-control {
  height: 31px;
}
.search-exp-sec-div1 .css-1p3m7a8-multiValue {
    margin: 0;

}
.search-exp-sec-div1 .css-b62m3t-container .css-wsp0cs-MultiValueGeneric {
    padding: 0;
    padding-left: 5px;

}
.search-exp-sec-div1 .css-b62m3t-container .css-13cymwt-control {
    padding-left: 5px;

}
.search-exp-sec-div1 .css-b62m3t-container {
       width: 317px;
      margin-right: 10px;
}
.search-exp-sec-div1 h3{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 32px;
color: #2D3134;
margin-right: 12px;
float: left;
}
.search-exp-sec-div1 select{
    width: 190px;
height: 31px;
background: #FFFFFF;
border: 0.21px solid #BFBCB8;
border-radius: 32.5px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;
color: #2D3134;
padding:0px 12px;
margin-right: 12px;
float:left;
}
.search-exp-sec-div1 input{
    width: 100%;
    height: 31px;
    background: #FFFFFF;
    border: 0.21px solid #BFBCB8;
    border-radius: 32.5px;
    padding: 0px 12px;
    outline: none;
    float: right;
}
.search-exp-sec-div1 input::placeholder{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2D3134;
    letter-spacing: 0.01em;
}
.search-exp-sec-div1 button {
    width: 29px;
    height: 28px;
    background: #F19357;
    box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
    border-radius: 100px;
    font-size: 21px;
    border: none;
	float:right;
 }
.input-btn-con{
    float: left;
    position: relative;
}
.card-container-handle{
    padding:20px 50px 50px 50px;
	position: relative;
	min-height: 325px;
	display:inline-block;
	width:100%;
}
.image-div-center button.follow-profile{
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    margin-right: 10px;
}
.childProfileUpdateView  .searchres-div11 .form-select .search-wrapper span{
    display: block;
    margin-top: 6px;
    padding-left: 10px;
}
/*========= Popup Styling Here ===========*/
.child-profile-popup{
       box-sizing: border-box;
    background: #FFFFFF;
    border: 2px solid rgba(255, 255, 255, 0.9);
    box-shadow: 0px 24px 90px rgba(192, 188, 161, 0.22);
    border-radius: 16px;
    min-height: 300px;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5%;
    }
    .child-profile-popup h3{
       max-width: 569px;
        width:100%;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: #2D3134;
        padding: 97px;
        text-align: center;
    }
	.child-profile-popupBg {
	position: absolute;
    left: 0;
    top: 0;
    background-color: rgb(247 246 246 / 80%);
    width: 100%;
    height: 100%;
    text-align: center;
	    z-index: 1;
	}
	.handleChildupdateViewCardInner {
		filter: blur(4px);
       -webkit-filter: blur(4px);
	}
	.child-cancel1{
		margin-top: 50px;
		border: none;
		background: #F19357;
		font-size: 18px;
		color: #fff;
		padding: 10px 15px;
		border-radius: 8px;
	}
	.child-cancel1 small {
		display:block;
	}
.profilepage .searchres-div11 .search-exp-sec-div1 input{
    width: auto;
    float: left;
}
.profilepage .searchres-div11 .saveResourceListgradeRange .form-select .multiSelectContainer .search-wrapper .chip{
    margin-left: 10px;
}

.childProfileUpdateView .searchres-div11 .search-exp-sec-div1 .saveResourceListgradeRange .optionListContainer input{
    width: auto;
    height: auto;
    float: left;
}
.childProfileUpdateView .searchres-div11 .search-exp-sec-div1 .childprofilegradeRange div.multiSelectContainer input[type="checkbox"]{
width: 13px; 
}

.childProfileUpdateView span.reviews{
    float: none;
}

.childProfileUpdateView .profileCardContainer .card-container-exp div.bookmark{
    margin-top: -25px;
    margin-right: 0;
}
.childProfileUpdateView .search-exp-sec-div1 .showgrademultiple div.searchWrapper {
     margin-top: 1px;
    overflow-y: auto;
    height: 26px;
    overflow-x: hidden; 
}
.childProfileUpdateView .search-exp-sec-div1 .showgrademultiple div.searchWrapper {
    width: 95%;
   /*  position: unset; */
}
.childProfileUpdateView .searchres-div11 .form-select div.search-wrapper span {
    display: inline-block;
}
.childProfileUpdateView .child-cancel1 {
    position: relative;
    top: 20%;
    transform: translateY(-20%);
}
.request-Permission-btn .cancel-butn {
	margin-right:0px;
	margin-left:10px;
}
.requestPermissionClose .sharepopupOuter {
	display:none;
}
.profilepage .child-profile-popupBg .child-profile-popup button{
    position: relative;
    bottom: 50px;
}



@media only screen and (max-width:1200px){
   .childProfileUpdateView .search-exp-sec-div1 select {
     width: 14%;
    }
}
@media only screen and (max-width:991px){
.search-exp-sec-div1 select {
	width: 100%;
	margin-bottom: 5px;
}
.search-exp-sec-div1 input {
	width: 100%;
}
.profilepage .input-btn-con {
	width: 85%;
}
.profilepage div.viewing56 {
    margin-left: 0;
 }
  .profilepage .card-container-handle {
    padding:20px 0 50px 0;
}
.profile-page-card .graph-img {
    width: 40%;
}
.profile-page-card .child-profile-content {
    width: 59%;
}
.childProfileUpdateView .card-container-handle {
    padding: 0;
 }
 .childProfileUpdateView .search-exp-sec-div1 h3{
	width:100%;
 }
 .childProfileUpdateView .search-exp-sec-div1 select {
    width: 49%;
    margin-bottom: 5px;
    float: left;
  }
  .childProfileUpdateView .search-exp-sec-div1 .childprofilegradeRange {
    float: left;
    width: 49%;
    margin-right: 0;
   }
   .childProfileUpdateView .search-exp-sec-div1 .input-btn-con {
      width: 44%;
  }
}
@media only screen and (max-width:767px){
.profile-page-card .graph-img,
.profile-page-card .child-profile-content {
  width: 100%;
}
.childProfileUpdateView .search-exp-sec-div1 div.saveResourceListgradeRange, .search-exp-section-div1 select {
    width: 100%;
}
}
@media only screen and (max-width:576px){
	.childProfileUpdateView .search-exp-sec-div1 .childprofilegradeRange,
.childProfileUpdateView .search-exp-sec-div1 h3 {
	width: 100%;
}
.childProfileUpdateView .search-exp-sec-div1 select {
	width: 100%;
	margin-bottom: 10px;
}
	.input-btn-con {
		width: 100%;
	}
	.search-exp-sec-div1 input {
	   width:100%;
	}
	.card-container-handle {
		padding:5px;
	}
	.childProfileUpdateView .search-exp-sec-div1 .input-btn-con {
    width: 88%;
   }
}
.childProfileUpdateView .saveResourceListgradeRange div.searchWrapper{
    overflow-y: hidden;
}
.childProfileUpdateView .saveResourceListgradeRange .form-select{
    background-image: none;
}
.childProfileUpdateView .searchres-div11 .childprofilegradeRange .form-select{
    padding-right: 0;
    background-image: none;
}
.childProfileUpdateView .searchres-div11 .saveResourceListgradeRange{
    padding: 0;
}
.childProfileUpdateView .search-exp-sec-div1 .saveResourceListgradeRange, .search-exp-section-div1 select{
    width: 18%;
}
.childProfileUpdateView .childprofilegradeRange .form-select .search-wrapper input,
.childProfileUpdateView .saveResourceListgradeRange .form-select .search-wrapper input{
position: absolute;
bottom: -1px;
font-family: 'Inter';
}
.childProfileUpdateView .childprofilegradeRange .form-select .search-wrapper input::placeholder{
    color: #000000;
}
.childProfileUpdateView .childprofilegradeRange .form-select .search-wrapper input{
    position: absolute;
    bottom: -2px;
    font-family: 'Inter';
}
.childProfileUpdateView .profileCardContainer .card-container-exp .bookmark{
    margin-top: 0;
}
.childProfileUpdateView .card-container-exp .text-curriculum11 .text-curriculum11{
    min-height: 40px;
}
.profilepage .searchres-div11 div.search-exp-sec-div1 input{
    color: #000000;
}
.childProfileUpdateView .search-wrapper .chip+#search_input::placeholder,
.childProfileUpdateView .search-wrapper .chip+#search_input {
    display: block;
}