@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
/* @import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css"; */

@font-face {
  font-family: 'effra_trialregular';
  src: url('./assets/Fonts/effra_trial_rg-webfont.woff2') format('woff2'),
    url('./assets/Fonts/effra_trial_rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.Toastify__toast-body {
  font-size: 17px!important;
  font-weight:800!important;
}
