/*--------- chatlist------ */
.chatList {
  height: 460px;
  overflow-y: scroll;
  width: 100%;
}

.chatList::-webkit-scrollbar,
.chatList1::-webkit-scrollbar {
  display: none;
}

.chatList,
.chatList1 {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  overflow: auto;
  -ms-overflow-style: none;
}


/* .search-list::-webkit-scrollbar {
  width: 0.5em;
} */

.chatList,
.chatList1::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.chatList,
.chatList1::-webkit-scrollbar-track {
  background-color: transparent;
}

.user-list {
  cursor: pointer;
  width: 100%;
  display: flex;
  gap: 4;
  border-bottom: 1px solid #f3f3f0;
}

.chatList1 {
  height:365px;
  overflow-y: auto;
}

.chat-user-profile {
  position: relative;
}

.chat-user-profile span {
  width: 10px;
  height: 10px;
  display: block;
  background: #337447;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  bottom: 10px;
}

.courser-point {
  cursor: pointer;
}

/*-------- search------ */
.user-search {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin: 5px 0px;
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
}

.user-search h2 {
  font-size: 16px;
  text-transform: capitalize;
  color: black;
}

.user-search button {
  padding: 3px 10px;
  border-radius: 5px;
  color: white;
  background-color: #f19357;
  border: none;
  font-size: 16px;
  font-weight: 700;
}

.search-list {
  height: 250px;
  overflow-y: auto;
}

.add-title {
  font-size: 24px;
  color: black;
  font-weight: 700;
  text-align: center;
}

.add-group {
  display: flex;
  flex-direction: column;
}

.add-group .addmemberInput {
  border-radius: 20px;
  border: 1px solid gray;
  padding: 8px 10px;
  width: 100%;
}

.add-group button,
.add-member-btn button {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  background-color: #f19357;
  border: none;
  font-size: 20px;
  font-weight: 700;
}

.grouplist {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.grouplist button {
  border: none;
  background-color: #f19357;
  color: #ffffff;
  font-size: 14px;
  padding: 8px 14px;
  border-radius: 3px;
}

.grouplist img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.grouplist h1 {
  font-size: 18px;
  margin: 10px 5px;
  color: black;
  font-weight: 500;
  /* font-family: "effra_trialregular"; */
  font-family: 'Rubik', sans-serif;
}

.box {
  position: relative;
  top: 0px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.box input {
  position: absolute;
  top: 5%;
  left: 0%;
  width: 40px;
  height: 40px;
  opacity: 0;
}

.box img {
  position: absolute;
  top: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.box i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(4);
}

.box:hover img {
  opacity: 0.8;
  filter: grayscale(100%);
}

.box * {
  transition: all 0.35s ease-in-out;
}

.box .carema-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  transform: scale(0);
}

.box:hover .carema-icon {
  transform: scale(1);
  transition: transform 0.35s ease-in;
}

.delete-massage {
  margin: 5px 0;
  cursor: pointer;
  /* transform: scale(0); */
}

.sent:hover .delete-massage,
.replies:hover .delete-massage {
  transform: scale(1);
  /* transition: transform 0.35s ease-in; */
}

.attech {
  position: relative;
  top: 0px;
  height: 40px;
  width: 40px;

}

.attech input {
  width: 40px;
  height: 20px;
  border: none;
  z-index: 10;
  background-color: transparent;
  opacity: 0;
  cursor: pointer;
}

.attech {
  position: relative;
}

.attech .attech-icon {
  position: absolute;
  left: 20px;
}

.pdf-image {
  width: 100px;
  height: 100px;
}

.extension {
  font-size: 90px !important;
}
.messagethreedostpopup {
	right:0;
}
.inspiration-div1 .Messanger {
	margin-bottom:10px;
}