.banner {
    position: relative;
    padding:130px 10px 1px 10px;
}
.images-Box {
    position: relative;
}
.banner h1 {
    text-align: left;
    position: relative;
    font-size: 51px;
    font-weight: 700;
    color: #2D3134;
    margin-bottom: 35px;
    font-family: 'Inter';
}
.para {
    text-align: left;
    margin-bottom: 50px;
}
.para p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    font-family: inter;
}
.para .sub-text {
    font-size: 31px;
    font-weight: 700;
    color: #2D3134;
    margin-bottom: 5px;
}
.inputOuterBox {
    background: #FFFFFF;
    border: 0.5px solid #D9D9D9;
    box-shadow: 0px 28px 56px rgba(246, 111, 77, 0.05);
    border-radius: 38px;
}
.inputOuterBox {
    position: relative;
    padding-bottom: 2px;
    display: inline-block;
    width: 100%;
}
.inputOuterBox input {
    float: left;
    width: 60%;
    height: 56px;
    font-size: 18px;
    border-radius: 38px;
    border: none;
    padding-left: 15px;
    color: #2D3134;
    font-family: inter;
    outline: none;
}
.inputOuterBox button {
    border: none;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    background-color: #F19357;
    color: white;
    position: absolute;
    right: 6px;
    top: 9px;
}
.girl {
    position: absolute;
    width: 280.5px;
    height: 280.5px;
    left: 90px;
    top: 20px;
}
.mom {
    width: 280.97px;
    height: 280.1px;
    top: 203px;
   right: auto;
    position: absolute;
    left: 350px;
}
.dad {
    width: 280px;
    height: 280px;
    border-radius: 50px;
    left: 63px;
    position: absolute;
    top: 340px;
    bottom: 0;
    right: auto;
}
.images-Box .mom,
.images-Box .girl,
.images-Box .dad {
    border-radius: 50%;
}
.inputOuterBox select {
    display: inline-block;
    font-size: 18px;
    float: left;
    background: #FFFFFF;
    height: 45px;
    border: none;
    border-left: 1px solid #d3d3d3;
    margin-top: 6px;
    padding: 0px 5px;
    font-family: 'Inter';
    color: #2D3134;
    width: 140px;
    outline: none;
    box-shadow: inset 0 0 0 1px #fff !important;
}
.inputOuterBox img {
    display: inline-block;
    position: relative;
    align-items: center;
    padding: 11px 9px 9px 10px;
    width: 41.03px;
    height: 42px;
    background: #F19357;
    border-radius: 31px;

}
.homerightimgbox {
	margin-top: 20px;
}
@media only screen and (max-width:1200px){
.girl {
    position: absolute;
    width: 265.5px;
    height: 265.5px;
    left: 0px;
    top: 48px;
}
.para .sub-text{
    font-family: 'Inter';
    font-size:26px;
}
.mom {
    width: 265.97px;
    height: 265.1px;
    top: 203px;
    right: auto;
    position: absolute;
    left: 224px;
}
.dad {
    width: 270px;
    height: 270px;
    border-radius: 50px;
    left: -21px;
    position: absolute;
    top: 341px;
    bottom: 0;
    right: auto;
}
.homepage .borderinline {
    min-height: 720px;
}
}
@media only screen and (max-width:767px) {
    .para .sub-text{
        font-family: 'Inter';
        font-size:26px;
    }
    div.banner {
        padding-top: 30px;
    }
    .para {
        padding: 0 10px;
    }
    div.inputOuterBox {
        width: 95%;
        margin: 0 10px;
    }
}
@media only screen and (max-width:576px) {
    .para {
        padding: 0px;
    }
}