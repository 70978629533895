
.ourwhy-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
     text-align: center;
    color: #2D3134;
    margin-top:40px;
}
.ourwhy-para{

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #5B5F62;
}
.logo-iconourwhy{
    margin-left: 20px;
}
.image-1{
    margin-top: 24px;
}
.image-1,.image-2{
    margin-bottom: 20px;
}
.spanpara{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;

    color: #5B5F62;
}
.spanpara2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #5B5F62;
}
.paragraph-ourwhy{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin: 0px 10px;
    color: #5B5F62;
}
.logo-ourwhy{
     position:absolute;
    left: 774px;
    top: 501px;
}
.paragraph-ourwhy2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin: 0px 10px;
    color: #5B5F62;
}
.paragraph-div-ourwhy{
    display:flex;
    justify-content: space-between;
}
.circle1{
background-color: #265472;
margin:0 auto -30px;
}
.circle1,.circle2,.circle3,.circle4{
	 width: 126.18px;
    height: 125.28px;
	 color:white;
	text-align: center;
	border-radius: 100%;
	 padding: 37px 7px;
 }
.circle2{
background-color: #AAC0CE;
 margin-top: 20px;
padding: 37px 7px;
float: left;
}
.circle2box {
display: inline-block;
width: 100%;
}
.circle3{
	background-color: #F0B285;
	margin:-30px auto 0;
	}
.circle4{
background-color: #F19357;
margin-top: 20px;
float: right;
}
.circle-text{
  /* font-family: 'effra_trialregular'; */
  font-family: 'Rubik', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 15.5424px;
line-height: 22px;
}
.heart-center{
       /*  font-family: 'effra_trialregular'; */
font-family: 'Rubik', sans-serif;
font-style: normal;
font-weight: 700;
}
.heart-center strong,.heart-center span {
	display:block;
}
.heart-center strong {
 font-size: 25.904px;
line-height: 31px;
font-weight: 600;
}
.believe-container{
    display: inline-block;
    padding:155px 61px 0 61px;
    margin-top: 70px;
    width: 84%;
    text-align: center;
    min-height: 515px;
}
.text-con{
margin-top:130px;
}
.borderinline ul{
    list-style-type:disc;
}
.we-Believe{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
    color: #2D3134;
    margin-bottom: 27px;
    text-align: left;
}
.para-list-1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: left;
}
.para-list-2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: left;
}
.para-list-3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: left;
}
.para-list-4{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: left;
}
.para-list-5{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: left;
}
.image-logo{
     width: 40%;
    left: 50.572998px;
    bottom: 43.773438px;
    margin-top: 0px;
    border-radius: 0px;
    position: relative;
}
@media only screen and (max-width: 767px) {
.ourwhylistitems {
    padding-left: 15px;
}
.ourStory {
    padding-bottom:0;
}
}
@media only screen and (max-width: 480px) {
div.believe-container {
    width:100%;
}
div.believe-container {
    min-height:385px;
}
}