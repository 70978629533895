.header1{
    padding:21px 0px;
    box-shadow:0 3px 5px rgb(0 0 0 / 9%);
    background: #fff;
position: relative;
    z-index: 1111;
  }
  .header-searchbar{
max-width: 393px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
    border-radius: 100px;
    width: 100%;
   position:relative;
   margin-left: 40px;
}
.value{
    width: 84%;
    height: 46px;
    outline: none;
    border: none;
    padding: 1px 10px 1px 10px;
    float: left;
    margin: 0 9px;
    box-sizing: border-box;
    border-radius: 24px;
	font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    color: #2D3134;
}
.value::placeholder {
	color: #65676B;
}
.icon-div{
    width: 40px;
    height: 40px;
    background: #F19357;
    box-shadow: 0px 1px 2px rgb(31 41 55 / 8%);
    border-radius: 100px;
    position: absolute;
    bottom: 3px;
    right: 6px;
    border: none;
}
.iconsearch{
    color: white;
    font-size: 20px;
}
.well-icon{
    margin-top: 10px;
    color: #2D3134;
    margin-right: 20px;
    font-size: 25px;
	position: relative;
	cursor:pointer;
}
.well-icon span{
	cursor:pointer;
}
.left-div{
    display: flex ;
    float: right;
}
.accountBox {
	cursor:pointer;
}
.user-img{
    height: 52px;
    width: 52px;
    border-radius: 50%;
    object-fit: cover;
}
.jane-deo{
   /*  width: 82px;*/
    height: 22px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-top: 16px;
    margin-right:15px;

}
.acc-buttun{
    position: absolute;
    right: 4px;
    top: 33px;
    font-size: 21px;
    background: none;
    border: none;
}

.dropdown-link{
       right: 10px;
    position: absolute;
    top: 74px;
    z-index: 100;
    border: 1px solid #e5e9f2;
    box-shadow: 0 3px 12px 1px rgba(44, 55, 130, 0.15);
    max-width: 215px;
    width: 100%;
    padding: 0px;
  border-radius:8px;
	background:#fff;
	overflow: hidden;
}


.down-top{
    margin-top: 20px;
}
.dropdown-link ul li {
  position: relative;
    border-radius: 0;
    font-size: 16px;

    margin-top: 0;
    color: #000;
    font-weight: 500;
    border-bottom: 1px solid #cdcdcd;
}
.dropdown-link ul li a{
  color: #000;
    text-decoration: none;
	 padding: 10px 0;
	  display: block;
	 text-align: left;
    padding-left: 20px;
}
.dropdown-link ul li a:hover{
     color:#F19357;
	 padding: 10px 0;
	 display: block;
    color: #fff;
	padding-left: 20px;
    background: #F19357;

}
.down-top{
    margin-top: 20px;
}

.noti{
	font-size: 13px;
    position: absolute;
    background: #F19357;
    color: #fff;
    min-width: 25px;
    line-height: 25px;
    border-radius: 24px;
    line-height: 24px;
    top: -19px;
    right: -15px;
    text-align: center;
    padding: 1px 1px;
}
.open .mystyle,
.open .mystyles {
  position: fixed;
    left: 0;
    top: 0;
    width: 100%;
     background: rgba(0,0,0,0.0);
    height: 57%;
    z-index: 0;
}
.headerscroll {
 transition: height 1s ease-in-out;
}
.headerfix {
	position:fixed;
	left:0;
	top:0;
	width:100%;
	transition: height 1s ease-in-out;
}

@keyframes smoothScroll {
	0% {
		transform: translateY(-30px);
	}
	100% {
		transform: translateY(0px);
	}
}

.login-helpcenter-header{
  float: right;
    padding-right: 25px;
}
.login-helpcenter-header{
    padding-top: 10px;
    font-size: 18px;
    font-family: 'Inter';
    font-weight: bold;
    }
.login-helpcenter-header h4 a{
   color: #000;
    text-decoration: none;
}
.login-helpcenter-header h4 {
	padding-top: 6px;
    font-weight: 600;
}
@media only screen and (max-width:1200px){
    .header1 .jane-deo{
        margin-right: 0px;
    }
    .header1 .well-icon{
        margin-right: 15px;
    }
}
@media only screen and (max-width:767px){
    .Imglogo {
		width: 140px;
	   float: left;
	}
	.dropdown-link {
        right:10px;
        top:50px;
    }
	.header1 {
      padding: 10px 0px;
   }
     .well-icon {
      margin-right: 13px;
      margin-top: 4px;
    }
	.jane-deo {
    margin-right: 0px;
    text-align: center;
    margin-top: 6px;
	font-size: 12px;
   }
   .accountBox .user-img {
    height: 35px;
    width: 35px;
  }
  .header-searchbar {
		margin:0 auto;
	}
	.header1 .left-div .modal-container {

      filter: none;
	  box-shadow: 0px 1px 5px #cdcdcd;
	  top: 60px;
   }
   div.login-helpcenter-header {
      padding-top:0;
   }
   .header-center{
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media only screen and (max-width:480px){
    .header1 .left-div .modal-container {
		top: 47px;
        /* bottom: 0; */
	}
   .left-div .well-chat-icon + .modal-container {
    position: absolute;
    top: 44px;
    bottom: 0;
   }
.dropdown-link {
        right:5px;
        position: absolute;
        max-width:250px;
        top:50px;
    }
	.dropdown-link ul li {
       font-size: 14px;
	}
	.dropdown-link ul li a {
       padding-left: 10px;
    }
	div.modal-container2 {
		top:47px;
	}

    .left-div .well-chat-icon + .modal-container {
        position: absolute;
        top: 44px;
        bottom: 0;
       }

       .header1 .container-fluid .left-div,
       .header1 .container-fluid a.Imglogo{
        float: left;
       }

       .header1 .container-fluid .login-helpcenter-header{
        position: absolute;
        right: 0;
        top: -47px;
       }
}
