.Ticket-page .marginboottom{
    /* display: none; */
}
.ticket-filter-page .ticket-page-inner{
    display: flex;
    justify-content: space-between;
margin-top: 50px;
margin-bottom: 50px;
}
.ticket-filter-page .filter-inner{
    display: flex;
}
.ticket-filter-page .filter-inner-button button{
 font-size: 20px;
   width: 185px;
   height: 50px;
    line-height: 22px;
    font-family: 'Inter';
    text-align: center;
    border: none;
    background: #F19357;
    color: #ffffff;
	border-radius: 50px;
    font-weight: 600;
    padding: 10px 15px;
}

.ticket-filter-page .ticket-page-head h3{
font-size: 30px;
line-height: 36px;
font-weight: 700;
font-family: 'Inter'
}
.ticket-filter-page .filer-inner-div{
     display: flex;

    height: 50px;
    border: 1px solid #D7D2D2;
    border-radius: 8px;
   margin-right: 30px;
    background: #fff;cursor:pointer;
	border-radius: 50px;
    font-weight: 600;
    padding: 10px 15px;
}
.ticket-filter-page .filer-inner-div img{
    width: 35px;
    height: 31px;
}
.ticket-filter-page .filer-inner-div h4{
   font-size: 18px;
   margin: 2px 17px;
    color: #000;

}
.ticket-filter-page .ticket-filer-content{
    border: 1px solid #D7D2D2;
    width: 100%;
   margin-bottom:50px;
}

.ticket-head-name h4{
    text-align: center;
}
.table-div table{
    border: hidden;
}
.table-div{
    margin-top: 30px;
}
.ticket-filer-content{
    background-color: #ffffff;
    border-radius: 8px;
}

.table-div table tr{
    border-bottom: 1px solid #D9D9D9;
}
.Ticket-page .table-div table tr th{
font-size: 18px;
padding: 0px 15px 30px 15px;
    text-align: center;
    font-weight: 700;
    line-height: 20px;
    font-family: 'Inter';
    color: #000000;
border: none;
}
.table-div table tr td{
    padding: 15px;
    font-size: 18px;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
        font-family: 'Inter';
        color: #000000;
        border: none
        ;
    }
.open-div-tacket{
    text-align: center;

}
.close-div-tacket button,
.open-div-tacket button,
.new-div-tacket button,
.pending-div-tacket button
{
color: white;
border: none;
padding: 3px 8px;
border-radius: 8px;
font-size: 16px;
line-height: 20px;
font-weight: 500;
font-family: 'Inter';
}
.open-div-tacket button{
    background: #EF1313;
    }
    .close-div-tacket button{
       background-color: #1B9607;
        }
        .pending-div-tacket button{
            background-color: #567fee;
             }
             .new-div-tacket button{
                background-color: #eeb956;
             }
.icon-tacket-list{
   text-align: center;
}
.icon-tacket-list i{
        margin: 5px;
    color: #6f6d6d;
}
div.ticket-popup.ticketpopupClose {
	display:none;
}

.table-div thead,.table-div thead tr,.table-div tbody tr,
.table-div tbody {
	display: block;
	width:100%;
}
.table-div thead th,.table-div tbody td{
	display: inline-block;
}
.table-div tbody {
    max-height: 350px;
    overflow-y: auto;
    height: 100%;
}
.Ticket-page .pop-up-container{
        padding: 30px 22px 0px;
        margin: 0 auto;
    }
	.ticket-detail-text img {
		margin-right:5px;
	}
	.filter-inner select {
		width: 185px;
    margin-right: 15px;
	height: 50px;
	}
   .ticket-list-popup .ticket-upload-page {
	  display: inline-block;
	width:100%;
   }
   .ticketSortbutton {
	   background:transparent;
	   border:none;
   }
   .duplod1 .dp {
	   font-weight:400;
   }
    .duplod1 .dp button p {
		font-weight:bold;
	}
@media only screen and (max-width:767px) {
	.ticket-filter-page .ticket-page-inner {
       display: inline-block;
	}
	  div.table-div {
       overflow-x: auto;
     }
     div.table-div table {
         width: 1000px;
        }
	.ticket-filter-page .filter-inner-button button,
	.filter-inner select {
        width: 165px;
		margin-top:10px;
     }
}