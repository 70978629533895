.ticket-detail .marginboottom {
   /*  display: none; */
}
.ticket-detail .ticket-detail-page .ticket-detail-page-content h3 {
    font-size: 30px;
    font-family: 'Inter';
    line-height: 36px;
    padding-bottom: 20px;
}
.ticket-detail-page-content button {
    background-color:#F19357;
    font-size: 16px;
    border: none;
    font-weight: 700px;
    font-family: 'Inter';
    line-height: 36px;
    color: #ffffff;
    border-radius: 10px;
}
.ticket-detail-page-content button.ticketgoback {
    border-radius: 50px;
    padding: 0px 15px;
}
.ticket-detail .ticket-detail-date h6 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}
.ticket-detail .ticket-detail-inner-page {
    width: 100%;
    background-color: #ffffff;
    overflow: hidden;
    /* overflow-y: auto;
    max-height: 400px; */

}
.ticket-detail-inner-pageScroll {
	display:inline-block;
	width:100%;
	max-height: 320px;
    overflow: hidden;
    overflow-y: auto;
}
.ticket-detail .scroll-ticket-detail {
    overflow-y: auto;
    max-height: 600px;
}
.ticket-detail .ticket-detail-image img {
    width: 60px;
    height: 60px;
}
.ticket-detail .ticket-detail-date {
    float: right;
}
.ticket-detail .ticket-detail-text h4 {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    color: #000000;
}
.ticket-detail .ticket-detail-text p,
.ticket-detail .ticket-detail-text h5 {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #353535;
}
.ticket-detail .ticket-detail-text h5 {
    font-size: 16px;
}
.ticket-detail .ticket-detail-text h5 {
    padding-bottom: 10px;
}
.ticket-detail .ticket-detail-text h4 {
    padding-bottom: 5px;
}
.ticket-detail .ticket-detail-inner-page-content {
    border-top: 1px solid #e0e0e0;
    padding: 15px 15px;
}
.ticket-detail .ticket-detail-textarea {
    padding-bottom: 5px;
}
.ticket-detail .ticket-detail-textarea textarea {
    width: 100%;
    height:125px;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
}
.ticket-detail .ticket-detail-textarea textarea::placeholder {
    font-size: 16px;
    font-family: 'Inter';
    font-weight: 400px;
    line-height: 22px;
    columns: #000000;
}
.ticket-popup .upload-image-ticket,
.upload-image-ticket {
    height: 52px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 100px;
}
.upload-image-ticket {
    display: inline-block;
    width: 100%;
}
.ticket-popup .ticket-thumbuploadImgz,
.ticket-thumbuploadImgz {
    padding: 10px 15px 0px 15px;
}
.ticket-popup .ticket-upload-scroll,
.ticket-upload-scroll {
    /* overflow-y: auto; */
    padding: 0;
    position: relative;
}

.ticket-popup .ticket-u-content,
.ticket-u-content {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5b5f62;
    padding: 0px 0px 0px 5px;
    display: flex;
}
.ticket-popup .ticket-u-content i,
.ticket-u-content i {
    font-size: 25px;
    padding-right: 15px;
    position: relative;
    margin-top: 2px;
    float: left;
}
.ticket-popup .ticket-u-content span,
.ticket-u-content span {
    line-height: 26px;
    overflow: hidden;
    overflow-y: auto;
    max-height: 30px;
    display: inline-block;
    width: 94%;
}
.ticket-popup .ticket-upload-scroll input,
.ticket-upload-scroll input {
    opacity: 0;
    width: 100%;
    height: 45px;
    bottom: 35px;
    cursor: pointer;

}
.ticket-popup .ticket-upload-scroll input {
    position: absolute;
    top: -6px;
    left: 0;
    height: 41px;
    width: 100%;
}
.ticket-detail-button button {
    width: 170px;
    height: 51px;
    font-family: 'Inter';
    font-size: 22px;
    line-height: 20px;
    font-weight: 600;
    color: #ffffff;
    background-color: #F19357;
    border: none;
    border-radius: 50px;
}
.ticket-detail .ticket-detail-button {
    float: right;
}
.ticket-detail .add-more-detail h2 {
    font-size: 20px;
    line-height: 22px;
    font-family: 'Inter';
    font-weight: 600;
    margin: 25px 0px 50px 100px;
}
.ticket-detail .ticket-submit-button {
    text-align: right;
    display: inline-block;
    width: 100%;
    padding: 15px 0 30px;
   /*  margin-top: 25px; */
}
.ticket-detail .ticket-submit-button button {
    background-color: #265472;
    color: white;
    width: 196px;
    height: 52px;
    font-family: 'Inter';
    align-items: center;
    font-size: 20px;
    line-height: 28px;
    border-radius: 50px;
    font-weight: 600;
    border: none;
}
.ticket-detail .ticket-detail-page {
    padding-top: 50px;
    padding-bottom: 30px;
}
.ticketfile-pupup-button button {
    font-size: 18px;
    line-height: 22px;
    font-family: 'Inter';
    color: white;
    background-color: #F19357;
    border: none;
    width: 140px;
    height: 51px;
    border-radius: 50px;
}

.ticket-detail .pop-up-container .input-popup2 {
    text-align: center;
}
.ticket-detail .duplod1333 .float-end span i{
        font-size: 17px;
    }
 .ticket-detail .ticket-detail-scroll-image{
    overflow-y: auto;
    max-height:144px;
 }
@media screen and (max-width:1200px) {
    .ticket-detail .ticket-detail-button {
        padding-top: 30px;
    }
     .ticket-detail-button button {
        width: 130px;
        height: 40px;
        font-size: 16px;
    }
     .ticket-detail .ticket-submit-button button {
        width: 190px;
        height: 40px;
        font-size: 20px;
    }
}
@media screen and (max-width:480px) {
    .ticket-detail .ticket-detail-button {
        padding-top: 30px;
    }
    .ticket-detail-button button {
        width: 130px;
        height: 40px;
        font-size: 16px;
    }
     .ticket-detail .ticket-submit-button button {
        width: 190px;
        height: 40px;
        font-size: 20px;
    }
	.ticket-detail .ticket-detail-page div.ticket-detail-page-content h3 {
      font-size: 24px;
	}
	.ticket-detail-date {
		margin-top:15px;
	}
}