.ourStory {
    padding: 50px 0 90px 0;
}

.storyInnerBox {
    text-align: left;
    padding: 25px 0 0 50px;
}
.storyInnerBox ul {
    max-height: 265px;
    overflow-y: auto;
    height: 100%;
    padding-left: 20px;
}
.storyInnerBox h4 {
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
    font-family: 'Inter';
    color: #2D3134;
    margin-bottom: 15px;
}
.storyInnerBox h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #AAC0CE;
}
.storyInnerBoxparainfo {
	 max-height: 270px;
    overflow-y: auto;
}
.storyInnerBox p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #5B5F62;
    font-family: 'Inter';
	margin-bottom:15px;
}

.storyInnerBox li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5B5F62;
    font-family: 'Inter';
}
