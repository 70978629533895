.tobarbackcontee .gobackcontainer {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  align-items: center;
  color: #2D3134;
  text-align: left;
}
.tobarbackcontee .gobackcontainer .jackey {
  margin-right: 10px;
  width: 138px;
  height: 32px;
  background: #F3F3F0;
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  border: none;
  color: #2D3134;
}
.tobarbackcontee .gobackcontainer h5 span { margin-right: 8px;}
.tobarbackcontee .gobackcontainer h5 span i {
  font-size: 19px;
}
.tobarbackcontee .jackey:focus-visible {
  outline: none;
  box-shadow: none;
}
.sprash-name-rt .invoicebtn {
  width: 140px;
}
.servepaymenthistory .sprash-name-rt .invoicebtn,
.sprash-name-rt .invoicebtn {
  font-family: 'Rubik', sans-serif;
  padding: 8px 8px;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2D3134;
  cursor: pointer;
}
.servepaymenthistory .sprash-name-rt .invoicebtn{
  width: 80px;
}
.bookmoniterdiv {
  text-align: left;
  margin-top: 30px;
  float: left;
  width: 100%;
}
.bookmoniterdiv h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #2D3134;
  padding-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 10px;
}
.bookmoniterdivbox {
  float: left;
  padding-bottom: 65px;
  width: 100%;
  text-align: left;
  margin-top: 0px;
}
.bookmoniterdivbox ul {
  margin: 0;
  padding: 0;
}
.bookmoniterdivbox ul li {
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  padding-bottom: 22px;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 22px;
}
.bookmoniterdivbox ul li .sprash-name {
  width: 24%;
  float: left;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-lt {
  float: left;
  width: auto;
  margin-right: 10px;
}
.servepaymenthistory .bookmoniterdivbox ul li .sprash-name .sprash-name-lt {
  width: 88px;
}
.bookmoniterdivbox ul li .sprash-name-rt {
  float: left;
  width: 76%;
  margin-top: 0;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-lt img {
  width: 69px;
    height: 69px;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-rts {
  margin-top: 8px;
  padding-right: 15px
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-rts h3 {
  font-family: 'Inter';
  font-style: normal;
  padding-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
  letter-spacing: 0.01em;
  color: #2D3134;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-rts p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  letter-spacing: 0.01em;
  color: #949698;
}
.bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #000000;
}
.bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #949698;
}
/*** ProfileUpcomingAppointmentsDetails page start ****/
.locationsinformation {
  margin-top: 32px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 12px;
}
.locationsinformation .locationsininner {
  float: left;
  width: 100%;
}
.locationsinformation .locationsininner h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 24px;
}
.locationsinformation .locationsininner p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 35px;
}
.locationsinformation .locationsininner p span {
  display: block;
}
ul.reschedule {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul.reschedule li {
  border-bottom: none;
  margin-bottom: 20px;
  padding-bottom: 0;
  margin-top: 0;
  padding-top: 0;
}
ul.reschedule li a {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #000000;
  text-decoration: none;
}
.locationsinformation .locationsininner p a {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 0;
  word-wrap: break-word;
}
/*** ProfileUpcomingAppointmentsDetails page start ****/
.resourcetypess span {
  margin-right: 13px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2D3134;
}
.resourcetypess {
  float: left;
}
.servepaymenthistory .resourcetypess,
.allchildSelect {
  float: right;
}
.allchildSelect {
  margin-left: 10px;
}
.borderbottomss h2 {
  border-bottom: none;
}
.borderbottomss {
  border-bottom: 1px solid #d9d9d9;
  float: none;
}
.resourcetypess select.restype {
  margin-top: 0px;
  border: 0.21px solid #BFBCB8;
  background-color: #fff;
  border-radius: 32.5px;
  padding: 5px 12px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  color: #2D3134;
  min-width: 155px;
  height: 31px;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-lt .iconcolor {
  background: #F19357;
  border-radius: 16px;
  width: 60px;
  height: 60px;
  float: left;
  padding: 4px;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-lt .iconcolor img {
  width: 42px;
  height: 42px;
  text-align: center;
  margin: 0px auto;
  display: block;
  float: none;
  object-fit: scale-down;
  padding-top: 3px;
  border-radius: 0;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-lt .purple {
  background: #265472;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-lt .blues {
  background: #AAC0CE;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-lt .green {
  background-color: #57886C;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-lt .yellow {
  background: #F9C976;
}
.bookmoniterdivbox ul li .sprash-name .sprash-name-lt .bluesky {
  background: #91CBCE;
}
.servepaymenthistory .menroginturorsds .tobarbackcontee .gobackcontainer {
  margin-top: 0;
}
.servepaymenthistory .bookmoniterdivbox ul li .sprash-name .sprash-name-rts {
  padding-left: 100px;
}
.servepaymenthistory .bookmoniterdivbox ul li {
  margin-bottom: 0px;
  padding-top: 15px;
}
.servepaymenthistory .input-popup1 .form-select,
.servepaymenthistory .input-popup3 .form-select,
.servepaymenthistory .input-popup2 .form-select {
  height: 45px;
}
@media screen and (max-width: 820px) {
  .marginboottom {
    margin-bottom: 0;
  }
.bookmoniterdivbox ul li .sprash-name .sprash-name-rts h3 {
    font-size: 14px;
  }
.bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box h4 {
    font-size: 14px;
  }
.bookmoniterdiv {
    padding: 0px 15px;
  }
.resourcetypess {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .bookmoniterdiv h2 {
    font-size: 16px;
    padding-bottom: 8px;
  }
.bookmoniterdivbox ul li .sprash-name {
    width: 100%;
    float: left;
  }
.bookmoniterdivbox ul li .sprash-name-rt {
    width: 100%;
  }
.bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box {
    width: 50%;
    float: left;
    margin-right: 0;
    margin-bottom: 11px;
    margin-top: 14px;
  }
.bookmoniterdivbox ul li .sprash-name .sprash-name-rts p {
    font-size: 14px;
  }
.tobarbackcontee .gobackcontainer {
    margin-top: 10px;
  }
.tobarbackcontee .gobackcontainer h5 {
    font-size: 15px;
    width: 100%;
    float: left;
  }
.bookmoniterdiv {
    margin-top: 30px;
  }
.invoicebtn {
    font-size: 16px;
  }
.locationsinformation .locationsininner h3 {
    margin-bottom: 12px;
  }
.locationsinformation .locationsininner p {
    margin-bottom: 24px;
  }
 ul.reschedule {
    margin-top: 20px;
  }
.locationsinformation .locationsininner p {
    font-size: 14px;
  }
ul.reschedule li a {
    font-size: 14px;
  }
ul.reschedule li {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
.resourcetypess {
    margin-bottom: 15px;
  }
}
@media screen and (max-width:480px) {
  .servepaymenthistory .css-10tlgzi {
    width: 100%;
  }
.tobarbackcontee .gobackcontainer .jackey {
    width: 165px;

  }
.bookmoniterdiv .resourcetypess select {
    width: 165px;
  }
   .bookmoniterdiv {
    padding: 0px;
  }
.invoicebtn {
    padding: 8px 10px;
  }
}
.session-history-content {
  margin-top: 63px;
  padding-left: 56px
}
.e-edit,
.e-delete {
  display: none !important;
}
.e-toolbar-pop {
  left: 0px !important;
}
.e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  width: 31px !important;
  background-color: #F19357 !important;
  margin-left: 0px !important;

}
.e-calendar .e-content td:hover span.e-day {
  width: 30px !important;
  border-radius: 50% !important;
  /*  margin-left: -2px !important; */
}
.e-toolbar-items {
  background-color: #F19357 !important;
}
.e-toolbar .e-toolbar-item .e-tbar-btn {
  background-color: transparent !important;
}
.e-icon-prev,
.e-icon-next,
.e-tbar-btn-text,
.e-icon-down-arrow {
  color: white !important;

}
/* syncfusin css */
.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td {
  vertical-align: bottom;
}
.timeline-resource.e-schedule.e-device .e-timeline-view .e-resource-left-td {
  width: 75px;
}
.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td {
  width: 160px;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
  display: flex;
  font-weight: 500;
  padding: 0;
  height: 36px;
}
.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  border-color: #0000001f;
  border-style: solid;
  border-width: 1px 1px 0 0;
  flex: 0 0 100%;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding-left: 5px;
}
.timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div:last-child {
  border-right: 0;
}
.timeline-resource.e-schedule .e-timeline-view .e-block-appointment {
  align-items: center;
  display: flex;
  justify-content: center;
}
.bootstrap4 .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5 .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
  height: 37px;
}
.bootstrap4 .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  height: 37px;
  line-height: 37px;
}
.material-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap>div,
.material-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  border-color: #616161;
}
.fabric-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap>div,
.fabric-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  border-color: #414040;
}
.fluent-dark .e-schedule .e-resource-cells .template-wrap>div,
.fluent-dark .e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  border-color: #292827;
}
.bootstrap-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap>div,
.bootstrap-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  border-color: #505050;
}
.bootstrap5-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap>div,
.bootstrap5-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  border-color: #444c54;
}
.tailwind-dark .timeline-resource.e-schedule .e-resource-cells .template-wrap>div {
  border-color: #4b5563;
}
.highcontrast .timeline-resource.e-schedule .e-resource-cells .template-wrap>div {
  border-color: #969696;
}
.bootstrap4.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.bootstrap5.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
  height: 39px;
}
.bootstrap4.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  height: 39px;
  line-height: 39px;
}
.tailwind .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.tailwind-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
  height: 39px;
}
.tailwind .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div,
.tailwind-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  height: 39px;
  line-height: 39px;
}
.tailwind .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  background-color: #f3f4f6;
  border-color: #e5e7eb;
}

.tailwind-dark .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  background-color: #374151;
  border-color: #4b5563;
}

.tailwind.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text,
.tailwind-dark.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
  height: 40px;
}

.tailwind.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div,
.tailwind-dark.e-bigger .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  height: 40px;
  line-height: 40px;
}

.highcontrast .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
  height: 36px;
}

.highcontrast .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div {
  border-color: #969696;
}

.timeline-resource.e-schedule .template-wrap {
  display: flex;
  height: 100%;
  text-align: left;
}

.timeline-resource.e-schedule .template-wrap>div {
  border-right: 1px solid #0000001f;
  flex: 0 0 100%;
  font-weight: 500;
  line-height: 58px;
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
}

.timeline-resource.e-schedule .template-wrap>div:last-child {
  border-right: 0;
}

.timeline-resource.e-schedule .e-timeline-view .e-resource-cells,
.timeline-resource.e-schedule .e-timeline-month-view .e-resource-cells {
  padding-left: 0;
}

.timeline-resource.e-schedule .e-timeline-view .e-date-header-wrap table col,
.timeline-resource.e-schedule .e-timeline-view .e-content-wrap table col {
  width: 100px;
}

.timeline-resource.e-schedule .e-read-only {
  opacity: .8;
}

.group-sale {
  display: flex;
  justify-content: space-between;
}

.group-sale h1 {
  font-size: 22px;
}

.servepaymenthistory .bookmoniterdiv .select-exp-div {
  background: #ffffff;
  border: 0.21px solid #bfbcb8;
  border-radius: 32.5px;
  padding: 5px 6px 3px 12px;
  width: 155px;
  height: auto;
  float: left;
  margin: 0 0 10px 8px;
}

.servepaymenthistory .bookmoniterdiv .resourcetypess .form-select,
.servepaymenthistory .bookmoniterdiv .tobarbackcontee .gobackcontainer .form-select {
  background-image: none;
  width: 100%;
  min-width: 155px;
  height: auto;
  padding-right: 0px;
}

.servepaymenthistory .bookmoniterdiv .menroginturorsdxs .react-datepicker-wrapper .react-datepicker__input-container input::placeholder {
  color: #000000;
}

.servepaymenthistory .menroginturorsds {
  width: 69%;
  margin-left: 60px;
}

@media (max-width: 550px) {
  .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td {
    width: 100px;
  }

  .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div,
  .timeline-resource.e-schedule .e-resource-cells .template-wrap>div {
    flex: 0 0 100%;
  }

  .timeline-resource.e-schedule .e-resource-cells .template-wrap>div:first-child {
    border-right: 0;
  }

  .timeline-resource.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text>div:first-child {
    border-right: 0;
  }

  .timeline-resource.e-schedule .type,
  .timeline-resource.e-schedule .capacity {
    display: none;
  }
}

.e-edit,
.e-delete,
.e-description,
.e-popup-footer {
  display: none !important;
}
.quick-info-header {
  background-color: white;
  padding: 5px 5px;
}

.quick-info-header-content {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px;
}

.duration-text {
  font-size: 11px;
  letter-spacing: 0.33px;
  height: 14px;
}

.content-area {
  margin: 0;
  padding: 10px;
  width: 100%;
}

.event-content {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
}

.meeting-type-wrap,
.meeting-subject-wrap,
.notes-wrap {
  font-size: 11px;
  color: #666;
  letter-spacing: 0.33px;
  height: 24px;
  padding: 5px;
}

.event-content div label {
  display: inline-block;
  min-width: 45px;
  color: #666;
}

.event-content div span {
  font-size: 11px;
  color: #151515;
  letter-spacing: 0.33px;
  line-height: 14px;
  padding-left: 8px;
}

.cell-footer .e-btn {
  background-color: #ffffff;
  border-color: #878787;
  color: #878787;
}

.cell-footer {
  padding-top: 10px;
}

.cancelbuttonflex1 {
  padding: 0px 75px;
  display: flex;
  padding-top: 0px !important;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content {
  padding: 0 14px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer {
  display: block !important;
}

.e-quick-popup-wrapper .e-popup-footer button:first-child {
  margin-right: 5px;
}

.e-quick-popup-wrapper {
  max-width: 332px !important;
  min-width: 332px !important;

}

.e-quick-popup-wrapper div.e-event-popup .e-popup-content {
  padding: 0px 8px 0px 0px;
}

.list-group-item {
  padding: 0.3rem 0.5rem !important;
}

.mat-typography p {
  margin: 0 0 5px !important;
}


legend {
  padding-left: 10px;
  width: 100%;
  padding: 10;
  margin-bottom: .5rem;
  font-size: calc(0.9rem);
}

.border {
  border: 1px solid #d9dee2 !important;
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.btn-sm,
full-calendar.fc .fc-button-primary,
.btn-group-sm>.btn,
.swal2-container .swal2-popup .swal2-actions .btn-group-sm>button {
  padding: 0.291rem 0.2rem !important;
  font-size: 0.790rem !important;
  border-radius: 0.25rem !important;
}

/* //extra css */

.quick-info-header {
  padding: 8px 18px;
  display: inline-block;
  color: white;
  background-color: rgb(241, 147, 87);
  width: 100%;
}

.quick-info-header-content {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px;
}

.quick-info-content {
  margin: 30px 0px -5px;
}

.quick-info-title {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: white;
  scrollbar-width: thin;
  margin-right: -25px;
}

.reschedulepopup h3:first-letter,
.quick-info-title:first-letter {
  text-transform: uppercase;
}

.duration-text {
  font-size: 11px;
  letter-spacing: 0.33px;
  height: 14px;
}

.content-area {
  margin: 0;
  padding: 10px;
  width: auto;
}

.event-content {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
}

.meeting-type-wrap,
.meeting-subject-wrap,
.notes-wrap {
  font-size: 14px;
  color: #666;
  letter-spacing: 0.33px;
  height: 30px;
  padding: 5px;
}

.event-content div label {
  display: inline-block;
  min-width: 45px;
  color: #666;
}

.event-content div span {
  font-size: 14px;
  color: #151515;
  letter-spacing: 0.33px;
  line-height: 20px;
  padding-left: 8px;
}

.cell-footer .e-btn {
  background-color: #ffffff;
  border-color: #878787;
  color: #878787;
}

.cell-footer {
  padding-top: 10px;
}

.e-quick-popup-wrapper .e-cell-popup .e-popup-content {
  padding: 0 14px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-footer {
  display: block !important;
}

.e-quick-popup-wrapper .e-popup-footer button:first-child {
  margin-right: 5px;
}
 .chat-popup {
  position: fixed;
  top: auto;
  left: auto;
  transform: none;
  z-index: 2222;
  bottom: 0;
  right: 0;
  width: 380px;
}

.sessionhistory .modal-cont .css-10tlgzi {
  max-width: 550px;
  padding: 0px;
  width: 100%;
}

.child-save {
  padding: 5px;
  width: 74px;
  line-height: 31px;
  height: 41px;
  display: block;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
}
.purchasepage #modal-modal-description .child-save{
	color: #000;
}
.purchasepage #modal-modal-description .child-save:hover{
	color: #fff;
}
button.child-save:hover {
    background: #F19357;
    color: #fff;
}

.bookmoniterdiv div.e-more-popup-wrapper .e-popup-close .e-lib .e-popup .e-control {
  z-index: 3;
  left: 261px;
  top: 120px;
}

.reschedulepopup {
  margin-bottom: 20px;
  text-align: left;
  background: #F19357;
  padding: 15px;
  border-radius: 16px 16px 0 0;
}

.reschedulepopup h3 {
  font-size: 18px;
  color: #fff;
}



.quick-info-title .fa-comment {
  font-size: 22px;
  line-height: 15px;
}

.e-time-wrapper .e-input-group-icon.e-icons.e-active,
.e-time-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active,
.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: #333 !important;
}

.servepaymenthistory div.e-schedule {
  overflow: visible;
}

.servepaymenthistory .css-4z9bxz-MuiPaper-root {
  background-color: transparent;
  box-shadow: none;
  border-bottom:1px solid #d9d9d9;
}

.servepaymenthistory .bookmoniterdiv .gobackcontainer select {
  background-color: #ffffff;
}

.servepaymenthistory .bookmoniterdiv {
  display: inline-block;
}
.servepaymenthistory .bookmoniterdivbox ul li .sprash-name .sprash-name-lt img {
  border-radius: initial;
  height:auto;
}

.servepaymenthistory .messanger-inner-div .edit-button span i {
  cursor: pointer;
}

.sessionhistory .quick-info-header {
  background-color: #265472;
}

.bookmoniterdiv .e-toolbar-items {
  background-color: #265472 !important;
}

.tobarbackcontee .gobackcontainer .form-select {
  background-image: none;
  width: 100%;
  min-width: 274px;
}

.tobarbackcontee .gobackcontainer .form-select div.multiSelectContainer div.search-wrapper input::placeholder {
  color: #000000;
}

.tobarbackcontee .gobackcontainer .form-select div.multiSelectContainer div.search-wrapper input {
  padding-top: 4px;
}

.tobarbackcontee .gobackcontainer .form-select div.multiSelectContainer div.search-wrapper .chip {
  padding: 12px 5px;
  font-size: 16px;
}

.tobarbackcontee .gobackcontainer .form-select div.multiSelectContainer div.search-wrapper img {
  position: absolute;
  right: 10px;
  top: 18px;
  transform: translateY(-50%);
  width: 14px;
}
.servepaymenthistory .bookmoniterdivbox .e-schedule-toolbar-container .e-toolbar-items .e-toolbar-right .e-today .e-tbar-btn-text,
.sessionhistory .bookmoniterdiv .e-schedule-toolbar-container .e-toolbar-items .e-toolbar-right .e-today .e-tbar-btn-text{
 display: none;
}
.profilepage .searchres-div11 .form-select .disable_ms{
  opacity: .9;
}
.servepaymenthistory .bookmoniterdiv .menroginturorsdxs .resourcetypess {
  width: 17%;
}
.servepaymenthistory .bookmoniterdiv .gobackcontainer .multiSelectContainer .search-wrapper .chip,
.servepaymenthistory .bookmoniterdiv .resourcetypess .multiSelectContainer .search-wrapper .chip {
  position: absolute;
  top: 3px;
}
.servepaymenthistory .bookmoniterdiv .react-datepicker-wrapper .searchDate,
.servepaymenthistory .bookmoniterdiv .resourcetypess .multiSelectContainer .search-wrapper input::placeholder {
  color: #000000;
  font-size:12px;
  font-weight:600;
}
.servepaymenthistory .bookmoniterdiv .resourcetypess .multiSelectContainer .search-wrapper input {
  position: absolute;
  bottom: -1px;
}
.sessionhistory .scrollmobile .picktimecalendaer .child-delete,
.sessionhistory .scrollmobile .picktimecalendaer .child-save{
  width: 112px;
  height: 44px;
  line-height:29px;
  font-size: 18px;
}
.bookasessionSelect {
	border: 1px solid #dee2e6;
    height: 52px;
    background: #fff;
    border-radius: 50px;
    padding: 15px 5px 10px 10px;
	margin-bottom:15px;
	width:225px;
	margin-left:5px;
}
.bookasessionSelect.sessionBookedhis .search-wrapper .chip{
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  width:90%;
}
.servepaymenthistory .saveResourceChildSelects .salesHistoryrightSelect .search-wrapper .chip{
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}
.servepaymenthistory .borderbottomss{
  border-bottom: none;
}
.servepaymenthistory .bookmoniterdiv .bookmoniterdivbox ul li{
  text-align: center;
}

.e-popup-footer .cancelbuttonflex{
  display: inline-block;
text-align: center;
}

.sessionhistory .sessionBookedhis .multiSelectContainer .optionListContainer ul li{
  white-space: nowrap;
 overflow: hidden;
  text-overflow: ellipsis;
}
 

.sessionhistory .reschedulepopup .resnamesec{
  max-height: 70px;
  overflow-y: auto;
  scrollbar-width: thin;
  margin-right: -10px;
}
.sessionhistory .e-event-popup .quick-info-header .quick-info-title{
  max-height: 100px;
  overflow-y: auto;
  word-break: break-word;
}

@media screen and (max-width:1200px) {
 
  .servepaymenthistory .bookmoniterdiv .menroginturorsdxs div.resourcetypess {
      width: 25%;
   }
   .servepaymenthistory div.bookmoniterdiv .bookmoniterdivbox ul li .sprash-name {
    width: 100%;
}
.servepaymenthistory div.bookmoniterdiv  .bookmoniterdivbox ul li .sprash-name-rt {
  width: 100%;
}
.servepaymenthistory div.bookmoniterdiv div.bookmoniterdivbox .sprash-name-rt .sprash-name-box h4 {
  text-align: left;
}
.servepaymenthistory div.bookmoniterdiv div.bookmoniterdivbox .sprash-name-rt .sprash-name-box p {
  text-align: left;
}
}
@media screen and (max-width:991px) {
  .servepaymenthistory .sprash-name-rt .invoicebtn {
    width: 85px;
  }
  .servepaymenthistory div.bookmoniterdiv .bookmoniterdivbox .sprash-name-rt .sprash-name-box h4 {
    text-align: left;
  }
  .servepaymenthistory div.bookmoniterdiv .bookmoniterdivbox .sprash-name-rt .sprash-name-box p {
    text-align: left;
  }
  .servepaymenthistory div.bookmoniterdiv .bookmoniterdivbox ul li .sprash-name {
    width: 100%;
}
.servepaymenthistory div.bookmoniterdiv  .bookmoniterdivbox ul li .sprash-name-rt {
  width: 100%;
}
  
}
@media screen and (max-width:767px) {
  .scrollmobile {
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
  }
.e-calendar {
    max-width: 100%;
  }

}
@media screen and (max-width:480px) {
  .bookmoniterdiv div.e-control {
    width: 100% !important;
  }
.bookmoniterdivbox .sprash-name-rt .sprash-name-box:last-child {
    display: flex;
    width: 70%;
  }
  .bookmoniterdivbox ul li .sprash-name-rt .sprash-name-box {
    width: 27%;
}
.servepaymenthistory .sprash-name-rt .invoicebtn {
    width: 75px;
  }
 .servepaymenthistory .bookmoniterdivbox ul li .sprash-name-rt {
    width: 95%;
  }
.servepaymenthistory .sprash-name-rt .invoicebtn,
  .sprash-name-rt .invoicebtn {
    font-family: 'Rubik', sans-serif;
    padding: 8px 7px;
    font-size: 18px;
  }
 .servepaymenthistory .bookmoniterdiv div.select-exp-div,
  .servepaymenthistory .servepaymenthistory div.resourcetypess,
  .servepaymenthistory .servepaymenthistory div.tobarbackcontee {
    width: 47%;
    float: left !important;
    margin-left: 8px;
  }
 .servepaymenthistory .bookmoniterdiv .menroginturorsdxs div.resourcetypess {
    width: 47%;
  }
 .servepaymenthistory .tobarbackcontee .gobackcontainer {
    width: 100%;
  }

.servepaymenthistory div.bookmoniterdiv .bookmoniterdivbox .sprash-name-rt .sprash-name-box h4 {
  text-align: left;
}
.servepaymenthistory div.bookmoniterdiv .bookmoniterdivbox .sprash-name-rt .sprash-name-box p {
  text-align: left;
}
.sessionhistory .tobarbackcontee .gobackcontainer {
  display: block;
}
.sessionhistory .tobarbackcontee  .saveResourceChildSelect{
  float: unset;
}
}
