.disabled-option {
    display: none;
}
.search-bar-div {
    background: #FFFFFF;
    border: 0.2px solid #265472;
    border-radius: 100px;
    display: flex;
    margin-bottom: 20px;
    position: relative;
}
.searchpage .img-icon .resource-img {
	padding:0;
}
.serveyour-resource .serveResourceInner .select-boxes-serv .input-btn-contain input{
    font-size: 14px;
    font-weight: 400;
}
div.search-wrapper .chip{
    font-size: 14px;
}
.center-div-search {
    background: #FFFFFF;
    border-radius: 19px;
}
.search-div1 {

    margin-top: 209px;
    margin-left: 17px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2D3134;
}
.search-no {
    margin-top: 190px;
    margin-left: 17px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: #2D3134;
}
.search-div1 h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36.31px;
    color: #5B5F62;
    margin-bottom: 10px;
}
.search-img-container {
    padding: 10px;
}
.search-div1 h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #5B5F62;
    width: 100%;
}
.image-s1 img {
    width: 187.32px;
    height: 187.32px;
    border-radius: 50%;
}
.image-s2 img {
    width: 187.32px;
    height: 187.32px;
    float: right;
    border-radius: 50%;
}
.image-s3 img {
    width: 180.34px;
    height: 180.4px;
    float: left;
    border-radius: 50%;
}
.image-s4 img {
    width: 180.34px;
    height: 180.4px;
    margin: 0 auto;
    border-radius: 50%;
}
.input-search-div {
    padding: 15px 15px 10px 25px;
    width: 23%;
    position: relative;
    border: 2px solid #fff;
    border-radius: 40px;
}
.search-bar-div .input-search-div:nth-of-type(1) .searchWrapper {
    overflow: hidden;
}
.select-exp-div:nth-of-type(7) .multiSelectContainer input[type="text"],
.select-exp-div:nth-of-type(6) .multiSelectContainer input[type="text"],
.select-exp-div:nth-of-type(5) .multiSelectContainer input[type="text"],
.select-exp-div:nth-of-type(4) .multiSelectContainer input[type="text"],
.select-exp-div:nth-of-type(3) .multiSelectContainer input[type="text"],
.select-exp-div:nth-of-type(2) .multiSelectContainer input[type="text"],
.select-exp-div:nth-of-type(1) .multiSelectContainer input[type="text"] {
    height: 22px;
}
.select-exp-div:nth-of-type(7) .multiSelectContainer input[type="text"]::placeholder,
.select-exp-div:nth-of-type(6) .multiSelectContainer input[type="text"]::placeholder,
.select-exp-div:nth-of-type(5) .multiSelectContainer input[type="text"]::placeholder,
.select-exp-div:nth-of-type(4) .multiSelectContainer input[type="text"]::placeholder,
.select-exp-div:nth-of-type(3) .multiSelectContainer input[type="text"]::placeholder,
.select-exp-div:nth-of-type(2) .multiSelectContainer input[type="text"]::placeholder,
.select-exp-div:nth-of-type(1) .multiSelectContainer input[type="text"]::placeholder {
    color: #65676B;
}
.input-search-div:before {
    border-right: 1px solid #E5E7EB;
    content: "";
    display: inline-block;
    position: absolute;
    right: -2px;
    top: 14px;
    height: 60%;
}
.input-search-div:nth-of-type(4):before {
    border-right: none;
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 14px;
    height: 60%;
}
.search-bar-div .input-search-div {
    border: 2px solid #fff;
    border-radius: 40px;
}
.input-search-div:hover {
    background: #FFFFFF;
    border-radius: 32.5px;
    padding: 15px 15px 0px 25px;
    border: 2px solid #F19357;
}
.searchpage .input-search-div:hover {
    padding-bottom: 10px;
}
.select-exp-div:hover {
    border: 1px solid #F19357;
}
.rightborder-0 {
    border-right: 0;
}
.input-search-div label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: block;
    align-items: center;
    color: #2D3134;
    text-align: left;
}
.input-search-div input[type="text"] {
    width: 173px;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #65676B;
    border: none;
    outline: none;
    padding: 0;
}
.search-bar-div button {
    display: block;
    width: 50px;
    height: 50px;
    background: #F19357;
    border-radius: 100%;
    position: absolute;
    bottom: 8px;
    right: 6px;
    border: none;
}
.search-bar-div button i {
    color: white;
    font-size: 20px;
    text-align: center;
}
.search-bar-div button {
    display: block;
    width: 50px;
    height: 50px;
    background: #F19357;
    border-radius: 100%;
    position: absolute;
    bottom: 8px;
    right: 6px;
    border: none;
    padding: 0px;
    margin: 0px;
    margin: 0 auto;
    text-align: center;
}
.input-search-div input::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #65676B;
}
.sermargin {
    margin-top: 50px;
    padding: 0 0px 0 40px;
}
.selects {
    background: none;
    outline: none;
    border: none;
    border-radius: none;
    border-style: none;
    border-width: none;
}
.background {
    background: #FFFFFF;
    border: 0.2px solid #BFBCB8;
    border-radius: 100px;
    display: flex;
    margin-bottom: 39px;
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF;
}
.input-search-div h3 {
    width: 185px;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #65676B;
    padding: 0;
    text-align: left;
}
div.searchWrapper {
    border: none;
    height: 0;
    padding: 0;
}
.search-wrapper .closeIcon {
    display: none;
}
.searchpage .searchres-div {
    width: 100%;
}
.search-wrapper .chip {
    background: transparent;
    border-radius: 11px;
    color: #000;
    display: inline-flex;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 2px;
    padding: 2px;
    margin-right: 1px;
    font-family: 'Inter';
    font-weight: 600;
}
.searchpage .search-wrapper .chip {
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    margin-bottom: 0;
}
.search-wrapper .chip+#search_input::placeholder,
.search-wrapper .chip+#search_input {
    font-size: 0;
}
#multiselectContainerReact .optionListContainer {
    position: absolute;
    left: 0;
    border-radius: 12px;
    border: 1px solid #ccc;
    padding: 5px;
}
.select-exp-div .searchWrapper {
    display: inline-block;
    width: 100%;
}
.search-exp-sec-div .select-exp-div {
    height: 34px;
}
.curriculam .select-exp-div .searchWrapper,
.search-exp-sec-div .select-exp-div .searchWrapper {
    overflow: hidden;
    height: 26px;
    overflow-y: auto;
}
.optionListContainer,
.multiSelectContainer ul {
    padding: 0px;
}
div.multiSelectContainer ul {
    border: none;
}
li.highlightOption,
div.multiSelectContainer li:hover {
    background: transparent;
    color: inherit;
}
div.multiSelectContainer li {
    padding: 5px 4px;
    font-family: 'Inter';
    font-size: 13px;
}
.notFound {
    font-family: 'Inter';
}
div.multiSelectContainer input[type="checkbox"] {
    accent-color: #F19357;
}
div.multiSelectContainer input[type="checkbox"]:checked:before {
    background: #ffffff !important;
}
.select-exp-div .multiSelectContainer input {
    margin-top: 0px;
}

/*================= Select Box Css ==================*/

.customdropdown {
    width: 96%;
    float: left;
    position: absolute;
    text-align: left;
    left: 0;
    padding-left: 15px;
    box-sizing: border-box;
    z-index: 2;
}

.customdropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.customdropdown ul li {
    width: 100%;
}
.customdropdown ul li a {
    text-align: left;
    color: #000;
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #65676B;
    border: none;
    outline: none;
    padding: 0 0 20px 0;
}
.customdropdown ul li:hover {
    background-color: #fff;
    color: #000;
}

.customdropdown .sub-menu-1 {
    display: none;
}

.customdropdown ul li:hover .sub-menu-1 {
    display: block;
    position: absolute;
    background-color: #000;
    color: #fff;
    top: 37px;
    left: -7px;
    transition: .5s;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 31px;
    z-index: 9999;
    width: 217px;
}
.customdropdown ul li:hover .sub-menu-1 ul {
    display: block;
    margin: 10px;
}
.customdropdown ul li:hover .sub-menu-1 ul li {
    width: 100%;

    background: transparent;
    border-radius: 0px;
    text-align: left;
    display: block;
}
.customdropdown ul li:hover .sub-menu-1 ul li a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    padding: 10px 10px;
    font-size: 15px;
    line-height: 18px;
    color: #5B5F62;
    display: block;
}

.customdropdown ul li:hover .sub-menu-1 ul li a:hover {
    background: #D9D9D9;
    opacity: 0.5;
    border-radius: 6px;
    color: #000;
}
.resourcetypecss {
    float: left;
    width: 100%;
    z-index: 99999;
    position: relative;
}
.loaderDataShowing {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    transform: translateY(-55%);
    position: absolute;
    top: 45%;
    display: block;
    text-align: center;
    width: 100%;
}
/*============= Switch Styling Here ============*/
.searchpage .switch {

    width: 45px;
    height: 20px;

}
.searchpage .slider:before {
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 2px;
}
.select-exp-div,
.select-exp-div input[type="date"] {
    font-family: 'Inter';
}
.select-exp-div input[type="date"] {
    font-family: 'Inter';
    min-height: 25px;
    margin-top: -3px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 18%;
    width: 100%
}
.multiSelectContainer input[type="checkbox"] {
    margin-right: 3px;
}
.search-exp-sec-div div.multiSelectContainer li {
    padding: 5px 0px 5px 0px;
    font-family: 'Inter';
    font-size: 11px;
}
.searchResultInnerBox {
    float: right;
    width: 84%;
}
.searchResultInnerBox .select-exp-div {
    width: 18.5%;

}
.searchpage .bookmark {
    margin-right: 0;
    margin-top: -16px;
}
.eligible-public-funding {
    min-height: 30px;
}
.keywordtextsearch {
    float: left;
}
.searchpage .sharepopupOuter .input-popup1,
.searchpage .sharepopupOuter .input-popup2,
.searchpage .sharepopupOuter .input-popup3 {
    position: relative;
}

.searchpage .sharepopupOuter .input-popup1 .error,
.searchpage .sharepopupOuter .input-popup2 .error,
.searchpage .sharepopupOuter .input-popup3 .error {
    position: absolute;
    top: auto;
    bottom: -15px;
    left: 0;
}
.searchpage .sharepopupOuter .input-popup1 .search-wrapper .chip,
.searchpage .sharepopupOuter .input-popup3 .search-wrapper .chip {
    font-size: 16px;
}
.searchpage .sharepopupOuter .input-popup3 div.searchWrapper {
    height: 30px;
    overflow: hidden;
    overflow-y: auto;
}
.curriculam {
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.curriculam .select-exp-div {
    height: 34px;
}
.text_capital::first-letter {
    text-transform: capitalize;
}
.pac-target-input::placeholder,
.searchDate::placeholder {
    color: #65676B;
}
.searchDate {
    line-height: 23px;
    border-radius: 24px;
}
.facustomeI {
    font-size: 15px;
    cursor: pointer;
    position: relative;
    float: right;
    color: #265472;
    width: 18px;
    height: 18px;
    text-align: center;
    background: #fff;
    border-radius: 50px;
    margin-left: 5px;
    border: 0.21px solid #bfbcb8;
}
.facustomeI .tootlippillerinfo {
    position: absolute;
    left: 30px;
    top: -8px;
    width: 266px;
    display: none;
    z-index: 2;
    background: #fff;
    padding: 10px;
    border-radius: 12px;
    color: #000;
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    box-shadow: 0px 0px 5px #bbb8b8;
}
.tootlippillerinfo:before {
    position: absolute;
    left: 0px;
    content: "";
    border-style: solid;
    border-color: transparent;
    border-top-color: #fff;
    border-width: 0.5em 0.5em 0 0.5em;
    transform: rotate(90deg) translate(30%, 100%);
    position: absolute;
    left: -3px;
    top: 10px;
}
.facustomeI:hover .tootlippillerinfo {
    display: block;
}
.facustomeI ul {
    padding-left: 15px;
}
.facustomeI ul li {
    margin-bottom: 5px;
    text-align: left;
}
.facustomeI ul li strong {
    padding-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
}
.searchpage .search-exp-sec-div .search-wrapper .chip {
    margin-top: 3px;
}
.searchpage .search-exp-sec-div .select-exp-sharedBy .search-wrapper{
	height:24px;
}
.searchpage .search-exp-sec-div .select-exp-sharedBy .search-wrapper .chip{
	width:93%;
}
.searchpage .removeScrollselectedcustomer .search-wrapper,
.searchpage .removeScrollselectedLocation .search-wrapper,
.searchpage .removeScrolldualSelected .search-wrapper,.searchpage .removeScrollselectedcustomer .search-wrapper,
.searchpage .removeScrollselectedAccredited .search-wrapper,
.searchpage .search-exp-sec-div .addScrollLearningPiller .search-wrapper{
 height:26px; 
}
.searchpage .removeScrollselectedcustomer .search-wrapper .chip,
.searchpage .removeScrollselectedLocation .search-wrapper .chip,
.searchpage .search-exp-sec-div .removeScrollLearningPiller .search-wrapper .chip{
	width:90%;
}
.searchResultInnerBox .select-exp-div .multiSelectContainer input.checkbox {
    display: inline-block;
}
.searchpage span.reviews,
.profilepage span.reviews {
    float: none;
}
.searchdata-withaddressDate {
    min-height: 200px;
}
.searchdata-withaddressDate .description {
    height: 80px;
    -webkit-line-clamp: 4;
}
.searchdata-withaddressDate .descriptionaddress {
    height: 40px;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.card-container-exp .viewableFriend h5 .viewablebyFriend-p {
    margin-bottom: 0;
}
.searchfriendlistview {
    position: relative;
}
.searchpage .searchfriendlistview {
    height: 42px;
	padding-top: 10px;
}
.searchfriendlistview .searchWrapper {
    z-index: 1;
}
.searchpage .singleSelect input::placeholder,
.searchpage .multiSelectContainer input::placeholder,
.searchpage .multiSelectContainer input::placeholder,
.searchpage .input-search-div input::placeholder {
    color: #000 !important;
}

.input-search-div input[type="text"],
.input-search-div input[type="text"]::placeholder {
    color: #000000 !important;
    font-weight: 400;
	font-size:12px;
}
.searchResultInnerBox {
    position: relative;
}

.searchResultInnerBox .facustomeI {
    position: absolute;
    right: -5px;
    top: 7px;
    line-height: 15px;
    font-family: Inter;
    font-weight: 500;
}
.searchResultInnerBox .facustomeI .tootlippillerinfo strong {
    display: inline-block;
    font-weight: 600;
}

.searchResultInnerBox .facustomeI .tootlippillerinfo {
    width: 345px;
    left: auto;
    right: 25px;
}

.searchResultInnerBox .facustomeI .tootlippillerinfo:before {
    transform: rotate(-90deg) translate(-22%, 100%);
    left: auto;
    top: 10px;
    right: -1px;
}
.searchfriendlistview div.multiSelectContainer li {
    display: flex;
}

.searchpage .curriculam .select-exp-div {
    width: 17%;
}

.createschedule .duration-text input::placeholder,
.createschedule .duration-text select,
.createschedule .createResourceFilters select,
.save-resource-list .bookmoniterdiv select::placeholder,
.save-resource-list .input-btn-contain input::placeholder {
    color: #000;
}

.searchpage .pop-up-container .multiSelectContainer,
.shareinspiration .pop-up-container .multiSelectContainer {
    padding: 8px 5px 9px 11px;
    cursor: pointer;
}

.sharepopupOuter .pop-up-container .multiSelectContainer,
.searchpage .pop-up-container .multiSelectContainer,
.shareinspiration .pop-up-container .multiSelectContainer {
    padding: 8px 5px 9px 11px;
    cursor: pointer;
}

.savepopup-content .multiSelectContainer select,
.savepopup-content select,
.sharepopupOuter .pop-up-container .multiSelectContainer select,
.sharepopupOuter .pop-up-container .multiSelectContainer input,
.searchpage .pop-up-container .multiSelectContainer input,
.shareinspiration .pop-up-container .multiSelectContainer input,
.searchpage .pop-up-container .multiSelectContainer select,
.shareinspiration .pop-up-container select,
.sharepopupOuter .pop-up-container .multiSelectContainer,
.searchpage .pop-up-container .multiSelectContainer,
.shareinspiration .pop-up-container select {
    cursor: pointer;
}

.sharepopupOuter .pop-up-container .search-wrapper .chip,
.searchpage .pop-up-container .search-wrapper .chip,
.shareinspiration .pop-up-container .search-wrapper .chip {
    line-height: 22px;
}

.sharepopupOuter .input-popup3 div.searchWrapper,
.searchpage .sharepopupOuter .input-popup3 div.searchWrapper,
.shareinspiration .sharepopupOuter .input-popup3 div.searchWrapper {
    line-height: 18px;
}

.searchpage .input-search-div input::placeholder {
    font-size: 12px;
}

.hide_search {
    display: none;
}
.searchpage .sermargin .main-card-container .card-container-exp .publish-name{
    min-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.searchpage .sermargin .main-card-container .card-container-exp .text-curriculum11{
    min-height: 25px;
}

.searchpage .share-spiner .overflowloader {
    width: 100%;
    height: auto;
}
.milesDistance {
	position:relative;
}
.milesDistance .facustomeI {
 position: absolute;
    right: -32px;
    left: auto;
    top: 0;
}
.milesDistance .facustomeI .tootlippillerinfo{
	font-size: 12px;
	width:200px;
}
.box-adjustment {
  min-height: 234px;
}
.searchpage .searchres-div .searchResultInnerBox .facustomeI{
    position: relative;
    bottom: 27px;
    right: -31px;
    top:auto;
}

.graderangeselectdiv #multiselectContainerReact div.searchWrapper {
    overflow: hidden;
    overflow-y: auto;
    height: 23px; 
}

.group-search-bar .graderangeselectdiv #multiselectContainerReact div.searchWrapper,
.graderangeselectdiv #multiselectContainerReact div.searchWrapper input{
    width: auto;
}

.searchpage .searchres-div .searchResultInnerBox div.facustomeI {
    bottom: 0;
    right:0;
    top: 8px;
}
.searchpage div.searchfriendlistview {
    height: 34px;
    padding-top: 5px;
}
.searchpage .searchres-div .select-exp-div .react-datepicker-wrapper input::placeholder{
    font-size: 14px;
    color: #000;
}
.searchpage .curriculam .select-exp-div .multiSelectContainer  .search-wrapper span,
.searchpage .searchres-div .select-exp-div .react-datepicker-wrapper input{
    font-size: 12px;
    color: #000;
}

.searchpage .searchfriendlistview .search-wrapper .chip {
  font-size: 12px;
  width: 90%;
}
.searchpage .sermargin div.addresziplocation{
    height: 42px;
}
.searchpage .addresziplocation .search-wrapper .chip {
line-height: 24px;
font-size: 14px;
}
.searchpage .addresziplocation .multiSelectContainer input[type="text"]{
  height: 28px;
}

.searchpage .searchres-div .select-exp-div .react-datepicker-wrapper input::placeholder{
    font-size: 14px;
    color: #000;
}
.searchpage .curriculam .select-exp-div .multiSelectContainer  .search-wrapper span,
.searchpage .searchres-div .select-exp-div .react-datepicker-wrapper input{
    font-size: 12px;
    color: #000;
}
.searchpage .experience-input-div .input-location input{
    color: #000;
}
.searchpage .search-bar-div .sharewithscroll .multiSelectContainer .searchWrapper{
    overflow: hidden;
    overflow-y: auto;
}
.searchpage .search-bar-div .sharewithscroll.selectedChildForRemoveScroll .multiSelectContainer .searchWrapper {
  overflow: hidden;	
}
.searchpage .search-bar-div .sharewithscroll.selectedChildForRemoveScroll .multiSelectContainer 
.searchWrapper  .chip+#search_input,
.searchpage .search-bar-div .sharewithscroll.selectedChildForaddScroll .multiSelectContainer 
.searchWrapper  .chip+#search_input  {
  display: inline-block;
    width: auto;	
}

@media only screen and (max-width:1200px) {
    .image-s2 img {
        width: 148.32px;
        height: 148.32px;
    }

    .curriculam .select-exp-div {
        width: 30%;
    }

    .searchpage .bookmark {
        margin-top: 0px;
    }
}
@media only screen and (max-width: 991px) {
    .searchpage .sermargin {
        margin-top: 15px;
        padding: 0px;
    }
   .search-div1,
    .search-no {
        margin-top: 19px;
    }

    .center-div-search .tabssearhimges {
        max-width: 100%;
        text-align: center;
        width: 100%;
    }

    .search-img-container {
        width: 60%;
        margin: 0px auto;
    }

    .search-img-container .image-s1 img,
    .image-s2 img,
    .image-s3 img,
    .image-s4 img {
        width: 150px;
        height: 150px;
    }

    .searchResultInnerBox {
        width: 100%;
    }
     .milesDistance .facustomeI .tootlippillerinfo {
       max-width: 123px;
	 }
    .searchResultInnerBox .select-exp-div {
        width: 30.5%;
    }
    .searchResultInnerBox .select-exp-div {
        margin-right: 9px;
    }
    .search-bar-div div.input-search-div:first-child {
        border-radius:36px 0px 0 36px;
    }
    .searchpage div.search-bar-div div.input-search-div {
        border-radius: 8px 8px 0 0;
    }
	.serveResourceInner .savebtnsearch,
	.serveResourceInner .input-btn-contain {
      margin-top: 10px;
   }
}

@media only screen and (max-width: 767px) {
    .searchpage .css-1t1lumd{width: auto;}
    .searchpage .css-1t1lumd h2{
        font-size: 18px!important;
        line-height: 25px!important;
        font-weight: normal;
    }
    .searchpage .css-10tlgzi{width: auto;}
    .search-exp-sec-div .select-exp-div {height: auto;}
    .noti {
        top: -14px;
    }
    .search-bar-div {
        border-radius: 0;
        display: inline-block;
        width: 100%;
        flex-wrap: wrap;
    }
    .input-search-div {
        border-right: none;
    }
    .search-exp-sec-div {
        display: inline-block;
    }
    .select-exp-div {
        margin-bottom: 12px;
        float: left;
        width: 100%;
    }
    .search-exp-sec-div h3 {
        text-align: left;
        margin-bottom: 14px;
    }
    .search-div1,
    .search-no {
        margin-top: 19px;
    }
    .search-div1 h3 {
        margin-bottom: 0px;
        font-size: 18px;
    }
    .search-div1 h4 {
        font-size: 13px;
    }
    .search-img-container {
        width: 100%;

    }
    .searchpage .sermargin {
        padding: 0px;
    }
    .searchpage .search-bar-div {
        border-radius: 8px;
    }
    .searchpage .input-search-div {
        width: 50%;
        float: left;
    }
    .searchpage .curriculam .select-exp-div {
        margin-right: 15px;
    }
    .curriculam .select-exp-div {
        width: 100%;
    }
    .pageNumber .select-exp-div {
        width: 44%;
    }
    .searchpage .curriculam .select-exp-div {
        width: 45%;
        line-height: 20px;
    }
    .searchpage .input-search-div:nth-of-type(3) {
        border-radius: 0px 0px 0px 8px;
    }
    .searchpage .input-search-div:nth-of-type(4) {
        border-radius: 0px 0px 8px 8px;
    }
	.milesDistance .facustomeI {
      right: 0;
      top: 43px;
    }
	.milesDistance .facustomeI .tootlippillerinfo {
       left: auto;
       right: 21px;
    }
	.milesDistance .facustomeI .tootlippillerinfo:before {
		display:none;
	}
	 .searchpage .sermargin .select-exp-div.searchfriendlistview {
        float:none;
        margin-left:0;
        }
	.serveResourceInner .savebtnsearch{
        margin-top:0px;
   }
}
@media only screen and (max-width:576px) {
    .search-bar-div button {
        display: none;
    }
    .hide_search {
        display: block;
    }
    .hide_search button{
        margin-bottom: 20px;
        padding: 5px;
        font-size: 16px;
        background-color: #F19357;
        color: white;
        border-radius: 5px;
        border: none;
    }
    .searchpage .input-search-div {
        width: 100%;
        border-bottom: 1px solid #cdcdcd;
    }
    .searchpage .input-search-div:nth-of-type(4) {
        border-bottom: none;
    }
    .input-search-div {
        padding: 15px 15px 10px 15px;
    }
    .input-search-div:hover {
        box-shadow: none;
        border-radius: 0;
        padding: 15px 15px 0px 15px;
    }

    .searchpage .curriculam {
        display: inline-block;
    }

    .col-lg-12 .select-exp-div,
    .searchpage .curriculam .select-exp-div {
        margin-left: 0;
    }

    .searchpage .curriculam,
    .searchResultInnerBox .select-exp-div {
        width: 100%;
        margin-left: 0;
    }
}
@media only screen and (max-width:480px) {
    .servepaymenthistory .select-exp-div,
    .servepaymenthistory .resourcetypess,
    .servepaymenthistory .tobarbackcontee {
        width: 47%;
        float: left !important;
        margin-left: 8px;
    }
}