.autorecordingsection .autorecordingform select{
    background-color: white;
    color: black;
    padding-left: 8px;
    }
  .autorecordingsection .autorecordingform .e-input-group input::placeholder{
        color: black;
        font-size: 14px;
    }
.recuringBookingselectHeight {
	width:50%;
}
.bookasession .mannualdatesection .picktimecalendaer .picktimeselectHeight{
    margin-top: 5px;
}
.recuringBookingselectHeight .css-1fdsijx-ValueContainer,
.picktimeselectHeight .css-1fdsijx-ValueContainer {
	height: 50px;
}
.tobarbackcontee .jackey {
    margin-right: 10px;
    width: 138px;
    height: 32px;
    background: #fff;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    border: none;
    color: #2D3134;
    padding-left: 6px;
}
.tobarbackcontee .gobackcontainer h5 span {
    margin-right: 8px;
}
.tobarbackcontee .gobackcontainer h5 span i {
    font-size: 19px;
}
.tobarbackcontee .jackey:focus-visible {
    outline: none;
    box-shadow: none;
}

.bookappominatsmeeting h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #2D3134;
}

.bookappominatsmeeting {
    position: relative;
}

.bookasession .MuiBox-root  div.child-save{
    color: #000;
}

.innerboxdatesst .form-group {
    display: block;
    margin-bottom: 15px;
    width: 100%;
    float: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000;
    text-align: left;
}

.innerboxdatesst .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.innerboxdatesst .form-group label {
    position: relative;
    cursor: pointer;
}

.innerboxdatesst .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 7px;
    width: 8px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.autorecordingform input.form-control:focus-visible {
    outline: none;
    box-shadow: none;
}

.mannualdatesection {
    float: left;
    width: 100%;
    margin-top: 30px;
}

.mannualdatesection .innerboxdatesst {
    width: 100%;
    display: inline-block;
}

.picktimecalendaer {
    width: 100%;
    float: left;
    text-align: left;
}

.picktimecalendaer h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #2D3134;
}
.picktimeRightSide {
   background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(19, 10, 46, 0.13);
    border-radius: 12px;
 margin: 12px 0px 0px 0px;
}
.picktimecalendaer ul {
    padding:15px;
    list-style-type: none;
    overflow: hidden;
}

.picktimecalendaer ul li {
    width: 46%;
    float: left;
    margin-bottom: 12px;
    margin-right: 4%;
}

.picktimecalendaer ul li a,.picktimecalendaer ul li button {
    background: #F5F5F5;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #2D3134;
    display: block;
    text-decoration: none;
    padding: 10px;
    border: none;
}

.picktimecalendaer ul li a.active,.picktimecalendaer ul li button.active  {
    background: #F19357;
    border-radius: 10px;
    color: #fff;
    border: none;
}

.autorecordingform input.form-control {
    border-radius: 8px;
    font-family: 'Inter';
    height: 46px;
    font-size: 15px;
}

.autorecordingsection .autorecordingform label.form-label {
    margin-bottom: 10px;
}

.autorecordingform .form-group {
    display: block;
    margin-bottom: 30px;
    width: 100%;
    float: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000;
    text-align: left;
}

.autorecordingform .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.autorecordingform .form-group label {
    position: relative;
    cursor: pointer;
}
.c-pointer {
    cursor: pointer;
}

.autorecordingform .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 7px;
    width: 8px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.picktimecalendaer ul li a.active {
    background: #F19357;
    border-radius: 10px;
    color: #fff;
}

.autorecordingsection {
    margin-top: 28px;
    float: left;
    width: 100%;
}

.timesestarntimes {
    width: 100%;
    float: left;
}

.timesestarntimes p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #2D3134;
}

.timesestarntimes p span {
    display: block;
}

.selectsessions {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 64px;
}

.bookasessionmentoringsession .selectsessions .selectsessionspd {
   margin-top: 10px;
}

.bookasessionmentoringsession .autorecordingmb{
    border-radius: 10px;
    margin-left: 9px;
}

.bookasessionmentoringsession .cancelbuttonflex{
    margin-bottom: 10px;
}

.bookasessionmentoringsession .cancelbuttonflex a{
    width: 100px    ;
}

.selectsessions .selectsessionspd h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    border-bottom: 0.5px solid #D9D9D9;
    text-align: left;
    color: #000000;
    display: block;
    padding-bottom: 6px;
}
.bookasession .MuiBox-root .child-save{
    color: #000;
}

.selectsessions .selectsessionspd ul {
    list-style-type: none;
    margin: 0;
    float: left;
    width: 100%;
}

.selectsessions .selectsessionspd ul li {
    padding: 10px 0px;
    display: inline-block;
    width: 100%;
}

.selectsessions .selectsessionspd ul li .selectsessionslt {
    width: 70%;
    float: left;
}

.selectsessions .selectsessionspd ul li .selectsessionslt p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #949698;
}

.selectsessions .selectsessionspd ul li .selectsessionslt p span {
    color: #000;
    margin-right: 5px;
}

.selectsessions .selectsessionspd ul li .selectsessionsrt {
    float: right;
    margin-top: 8px;
    width: auto;
}

.selectsessions .selectsessionspd ul li .selectsessionsrt a.removebutton {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    width: 100%;
    float: left;
    text-decoration: none;
    text-align: left;
}

.cancelbuttonflex {

    display: flex;
    padding-top: 23px;
    width: 100%;
    align-items: center;
    justify-content: left;
}

.cancelbuttonflex button.cancelflexbutton {
    background: #FFFFFF;
    text-decoration: none;
    margin-right: 12px;
    border: 1px solid #D9D9D9;
    padding: 11px 5px;
    color: #2D3134;
}
.cancelbuttonflex button.cancelflexbutton,
.cancelbuttonflex a.contiundeflexbutton,.cancelbuttonflex button.contiundeflexbutton{
   border-radius: 8px;
   font-family: 'Rubik', sans-serif;
    font-weight:500;
    text-align: center;
    font-size:16px;
    line-height: 100%;
}
.cancelbuttonflex a.contiundeflexbutton,.cancelbuttonflex button.contiundeflexbutton {
    background: #F19357;
    border: 1px solid #F19357;
    text-decoration: none;
    padding: 10px 3px;
    color: #fff;
}

.selectsessions .selectsessionspd ul li .selectsessionslt p.unavailable {
    color: #F04923;
}

.nobackgruoundsessions .selectsessionspd {
    background: transparent;
    border: none;
    box-shadow: none;
}

.notavailable {
    color: gray !important;
    background-color: transparent !important;
    cursor: not-allowed !important;
    pointer-events: none;
}

.selectedTime {
    background-color: #f19357 !important;
    color: white !important;
}


.paddingcontrighs .datebackground .e-calendar {
    max-width: 100%;
    border-radius: 10px;
}

.paddingcontrighs .e-content table tr th,.paddingcontrighs .e-cell {
    border: none;
}

.paddingcontrighs .e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    background: #F19357;
    border: 1px solid #F19357;
    box-shadow: none;
    color: black;

}

.paddingcontrighs .e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {

    background-color: #F19357;
    border: none;
    border-radius: 10px;
    color: #fff;
}
.paddingcontrighs .e-calendar .e-content td.e-selected span.e-day:hover,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day:hover {
    background-color: #F19357;
    border: none;
    border-radius: 10px;
    color: #fff;


}

 .e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day:hover {
    background-color: #F19357;

}

 .e-calendar .e-content td.e-today span.e-day {

    border: none;
    border-radius: 10px;
    box-shadow: none;
    color: black;

}

 .e-calendar .e-content td.e-today span.e-day:hover {
    background: #F19357;
    border: none;
    border-radius: 10px;
    box-shadow: none;
    color: white;

}


 .e-calendar .e-content td.e-today span.e-day:hover {
    background: #F19357;
    border: none;
    border-radius: 10px;
    box-shadow: none;
    color: white;

}

 .e-calendar .e-content td .e-focused-date .e-today span.e-day:hover {
    background: #F19357;
    border: none;
    box-shadow: none;
    color: white;
}

.e-calendar .e-content td:hover span.e-day,
.e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
    background-color: #F19357;
    border: none;
    border-radius: 10px;
    color: white;

}

 .e-calendar .e-btn.e-today.e-flat.e-disabled,
.e-calendar .e-btn.e-today.e-flat.e-disabled:hover,
.e-calendar .e-btn.e-today.e-flat.e-disabled:active,
.e-calendar .e-btn.e-today.e-flat.e-disabled:focus,
.e-calendar .e-btn.e-today.e-flat.e-disabled:hover:active {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.26);
    cursor: default;
    opacity: 0.35;
    outline: none;
    pointer-events: none;
    -ms-touch-action: none;
    touch-action: none;
}

 .e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
    background: none;
    border-color: none;
    color: white;
    cursor: none;
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
    background: #F19357;
    border: none;
    box-shadow: none;
    color: white;
}
.e-calendar .e-content td:hover span.e-day, .e-calendar .e-content td:focus span.e-day, .e-bigger.e-small .e-calendar .e-content td:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
    border: none;
    border-radius: 10px;
    color: black;
}


.manual-session-div ul li {
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
    padding-bottom: 22px;
    padding-top: 22px;
}

.manual-session-div ul li .sprash-name {
    width: 70%;
    float: left;
}

.manual-session-div ul li .sprash-name .sprash-name-lt {
    float: left;
    width: auto;
    margin-top: 8px;
    margin-right: 14px;
}

 .manual-session-div ul li .sprash-name .sprash-name-lt img {
    width: 44px;
    height: 44px;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.manual-session-div ul li .sprash-name .sprash-name-rts {
    margin-top: 8px;
}

 .manual-session-div ul li .sprash-name .sprash-name-rts h3 {
    font-family: 'Inter';
    font-style: normal;
    padding-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    letter-spacing: 0.01em;
    color: #2D3134;
}

.manual-session-div ul li .sprash-name .sprash-name-rts p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    letter-spacing: 0.01em;
    color: #949698;
}


.manual-session-div ul li .sprash-name-rt {
    float: left;
    width: 30%;
    margin-top: 0;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.manual-session-div ul li .sprash-name-rt .sprash-name-box h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}

 .manual-session-div ul li .sprash-name-rt .sprash-name-box p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #949698;
}

.manual-session-div ul li .sprash-name-rt .sprash-name-box h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}

.manual-session-div ul li .sprash-name-rt .sprash-name-box p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #949698;
}
.autorecordingform .jackey,
.autorecordingform .form-label input,
.autorecordingsection .moniertindmobiles select{
    height: 40px;
    width: 100%;
    border-radius: 10px;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.12);

}
.autorecordingform input#timepicker::placeholder{
	 font-size: 14px;
	 color:#111;
}
.paddingcontrighs .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left){
border: 1px solid rgba(0, 0, 0, 0.12);
}

.autorecordingmb .autorecordingform span.e-input-group, .create-schedule-div .e-input-group .e-timepicke{
    width: 100%;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.12);
    height: 40px;
    border-radius: 10px;
    padding-left: 7px;
    border-color: rgba(0, 0, 0, 0.42);
    font-size: 18px;
}

.autorecordingmb .autorecordingform input, .e-input-group textarea.e-input {
    border-width: 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.autorecordingform input{
width: 100%;
height: 40px;
border-radius: 10px;
padding: 5px;
border: 1px solid rgba(0, 0, 0, 0.12);
}

.bookasession .autorecordingmb .checkboxdiv .css-b62m3t-container .css-13cymwt-control,
.paddingcontrighs .css-b62m3t-container .css-t3ipsp-control{
    height: 52px;
}
.bookasession .picktimecalendaer .mentorSessionmultipleselection .css-t3ipsp-control,
.bookasession .picktimecalendaer .mentorSessionmultipleselection .css-13cymwt-control{
    height: 52px!important;
}
.mentorSessionmultipleselection .css-3w2yfm-ValueContainer {
	max-height: 40px;
    overflow-y: auto;
}

.autorecordingmb .selectedTime,
.autorecordingmb .false,
.autorecordingmb .undefined {
    height: 40px;
    width: 106px;
    background: #F5F5F5;
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    color: #2D3134;
    display: block;
    text-decoration: none;
}

.sessionhistory .picktimecalendaer .picktimeRightSide{
  margin: 0;
  width: 100%;
}

.sessionhistory .picktimecalendaer .picktimeRightSide ul {
    padding: 15px;
    list-style-type: none;
    overflow: hidden;
    min-height: 312px;
	max-height: 312px;
    overflow-y: auto;
}


.moniertindmobiles .allchildSelect{
    margin: 0;
    float: right;
	    width: 100%;
}

.moniertindmobiles  .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width:100%;
}
.moniertindmobiles .css-b62m3t-container .css-13cymwt-control {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5b5f62;
   /*  width: 350px; */
    height:52px;
	margin-top:0;
}
.manual-session-div.manual-session-div-inner  ul li .sprash-name {
    width: 40%;

}
.manual-session-div.manual-session-div-inner ul li .sprash-name-rt {
   width: 50%;
}
.e-popup.e-popup-open {
    border-radius: 16px;
}
.e-popup-header .quick-info-header,
.e-quick-popup-wrapper .e-event-popup .e-popup-header {
    border-radius: 16px 16px 0 0;
}
.bookasessionmentoringsession .react-tabs .react-tabs__tab {
     padding: 15.5px 16px;
    border: none;
}
.width-fit{
    width: fit-content !important;
    margin : 0px 5px
}
.width-fit button{
  border: none;
}
.sessionhistory .e-event-popup .quick-info-footer .cancelflexbutton{
  background-color: #F19357;
  color:#fff;
}
.bookasession .MuiBox-root .child-save:hover{
    color: #fff;
}

@media screen and (max-width: 1200px) {
    div.paddingcontrighs {
        padding: 10px;
    }
    .bookasessionmentoringsession .bookappominatsmeeting h5 {
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 991px) {
    div.paddingcontrighs {
        padding: 10px;
    }
}
@media screen and (max-width: 480px) {
    .paddingcontrighs .gobackcontainer h5 {
        width: 100%;
        text-align: left;
        margin-top: 10px;
    }
    .bookasessionmentoringsession .manual-session-div ul li .sprash-name-rt {
        width: 92%;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
    }
    .bookasessionmentoringsession .manual-session-div ul li .sprash-name {
        width: 90%;
        display: flex;
        justify-content: space-between;
    }
	.recuringBookingselectHeight,
    .paddingcontrighs .mannuldatemb {
        width: 100%;
    }
    .tobarbackcontee .gobackcontainer {
        display: inline-block;
    }
    .paddingcontrighs .picktimecalendaer h3 {
        margin-top: 20px;
    }
    div.paddingcontrighs {
        padding: 10px;
    }
    .moniertindmobiles .css-b62m3t-container {
        position: relative;
        box-sizing: border-box;
        bottom: auto;
        left: auto;
    }
    .moniertindmobiles .css-b62m3t-container .css-13cymwt-control {
        width: 350px;
    }
}