.restPass {
    position: relative;
    max-width: 622px;
    background-color: white;
    border-radius: 10px;
    padding: 10px 30px;
    margin: 80px auto;
	overflow: hidden;
}

.restPass h1,.restPass h1{
    color: #2D3134;
    margin-top: 20px;
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 45px;
line-height: 54px;
text-align: center;
}
.restPass input{
   width: 100%;
    position: relative;
    border-radius: 50px;
    font-size: 18px;
    margin: 20px auto;
}
.restPass input::placeholder{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 28px;
color: #2D3134;
}
.form-control{
    width: 100%;
    height: 52px;
}
.restPass select{
    width: 100%;
    height: 45px;
    border-radius: 50px;
    margin: 15px 20px;
    font-size: 18px; 
}
.btn22,.btn11{
	   position: relative;
    max-width: 400px;
    height: 56px;
    border-radius: 10px;
 	 color: white;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
       width: 100%;
}
.restPass .bottomText{
    text-align: center;
    padding: 25px 28px 40px 10px;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.01em;
color: #2D3134;
}
.restPass .bottomText span a{
    text-decoration: underline;  
     font-weight: bold;    
     color: #2D3134;
     font-family: 'Inter';
font-size: 16px;
line-height: 20px;
}
.btnchangepass {
	max-width: 140px;
}
@media only screen and (max-width:767px){
    .restPass .bottomText {
         padding: 10px;
    }
    .restPass h1 {
       
        font-size: 30px;
    
    }
    
    }