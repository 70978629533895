.searchheading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #65676B;
    margin-bottom: 35px;
}

.timeframediv .form-check-input {
    width: 25px;
    height: 25px;
}
.timeframediv .radiosdiv input[type=checkbox], input[type=radio]{
    margin: 0px 0 0;

}
.servepage .middlediv .servewithoutOuterBox .css-b62m3t-container .css-13cymwt-control .css-1dyz3mf{
    overflow-y:auto;
    height:40px;
    }
.servepage .middlediv .kayword-associated .css-1wuilmg-MuiAutocomplete-root {
    border: 1px solid #D9D9D9;;
    height: 52px;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    border-radius: 50px;
    background: white;
}   
.selection:focus-visible {
    outline: none;
    box-shadow: none;
}
.searchheading1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #65676B;
    margin-bottom: 25px;
}

.hrtag {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.profiledivision1 {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}
.profile-division {
    display: flex;
    gap: 10px;
}
.deshbord span {
    padding-right: 14px;
}

.jackey-name {
    text-transform: capitalize;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    margin-top: 8px;
}

.content-div {
    height: 133px;
    width: 212px;
    background: white;
    border-radius: 0px 0px 6px 6px;
    padding: 12px 10px 0px 45px;
}

.childs-intrests-btns {
    align-items: center;
    padding: 12px 24px;
    width: 139px;
    height: 44px;
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 8px;
    margin-top: 79px;
    margin-bottom: 144px;
    float: right;
}

.saves-btts {
    border: none;
    /* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    align-items: center;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #F19357;
}

.my-friend {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
}

.input-handle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2D3134;
    border: none;
    background: none;
    outline: none;
}

.learning-focus1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-top: 110px;
    color: #000000;
    text-align: left;
}

.three-dots {
    position: relative;
    padding: 0px 10px;
    cursor: pointer;
}

.deshbord {
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2D3134;
    padding: 10px 0 30px 0;
    border: none;
    background: none;
    float: left;
    padding-top: 65px;
    width: 100%;
    text-align: left;
}

.slider1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
    width: 212px;
    height: 898px;
    border: 1px solid red;
}

.slider1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
    width: 212px;
    height: 898px;
    border: 1px solid red;
}

.h4content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #2D3134;
    margin: 63px 0px 12px 0px;
    text-align: left;
}

.h2content {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
}

.selectcontent {
    width: 100%;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
    color: #5B5F62;
    background-color: white;
    height: 52px;
    padding: 8px 8px 8px 20px;
    background: #FFFFFF;
    /* border: 1px solid #2D3134; */
    border: 1px solid #dfdfdf;
    border-radius: 50px;
}
.form-select:focus {
    border-color: #dfdfdf;
    outline: 0;
   /*  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25); */
}
.selectcontent1 {
    width: 95%;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
    color: #5B5F62;
    background-color: white;
    margin-bottom: 10px;
    height: 52px;
    padding: 8px 8px 8px 24px;
    background: #FFFFFF;
    border: 1px solid #2D3134;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 50px;
    margin-left: 20px;
}

.timeframediv {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 20px;
    padding: 10px;
    display: inline-block;
    width: 100%;
}

.timeframe {
    width: 205px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin: 8px 16px;
}

.flexible {
    padding: 5px 10px;
    width: 173px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF;
    border-radius: 14px;
    margin-left: 15px;
    border: 2px solid #eae7e7;
}

.kayword-associated .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,
.css-1d3z3hw-MuiOutlinedInput-notchedOutline legend {
    display: none;
}

.kayword-associated .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    top:0px;
}

.daterange {
    width: 214px;
    height: 31px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin: 11px 13px;
    text-align: left;
    margin: 23px 0px 0px 20px;
}

.divcoldd {
    position: relative;
}

.divcoldd .error-span {
    bottom: -19px;
    position: absolute;
    left: 0;
}

.input1 {
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    max-width: 359px;
    width: 100%;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 8px;
    margin-top: 4px;
    margin-left: 12px;
    float: left;
    padding: 10px;
    margin-left: 18px;
    margin-bottom: 8px;
}

.times {
    width: 245px;
    height: 31px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin: 25px 16px;
}

.insstyle {
    width: 271px;
    height: 31px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
}

.labelwithtext label,
.space-inner-div h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    text-align: left;
  
}
.labelwithtext label {
	 margin: 4px 15px;
}
.labelwithtext label,
.space-inner-div h2 { 
  margin: 4px 0px;
}
.labelwithtext label {
    display: block;
}

.space-inner-div {
    margin-bottom: 23px;
}

.sreve-content1 {
    width: 153px;
    /* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 18px;
    color: #2D3134;
    padding: 8px 5px 0;
}

.sreve-content {
    width: 153px;
    /* font-family: 'effra_trialregular'; */
	font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #2D3134;
    padding: 10px;
}

.aboutta {
    box-sizing: border-box;
    width: 896px;
    height: 241px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 20px;
    /* margin-left: 12px; */
}

.aboutinsta {
    box-sizing: border-box;
    width: 100%;
    height: 241px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 20px;
    padding: 10px 25px;
}

.smalldiv {
    display: flex;
}

.dlocation {
    height: 155px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    border-radius: 20px;
    text-align: left;
    width: 100%;
}

.dltext {
    /* width: 305px; */
    height: 31px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    margin: 10px 5px 8px 24px;
}

.uploadmaindiv {
    width: 100%;
    margin-top: 10px;
}

.duplod {
    width: 100%;
    background: #F5F5F5;
    border: 1px solid grey;
    display: flex;
    justify-content: space-between;
    padding: 24px 80px 24px 24px;
    border: none;
}

.duplod1 {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid grey;
    border: none;
    border-bottom: 1px solid #f3f3f0;
    display: flex;
    justify-content: space-between;
    padding: 24px 80px 24px 28px;
}

.duploadtext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    height: 20px;
    width: 296px;
    border-radius: nullpx;
}

.form-insstyle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    padding-left: 15px;
    color: #000000;
    margin-top: 82px;
    text-align: left;
    margin-bottom: 10px;
    text-align: left;
    padding: 10px 0px 0px 21px;
}

.form-checkradio {
    margin-left: 11px;
}

.radiotext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    position: relative;
    left: 25px;
    bottom: 3px;
}

.pbtton {
   
    float: right;
    margin-top: 15px;
    margin-bottom: 100px;
}
.pbtton button {
	 align-items: center;
    padding: 9px 19px;
    width: 113px;
    height: 44px;
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 8px;
}

.btnstyle {
    width: 65px;
    height: 20px;
    font-family: 'Effra';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    align-items: center;
    text-transform: capitalize;
    color: #FFFFFF;
}

.c {
    padding: 8px 0px 8px 24px;
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 1000px;
}

.iconupload {
    display: flex;
    gap: 10px;
}

.i {
    padding: 8px 12px;
}

.u {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
    padding: 2px;
}

.dp {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
}

.ed {
    font-family: 'Euclid Circular B';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: #2D3134;
}

.ed1 {
    font-family: 'Euclid Circular B';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: #2D3134;
    margin-right: 22px;
}

.one {
    width: 5px;
    height: 18px;
    font-family: 'Euclid Circular B';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    background-color: #F19357;
    padding: 5px 8px;
    margin: 10px;
    border-radius: 6px;
}

.btnstylebtn button {
    outline: none;
  
    color: white;
    border: none;
 
	font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
}

.selection {
    margin-top: 15px;
    font-size: 18px;
    padding-left: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #5B5F62;
}

/* .mainfromdiv input.form-control[type="date"]::-webkit-inner-spin-button,
.mainfromdiv input.form-control[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */

.mainfromdiv {
    float: left;
    width: 100%;
    padding: 0px 25px;
    margin-top: 30px;
}

.mainfromdiv label.formlabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    text-align: left;
    float: left;
    padding-left: 10px;
    margin-bottom: 10px;
    height: 32px;
}

.mainfromdiv label.formlabel span {
    color: #5B5F62;
    font-weight: 400;
}

.mainfromdiv .col {
    margin-bottom: 30px;
    position: relative;
}

.mainfromdiv input.form-control:focus-visible {
    outline: none;
    box-shadow: none;
}

.mainfromdiv input.form-control {
    background: #FFFFFF;
    padding-left: 15px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 8px;
}
.divcoldd #start_date,.divcoldd #end_date {
	padding-left:15px;
}

.mainfromdiv img.timepikcerclass {
    position: absolute;
    right: 30px;
    top: 67px;
    display:none;
}

.mainfromdiv img.timepikcerclass1 {
    position: absolute;
    left: 30px;
    top: 58px;
    display: none;
}

.mainfromdiv .divcoldd {
    position: relative;
    margin-bottom: 24px;
}

.serve-typecontent .error-span,
.timeFrameSemester .error-span {
    margin-left: 15px;
    margin-top: 5px;
}

.radio-btn-handle .form-check-input[type=radio] {
    float: left;
    width: 20px;
    height: 20px;

}

.radio-btn-handle,
.form-checkradio {
    display: inline-block;
    width: 100%;
}

.radio-btn-handle .form-checkradio {
    margin-bottom: 15px;
}

.instructionStylecontetWithRadio .form-checkradio {
    margin-bottom: 5px;
}

.radio-btn-handle .radiotext {
    float: left;
    padding-left: 10px;
    width: 94%;
    box-sizing: border-box;
}

span.clearnone {
    display: inline-block;
}

.defineExpOuter .error-span,
.timeDaysWeekOuter .error-span {
    padding-left: 15px;
    padding-top: 5px;
}

.studentTypeSupportOuter .css-13cymwt-control,
.servegradeRangeOuter .css-13cymwt-control,
.servePillerOuter .css-13cymwt-control {
    margin-bottom: 0;
}

.uploadmaindiv button.btn.active {
    border: 0 !important;
    background: transparent;
}

.facilityLocationdiv h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2D3134;
    margin-bottom: 5px;
    text-align: left;
}
.thumbuploadImgz {
	position: relative;
}
.servepage .r-title-div div input {
    padding: 3px 5px;
    height: 30px;
}
.servepage .r-title-div div input.input-file-upload {
	position: absolute;
    top: 2px;
	cursor:pointer;
	height: 45px;
}
.viewing56 select {
    height: 31px;
    border-radius: 50px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    width: 125px;
    background-color: #fff;
}

.profilepage .viewing56 select {
    padding-left: 10px!important;
}

.servepage .input-popup2 img {
    width: 100%;
}

.servepage .pop-up-container .input-popup2 {
    overflow: hidden;
    max-height: 100%;
    height: 100%;
}

.instructionStylecontetWithRadio {
    margin-bottom: 10px;
}

.instructionStylecontetWithRadio .form-checkradio {
    width: 98%;
}

.servepage .viewableFriend .viewableSwitchtag {
    display: inline-block;
    width: 260px;
}

.servepage .viewableFriend h5 {
    padding-left: 25px;
}

.servepage .middlediv {
    padding: 0 50px;

}

.servepage .r-title-div div {
    padding: 10px 15px 15px 15px;
}

.servepage .css-1q79v3g-MuiButtonBase-root-MuiChip-root {
    color: #fff;
    background-color: #F19357;
}

.servepage .css-1q79v3g-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    color: #fff;
}

.servepage .css-b62m3t-container .css-13cymwt-control {
    height: 52px;
}
.servepage .css-t3ipsp-control .css-1dyz3mf {
	height:40px;
	overflow-y:auto;
}
.servepage .aboutinsta,
.defineLocation input[type="text"] {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #5B5F62;
	width:100%;
}

.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 7px 9px;
}

.servepage .middlediv input::-webkit-input-placeholder {
    color: #808385;
    font-size: 18px;
    font-weight: 400;
}

.servepage .sharepopupOuter .pop-up-container {
    margin: 20px auto;
}

.servepage .pop-up-container {
    max-width: 750px;
}

.servepage .sharepopupOuter {
    z-index: 3000;
}

/* .servepage.removescroll {
    overflow: hidden;
} */

.servepage .css-1jqq78o-placeholder {
   /*  margin-left: 10px; */
}
.servepage .manyresourceBox .error-span,
.servepage .servewithoutOuterBox .error-span {
    margin-left: 20px;
}
.servepage .space-inner-div div.locationcomunity .error-span {
    margin-left:45px;
}
.servewithoutOuterBox .dlocation .error-span {
    margin-left: 25px;
}

.teacherLocation h5 {
    text-align: left;
}

.servepage .teacherTypesupport .css-b62m3t-container .css-13cymwt-control {
    margin-bottom: 0;
}

.pac-target-input {
    border-radius: 24px;
}

.kayword-associated .css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    border-radius: 50px;
    background: #fff;
   
}

.servepage .kayword-associated .css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    overflow: hidden;
    overflow-y: auto;
    height: 55px;
}

.servepage .css-3w2yfm-ValueContainer {
    height: 40px;
    overflow-y: auto;
}
.servepage .pop-up-container.serveConfirmationbox {
    max-width: 550px;
}
.serveConfirmationbox p{
	font-size: 16px;
    font-weight: 500;
	text-align: center;
}
.serveConfirmationbox .popup-btn-div button {
	font-size: 16px;
}
.exampleLink {
  padding-left:20px;
 margin-top: 5px;  
}
h2.spacemarginleft{
	margin-left:20px;
}


.servepage .h4content {
    margin: 63px 0px 12px 13px;
}
.servepage .deshbord {
   padding: 10px 0 30px 62px;
    margin-top: 50px;
}
 .selectcontent {
    border: 1px solid #D9D9D9;
  padding: 8px 8px 8px 15px;
    
}
.labelwithtext label,
.space-inner-div h2 { 
  margin: 4px 15px;
}

.middlediv .css-1fdsijx-ValueContainer {
    padding: 2px 15px;
}
.servewithoutOuterBox .selection {
   padding-left: 15px;
    
}

.kayword-associated .css-1wuilmg-MuiAutocomplete-root {
    z-index: 0;
    position: relative;
}


.innerboxalignment .share-with-group,
.middlediv .servewithoutOuterBox .share-with-group{
    margin-left: 16px;
    margin-right: 70%;
    display: flex;
    justify-content: space-between;
}
.innerboxalignment .share-with-group .slider.round:before,
.middlediv .servewithoutOuterBox .share-with-group .slider.round:before {
    width: 15px;
    border-radius: 50px;
    height: 15px;
}
.innerboxalignment .share-with-group .slider.round,
.middlediv .servewithoutOuterBox .share-with-group .slider.round {
    width: 45px;
    border-radius: 34px;
    height: 18px;
}
.innerboxalignment .share-with-group .slider:before,
.middlediv .servewithoutOuterBox .share-with-group .slider:before {
    position: absolute;
    content: "";
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
}
.add-more-information{
    border: none;
    background: #f8f9fa;
    color: black;
    font-family: "Inter";
    font-size: 14px;
    padding: 0;
}
.servepage .kayword-associated .css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    border: 1px solid #dee2e6;
}

.servepage .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
border-width: none;
border-style: none;
}
.servepage .thumbuploadImgz div.ant-upload,
.savepage .thumbuploadImgz div.ant-upload{
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 100%;
    opacity: -1;
}
.savepage .thumbuploadImgz div.ant-upload{
    top: -21px;
}
.servepage .thumbuploadImgz div.ant-upload{
    top: -11px; 
}
.servepage .thumbuploadImgz div.ant-upload button,
.savepage .thumbuploadImgz div.ant-upload button{
    width: 100%;
    height: 50px;
}

.servepage .servewithOuterBox .timeframediv .serve-typecontent .error-span {
margin-left: 36px;
}

.servepage .servewithOuterBox .timeframediv .radio-btn-handle .error-span{
margin-left: 7px;
}

.servepage .servewithOuterBox .timeframediv .r-title-div .error-span{
margin-left: 10px;

}

.servepage .servewithOuterBox .timeframediv .accre-span .error-span{
 margin-left: 20px;
}
.servepage .middlediv .servewithoutOuterBox .thumbuploadImgz .u-content{
    padding-left: 6px;
	width: 100%;
}
.servepage .middlediv .r-title-div p{
margin-left: 23px;
}
.publicFunding .css-b62m3t-container {
	z-index:5;
}

.servepage .servewithOuterBox .timeframediv .support-span,
.servepage .servewithOuterBox .timeframediv .funding-span,
.servepage .servewithOuterBox .timeframediv .typeof-span,
.servepage .servewithOuterBox .timeframediv .location-span-orag,
.servepage .servewithOuterBox .timeframediv .oganization-span span,
.servepage .servewithOuterBox .timeframediv .experie-span{
    margin-left: 22px;
} 
.servepage .servewithOuterBox .timeframediv .location-span-facility,
.servepage .servewithOuterBox .timeframediv .location-span-support,
.servepage .servewithOuterBox .timeframediv .location-span-orag{
    margin-left: 31px;
}

.servepage .servewithOuterBox .timeframediv .r-title-div .exper-zipcode{
 position: relative;
 bottom:0px;   
}
.servepage .servewithOuterBox .timeframediv .location-span{
    margin-left: 31px;
}
.servepage .servewithOuterBox .timeframediv .support-span span{
    margin-left: 17px; 
}
.servepage .servewithOuterBox .timeframediv .radiosdiv{
    margin-bottom: 0;
    margin-left: 16px;
}
.servepage .timeframediv .r-title-div{
    margin-top: 10px;
}
.servepage .radiosdiv .engagelist li {
	margin-right:15px;
}
.thumbuploadImgz small {
	overflow: hidden;
    text-overflow: ellipsis;
    width:75%;
    display: inline-block;
    white-space: nowrap;
}
.resourceinfoTool .select-one {
	position:relative;
}
.resourceinfoTool .select-one .facustomeI {
	position: absolute;
    left: auto;
    top: 0;
    right: -25px;
}




@media screen and (max-width: 1200px) {
    .smalldiv {
        flex-wrap: wrap;
    }

    .smalldiv .flexible {
        margin-top: 12px;
        width: 155px;
    }

    .radio-btn-handle {
        padding-bottom: 20px;
    }
}
@media screen and (max-width:991px) {
	.servepage .middlediv {
		padding: 0;
	}

    .middlediv .deshbord {
        font-size: 16px;
        right: auto;
        top: 0;
        padding-left: 0;
        left: 18px;
        text-align: left;
        float: left;
        width: 100%;
    }

    .middlediv .h4content {
        margin: 63px 0px 12px 15px;
    }

    .css-1jqq78o-placeholder {
        padding-left: 10px;
        font-size: 15px;
    }

    .selection {
        padding-left: 20px;
        font-size: 15px;
    }

    .r-title-div div input.input-file-upload {
        padding: 0px !important;
    }

    .r-title-div div {
        padding: 13px 0px;
    }

    .r-title-div div span.u-content {
        padding-left: 20px;
        font-size: 14px;
    }

    .r-title-div div .uploadicon {
        font-size: 20px;
    }

    .dlocation {
        height: auto;
        padding-bottom: 20px;
    }
	.servepage .aboutinsta,
	.defineLocation input[type="text"] {
	   margin:0;
	}
}

@media screen and (max-width: 767px) {

    .servepage .css-1t1lumd {/* width:auto; */}

    .servepage .css-1t1lumd h1{
        font-size: 18px!important;
        line-height: 25px!important;
        font-weight: normal;
    }


    .save-div-name {
        display: flex !IMPORTANT;
        width: 100%;
        flex-wrap: wrap;
    }



    .dotspop {
        left: -135px;
    }

    .middlediv .h4content {
        font-family: 'Inter';
        font-style: normal;
        padding-left: 15px;
        font-weight: 700;
        font-size: 20px;
    }

    .space-inner-div h2 {
        padding-left: 0;
        margin-left: 0;
    }

    .smalldiv {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .smalldiv .flexible {
        padding-bottom: 0;
        width: 130px;
        margin-left: 0;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sreve-content {
        width: auto;
        font-size: 16px;
        text-align: left;
    }

    .sreve-content1 {
        width: auto;
        font-size: 16px;
        text-align: center;
    }

    .mainfromdiv .col {
        margin-bottom: 0;
    }

    .mainfromdiv .margintopcss {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .space-inner-div h2 {
        padding-left: 0;
        margin-left: 0;
    }

    .mainfromdiv .margintopcss {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .mainfromdiv .divcoldd {
        position: relative;
        margin-bottom: 0px;
    }

    .mainfromdiv label.formlabel {
        margin-bottom: 14px;
        font-size: 14px;
        height: auto;
    }

    .mainfromdiv {
        padding: 0px 0px;
    }

    .css-1jqq78o-placeholder {
        padding-left: 10px;
        font-size: 15px;
    }

    .selection {
        padding-left: 20px;
        font-size: 15px;
    }

    .mainfromdiv img.timepikcerclass1 {
        top: 60px;
    }

    .mainfromdiv img.timepikcerclass2 {
        top: 28px;
    }

    .radio-btn-handle .form-checkradio {
        margin-left: 0;
        display: inline-block;
        width: 100%;
    }

    .form-insstyle {
        padding: 10px 0px 0px 6px;
    }

    .radiotext {
        width: 84%;
        font-size: 13px;
        left: 10px;
        bottom: 3px;
        right: 10px;
    }

    .radio-btn-handle .form-checkradio input {
        float: left;
        left: 0;
    }

    .space-inner-div label {
        padding-left: 0;
    }

    .labelwithtext label,
    .space-inner-div h2 {
        padding-left: 10px;
        font-size: 13px;
    }


    .r-title-div h3 {
        font-size: 14px !important;
        text-align: left !important;
        padding-left: 10px !important;
    }

    .r-title-div div input.input-file-upload {
        padding: 0px !important;
    }

    .r-title-div div span.u-content .uploadicon {
        font-size: 19px;
        margin-top: 4px;
     }

    .r-title-div div span.u-content spam {
        padding-left: 40px;
        margin-top: -2px;
        float: left;
    }

    .dltext {
        font-size: 16px;
    }

    .duplod {
        padding: 15px 0px 15px 15px;
    }

    .duplod1 {
        padding: 15px 0px 15px 0px;
    }

    .dp {
        font-size: 14px;
    }

    .ed {
        padding-right: 10px;
    }

    .middlediv .h4content {
        margin: 63px 0px 0px 1px;
    }
	.servepage .middlediv {
         padding: 0px;
    }
	
	.duplod1 {
       display: inline-block;
	}
	.duplod1 .dp {
       font-size: 12px;
		width: 100%;
	}
	.radio-btn-handle .radiotext {
       width: 90%;
   }
   .servepage .servewithoutOuterBox .labelwithtext label{
      margin: 0 auto;
   }

 .servepage .middlediv .manyresourceBox .selection{
    margin-top: 0;
}
.servepage .deshbord {
    padding-left:8px;
 }

 .servepage .middlediv .h4content {
    padding-left: 8px;
}


}
@media screen and (max-width: 576px) {
.u-content span{
	line-height: 15px;
    display: inline-block;
    width: 75%;
}
.servepage .deshbord {
    padding-left:12px;
 }
}
@media screen and (max-width: 480px) {


}