.dropdown-content {
   position:absolute;
    margin-left: 200px;
    margin-top: 20px;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 15px;
  }
.dropdown a:hover {background-color: #ddd;}
