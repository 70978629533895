.SeereviewOuterdiv{
  position: fixed;
  background-color:rgba(255, 255, 255, 0.9);
  left: 0;
  z-index: 300;
  top: 0;
  width: 100%;
  height: 100%;

}
.groupJoin .your-main-group .SeereviewOuterdiv .seeReviewinnerdiv2{
  max-height: 250px;
  overflow-y: auto;
}
.seeReviewinnerdiv {
    background: #FFFFFF;
    border: 2px solid rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    max-width: 600px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 25px;
    width: 100%;
   margin: 100px auto;
   position:relative;
   /* height: 300px; */
}

.seeReviewinnerdiv h1{
  font-family: 'Inter';
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #2D3134;
}
/* .f5{
  border: none;
  line-height: 35px;
  border-radius: 50%;
  width: 37px;
  height: 37px;
  background-color: #D9D9D9;
margin-top: -38px;
float: right;
font-size: 15px;
color: #5B5F62;

} */
.seeReviewinnerdiv2{
  display: flex;
  flex-direction: column;
  /* margin-top: 45px; */
}

.maindiv{
display: flex;
}

 .cls_btn {
    width: 37px;
   line-height:35px;
    text-align: center;
    background: #D9D9D9;
    height: 37px;
    border-radius: 50%;

    color: #5B5F62;
    border: 1px solid #D9D9D9;
    font-weight: normal;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 15px;
    cursor:pointer;

}
.img-fluidd{
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
