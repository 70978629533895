.your-main-group .slider-page img {
    width: 280px;
    height: 260px;
    /*   margin-top: 40px; */
    border-radius: 8px;
}

.your-grp {
    padding-top: 30px;
}

.your-main-group .the-secret {
    display: flex;
    margin-top: 27px;
}
.groupJoin .your-main-group .manageresourcebtn{
    height: 44px;
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 100px;
    font-family: 'Inter';
    padding: 5px 20px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #FFFFFF;
    margin-right: 40px;
    cursor: pointer;
    }
.groupJoin .your-main-group .chat-group-innere .descTextDatainfo .pdesctext{
    overflow-y: auto;
    max-height: 300px;
}
.your-main-group .the-secret h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2D3134;
}

.padding-row .your-main-group .the-secret p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #82A3A1;
    margin: 5px 0;
    margin-left: 1px;
}

.your-main-group .partsearch {
    margin-top: 0px;
    /* border-top: 1px solid #BFBCB8; */
    border-bottom: 1px solid #BFBCB8;
    margin-bottom: 27px;
    width: 100%;
    display: flex;
}

.your-main-group .searchpart-div {
    display: inline-block;
    width: 100%;
    margin: 24px 0px;
}

.float-end-custome {
    float: right;
}


.your-main-group .searchpart-div h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
    color: #2D3134;
    margin-right: 12px;
    float: left;
}

.padding-row .your-main-group .chat-group .joins-group {
    margin-right: 0px;
}


.your-main-group .searchpart-div select {
    width: 145px;
    height: 31px;
    background: #FFFFFF;
    border: 0.21px solid #BFBCB8;
    border-radius: 32.5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2D3134;
    padding: 0px 10px 0 5px;
    margin-right: 12px;
    float: left;
}

.inspiration-inner-div img {
    width: 50px;
    height: 50px;
}

.announce-img {
    width: 100px !important;
    height: 100px !important;
}

.inspiration-inner-div label,
.inspiration-inner-div h3 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin: 3px 0px;
    text-transform: lowercase;
    color: #2D3134;
}

.inspiration-inner-div span {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    opacity: 0.5;
}

/* .SummerCamp {
    display: flex;
    justify-content: space-between;
    padding: 35px 10px 10px 10px;
} */
.green-icon {
    width: 119px;
    height: 90px;
    background: #57886C;
    border-radius: 16px;
    padding: 13px 13px;
}

.green-icon img {
    width: 62px;
    height: 62px;
}

.heart,
.mind,
.soul,
.strength {
    color: #265472;
    /* font-family: 'effra_trialregular'; */
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: -5px 3px;
}

.hearticon {
    font-size: 15px;
    color: #2D3134;
    padding: 0px 4px 0px 0px;
    cursor: pointer;
}

.hearticon1 {
    padding: 0px 4px 0px 10px;
    font-size: 15px;
    color: #2D3134;
}


.star {
    color: #F19357;
    border-radius: 0px;
}

.reviews {

    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-decoration: underline;
    color: #000000;
    margin-left: 5px;
    float: right;
}
.reviews span {
	text-decoration-line: initial;
	display:inline-block;
}
.summer-text h5 {

    font-family: 'Rubik', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #808080;
}

.shareicon-img img {
    max-width: 19px;
    height: 24px;
}

/* .SummerCamp {
    display: flex;
    justify-content: space-between;
    padding: 35px 10px 10px 10px;
} */
/* .comments {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 1px 10px;
    border-bottom: 1px solid black;
} */
.like {
    display: flex;
}

.like span {
    background: #F19357;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 21px;
    text-align: center;
}

.like span i.fa.fa-thumbs-up {
    color: #fff;
}

.like label {
    margin-left: 10px;
    font-size: 18px;
}

.comments1 {
    display: flex;
}

.comments1 span {
    margin-right: 20px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.comments1 label {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.your-main-group .comments-div {
    display: flex;
    justify-content: space-between;
    padding: 10px 92px 10px 94px;
    border-bottom: 1px solid #CED0D4;
}

/* .like-box {
    display: flex;
    font-size: 16px;
} */
.like-box label {
    margin-left: 8px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.comments-box {
    display: flex;
    font-size: 16px;
}

.comments-box label {
    margin-left: 8px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.share-box {
    display: flex;
    font-size: 16px;
}

.share-box label {
    margin-left: 8px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

/*

.See-more-div {
    padding: 11px 13px 12px 15px;
} */

.See-more-div h4 {
    text-align: left;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.comment-div-box {
    display: flex;
    margin-top: 10px;

}

.comment-div-box img {
    width: 32px;
    height: 32px;
    text-align: left;
    margin-right: 10px;
}

/* .joana-comments {
    width: 196px;
    height: 56px;
    background: #F5F5F5;
    border-radius: 18px;
    padding: 7px 0px 0px 10px;
} */

.joana-comments h3 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #2D3134;
}

.joana-comments span {
    text-align: left;
    margin-right: 15px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #5B5F62;
}

.comments-time {
    width: 164px;
    display: flex;
    justify-content: space-between;
    margin: 4px 56px;
    margin-bottom: 10px;
}

.comments-time span {
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #65676B;
}

.comments-time span {
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #65676B;
}

.comments-time span {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #65676B;
}
div.joana-comments {
	position:relative;
}
.jane-image-div {
    display: flex;
}

.jane-image-div img {
    width: 32px;
    height: 32px;
}

.jane-image-div input {
    width: 100%;
    height: 36px;
    font-size: 16px;
    background: #F5F5F5;
    border-radius: 30px;
    padding: 6px 15px 7px 13px;
    margin-left: 10px;
    border: none;
}



.boy-images p {
    padding: 17px 10px 10px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #2D3134;
    text-align: left;
}

.boy-images img {
    width: 100%;
    height: 300px;
    padding: 10px;
}

.your-main-group .group-member-div .rating-div h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2d3134;
    text-align: left;
}

.your-main-group .group-member-div .review-div {
    margin-top: 5px;
    float: left;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.your-main-group .group-member-div .review-button button {
    width: 100%;
    border: none;
    background: #F19357;
    border-radius: 8px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: block;
    text-align: center;
    align-items: center;
    color: #FFFFFF;
    height: 56px;
    padding: 10px;
    margin-top: 10px;
}

.postTextlist li {
    list-style: none;
    float: left;
    margin-right: 15px;
    font-size: 0.875rem;

}

.postTextlist .button {
    width: auto;
    position: relative;
}

.postTextlist .button:before {
    background: #3f51b5;
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    height: 2px;
    bottom: -10px;
}

.your-main-group .group-member-div .group-mem-div .group-mem-inner-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 26px;
}

.your-main-group .group-member-div .group-mem-div .member-request .group-mem-inner-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 26px;
    cursor: pointer;
}

.your-main-group .group-member-div .group-mem-div h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #2d3134;
}

.your-main-group .group-member-div .group-mem-div p {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #5B5F62;
    cursor: pointer;
}

/* .your-main-group .group-member-div .group-mem-div input {
     width: 95%;
    height: 29px;
    padding: 10px;
    font-family: 'Inter';
    margin-top: 11px;
} */

.search-div {
    margin-top: 11px;
    width: 100%;
    display: flex;
    border: 0.25px solid #BFBCB8;
    background: rgba(170, 192, 206, 0.20);
    border-radius: 4px;
    height: 29px
}

.your-main-group .group-member-div .group-mem-div input {
    border: none;
    background: transparent;
    width: 96%;
    padding: 5px;
    border-radius: 4px;
    height: 29px
}

.search-incon {
    padding: 5px;
    font-size: 20px;

}

.your-main-group .group-member-div .group-mem-div .search-incon {
    padding: 2px 5px;

}

.your-main-group .group-mem-div ul {
    margin-top: 15px;
}

.your-main-group .group-mem-div ul li {
    margin-top: 20px;
}

.your-main-group .group-mem-div .search-bar-inner .search-image-div img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.your-main-group .group-mem-div .search-bar-inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.your-main-group .group-mem-div .view-chat {
    background-color: #fff;
    width: 100%;
    max-width: 168px;
    position: absolute;
    right: 25px;
    border-radius: 4px;
    top: 0;
    z-index: 2;
}

.your-main-group .group-mem-div .view-chat h1 {
    border-bottom: none;
    height: 34px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2D3134;
    padding: 10px;
    text-align: left
}

.your-main-group .group-mem-div .view-chat h1:hover {
    /*    color: white;
    background-color: #265472;
    border-radius: 8px; */
}

.your-main-group .group-mem-div .search-bar-inner .search-image-div {
    display: flex;
}

.your-main-group .group-mem-div .search-bar-inner .search-image-div .heading-div {
    text-align: left;
    margin-left: 10px;
}

.your-main-group .group-mem-div .search-bar-inner .search-image-div .heading-div h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #5B5F62;
}

.your-main-group .group-mem-div .search-bar-inner .search-image-div .heading-div p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #949698;
}

.your-main-group .group-mem-div .search-bar-inner i {
    margin-left: 150px;
    cursor: pointer;
}

.your-main-group .group-mem-div button {
    width: 100%;
    height: 47px;
    border: none;
    margin-top: 15px;
    background: #FFFFFF;
    border: 0.5px solid #BFBCB8;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #2D3134;

}

.your-main-group .member-request .member-request-inner .member-request-flex {
    display: flex;
}

.your-main-group .member-request .member-request-inner .member-request-flex img {
    width: 40px;
    height: 40px;
    float: left;
    border-radius: 50%;
}

.your-main-group .member-request .member-request-inner .member-request-flex h4 {
    position: relative;
    bottom: 10px;
    left: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 60px;
    color: #000000;
}

.your-main-group .member-request .member-request-inner {
    display: flex;
    justify-content: space-between;
    flex: auto;
    flex-wrap: wrap;
}

.your-main-group .member-request .member-request-inner .member-request-icon {
    display: flex;
    margin-top: 12px;
}

.your-main-group .member-request .member-request-inner .member-request-icon span {
    background: #BFBCB8;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 4px;
    padding: 0;
    line-height: 20px;
    text-align: center;
}

.member-request-icon span i {
    align-items: center;
    color: #fff;
    margin-right: 1px;
}

.member-request-icon .cross-icons {
    background: #969696;
    border-radius: 40%;

}

.member-request-icon .cross-icons,
.member-request-icon .check-icons {
    cursor: pointer;
    width: 20px;
    display: inline-block;
    text-align: center;
    height: 20px;
    line-height: 20px;
}

.member-request-icon .check-icons {
    background: #265472;
    border-radius: 40%;
    margin: 5px;
    margin-right: 0;
}

.your-main-group .member-request ul li {
    margin-top: 2px;
}

.padding-row {
    padding-left: 29px;
}

.blur-part {
    position: relative;
}

.blur-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    /* Semi-transparent white */
    backdrop-filter: blur(10px);
    /* Apply the blur effect */
    pointer-events: none;
    /* Allow clicking through the overlay */
}

.grouptablayout .MuiPaper-elevation1 {
    box-shadow: none;
}

.grouptablayout .MuiPaper-root {
    background-color: transparent;

}

.grouptablayout .MuiPaper-root .MuiTabs-flexContainer {
    margin-bottom: 10px;
    border-bottom: 1px solid #130A2E21;
}

.grouptablayout .MuiTab-textColorInherit.Mui-selected {
    background: #265472;
    color: white !important;
    border-radius: 10px 10px 0px 0px;
}

.grouptablayout .MuiTab-root {
    text-transform: inherit;
}

.group-member-containersOuter {
    /* max-height: 300px; */
    overflow-y: auto;
}
.group-memberNewScroll {
	max-height: 250px;
    overflow-y: auto;
}
.sub-group-member-containers li,
.group-member-containers li {
    list-style: none;
    padding: 2px 0;
}

.your-main-group .sub-group-member-containers .search-bar-inner {
    justify-content: left;
}

.your-main-group .searchpart-div .refine-s-r {
    width: 18%;
}

.your-main-group .groupOptionwidth {
    width: 145px;
    float: left;
}

.searchpart-div .saveResourceListgradeRange {
    padding: 0 0 0 8px;
}

.searchpart-div .saveResourceListgradeRange div.searchWrapper {
    margin-top: 0;
}

.searchpart-div .optionContainer .notFound {
    padding: 0px;
    font-size: 12px;
}

.groupdate {
    background: #FFFFFF;
    border: 0.21px solid #BFBCB8;
    border-radius: 32.5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    height: 31px;
    margin-right: 15px;
    font-size: 12px;
    line-height: 16px;
    color: #2D3134;
    padding: 2px 10px;

}

.groupdate input::placeholder {
    font-weight: 600;
    color: #2D3134;
}

.groupmemberEllips {
    text-align: center;
    cursor: pointer;
}

.your-main-group .group-member-div .groupmemberEllips i {
    margin-left: 0;
    cursor: pointer;
}

.groupmemberEllips,
.groupmemberEllips:hover i {
    height: 23px;
    width: 23px;
    border-radius: 50%;
    line-height: 23px;
}

.groupmemberEllips:hover i {
    position: absolute;
    right: 0px;
    top: 1px;
    background-color: rgba(0, 0, 0, 0.04);
}

.your-main-group .group-mem-div ul li {
    position: relative;
}

.your-main-group .group-member-containersOuter .search-bar-inner {
    justify-content: space-between;
}

.descTextDatainfo p h2,
.descTextDatainfo p h3 {
    text-align: left !important;
    font-family: Inter !important;
    background: transparent !important;

}



.createaddmembers {
    border-left: 1px solid #cdcdcd;
    padding-left: 10px;
    line-height: 19px;
    font-family: 'Inter';
}

.grouptablayout {
    /* border-top: 1px solid #BFBCB8; */
    margin-top: 15px;
    padding-top: 25px;
}

.anncounce img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}



.padding-row .your-main-group .chat-group {
    float: left;
}

.padding-row .your-main-group .chat-group button {
    width: 133px;
    height: 44px;
    background: #F19357;
    border: 1px solid #F19357;
    border-radius: 100px;
    font-family: 'Inter';
    padding: 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #FFFFFF;
    position: relative;
    top: 0px;
}

.searchpart-div .saveResourceListgradeRange {
    margin-right: 0;
}

.searchpart-div .groupdate {
    margin-right: 0;
}

.padding-row .your-main-group .searchpart-div select {
    margin-right: 0;
}

.refine-group {
    display: flex;
    align-items: center;
    border: none;
}

.refine-group button {
    background-color: #F19357;
    border: none;
    padding: 10px;
    border-radius: 50%;
}

.padding-row .your-main-group .friendSelect button {
    margin: 0px 2px;
    border: none;
    border-radius: 50%;
    padding: 8px;
    background-color: #F19357;
    width: 37px;
    float: left;
}

.your-main-group .groupOptionwidth {
    margin-left: 10px;
    margin-right: 0;
}

.sent .sender-name {
    color: gray;
    font-size: 11px;
    line-height: 70%;
}
.your-main-group .sent .message-time {
    line-height: 80%;
    margin-top: 5px;
}
.your-main-group .sent .doc_div {
    width: 100%;
    height: auto;
}

.your-main-group .replies .doc_div_r {
    width: 100%;
    height: auto;
}

.your-main-group .chat-group-innere {
    display: inline-block;
    width: 100%;
    overflow: hidden;
}

.your-main-group .summer-text {
    width: 80%;
}

.your-main-group .SummerCamp {
    position: relative;
}

.your-main-group .SummerCamp .shareicon-img {
    position: absolute;
    right: 20px;
    top: 15px;
}

div.multiSelectContainer input[type="checkbox"] {
    display: inline-block;
}

.text-curriculum11.groupmembers h2.createaddmembers {
    font-weight: 700;
    color: #2D3134;
    font-size: 14px;
    margin-top: 2px;
    line-height: 16px;
}

.side-img {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.group-btn {
    float: left;
}




.group-member-div [id*="open-accordion"],
.group-member-div [id*="close-accordion"] {
    /*
  border-bottom: 1px solid #fff; */
    line-height: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    position: relative;
    width: 99%;
}

.group-member-div [id*="close-accordion"] {
    display: none;
}

.group-member-div .accordion a {
    color: #000;
    text-decoration: none;
    text-shadow: none;
}

.group-member-div [id*="open-accordion"]:after,
.group-member-div [id*="close-accordion"]:after {
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    position: absolute;
    right: 5px;
    top: 15px;
    z-index: 222;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);

}

.group-member-div .target-fix {
    display: block;
    top: 0;
    left: 0;
    position: fixed;
}

.group-member-div .accordion-content {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
}

.group-member-div .accordion span:target~.accordion-content {
    display: block;
    height: auto;
    padding-bottom: 15px;
    /*   padding-top: 10px; */
}

.group-member-div .accordion span:target~[id*="close-accordion"] {
    display: block;
}

.group-member-div .accordion span:target~[id*="open-accordion"] {
    display: none;
}

.group-member-div .accordion span:target~[id*="close-accordion"]:after {
    border-top: 5px solid #333;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.padding-row .Joingroup2_mid_div button.cross_btn {
    width: 37px;
    height: 37px;
    background: #D9D9D9;
    float: right;
    border-radius: 50%;
    color: #5B5F62;
    font-weight: 500;
    text-align: center;
    border: none;
    position: absolute;
    right: 15px;
    top: 15px;
}

.group-search-bar #multiselectContainerReact div.searchWrapper {
    /*  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    overflow: hidden;
    overflow-y: auto;
    height: 23px;
}

.accordion .input-search-div {
    width: 31%;

}

.input-search-div#groupfeedsearchbox {
    margin-top: 0px;
}

.padding-row .your-main-group .chat-group .joins-group {
    margin-right: 70px;
}

.SummerCamp-2 {
    position: relative;
    width: 100%;
}

.unpin {
    cursor: pointer;
    justify-self: flex-end;
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
}

.unpin-div {
    display: flex;
    justify-content: flex-end;
}

.unpin-div img {
    width: 20px;
    height: 20px;
}

.unpin img {
    width: 20px;
    height: 20px;
}

.group-search-bar .multiSelectContainer {
    height: 20px;
}

.inspiration-inner-div {
    position: relative;
}

.inspiration-inner-div .unpin-div {
    position: absolute;
    left: auto;
    right: 40px;
    top: 22px;
}

#long-menu .MuiButtonBase-root {
    display: inline-block;
    padding: 5px 10px;
    width: 100%;
}

.groupmemberthreedots {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: auto;
    left: auto;
}

.serveyour-resource .text-curriculum11 {
    min-height: 35px;
}

.serveyour-resource .reviews {
    float: none;
}

.grouptablayout .MuiTab-wrapper {
    width: 100px !important;
    text-transform: capitalize !important;
}

.group-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.groupJoin .your-main-group .learninghiddenscroll #multiselectContainerReact .searchWrapper{
    overflow-y: hidden;
}
.groupJoin .your-main-group .learninghiddenscroll #multiselectContainerReact .searchWrapper input{
   width: auto;
}

.groupJoin .your-main-group .inspiration-div .SummerCamp .summer-text-description-datas{
    max-height: 200px;
    overflow-y: auto;
}
.yourgroup .your-main-groups .facustomeI .tootlippillerinfo p,
.groupJoin .facustomeI .tootlippillerinfo p{
    color: #000;
    text-align: left;
	font-weight:500;
}
.yourgroup .your-main-groups .inviteGpopupinnersub .facustomeI{
    margin: 17px 5px;
}
.groupJoin .your-main-group .searchpart-div .group-search-bar{
    /* border: none; */
}
.groupJoin .your-main-group .blur-part .borderpartremove{
    border-bottom: none;
}
.groupJoin .your-main-group .chat-box {
    margin-top: 0;
}

.chat-box .chat-message-input{
    display: flex;
    height: auto;
    position: relative;
    width: 100%;
}
.chat-message-input .chat-message-input-pr div.chat-button-area {
    border-left: none;
}


.chat-message-input-pr .textareaautosize{
    padding-right: 80px;
    overflow: hidden;
    padding-top: 6px;
    border-radius: 8px;
	overflow-y: auto;
    max-height: 120px;
}


.chat-message-input .chat-message-input-pr div.areabtnauto {
    top: auto;
    bottom: -7px;
}
.chat-message-input .chatmessageautotext{
position: absolute;
right: 0;
bottom: 5px;
}
.your-main-group .chatManuiocns{
    position: absolute;
    top: 26px;
    width: 96%;
}
.chating-person .chating-all ul li.replies p{
    word-break: break-word;
    }
    
@media screen and (max-width: 1200px) {
    .searchpart-div .saveResourceListgradeRange {
        margin-right: 0;
    }

    .searchpart-div .groupdate {
        margin-right: 0;
    }

    .your-main-group .groupOptionwidth {
        width: 124px;
    }

    .padding-row .your-main-group .chat-group button {
        width: 117px;
        position: relative;
        top: 0px;
        right: 17px;
    }

}



@media screen and (max-width: 767px) {
    .padding-row .your-main-group .saveResourceListgradeRange {
        padding: 0px 0px 0px 8px;
        margin: 0px;
    }

    .padding-row .your-main-group .group-search-bar {
        border-radius: 24px;
        display: inline-block;
        width: 100%;
        flex-wrap: wrap;
    }

    .padding-row .your-main-group .input-search-div {
        width: 100%;
        border-bottom: 1px solid #cdcdcd;
        float: left;
    }

    .padding-row .your-main-group .chat-group .joins-group {
        margin-right: 0px;

    }

}

@media screen and (max-width: 480px) {
    .groupJoin .padding-row .your-main-group .chat-group button {
        top: -12px;
        right: 0;
    }
    .padding-row {
        padding: 10px;
    }

    .your-main-group .slider-page img {
        width: 100%;
        height: 100%;
    }

    .padding-row .your-main-group .chat-group {
        margin-top: 10px;
        margin-right: 0;
        width: 100%;
        text-align: center;

    }

    .padding-row .your-main-group div.chat-group button {
        position: relative;
    }

    .padding-row .your-main-group .input-search-div {
        width: 100%;
        border-bottom: 1px solid #cdcdcd;
        border-right: none;
    }

    .padding-row .your-main-group .chat-group button {
        width: 159px;
    }

    .padding-row .your-main-group .chat-group button.cross_btn {
        width: 37px;
        top: -35px;
        right: -10px;
    }

    .your-main-group .searchpart-div .refine-s-r {
        width: 100%;
    }

    .your-main-group .searchpart-div select {
        width: 100%;
    }

    .padding-row .your-main-group .groupOptionwidth {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;

    }

    .padding-row .your-main-group .input-btn-contain input {
        width: 100%;
        float: left;
    }

    .padding-row .your-main-group .friendSelect button {
        margin: 6px 2px;
    }

    .padding-row .your-main-group .saveResourceListgradeRange {
        padding: 0px 0px 0px 8px;
        margin-top: 10px;
    }

    .descTextDatainfo {
        margin-bottom: 30px;
    }

    .float-end-custome {
        float: none;
        width: 100%;
    }

    .grouptablayout .MuiTab-root {
        /* float: left; */
    }

    .grouptablayout .MuiPaper-root .MuiTabs-flexContainer {
        display: inline-block;
        width: 100%;
    }

    .your-main-group div.comments-div {
        padding: 10px;
    }

    .grouptablayout .MuiTab-root {
        padding: 6px 6px;
    }
}


@media screen and (max-width: 360px) {
    .padding-row .your-main-group .chat-group button {
        width: 152px;
    }

}